import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllCustomRoles = createAsyncThunk(
    'get/custom-roles',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/roles/custom?platform=BUYER`, {
                headers
            })
            if(response.data) {
                return response.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   


export const getDetailCustomRoles = createAsyncThunk(
    'get/one-custom-roles',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/roles/custom/${id}/details`, {
                headers
            })
            if(response.data) {
                return response.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   

export const getAllNavigations = createAsyncThunk(
    'get/navigations-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/navigations/fetch/BUYER?status=true`, {
                headers
            })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.name,
                        sort_number : item.sort_number,
                        childs : item.children
                    })
                }
                let sortAccending = data.sort((a, b) => a.sort_number - b.sort_number)
                return sortAccending
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAllFeatureNavigations = createAsyncThunk(
    'get/feature-navigations-fetch',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/feature/fetchByNavigation/${id}`, {
                headers
            })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.name,
                    })
                }
                return data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAllFeatureDashboard = createAsyncThunk(
    'get/feature-navigations-dashboard',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/feature/fetchByNavigation/61d69b1125585e58dcbdae8a`, {
                headers
            })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.name,
                    })
                }
                return data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const createNewCustomRoles = createAsyncThunk(
    'post/create-new-custom-roles',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/roles/custom`, body, {
                headers
            })
            if(response.data) {
                return true
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateExistingCustomRoles = createAsyncThunk(
    'put/update-custom-roles',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/roles/custom/${params.id}`, params.body, {
                headers
            })
            if(response.data) {
                return true
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);