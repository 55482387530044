import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Stack, Box} from '@mui/material/';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';

const BreadCrumbs = ({ current, page, link, isPage, second_title, slug, name } : any) => {
  const history = useHistory();

  const onClickDetail = (slug : string) => {
    history.push({
      pathname: "/dashboard/products",
      search: `?page=detail-product`, 
      state : {
        slug : slug
      }
    })
  }

  return (
    <Stack >
    <div role="presentation" >
      <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 13 }}>
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
        { 
        isPage ? 
        <Link
          underline="hover"
          color="inherit"
          href={link}
        >
          {page}
        </Link> : null }
        { second_title ? 
        <Box 
          sx={{ cursor: 'pointer' }} 
          onClick={() => onClickDetail(slug)}
        >
          {name}
        </Box>  : null }
        <Typography fontSize={13} color="text.primary">{current}</Typography>
      </Breadcrumbs>
    </div>
    </Stack>
  );
}

export default BreadCrumbs;