import { Grid, Box, Stack, Skeleton, Chip, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import searchimg from '../../../assets/img/icon/search1.png'
import removeimg from '../../../assets/img/icon/close.png'
import { useState, useEffect } from 'react'
import { RootState } from '../../../app/store'
import { getAddressCoverage, getFilterData, getProducts, getProductsLoadMore } from './redux/productReducers'
import FilterArea from './component/FilterArea'
import FilterBrand from './component/FilterBrand'
import MenuCategory from './component/Category'
import { useHistory, useLocation } from 'react-router-dom'
import { getVendorDetail, getVendorWarehouseSchedule } from '../masters/mastersReducers'
import BreadCrumbs from '../../../components/BreadCrumbs'
import VendorBanner from './component/VendorBanner'
import swal from 'sweetalert';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../components/LoaderProgress'
import HeadlineTitle from '../../../components/HeadlineTitle'
import ProductCard from './component/ProductCard'
import ProductCardLoading from './component/ProductCardLoading'
import DataNotFound from '../../../components/DataNotFound'
import ReactHtmlParser from 'react-html-parser';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterMobile from './component/FilterMobile'
import { addLengthInfiniteProduct } from './redux/productSlice'


function ProductVendor() {
    const dispatch = useDispatch()
    const location : any = useLocation()
    const history = useHistory()

    const id_company = location.state === undefined ? null : location.state.id
    const name_company = location.state === undefined ? null : location.state.name

    const { 
        data, total, loading, loaded, 
        coverage, filter_brand,
        loading_filter, loading_coverage,
        filter_category, total_infinite 
    } = useSelector((state: RootState) => state.products)

    const { 
        vendor_detail, schedule_delivery, loading_schedule_delivery
    } = useSelector((state: RootState) => state.masters)


    const [operationalTime, setOperationalTime] = useState<any>([]);

    const [activeTab, setActiveTab] = useState("product");
    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [limit] = useState(20);
    const [skip, setSkip] = useState(0);
    const [hasMore,setHasMore] = useState(true);
    const [sortTopic, setSortTopic] = useState("createdAt");
    const [sortValue, setSortValue] = useState("DESC");
    const [sortBy, setSortBy] = useState("relevant");
 
    const [selectedArea, setSelectedArea] = useState("");
    const [selectedVendor, setSelectedVendor] = useState<any>([]);
    const [selectedBrand, setSelectedBrand] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>([]);

    const [inputSearch, setInputSearch] = useState("");
    const [valueSearch, setValueSearch] = useState("");
    const [openFilter, setOpenFilter] = useState(false);

    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    }

    function onSortBy(e:any) {
        setSortBy(e.target.value)
        if(e.target.value === "relevant") {
            setSortTopic("createdAt")
            setSortValue("DESC")
        } else if(e.target.value === "price-asc") {
            setSortTopic("price")
            setSortValue("ASC")
        } else if(e.target.value === "price-desc") {
            setSortTopic("price")
            setSortValue("DESC")
        }
    }
    

    const isNotEmpty = (obj: any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }
    
    const fetchMoreData = async () => {
        const params = {
            skip : skip + 1,
            limit,
            sortTopic : sortTopic,
            sortValue : sortValue,
            'search' : valueSearch,
            'coverages[]' : selectedArea,
            'company[]' : selectedVendor,
            'brand[]' : selectedBrand,
            'category[]' : selectedCategory
        }
         
        const newdata : any = await dispatch(getProductsLoadMore(params))
        if(newdata.type === "productss-load-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                dispatch(addLengthInfiniteProduct(newdata.payload.data.length))
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };



    useEffect(() => {
        if(selectedVendor.length !== 0) {
            dispatch(getAddressCoverage())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVendor]);

    useEffect(() => {
        const params : any = {
            skip,
            limit,
            sortTopic : sortTopic,
            sortValue : sortValue,
            'search' : valueSearch,
            'coverages[]' : selectedArea,
            'company[]' : selectedVendor,
            'brand[]' : selectedBrand,
            'category[]' : selectedCategory
        } 
        const param = {
            'coverages[]' : selectedArea
        }
        if(selectedArea !== "") {
            dispatch(getProducts(params))
            dispatch(getFilterData(param))
            setFetch(true)
            setHasMore(true)
            setSkip(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueSearch, selectedArea, selectedBrand, selectedCategory, sortTopic, sortValue]);

    useEffect(() => {
        let area = localStorage.getItem('area') || ""
        if(coverage.length) {
            if(area === "") {
                localStorage.setItem('area', JSON.stringify(coverage[0]))
                setSelectedArea(coverage[0].value)
            } else {
                setSelectedArea(JSON.parse(area).value)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverage]);

    function onClickClearFilter() {
        setSelectedVendor([])
        setSelectedBrand([])
        setSelectedCategory([])
        setValueSearch("")
        setInputSearch("")
    }

    useEffect(() => {
        if(id_company && name_company !== null) {
            setSelectedVendor([name_company])
            dispatch(getVendorDetail(id_company))
            dispatch(getVendorWarehouseSchedule(id_company))
        } else {
            history.push('/dashboard/products')
        }
        // eslint-disable-next-line
    }, [id_company, name_company]);

    useEffect(() => {
        if(loaded) {
            setDataInfinite(data)
        }
        // eslint-disable-next-line
    }, [data, loaded]);

    useEffect(() => {
        if(fetch && loaded) {
            if(total_infinite >= total) {
                setHasMore(false)
            }
        }
    }, [fetch, loaded, total_infinite, total]);

    useEffect(() => {
        if(loaded && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, loaded, fetch, total_infinite]);


    useEffect(() => {
        if(isNotEmpty(vendor_detail)){
            if(vendor_detail.operationTime && vendor_detail.operationTime.length !== 0) {
                const result = days.map(day => {
                    const operation = vendor_detail.operationTime.find((op:any) => op.day === day);
                    return operation ? { ...operation, active: true } : { day, active: false  };
                });
                setOperationalTime(result)
            } else {
                const result = days.map(day => {
                    return { day, active: false };
                });
                setOperationalTime(result)
            }
        }
    }, [vendor_detail]);
    
    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivProduct").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    useEffect(() => {
        const debounceSearch = setTimeout(() => {
          setValueSearch(inputSearch);
        }, 500); // set a delay of 500 milliseconds
    
        return () => {
          clearTimeout(debounceSearch);
        };
      }, [inputSearch]);


    return (
        <Stack sx={{m:2}}>
            <BreadCrumbs 
                isPage={true}
                link="/dashboard/products"
                page='Browse Product'
                current="Browse Product Vendor"
            />
            <Box my={2}>
                { isNotEmpty(vendor_detail) && 
                <VendorBanner 
                    profile={vendor_detail}
                /> }
                <Box className='tabs-menu'>
                    { arrayTabs.map((item, index) => (
                        <Box 
                            key={index} className={`item-tab ${activeTab === item.value && 'active'}`}
                            onClick={() => setActiveTab(item.value)}
                        >
                            <h6>{item.name}</h6>
                        </Box>
                    ))}
                </Box>
            </Box>

            { activeTab === "product" ?
            <>
            <Box className='header-search' >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={1.9} sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                        <MenuCategory 
                            data={filter_category}
                            loading={loading_filter}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={10.1}>
                        <form onSubmit={onSearch}>
                        <Box className='search-box'>
                                <input 
                                    placeholder='Search Product'
                                    onChange={(e) => setInputSearch(e.target.value)}
                                    value={inputSearch}
                                />
                                <Box>
                                    { valueSearch === "" ? 
                                    <Box className='notyping'>
                                        <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                                    </Box>
                                    :
                                    <Box className='typing'>
                                        <img 
                                            alt='removeimg' className='removeimg' src={removeimg} 
                                            onClick={() => {
                                                setInputSearch("")
                                                setValueSearch("")
                                            }}
                                        />
                                        <Box className='divider-line'/>
                                        <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                                    </Box> }
                            </Box>
                        </Box>
                        </form>
                    </Grid>
                </Grid>
            </Box>
            <Box className='container-product' >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={2.5} xl={2.5} sx={{ display: {xs: 'none', sm: 'none', md: 'none', lg: 'block' }}}>
                        <Box className='menu-left'>
                            <FilterArea 
                                data={coverage} 
                                loading={loading_coverage} 
                                selectedArea={selectedArea}
                                setSelectedArea={setSelectedArea}
                            />
                            <FilterBrand 
                                data={filter_brand} 
                                loading={loading_filter}
                                selectedBrand={selectedBrand}
                                setSelectedBrand={setSelectedBrand}
                            />
                        </Box>
                        <FilterMobile 
                            open={openFilter} 
                            setOpen={setOpenFilter} 
                            coverage={coverage}
                            loading_coverage={loading_coverage}
                            selectedArea={selectedArea}
                            setSelectedArea={setSelectedArea}
                            filter_brand={filter_brand} 
                            selectedBrand={selectedBrand}
                            setSelectedBrand={setSelectedBrand}
                            vendor={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={9.5} xl={9.5} >
                        { loading ? 
                        <Box className='filter'>
                            <Box>
                                <Skeleton width={250} height={30} />
                            </Box>
                            <Box>
                                <Skeleton height={50} width={100} />
                            </Box>
                        </Box>
                        :
                        <Box className='filter'>
                            { selectedCategory.length !== 0 || selectedBrand.length !== 0 ? 
                            <Box>
                                { selectedCategory.length ? 
                                <Chip 
                                    sx={{m:0.5}}
                                    label={selectedCategory[0]}
                                    color="primary"
                                    variant="outlined"
                                    onDelete={() => setSelectedCategory([])} 
                                /> : null }
                                { selectedBrand.length ?
                                    selectedBrand.map((item:any) => (
                                    <Chip 
                                        key={item}
                                        sx={{m:0.5}}
                                        label={item} 
                                        variant="outlined"
                                        color="primary"
                                        onDelete={() => {
                                            let filter = selectedBrand.filter((i:any) => i !== item)
                                            setSelectedBrand(filter)
                                        }} 
                                    /> 
                                    )) 
                                    : null 
                                }
                                <Chip sx={{m:0.5}} label="Clear All" color="error"  onClick={onClickClearFilter} />
                            </Box>
                            :
                            <Box sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                                <p>Select category for spesific searching.</p> 
                            </Box>
                            } 

                            <Box className='sort'>
                                <h6>Sort By</h6>
                                <Box>
                                    <select name="sortby" id="sortby" onChange={onSortBy} value={sortBy}>
                                        <option value="relevant">Relevant</option>
                                        <option value="price-desc">High Price</option>
                                        <option value="price-asc">Low Price</option>
                                    </select>
                                </Box>
                            </Box>
                            <Box onClick={() => setOpenFilter(true)} className='filter-product' sx={{ display: {sm: 'block', lg: 'none' }}}>
                                <FilterListIcon sx={{ color: 'ActiveBorder' }}/>
                                <p>Filter</p> 
                            </Box>
                        </Box> }
                        <Box className='content'>
                            { loading ? 
                            <ProductCardLoading loader={8} />
                            :
                            <Box>
                            { loaded  && 
                                <div id="scrollableDivProduct"  className="overhiddenflowProduct">
                                    { dataInfinite.length > 0 ? 
                                    <Box mx={0.5}>
                                        <InfiniteScroll
                                            dataLength={dataInfinite.length}
                                            next={fetchMoreData}
                                            hasMore={hasMore}
                                            loader={<LoaderProgress/>}
                                            scrollableTarget="scrollableDivProduct"
                                            endMessage={
                                                <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                                    <Box>
                                                        <HeadlineTitle title="No more products." variant="body2" bold='400' />
                                                    </Box>
                                                    { data.length > 5 && 
                                                    <Box>
                                                        <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                                            <p>Back to Top</p>
                                                        </Button>
                                                    </Box>  }
                                                </Stack>
                                            }
                                        >
                                            <Grid container spacing={2} >
                                                { dataInfinite.map((ele:any, i : any) => (
                                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                                                        <ProductCard item={ele} selectedArea={selectedArea}  />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </InfiniteScroll>
                                    </Box> :
                                    <Box mt={2}>
                                        <DataNotFound 
                                            message="Sorry, we couldn't find any Products for you" 
                                            caption="Try to search another keyword or filter."
                                        />
                                    </Box> }
                                </div> }
                            </Box> }
                        </Box> 
                    </Grid>
                </Grid>
            </Box>
            </>
            :  activeTab === "info" ?
            <Box className='container-info' >
                { isNotEmpty(vendor_detail) && 
                <Box className='content'>
                    {vendor_detail?.description ?
                    <>
                    { ReactHtmlParser(vendor_detail?.description) } 
                    </> : 
                    <Box>
                        <p>No description available</p>
                    </Box>
                    }
                </Box> }
            </Box> 
            : activeTab === "contact_address" ?
            <Box className='container-info' >
                { isNotEmpty(vendor_detail) && 
                <Box className='content-contact-address'>
                    <Box className='contact'>
                        <Box className='title'>
                            <h3>Contact</h3>
                        </Box>
                        <Box className='content-contact'>
                            <Box className='phone'>
                                <h4>Email :</h4>
                                <p>{vendor_detail?.contactUs[0].email}</p>
                            </Box>
                            <Box className='phone'>
                                <h4>Phone :</h4>
                                <p>{vendor_detail?.contactUs[0].phone[0].number}</p>
                            </Box>
                            <Box className='phone'>
                                <h4>Whatsapp :</h4>
                                <p>{vendor_detail?.contactUs[0].phone[0].number}</p>
                            </Box>
                        </Box>
                        <Box className='title'>
                            <h3>Address</h3>
                        </Box>
                        <Box className='content-contact'>
                            { vendor_detail?.address.length ?
                            <Box className='address'>
                                <h4>{vendor_detail?.address[0].label}</h4>
                                <h4>
                                    {vendor_detail?.address[0].street}, {vendor_detail?.address[0].village}, {vendor_detail?.address[0].subdistrict}, {vendor_detail?.address[0].city}, <br/> {vendor_detail?.address[0].country}, {vendor_detail?.address[0].postcode}
                                </h4> 
                            </Box> : 
                            <Box className='phone'>
                                <h4>N/A</h4>
                            </Box> }
                        </Box>
                    </Box>
                </Box> }
            </Box> 
            : activeTab === "operation_time" ?
            <Box className='container-info' >
                { isNotEmpty(vendor_detail) && 
                <Box className='content-contact-address'>
                    <Box className='contact'>
                        <Box className='title'>
                            <h3>Operations Time</h3>
                        </Box>
                        <Box className='content-contact'>
                            {operationalTime?.map((ele:any, i:any) => (
                                <Box className='phone' key={i}>
                                    <h4 style={{width : '100px'}}>{ele.day}</h4>
                                    {ele.active ?
                                    <p>: <strong>{ele.open} AM - {ele.close} PM</strong></p> :
                                    <p>: <strong>Closed</strong></p> }
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box> }
            </Box> 
            :
            <Box className='schedule-info' >
                { isNotEmpty(vendor_detail) && 
                <Box className='content-delivery-times'>
                    { loading_schedule_delivery ? "Loading..." : 
                    <>
                        { schedule_delivery && schedule_delivery.length ? 
                        <Grid container spacing={2}>
                            {schedule_delivery.map((item:any, idx:any) => (
                                <Grid item lg={6} md={12} xs={12}  key={idx}>
                                    <Box className='schedule'>
                                        <Box className='title'>
                                            <h3>Schedule : {item.schedule.name}</h3>
                                            <h5>({item.warehouse})</h5>
                                        </Box>
                                        <Box className='content-schedule'>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={12} xs={12} >
                                                <Box>
                                                    {item.cities && item.cities?.map((ele:any, idxx:any) => (
                                                        <Box key={idxx} className='cities'>
                                                            <h4>{ele}</h4>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6} md={12} xs={12} >
                                                {item.operationTime && item.operationTime.length ? 
                                                <Box>
                                                    {item.operationTime?.map((ele:any, i:any) => (
                                                        <Box className='phone' key={i}>
                                                            <h4 style={{width : '100px'}}>{ele.day}</h4>
                                                            {ele.active ?
                                                            <p>: <strong>{ele.open} AM - {ele.close} PM</strong></p> :
                                                            <p>: <strong>-</strong></p> }
                                                        </Box>
                                                    ))}
                                                </Box> : <p>No delivery times found.</p> }
                                            </Grid>

                                        </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid> :
                        <Box>
                            <p>There is no delivery times setup for this Vendor.</p>
                        </Box>
                            
                        }
                    </> }
                </Box> }
            </Box> 
            }
        </Stack>
    )
}

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const arrayTabs = [
    {
        name: "All Product",
        value: "product",
    },
    {
        name: "Company Information",
        value: "info",
    },
    {
        name: "Contact & Address",
        value: "contact_address",
    },
    {
        name: "Operation Time",
        value: "operation_time",
    },
    {
        name: "Delivery Times",
        value: "delivery_times",
    },
]

export default ProductVendor;
