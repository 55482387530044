import React, {  useState } from 'react'
import { Box } from '@mui/material'
import nexticon from '../../../../assets/img/icon/next.png'
import previcon from '../../../../assets/img/icon/back.png'
import { useHistory } from 'react-router-dom'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ImageSlider : React.FC<any> = ({ images, slug, href, lightbox, selectedArea, thumbnail }) => {

    const history = useHistory()
    const [indexImage, setIndexImage] = useState(0);
    const [openGallery, setOpenGallery] = useState({
        isOpen : false,
        photoIndex : 0
    });

    const onNextSlider = (length:number) => {
        setIndexImage(() => {
            if(indexImage >= length - 1) { 
                return 0
            } else {
                return indexImage + 1
            }
        })
    }

    const onPrevSlider = (length:number) => {
        setIndexImage(() => {
            if(indexImage <= 0) {
                return length - 1
            } else {
                return indexImage - 1
            }
        })
    }

    const onClickDetail = (slug : string, area:string) => {
        history.push({
          pathname: "/dashboard/products",
          search: `?page=detail`, 
          state : {
            slug,
            area
          }
        })
    }

    function openLightbox(index:number) {
        setOpenGallery({...openGallery, isOpen: lightbox, photoIndex: index})
    }

    return ( 
    <div>
        { images.length > 0 && 
        <Box 
            className="image-card-new" 
            onClick={() => {
                if(href) {
                    onClickDetail(slug,selectedArea)
                } 
            }}
        >
            <Box 
                component="img"
                alt='property'
                src={images[indexImage]}
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: 3,
                    cursor: 'pointer',
                    filter: 'brightness(0.7)',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.5s ease-in-out',
                }}
                onClick={() => openLightbox(indexImage)}
            />
        </Box> }
        
        { images.length <= 1 ? null :
        <div>
            <div>
                { thumbnail ? null :
                <Box className='dot-mapping'>
                    { images.map((ele:any, i:any) => (
                        <Box key={i}>
                            { i === indexImage ? 
                            <span className='dot-slider-active' /> :
                            <span className='dot-slider' /> }
                        </Box>
                    ))}
                </Box> }
                <Box 
                    className="next-arrow-slider"
                    onClick={() => onNextSlider(images.length)}
                >
                    <img alt='next' src={nexticon} />
                </Box>
                <Box 
                    className="prev-arrow-slider"
                    onClick={() => onPrevSlider(images.length)}
                >
                    <img alt='prev' src={previcon} />
                </Box>
            </div>
            { thumbnail ? 
            <Box className='thumbnail-container'>
                { images.map((ele:any, i:any) => (
                    <Box 
                        key={i}
                        component="img"
                        alt='property'
                        src={ele}
                        className={`thumb-img ${i === indexImage ? 'active' : ''}`}
                        onClick={() => setIndexImage(i)}
                    />
                ))}
            </Box> : null }
        </div> }
        { openGallery.isOpen && (
            <Box sx={{zIndex: '9999 !important'}}>
            <Lightbox
                mainSrc={images[openGallery.photoIndex]}
                nextSrc={images[(openGallery.photoIndex + 1) % images.length]}
                prevSrc={images[(openGallery.photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setOpenGallery({ ...openGallery, isOpen: false })}
                onMovePrevRequest={() =>
                    setOpenGallery({ ...openGallery,
                        photoIndex: (openGallery.photoIndex + images.length - 1) % images.length
                    })
                }
                onMoveNextRequest={() =>
                    setOpenGallery({ ...openGallery,
                        photoIndex: (openGallery.photoIndex + 1) % images.length
                    })
                }
                mainSrcThumbnail={images[openGallery.photoIndex]}
            />
            </Box>
        )}
    </div>
  )
}

export default ImageSlider