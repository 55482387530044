import crypto from 'crypto-js'; 
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetPasswordAction, signInAction, signInSuperAdminAction, signUpAction  } from './authReducers';
import { IAutSliceState } from '../types';
import { userCredentials } from '../../../utilities/config';
import swal from 'sweetalert';

export const checkAuthorization = (credential : any) => {
  let login = false
  if(credential !== null) {
    login = credential.login
  } 
  return login;
}

export const checkIsRegistered = (credential : any) => {
  let isRegistered = false
  if(credential !== null) {
    isRegistered = credential.isRegistered
  } 
  return isRegistered;
}

const initialState : IAutSliceState = {
  signup : false, 
  loading_signup : false,
  error_signup : null,
  success_signup: null,
  signin : checkAuthorization(userCredentials),
  isRegistered : checkIsRegistered(userCredentials),
  loading_signin : false,
  error_signin : null,
  forgot : false, 
  loading_forgot : false,
  error_page : false,
  error_status : null,
  error_message : null,
}; 



export const errorAction = createAsyncThunk(
  'error',
  async (value : any) => {
      return value
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // signin
      .addCase(signInAction.pending, (state) => {
        state.loading_signin = true;
        state.error_signin = null;
      })
      .addCase(signInAction.fulfilled, (state, action:any) => {
        state.loading_signin = false;
        state.signin = true;
        state.isRegistered = action.payload.isRegistered;
        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(action.payload), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
        localStorage.setItem('secret_credential', saveToLocalStorage)
      })
      .addCase(signInAction.rejected, (state, action : any) => {
        state.loading_signin = false;
        state.error_signin = action.payload;
      })

      // signin super admin
      .addCase(signInSuperAdminAction.pending, (state:any) => {
        state.loading_signin = true;
        state.error_signin = null;
      })
      .addCase(signInSuperAdminAction.fulfilled, (state:any, action:any) => {
        state.loading_signin = false;
        state.signin = true;
        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(action.payload), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
        localStorage.setItem('secret_credential', saveToLocalStorage)
      })
      .addCase(signInSuperAdminAction.rejected, (state:any, action : any) => {
        state.loading_signin = false;
        state.error_signin = action.payload;
      })

      // signin super admin
      .addCase(signUpAction.pending, (state:any) => {
        state.loading_signup = true;
        state.error_signup = null;
        state.success_signup = null;
      })
      .addCase(signUpAction.fulfilled, (state:any, action:any) => {
        state.loading_signup = false;
        state.signup = action.payload.data;
        state.success_signup = "Registration Successful! We've sent link to your email. Please verify your email to login..";
      })
      .addCase(signUpAction.rejected, (state:any, action : any) => {
        state.loading_signup = false;
        state.error_signup = action.payload;
      })

      // signin super admin
      .addCase(resetPasswordAction.pending, (state:any) => {
        state.loading_forgot = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state:any, action:any) => {
        state.forgot = action.payload
        state.loading_forgot = false;
        swal("Success", "We've just sent you an email to reset your password.", "success")
      })
      .addCase(resetPasswordAction.rejected, (state:any, action : any) => {
        state.loading_forgot = false;
        swal('error', `${action.payload}`, 'error')
      })

      // errorr
      .addCase(errorAction.pending, (state:any) => {
        state.error_page = false;
      })
      .addCase(errorAction.fulfilled, (state:any, action:any) => {
        state.error_status = action.payload.status;
        state.error_message = action.payload.message;
        state.error_page = true;
      })
      .addCase(errorAction.rejected, (state:any, action : any) => {
        state.error_page = false;
      })
  },
});


export default authSlice.reducer;
