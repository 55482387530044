import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EmptyData from '../../../../components/EmptyData';
import DialogInstall from './DialogInstall';

const AllExtensions : React.FC<any> = ({data}) => {

    const [viewDialog, setViewDialog] = useState(false);
    const [item, setItem] = useState({});

    return (
    <Box className='extension-container' mt={1}>
        { data.length ? 
        <Grid container spacing={2}>
            { data.filter((elem :any) => elem.isActive).map((item:any, index:number) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                <Box 
                    className='extension-card' 
                    sx={{ border: item.used ? '2px solid #43a047' : '' }}
                    onClick={() => {
                        if(item.used) return null
                        setItem(item)
                        setViewDialog(true)
                    }}
                >
                    <Box className='extension-item' >
                        <Box className='extension-item__image'>
                            <img src={item.logoUrl} alt="rolesimg" />
                        </Box>
                        <Box className='extension-item__name'>
                            <h1>{item.name}</h1>
                            <a href={item.website} target='_blank' rel="noreferrer">{item.website}</a>
                            <h6>{item.description}</h6>
                        </Box>
                        { item.used ? 
                        <Box className='used'>
                            <CheckBoxIcon/>
                        </Box> : null }
                    </Box>
                </Box>
            </Grid>
            ))}
        </Grid> : 
        <Box width="100%">
            <EmptyData 
                message="No Extensions Found"
            />
        </Box>
        }
        <DialogInstall 
            open={viewDialog} 
            setOpen={setViewDialog} 
            item={item}
        />
    </Box> 
  )
}

export default AllExtensions