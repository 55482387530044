import { Stack, Grid, Box, Skeleton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { getStatusCount, getStatusPackageCount } from '../redux/ordersReducers'
import SelectDate from '../../../../components/SelectDate'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const OrderSearch : React.FC<any> = ({
    setValueSearch, valueSearch,
    activeStatus, setActiveStatus,
    setFilterTopic, setFilterValue,
    statusPackage, setStatusPackage,
    setSkip, setRangeTopic,
    setRangeFrom, setRangeTo,
    rangeFrom, rangeTo
}) => {
    const dispatch = useDispatch()
    const { status, loading_status, packages, loading_package } = useSelector((state : RootState) => state.orders)
    const [inputSearch, setInputSearch] = useState("");
    const [showStatus, setShowStatus] = useState(true);

    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    }

    function onChangeStatus (name:string) {
        setActiveStatus(name)
        setSkip(0)
        if(name === "Active") {
            setFilterTopic(["lastStatus", "packages._id"])
            setFilterValue([name, "New"])
            setStatusPackage("New")
        } else {
            setFilterTopic(["lastStatus"])
            setFilterValue([name])
        }
    }

    function onChangeStatusPackage (name:string) {
        setStatusPackage(name)
        if(name === "All") {
            setFilterTopic(["lastStatus"])
            setFilterValue(["Active"])
        } else {
            setFilterTopic(["lastStatus", "packages._id"])
            setFilterValue(["Active", name])
        }
    }

    const getDataCount = (name:string) => {
        if(name !== "Completed") {
            const data = status.filter((item:any) => item._id === name)
            return data.length ? <span className="notify-badge">{data.length ? data[0].count : 0}</span> : null
        }
    }

    const getDataCountPackage = (name:string) => {
        if(name === "All") {
            return <span className="notify-badge">{packages.length ? packages.reduce((a:any, b:any) => a + b.count, 0) : 0}</span>
        } else {
            if(name !== "Canceled") {
                const data = packages.filter((item:any) => item._id === name)
                return data.length ? <span className="notify-badge">{data.length ? data[0].count : 0}</span> : null
            }
        }
    }

    useEffect(() => {
        let params = {
            from : rangeFrom.length ? moment(rangeFrom).format("YYYY-MM-DD") : '',
            to : rangeTo.length ? moment(rangeTo).format("YYYY-MM-DD") : '',
        }
        dispatch(getStatusCount(params))
        dispatch(getStatusPackageCount(params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeFrom, rangeTo]);

    return (
    <Stack className="container-search">
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={9}>
                <form onSubmit={onSearch}>
                <Box className='search-box'>
                        <input 
                            placeholder={`Search vendor, order code ...`}
                            onChange={(e) => setInputSearch(e.target.value)}
                            value={inputSearch}
                        />
                        <Box>
                            { valueSearch === "" ? 
                            <Box className='notyping'>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box>
                            :
                            <Box className='typing'>
                                <img 
                                    alt='removeimg' className='removeimg' src={removeimg} 
                                    onClick={() => {
                                        setInputSearch("")
                                        setValueSearch("")
                                    }}
                                />
                                <Box className='divider-line'/>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box> }
                    </Box>
                </Box>
                </form>
            </Grid>
            <Grid item xs={12} md={12} lg={3}  sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                <SelectDate 
                    setRangeTopic={setRangeTopic}
                    setRangeFrom={setRangeFrom}
                    setRangeTo={setRangeTo}
                    type='date'
                />
            </Grid>
        </Grid>
       
        <Stack className='status'>
            <Box className='headline-status'>
                <h5>Status</h5>
                <div 
                    className='icon-arrow'
                    onClick={() => setShowStatus(!showStatus)}
                >
                   {  showStatus ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                </div>
            </Box>

            <Box sx={{display: showStatus ? 'block' : 'none !important'}}>
                { loading_status ? 
                <Box className='list-status'>
                    { order_status.map((_, index:number) => (
                        <Box className='container-status' key={index}>
                            <Box className='status-box'>
                                <Skeleton height={25} width={70} sx={{m:0}} />
                            </Box>
                        </Box>
                    )) }
                </Box> :
                <Box className='list-status'>
                    { order_status.map((item:any, index:number) => (
                        <Box className='container-status' key={index}> 
                            {getDataCount(item.value)}
                            <Box 
                                className={activeStatus === item.value ? 'status-box active' : 'status-box'}
                                onClick={() => onChangeStatus(item.value)}
                            >
                                <h6>{item.label}</h6>
                            </Box>
                        </Box>
                    )) }
                </Box> }
            </Box>
        </Stack> 

        { activeStatus === "Active" && 
        <Stack className='status-package' sx={{display: showStatus ? 'block' : 'none !important'}}>
            <Box></Box>
            { loading_package ? 
            <Box className='list-status'>
                { array_tabs.map((_, index:number) => (
                    <Box className='container-status' key={index}>
                        <Box   className='status-box'>
                            <Skeleton height={23} width={72} sx={{m:0}} />
                        </Box>
                    </Box>
                )) }
            </Box>:
            <Box className='list-status'>
                { array_tabs.map((item:any, index:number) => (
                    <Box className='container-status' key={index}>
                        {getDataCountPackage(item.value)}
                        <Box 
                            className={statusPackage === item.value ? 'status-box active' : 'status-box'}
                            onClick={() => onChangeStatusPackage(item.value)}
                        >
                            <h6>{item.label}</h6>
                        </Box>
                    </Box>
                )) }
            </Box> }
        </Stack> } 
    </Stack>
  )
}

const order_status = [
    { label: "Waiting DP", value: "Waiting Down Payment" },
    { label: "On Going", value: "Active" },
    { label: "Completed", value: "Completed" },
]

const array_tabs = [
    { label: "All", value: "All" },
    { label: "New", value: "New" },
    { label: "Pick & Pack", value: "Pick" },
    { label: "Ready to Ship", value: "Ready to Ship" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
    // { label: "Completed", value: "Completed" },
    { label: "Canceled", value: "Cancel" },
]



export default OrderSearch