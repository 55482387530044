import { Box } from '@mui/material'
import React, { useState } from 'react'
import StoreIcon from '@mui/icons-material/Store';
import ViewNote from '../../../../components/ViewNote';

const ItemCard : React.FC<any> = ({ item, index }) => {

    function sumTotal (data : any, name : string) {
        const price = data.reduce((total : any, itm : any) => {
          return total + itm[name]
        }, 0)
        return parseInt(price);
    } 

    function sumTotalDownPayment (data : any) {
        const price = data.reduce((total : any, itm : any) => {
          return total + (itm.down_payment / 100 * itm.total)
        }, 0)
        return parseInt(price);
    } 

    const [openNote, setOpenNote] = useState({
        open : false, 
        note : ""
    });

    return (
    <div key={index}>
        <Box className="cart-list-detail">
            <Box className='container-company'>
                <Box className="item-company">
                    <StoreIcon className='icon-store' />
                    <Box>
                        <h2>{item[1][0].vendor.name}</h2>
                        <p>{item[1][0].warehouse.city.toLowerCase()}</p>
                    </Box>
                </Box>
                <Box 
                    className='view-note' 
                    onClick={() => setOpenNote({open : true, note : item[1][0].note || "N/A"})}
                >
                    <p>View Note</p>
                </Box>
            </Box>
            { item[1].map((ele : any, idx : number) => (
                <ProductItem ele={ele} idx={idx} key={idx} />
            ))}
            <Box className='bottom-content-vendor'>
                <Box className='total-dp'>
                    <h2>Down Payment Rp.{sumTotalDownPayment(item[1]).toLocaleString() || "N/A"}</h2>
                </Box>
                <Box className='line-divide' />
                <Box className='total-tax'>
                    <h2>PPN Rp.{sumTotal(item[1], 'total_tax').toLocaleString() || "N/A"}</h2>
                </Box>
                <Box className='line-divide' />
                <Box className='total-prices'>
                    <h2>Total : Rp.{(sumTotal(item[1], 'total') - sumTotal(item[1], 'total_tax')).toLocaleString() || "N/A"}</h2>
                </Box>
            </Box>
        </Box>
        <Box className="line-divider-bottom" />
        <ViewNote open={openNote} setOpen={setOpenNote} />
    </div>
  )
}

const ProductItem : React.FC<any> = ({ele, idx}) => {
    
    return (
        <Box className='item-product' key={idx}>
            <Box 
                component="img"
                src={ele.images[0]}
            />
            <Box className='right-product'>
                <Box className='title-product'>
                    <h2>{ele.name}</h2>
                </Box>
                { ele.discount_type === "PERCENTAGE" && ele.discount !== 0  ?  
                <Box className='price'>
                    { ele.extraDiscountType === "AMOUNT" ? 
                    <Box sx={{display:'flex'}}>
                        <Box className='discount'>
                            <h3>{ele.discount}%</h3> 
                        </Box> 
                        <Box mx={0.5}>&</Box>
                        <Box className='discount-amount'>
                            <h3>- Rp.{ele.extraDiscount.toLocaleString()}</h3>
                        </Box> 
                    </Box> :
                    <Box className='discount'>
                        <h3>{ele.discount + ele.extraDiscount}%</h3> 
                    </Box> }
                    <Box className='title-discount'>
                        <h2>Rp. {ele.retail_price.toLocaleString() || "N/A"}</h2>
                    </Box>
                    { ele.extraDiscountType === "AMOUNT" ? 
                    <Box className='title-price'>
                        <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount) / 100)) - ele.extraDiscount).toLocaleString()}</h1> 
                    </Box> :
                    <Box className='title-price'>
                        <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount + ele.extraDiscount) / 100))).toLocaleString()}</h1> 
                    </Box> }
                </Box> 
                :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                <Box className='price'>
                    <Box className='discount-amount'>
                    <h3>- Rp.{(ele.discount + ele.extraDiscount).toLocaleString()}</h3>
                    </Box> 
                    <Box className='title-discount'>
                        <h2>Rp.{ele.retail_price.toLocaleString()}</h2>
                    </Box>
                    <Box className='title-price'>
                    <h1>Rp.{(ele.retail_price - (ele.discount + ele.extraDiscount)).toLocaleString() || "N/A"}</h1>
                    </Box>
                </Box>  
                :  ele.discount_type === "SPECIAL" ? 
                <Box className='price'>
                    <Box className='discount'>
                        <h3>SPECIAL</h3>
                    </Box> 
                    {/* <Box className='title-discount'>
                        <h2>Rp.{ele.retail_price.toLocaleString()}</h2>
                    </Box> */}
                    <Box pl={1} className='title-price'>
                        <h1>Rp.{ele.discount.toLocaleString() || "N/A"}</h1> 
                    </Box>
                </Box> :
                <Box className='price'>
                    <Box className='title-price'>
                        <h1>Rp.{ele.retail_price.toLocaleString() || "N/A"}</h1> 
                    </Box>
                </Box> }
                <Box className='tax'>
                    <p>+ PPN {ele.tax.label}</p>
                </Box>
                <Box className='bottom-content'>
                    <Box className='total-tax'>
                        <h2>DP {ele.down_payment}%</h2>
                    </Box>
                    <Box className='line-divide' />
                    <Box className='total-tax'>
                        <h2>PayTerm {ele.payment_term} days</h2>
                    </Box>
                    <Box className='line-divide' />
                    { ele.total_tax === 0 ? null : 
                    <>
                    <Box className='total-tax'>
                        <h2>PPN Rp.{ele.total_tax.toLocaleString() || "N/A"}</h2>
                    </Box>
                    <Box className='line-divide' /> 
                    </>
                    }
                    <Box className='total-tax'>
                        <h2>{ele.quantity.toLocaleString() || "N/A"} {ele.unit || "N/A"}</h2>
                    </Box>
                    <Box className='line-divide' />
                    <Box className='total-prices'>
                        <h2>Rp.{(ele.total - ele.total_tax).toLocaleString() || "N/A"}</h2>
                    </Box> 
                </Box>
            </Box>
        </Box>
    )
}

export default ItemCard