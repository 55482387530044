import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAddressProvince = createAsyncThunk(
    'get/address-province',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/province?country=Indonesia`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAddressCity = createAsyncThunk(
    'get/address-city',
    async (name : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/city?province=${name}`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAddressDistrict = createAsyncThunk(
    'get/address-district',
    async (name : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/district?city=${name}`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAddressVillage = createAsyncThunk(
    'get/address-village',
    async (name : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/village?district=${name}`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getMasterWarehouse = createAsyncThunk(
    'get/warehouse-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/warehouse/fetch/specification`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.name,
                    })
                }
                return data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getMasterTax = createAsyncThunk(
    'get/tax-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/tax`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.label,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getMasterBank = createAsyncThunk(
    'get/bank-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank/fetch`, { headers })
            if(response.data) {
                let data = []
                for(let element of response.data) {
                    data.push({
                        value: element.code, 
                        name : element.name_code, 
                        label: element.name, 
                        id: element._id, 
                        iconUrl : element.imageUrl 
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getVendorDetail = createAsyncThunk(
    'get/vendor-detail',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/company/get-one/${id}`, { headers })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getVendorWarehouseSchedule = createAsyncThunk(
    'get/vendor-schedule',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/warehouse/${id}/by-company`, { headers })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getEmailMembers = createAsyncThunk(
    'get/email-members',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user/team/${id}/email`, { headers })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getDepartmentByUser = createAsyncThunk(
    'get/department-by-user',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/department/fetch`, { headers })
            if(response.data) {
                let data = []
                for(let element of response.data) {
                    data.push({
                        value: element.id, 
                        label: element.name, 
                    })
                }
                return data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getCategoryTree = createAsyncThunk(
    'get/category-tree',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category-product/tree`, { headers })
            if(response.data) {
                const data = response.data.map((item : any, idx:number) => (
                    {
                        _idx : idx,
                        name : item.name,
                        child: item.child
                    }
                ))
                return data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

