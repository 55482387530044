import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import store from '../../../../assets/img/icon/store.png'
import ChatButton from '../../../../components/ChatButton';
import DialogDetail from './DialogDetail';
import { useHistory } from 'react-router-dom';


const CardPreferred : React.FC<any> = ({ item }) =>  {
    const history = useHistory()
    const [dialogDetail, setDialogDetail] = useState(false);
    const [dialogName, setDialogName] = useState("");

    function getStyle (name:string) {
        switch (name) {
            case 'ACTIVE':
                return {  backgroundColor : '#43a047' }
            case 'REQUESTED':
                return {  backgroundColor : '#fb8500' }
        }
    }

    function amountCreditUsed(params:any) {
        let increase = params.filter((item:any) => item.type === "INCREASE")
        let decrease = params.filter((item:any) => item.type === "DECREASE")
        let totalIncrease = increase.reduce((a:any, b:any) => a + b.amount, 0)
        let totalDecrease = decrease.reduce((a:any, b:any) => a + b.amount, 0)
        return (totalIncrease - totalDecrease).toLocaleString()
    }


    const onClickProductVendor = () => {
        history.push({
            pathname: "/dashboard/products",
            search: `?page=vendor`, 
            state: { 
                id : item.company._id,
                name : item.company.name,
            }
        })
    }

    return (
    <div className={`container-item-preferred ${item.status === "REQUESTED" && 'requested'}`}>
        <Box className="top-div">
            <Box className='left'>
                <h5>Company Code : {item.company.code}</h5>
            </Box>
            <Box className='right'> 
                <Box className='status' sx={getStyle(item.status)}>
                    <h6>{item.status}</h6>
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={6} xl={6}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.company.logo || store} alt="store" onClick={() => onClickProductVendor()} />
                            <Box className='name'>
                                <h2 onClick={() => onClickProductVendor()}>{item.company.legalName}</h2>
                                <Box mt={0.5} className='chat'>
                                    <ChatButton vendor_detail={item.company} />
                                </Box>
                            </Box>
                        </Box>
                        <Box className='address'>
                            <h2>{(item.company && item.company.address.label) || "-"}</h2> 
                            <p>{(item.company && item.company.address.street) || "-"}, {(item.company && item.company.address.city.toLowerCase()) || "-"}</p>
                            <p>{(item.company && item.company.address.province) || "-"}, {(item.company && item.company.address.country) || ""}, {(item.company && item.preferred.address.postcode) || "-"}</p>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={12} lg={6} xl={6}>
                    <Box className='right-box'>
                        <Box className='total-discount'>
                            <p>Discount Applied</p>
                            <h3>{item.initial_discount}%</h3>
                        </Box>
                        <Box className='total-discount'>
                            <p>Payment Term</p>
                            <h3>{item.payment_term.label}</h3>
                        </Box>
                        <Box className='total-discount'>
                            <p>Down Payment</p>
                            <h3>{item.down_payment || "0"}%</h3>
                        </Box>
                        <Box className='total-discount'>
                            <p>Minimum Total Order</p>
                            { item.minimum_total_order && item.minimum_total_order.isActive ?  
                            <h3>: Rp. {item.minimum_total_order ? item.minimum_total_order.amount.toLocaleString() : "0"}</h3> :
                            <h3>: Not Active</h3> }
                        </Box>
                        <Box className='total-discount'>
                            <p>Credit Limit</p>
                            { item.credit_limit && item.credit_limit.isActive ?  
                            <h3>: Rp. {item.credit_limit ? item.credit_limit.amount.toLocaleString() : "0"}</h3> :
                            <h3>: Not Active</h3> }
                        </Box>
                        { item.credit_limit && item.credit_limit.isActive ?  
                        <Box className='total-discount'>
                            <p>Credit Used</p>
                            <h3>: Rp. {amountCreditUsed((item.historyLimitCredit && item.historyLimitCredit) || [])}</h3> 
                        </Box> : null }
                    </Box>
                </Grid>
            </Grid> 
        </Box>
        { item.status !== "REQUESTED" && 
        <Box className='bottom-div'>
            <Box className='left-div' />
            <Box className='right-div'>
                <Box className='btn-confirm' >
                    <button 
                        onClick={() => {
                            setDialogDetail(true)
                            setDialogName("Detail Product Discount")
                        }}
                    >
                        <p>View Product Discount</p>
                    </button>
                </Box>
            </Box>
        </Box> }
        <DialogDetail  
            open={dialogDetail} 
            setOpen={setDialogDetail} 
            name={dialogName} 
            detail={item}
        />

    </div>
  )
}

export default CardPreferred