import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import ItemPurchase from './ItemPurchase';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import HoverInfoPurchase from './HoverInfoPurchase';
import { addItemToPurchase } from '../../purchase_request/redux/purchasesReducers';
import { additemToTempalte } from '../../template/redux/templateReducers';
import { clearStateCalculte } from '../redux/productSlice';

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogPurchase : React.FC<any> = ({ detail, setDetail }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { item, loading_item, type_error } = useSelector((state: RootState) => state.products)
    const { purchase_open, loading_purchase_open, purchase_item } = useSelector((state: RootState) => state.purchases)
    const { template_open, loading_template_open, template_item,  } = useSelector((state: RootState) => state.templates)

    const handleClose = () => {
      dispatch(clearStateCalculte())
      setDetail({
          ...detail,
          open : false
      })
    }


    const onClickCreatePurchase = async () => {
      if(detail.type === "Purchase Request") {
        try {
          const add_purchases = await db.collection('purchase_request').set([item])
          if(add_purchases) {
            localStorage.removeItem('saved')
            history.push('/dashboard/purchase-requests?page=create')
          }
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
      } else {
        try {
          const add_template = await db.collection('template').set([item])
          if(add_template) {
            localStorage.removeItem('edited')
            history.push('/dashboard/templates?page=create')
          }
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
      }
    }

    const onClickAddExisting = (id:string) => {
      if(detail.type === "Purchase Request") {
        let data = {
          id : id,
          param : item
        }
        dispatch(addItemToPurchase(data))
      } else {
        let data = {
          id : id,
          param : item
        }
        dispatch(additemToTempalte(data))
      }
    }

    React.useEffect(() => {
      if(purchase_item || template_item) {
        handleClose()
      }
      // eslint-disable-next-line
    }, [purchase_item, template_item]);
    
    return (  
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={detail.open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Add Item to {detail.type}</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >

          <Grid container> 
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {/* { loading_item ?
              <LoadingCard />
              :  */}
              <Box className="product-dialog-add">
                { !item ? "Product Stock is empty" :
                  <ItemPurchase loading_item={loading_item}/>
                }
              </Box>
              
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              { loading_purchase_open || loading_template_open ?
              <LoadingCard />
              : 
              <>
              <Box className="open-list-purchase">
                <h2>Add to {detail.type} List</h2>
                { detail.type === 'Purchase Request' ?
                <Box className="list">
                  { purchase_open.length > 0 ?
                  <>
                  { purchase_open.map((item: any, index: number) => (
                    <Box key={index} onClick={() => onClickAddExisting(item._id)}>
                      <HoverInfoPurchase item={item} />
                    </Box>
                  ))}
                  </>
                  :
                  <Box className="empty">
                    <h5>{detail.type} empty!</h5>
                  </Box> }
                </Box>
                :
                <Box className="list">
                  { template_open.length > 0 ?
                  <>
                  { template_open.map((item: any, index: number) => (
                    <Box key={index} onClick={() => onClickAddExisting(item._id)}>
                      <HoverInfoPurchase item={item} />
                    </Box>
                  ))}
                  </>
                  :
                  <Box className="empty">
                    <h5>{detail.type} empty!</h5>
                  </Box> }
                </Box> }
              </Box>
              <Box className="center-or">
                <h1>or</h1>
              </Box>
              <Box className="create-new">
                <Box className="button-cards">
                  { !loading_item || type_error === "minimum" || type_error === "stock" ? 
                  <Box className="pr" onClick={onClickCreatePurchase} >
                    Create New {detail.type}
                  </Box> :
                  <Box className="pr" sx={{ backgroundColor: '#757575 !important', color: '#ddd !important', cursor: 'not-allowed !important' }} >
                    Create New {detail.type}
                  </Box> }
                </Box> 
              </Box>
              </> }
            </Grid>
          </Grid>
          {/* // : 
          // <Box minHeight={200}>
          //   <p>Product Stock is empty.</p>
          // </Box> */}
          
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

const LoadingCard = () => {
    return (
        <Box className="box-Skeleton">
          <Skeleton height={40} />
          <Skeleton height={50} />
          <Skeleton height={40} />
          <Skeleton height={50} />
          <Skeleton height={40} />
        </Box>
    )
  }
export default DialogPurchase;