import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Stack, TextField } from '@mui/material';
import ButtonCreate from '../../../../components/ButtonCreate';
import ModuleItems from './ModuleItems';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import swal from 'sweetalert';
import { createNewCustomRoles, getDetailCustomRoles, updateExistingCustomRoles } from '../redux/rolesReducers';
import StarRequired from '../../../../components/StarRequired';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose} 
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const DialogFormRoles : React.FC<any> = ({ 
  open, setOpen, 
  moduleItems, setModuleItems,
  type, roleName, setRoleName, IdRole
 }) => {

    const dispatch = useDispatch()
    const { detail, loading_detail } = useSelector((state : RootState) => state.roles)

    function handleClose () {
        setOpen(false);
    };
  
    const onSubmit = (e:any) => {
      e.preventDefault()
      if(moduleItems.length <= 1) {
          swal('Error', "Please at least add one more navigation!", "error")
      } else {
          let module_array : any = [...moduleItems]
          let array_send = []
          for(let element of module_array) {
            if(element.selectedParent.value !== undefined) {
              let array_childs = []
              let array_features = []
              if(element.selectedChild.length !== 0) {
                  for(let i of element.selectedChild) {
                      array_childs.push(i.value)
                  }
              }
              if(element.selectedFeatures.length !== 0) {
                  for(let i of element.selectedFeatures) {
                      array_features.push(i.value)
                  }
              }
              array_send.push({
                  navigation: element.selectedParent.value,
                  features: array_features,
                  child : array_childs,
              })
            }
          }
          if(type === "Add") {
              let body_send = {
                  name : roleName,
                  default: false,
                  navigation_roles : array_send,
                  platform : "BUYER",
                  
              }
              dispatch(createNewCustomRoles(body_send))
          } else {
              let body_update = {
                  body : {
                      name : roleName,
                      default: false,
                      navigation_roles : array_send,
                      platform : "BUYER"
                  },
                  id : IdRole
              }
              dispatch(updateExistingCustomRoles(body_update))
          }
      }
    }

    function isNotEmpty(obj:any) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return true;
      }
      return false;
    }


    const proceedOptionsFeatures = (data : any) => {
      let data_options = []
      for(let element of data) {
          data_options.push({ value: element._id, label: element.name })
      }
      return data_options
    }

    const proceedOptionsChild = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsParent = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name, childs : element.childs })
        }
        return data_options
    }

    const onUpdateRoles = (row:any) => {
      let array_modules = []
      for(let element of row) {
          array_modules.push({
              selectedParent : { value : element.navigation._id, label: element.navigation.name  },
              selectedChild : element.child ? proceedOptionsChild(element.child) : [],
              selectedFeatures: proceedOptionsFeatures(element.features),
              optionFeatures : proceedOptionsParent(element.features_sibling),
              optionChild : proceedOptionsParent(element.child_sibling),
              has_child_module : element.child.length > 0 ? true : false,
              mandatory: true,
          })
      }
      setModuleItems(array_modules)
      
    }

    useEffect(() => {
      if(open && IdRole !== "" && type === "Edit") {
        dispatch(getDetailCustomRoles(IdRole))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, IdRole, type]);

    useEffect(() => {
      if(isNotEmpty(detail)) {
        onUpdateRoles(detail.hasNavigation)
        setRoleName(detail.name)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);
  
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{type === "Add" ? "Add New" : "Edit"} Custom Role</Box>
           </Stack>
        </BootstrapDialogTitle>
        <form onSubmit={onSubmit}>
        <DialogContent dividers >
          { loading_detail ?
          <LoaderView />
          :
          <Box className="create-roles">
            <Box pt={1}><h4>Role Name <StarRequired/></h4></Box>
            <TextField
              margin="dense"
              fullWidth
              id="name"
              placeholder="Warehouse Manager"
              name="name"
              size='small'
              onChange={(e) => setRoleName(e.target.value)}
              value={roleName}
              required
            />
            <Box mt={2}>
              <ModuleItems 
                moduleItems={moduleItems}
                setModuleItems={setModuleItems}
                type={type}
              />
            </Box>
          </Box> }
        </DialogContent>
        <DialogActions>
          <ButtonCreate 
            name={type === "Add" ? "Submit Role" : "Save Role"}
            type="submit"
          />
        </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}


const LoaderView = () => {
  return (
    <div>
      <Skeleton variant='text' height={75} />
      <Box my={0.5} />
      <Skeleton variant='text' height={60} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
    </div>
  )
}

export default DialogFormRoles;