import { createSlice } from '@reduxjs/toolkit';
import { getAddressCity, getAddressDistrict, getAddressProvince, getAddressVillage, getCategoryTree, getDepartmentByUser, getEmailMembers, getMasterBank, getMasterTax, getMasterWarehouse, getVendorDetail, getVendorWarehouseSchedule } from './mastersReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  province : [],
  loading_province : false,
  city : [],
  loading_city : false,
  district : [],
  loading_district : false,
  village : [],
  loading_village : false,
  warehouse : [],
  loading_warehouse : false,
  tax : [],
  loading_tax : false,
  setting_platform : {},
  loading_setting_platform : false,
  bank : [],
  loading_bank : false,
  vendor_detail : {},
  loading_vendor_detail : true,
  emails : [],
  loading_emails : false,
  departments : [],
  loading_departments : false,
  category : [],
  loading_category : false,
  schedule_delivery : [],
  loading_schedule_delivery : false,
};

export const getMasterReducers = createSlice({
  name: 'masters',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all province
      .addCase(getAddressProvince.pending, (state) => {
        state.loading_province = true;
      })
      .addCase(getAddressProvince.fulfilled, (state, action:any) => {
        state.loading_province = false;
        state.province = action.payload;
      })
      .addCase(getAddressProvince.rejected, (state, action : any) => {
        state.loading_province = false;
        errorSweetAlert(action.payload)
      })

      // get all city
      .addCase(getAddressCity.pending, (state) => {
        state.loading_city = true;
      })
      .addCase(getAddressCity.fulfilled, (state, action:any) => {
        state.loading_city = false;
        state.city = action.payload;
      })
      .addCase(getAddressCity.rejected, (state, action : any) => {
        state.loading_city = false;
        errorSweetAlert(action.payload)
      })

      // get all district
      .addCase(getAddressDistrict.pending, (state) => {
        state.loading_district = true;
      })
      .addCase(getAddressDistrict.fulfilled, (state, action:any) => {
        state.loading_district = false;
        state.district = action.payload;
      })
      .addCase(getAddressDistrict.rejected, (state, action : any) => {
        state.loading_district = false;
        errorSweetAlert(action.payload)
      })

      // get all village
      .addCase(getAddressVillage.pending, (state) => {
        state.loading_village = true;
      })
      .addCase(getAddressVillage.fulfilled, (state, action:any) => {
        state.loading_village = false;
        state.village = action.payload;
      })
      .addCase(getAddressVillage.rejected, (state, action : any) => {
        state.loading_village = false;
        errorSweetAlert(action.payload)
      })

      // get all master warehouse
      .addCase(getMasterWarehouse.pending, (state) => {
        state.loading_warehouse = true;
      })
      .addCase(getMasterWarehouse.fulfilled, (state, action:any) => {
        state.loading_warehouse = false;
        state.warehouse = action.payload;
      })
      .addCase(getMasterWarehouse.rejected, (state, action : any) => {
        state.loading_warehouse = false;
        errorSweetAlert(action.payload)
      })

      // get all master tax
      .addCase(getMasterTax.pending, (state) => {
        state.loading_tax = true;
      })
      .addCase(getMasterTax.fulfilled, (state, action:any) => {
        state.loading_tax = false;
        state.tax = action.payload;
      })
      .addCase(getMasterTax.rejected, (state, action : any) => {
        state.loading_tax = false;
        errorSweetAlert(action.payload)
      })

      // get all bank tax
      .addCase(getMasterBank.pending, (state) => {
        state.loading_bank = true;
      })
      .addCase(getMasterBank.fulfilled, (state, action:any) => {
        state.loading_bank = false;
        state.bank = action.payload;
      })
      .addCase(getMasterBank.rejected, (state, action : any) => {
        state.loading_bank = false;
        errorSweetAlert(action.payload)
      })

      // get detail vendor
      .addCase(getVendorDetail.pending, (state) => {
        state.loading_vendor_detail = true;
      })
      .addCase(getVendorDetail.fulfilled, (state, action:any) => {
        state.loading_vendor_detail = false;
        state.vendor_detail = action.payload;
      })
      .addCase(getVendorDetail.rejected, (state, action : any) => {
        state.loading_vendor_detail = false;
        errorSweetAlert(action.payload)
      })

      // get detail warehouse schedule delivery
      .addCase(getVendorWarehouseSchedule.pending, (state) => {
        state.loading_schedule_delivery = true;
      })
      .addCase(getVendorWarehouseSchedule.fulfilled, (state, action:any) => {
        state.loading_schedule_delivery = false;
        state.schedule_delivery = action.payload;
      })
      .addCase(getVendorWarehouseSchedule.rejected, (state, action : any) => {
        state.loading_schedule_delivery = false;
        errorSweetAlert(action.payload)
      })

      // get email members
      .addCase(getEmailMembers.pending, (state) => {
        state.loading_emails = true;
      })
      .addCase(getEmailMembers.fulfilled, (state, action:any) => {
        state.loading_emails = false;
        state.emails = action.payload;
      })
      .addCase(getEmailMembers.rejected, (state, action : any) => {
        state.loading_emails = false;
        errorSweetAlert(action.payload)
      })

      // get all department
      .addCase(getDepartmentByUser.pending, (state) => {
        state.loading_departments = true;
      })
      .addCase(getDepartmentByUser.fulfilled, (state, action:any) => {
        state.loading_departments = false;
        state.departments = action.payload;
      })
      .addCase(getDepartmentByUser.rejected, (state, action : any) => {
        state.loading_departments = false;
        errorSweetAlert(action.payload)
      })

      // get all category
      .addCase(getCategoryTree.pending, (state) => {
        state.loading_category = true;
      })
      .addCase(getCategoryTree.fulfilled, (state, action:any) => {
        state.loading_category = false;
        state.category = action.payload;
      })
      .addCase(getCategoryTree.rejected, (state, action : any) => {
        state.loading_category = false;
        errorSweetAlert(action.payload)
      })
  },
});


export default getMasterReducers.reducer;
