import { Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import calender from '../../../../assets/img/icon/calendar.png'
import store from '../../../../assets/img/icon/store.png'
import { RootState } from '../../../../app/store'
import { printDownloadPDF, } from '../redux/ordersReducers'
import {  updateValueAlert } from '../redux/ordersSlice'
import DialogPayment from './DialogPayment'
import ChatButton from '../../../../components/ChatButton'
import DownloadPdf from '../../../../components/DownloadPdf'
import HistoryStatus from '../../purchase_request/components/HistoryStatus'
import more from '../../../../assets/img/icon/more.png'
import DialogNote from './DialogNote'
import PreferredButton from '../../../../components/PreferredButton'
import { printDownloadInvPDF } from '../../finance/invoices/redux/invoicesReducers'
import { useHistory } from 'react-router-dom'

const OrderCard : React.FC<any> = ({ 
    item, statusPackage, onClickDetailPackage
}) =>  {
    const dispatch = useDispatch()
    const history = useHistory()


    const { proof_payment } = useSelector((state : RootState) => state.orders)

    const [openProof, setOpenProof] = useState(false);
    const [fileUrl, setFileUrl] = React.useState({
        name : '',
        url : '',
        type : 'upload'
    });
    const [openStatus, setOpenStatus] = useState({
        open: false,
        data : []
    });
    const [openNote, setOpenNote] = useState(false);
    const [note, setNote] = useState("");

    const getDataCount = (name:string, packages:any) => {
        const data = packages.filter((item:any) => item._id === name)
        return data.length ? data[0].count : 0 
    }
    
    useEffect(() => {
        if(proof_payment) {
            setOpenProof(false)
            setTimeout(() => {
                dispatch(updateValueAlert())
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proof_payment]);


    const onCLickDownloadPdf = (item:any) => {
        let params = {
            type : "purchase-order",
            body : { 
                "data": {
                    "id": item._id,
                    "company": "null"
                }   
            }
        }
        dispatch(printDownloadPDF(params))
    } 
    
    return (
    <div className={`container-item-orders disable-item`}>
        <Box className="top-div">
            <Box className='left'>
                <h6>
                    {item.code} 
                    {item.lastStatus === "Waiting Down Payment" ? <span>| <span className='color-red'>
                        { item.proof_of_advance_payment && item.proof_of_advance_payment.file ? "Waiting for Confirmation" : "Waiting for Payment" }
                    </span> </span> : null }
                </h6>
                <p>Department : {item.department || "N/A"}</p>
            </Box>
            <Box className='right'>
                <img src={calender} alt='calender' />
                <p>{moment(item.date).format('lll')}</p>
                <Box onClick={() => setOpenStatus({open:true, data: item.statuses})}>
                    <img src={more} alt='more' />
                </Box>
            </Box> 
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.vendor.logo || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.vendor.legalName || item.vendor.name || "N/A"}</h2>
                                <Box className='chat'>
                                   <ChatButton vendor_detail={item.vendor} />
                                   <PreferredButton vendor_detail={item.vendor} />
                                </Box>
                            </Box>
                        </Box>
                        { item.address && item.address.label ?
                        <Box className='address'>
                            <h2>{item.address.label}</h2> 
                            <p>{item.address.street}, {item.address.city.toLowerCase() || "N/A"}</p>
                            <p>{item.address.province}, {item.address.country}, {item.address.postcode}</p>
                        </Box> :
                        <Box className='address'>
                            <h2>N/A</h2> 
                        </Box> }
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sm={12} lg={4} xl={4}>
                    <Box className='content-right'>
                        <Box className='content'>
                            <p>Down Payment</p>
                            <h3>Rp.{(Math.floor(item.down_payment)).toLocaleString()}</h3>
                        </Box>
                        {/* <Box className='content'>
                            <p>Total Shipping Cost</p>
                            <h3>Rp.{sumTotalShippingCost(item.shipping_costs, "shipping_cost").toLocaleString()}</h3>
                        </Box> */}
                        <Box className='content'> 
                            <p>Total Tax</p>
                            { item.grand_tax_receive && item.grand_tax_receive ? 
                            <h3>Rp.{(Math.floor(item.grand_tax_receive)).toLocaleString()}</h3> :
                            <h3>Rp.{(Math.floor(item.grand_tax)).toLocaleString()}</h3> }
                        </Box>
                        <Box className='linedivder' />
                        <Box className='content'>
                            <p>Total Purchase</p>
                            { item.grand_total_receive && item.grand_total_receive ? 
                            <h2>Rp.{(Math.floor(item.grand_total_receive)).toLocaleString()}</h2> :
                            <h2>Rp.{(Math.floor(item.grand_total)).toLocaleString()}</h2> }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='left-div'>
                { item.lastStatus === "Waiting Down Payment" ? null : 
                <Box className='list-status'>
                    { array_tabs.map((ele:any, index:number) => (
                        <Box className='container-status' key={index}>
                            <Box 
                                key={index} 
                                className='status-box'
                                onClick={() => onClickDetailPackage(item, ele.value)}
                            >
                                {getDataCount(ele.value, item.packages) === 0 ?
                                 <>
                                <h4>{getDataCount(ele.value, item.packages)}</h4>
                                <h6>{ele.label}</h6>
                                </>
                                :
                                <>
                                <h5>{getDataCount(ele.value, item.packages)}</h5>
                                <h3>{ele.label}</h3> 
                                </>
                                }
                            </Box>
                        </Box>
                    )) }
                </Box> }
            </Box>
            { item.lastStatus === "Waiting Down Payment" ?
            <Box className='right-div-disabled'>
                <Box 
                    className='view-btn' 
                    onClick={() => onClickDetailPackage(item, (statusPackage === "All" ? "New" : statusPackage))}
                >
                    <p>View Purchase Items</p>
                </Box>
                <DownloadPdf 
                    name="Download Invoice"
                    onClick={() => {
                        let value = {
                            id: item._id,
                            type: "Down Payment",
                        }
                        dispatch(printDownloadInvPDF(value))
                    }}
                />
                { item.proof_of_advance_payment && item.proof_of_advance_payment.file ? null :  
                <Box className='view-btn-status' 
                    onClick={() => {
                        history.push({
                            pathname: '/dashboard/finance/invoice',
                            search: `?page=detail`, 
                            state : {
                                id : item._id,
                                type : "by-order",
                            }
                        })
                    }}
                >
                    <p>Pay Invoice</p> 
                </Box>  }
            </Box>
            :
            <Box className='right-div'>
                { item.notes ? 
                <Box 
                    className='view-btn' 
                    onClick={() => {
                        setOpenNote(true)
                        setNote(item.notes)
                    }}
                >
                    <p>View Note</p>
                </Box> : null }
                <Box className='line-center' />
                { item.lastStatus === "Completed" ?
                <Box 
                    className='view-btn' 
                    onClick={() => onClickDetailPackage(item, ("Completed"))}
                >
                    <p>View Package</p>
                </Box> :
                <Box 
                    className='view-btn' 
                    onClick={() => onClickDetailPackage(item, (statusPackage === "All" ? "New" : statusPackage))}
                >
                    <p>View All Packages</p>
                </Box> }
                <Box className='line-center' />
                <DownloadPdf 
                    name="Download PDF"
                    onClick={() => onCLickDownloadPdf(item)}
                /> 
            </Box> }
        </Box>
        
        <DialogPayment open={openProof} setOpen={setOpenProof} data={item} setFileUrl={setFileUrl} fileUrl={fileUrl} />
        <HistoryStatus open={openStatus} setOpen={setOpenStatus} />
        <DialogNote open={openNote} setOpen={setOpenNote} note={note} name="Note to Vendor" />
    </div>
  )
}


const array_tabs = [
    { label: "New", value: "New" },
    { label: "Pick & Pack", value: "Pick" },
    { label: "Ready to Ship", value: "Ready to Ship" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
    // { label: "Completed", value: "Completed" },
    { label: "Canceled", value: "Cancel" },
]
export default OrderCard