import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import InvoiceDetail from './InvoiceDetail';
import PageInvoices from './PageInvoices';
import PaymentInvoice from './PaymentInvoice';

function MainPageInvoice() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')
 
    const switchPage = () => {
        if (active_page === 'detail') {
            return <InvoiceDetail />
        } if (active_page === 'payment') {
            return <PaymentInvoice />
        } else {
            return <PageInvoices/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default MainPageInvoice
