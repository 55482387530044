import { Stack, Box, Button, TextField, Grid, Skeleton } from '@mui/material'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { updateProfileCompany } from '../reducers/profilesReducers';
import Select from 'react-select' 
import { getActionCategoryCompany } from '../../step_register/components/stepFormSlice';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateProfileSnackBar } from '../profileSlice';
import StarRequired from '../../../../components/StarRequired';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const Profile : React.FC<any> = ({ profile }) => {

    const dispatch = useDispatch()
    const { category, loading_category } = useSelector((state : RootState) => state.step_state)
    const { update_profile, loading_update_profile } = useSelector((state : RootState) => state.profile)

    const [companyForm, setCompanyForm] = useState<any>({
        name : "",
        legalName : "",
    });

    const onChangeFormValue = (e : any) => {
        const { name, value } = e.target 
        setCompanyForm({...companyForm, [name] : value })
    }

    const [selectedCategoryCompany, setSelectedCategoryCompany] = useState<any>([]);
    const [errorCategoryCompany, setErrorCategoryCompany] = useState<boolean>(false);

    const optionsIsPKP  = [
        { value : false, label : "Perusahaan Non PKP"},
        { value : true, label : "Perusahaan PKP"}
    ]
    const [selectedIsPKP, setSelectedIsPKP] = useState<any>([]);
    const [errorIsPKP, setErrorIsPKP] = useState<boolean>(false);


    const handleChangeCategoryCompany = (value: any) : void => {
        setErrorCategoryCompany(false)
        setSelectedCategoryCompany(value)
    }

    const handleChangeIsPKP = (value: any) : void => {
        setErrorIsPKP(false)
        setSelectedIsPKP(value)
    }

    useEffect(() => {
        dispatch(getActionCategoryCompany())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(profile) {
            setCompanyForm({...companyForm,
                legalName : profile.legalName,
                name : profile.name
            })
            setSelectedIsPKP({value : profile.isPKP, label : profile.isPKP ? "Perusahaan PKP" : "Perusahaan Non PKP"})
            setSelectedCategoryCompany({value : profile.category._id, label : profile.category.name})
        }
        // eslint-disable-next-line
    }, [profile]);

    const onClickSave = () => {
        const body = {
            id : profile._id,
            data : {
                "name": companyForm.name,
                "legalName": companyForm.legalName,
                "isPKP": selectedIsPKP.value,
                "category": selectedCategoryCompany.value,
                "type": profile.type._id,
                "owner": profile.owner,
                "platform": profile.platform,
                "logo": profile.logo,
                "banner": profile.banner
            }
        }
        dispatch(updateProfileCompany(body))
    }

    useEffect(() => {
        if(update_profile) {
            setTimeout(() => {
                dispatch(updateProfileSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [update_profile]);

    return (
        <Stack mt={2}>
            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box><h4>Legal Name <StarRequired/></h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        id="legalName"
                        name="legalName"
                        size="small"
                        placeholder='PT Pasar Pemesanan Indonesia'
                        value={companyForm.legalName}
                        onChange={onChangeFormValue}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box><h4>Business Name <StarRequired/></h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        id="name"
                        placeholder='Pasar Pemesanan'
                        value={companyForm.name}
                        name="name"
                        size="small"
                        onChange={onChangeFormValue}
                        required
                        disabled
                    />
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box><h4>Category Company <StarRequired/></h4></Box>
                    <Box pt={1}>
                        { loading_category ? 
                        <Box mt={0} pt={0}><Skeleton height={60} /></Box>
                        :
                        <Select
                            placeholder="Select Category Company"
                            value={selectedCategoryCompany}
                            isSearchable={true}
                            options={category && category}
                            onChange={handleChangeCategoryCompany}
                            id="select-style-cat"
                        /> }
                    </Box>
                    { errorCategoryCompany ? <div className="error-p"><p>Category is required</p></div> : null }
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box ><h4>Taxable Type <StarRequired/></h4></Box>
                    <Box pt={1}>
                        <Select
                            placeholder="Select Taxable "
                            value={selectedIsPKP}
                            isSearchable={true}
                            options={optionsIsPKP && optionsIsPKP}
                            onChange={handleChangeIsPKP}
                            id="select-style-type"
                        />
                    </Box>
                    { errorIsPKP ? <div className="error-p"><p>Taxable Type is required</p></div> : null }
                </Grid>
            </Grid>

            { checkValidation('UPDDETCOM733096') &&
            <Stack sx={{my:3}} flexDirection="row" justifyContent="flex-end">
                <Button 
                    variant='contained'
                    onClick={onClickSave}
                    disabled={loading_update_profile}
                >{loading_update_profile ? "Loading.." : "Save"}</Button>
            </Stack> }
            <SnackBarAlert message={`Success update Profile Company.`} initialState={update_profile} />
        </Stack>
    )
}

export default Profile
