import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import emailRegex from '../../invitation/regexEmail';
import { sendPurchaseToEmail } from '../redux/ordersReducers';
import { RootState } from '../../../../app/store';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateValueAlert } from '../redux/ordersSlice';
import { getEmailMembers } from '../../masters/mastersReducers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogSendEmail : React.FC<any> = ({ open, setOpen, id, vendor}) => {

    const dispatch = useDispatch()
    const { send_email } = useSelector((state : RootState) => state.orders)
    const { emails : email_lists, loading_emails } = useSelector((state : RootState) => state.masters)

    const [emails, setEmails] = useState<string[] | []>([]);
    const [invalidEmails, setInvalidEmails] = useState<string[] | []>([]); 

    function handleClose () {
        setOpen(false);
    };

    function onClickSubmit () {
        let body = {
          "to": emails,
          "legalName": "null",
          "template": "package-order",
          "data": {
              "id": id,
              "company": "null"
          }
        }
        dispatch(sendPurchaseToEmail(body))
    }

    useEffect(() => {
      if (send_email) {
        setOpen(false)
        setTimeout(() => {
          dispatch(updateValueAlert())
        }, 1500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [send_email]);

    useEffect(() => {
      if(vendor._id !== null) {
        dispatch(getEmailMembers(vendor._id))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendor]);

    useEffect(() => {
      if(email_lists.length > 0) {
        setEmails(() => {
          let arr : string[] = []
          email_lists.forEach((item : any) => {
            arr.push(item.email)
          })
          return arr
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email_lists]);

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Confirm Email Lists</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <Box py={1} >
              <Box pb={1}><h4>Target Emails</h4></Box>
              { loading_emails ? 
              <Box my={1}>
                <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: 2 }}/>
              </Box>
               :
              <ReactMultiEmail
                  placeholder="Target email addresses"
                  emails={emails}
                  validateEmail={email => {
                      const isValid = emailRegex.test(email);

                      if (!isValid) {
                          setInvalidEmails([...invalidEmails, email]);
                      }
                      return isValid;
                  }}
                  onChange={(_emails: string[]) => {
                      setEmails(_emails);
                  }}
                  getLabel={(
                      email: string,
                      index: number,
                      removeEmail: (index: number) => void
                  ) => {
                  return (
                      <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                      </span>
                      </div>
                  );
                  }}
                  style={{cursor: 'auto'}}
              /> }
              <Box fontWeight={400} fontSize={12} py={1}><p>You can also add another email to the input list.</p></Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            color="primary"
            size="small" autoFocus variant='contained' 
            disabled={emails.length === 0 ? true : false}
            onClick={onClickSubmit}
          >
            Send Email
          </Button> 
        </DialogActions>
      </BootstrapDialog>
      <SnackBarAlert message="Succesfully send email." initialState={send_email} />
    </div>
  );
}

export default DialogSendEmail;