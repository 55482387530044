import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import arrowright from '../../../../../assets/img/icon/next.png';
import { getAllBankPaymentMethod } from '../redux/invoicesReducers';
import { RootState } from '../../../../../app/store';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogPaymentMethod : React.FC<any> = ({ open, setOpen, setErrorMethod, setSelectMethod }) => {

    const dispatch = useDispatch()
    const { 
      bank_payment_method, loading_bank_payment_method
    } = useSelector((state : RootState) => state.invoice)

    function handleClose () {
        setOpen(false);
    };

    useEffect(() => {
      if(open) {
        dispatch(getAllBankPaymentMethod())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Select Payment Method</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
        {loading_bank_payment_method ? 
          <Loading /> :
          <Box>
            { bank_payment_method.length &&
            <Box className='container-payment-method'>
              <Box className="box-list">
                <h2>Virtual Account</h2>
                {bank_payment_method.filter((value : any) => value.category.some((item : any) => item.name === "Virtual Account")).map((itm:any, index:any) => (
                  <Box 
                    className="box-list-item" 
                    onClick={() => {
                      setSelectMethod({...itm, payment_method: "Virtual Account", method : "VA"})
                      setOpen(false)
                      setErrorMethod(false)
                    }}
                    key={index}
                  >
                    <Box className='left'>
                      <img src={itm.imageUrl} alt="logo-bca" />
                      <h3>{itm.name} VA</h3>
                    </Box>
                    <Box className='right'>
                      <img src={arrowright} alt="logo-arrow" />
                    </Box>
                  </Box>
                ))}
              </Box> 
              <Box className="box-list">
                <h2>Bank Transfer</h2>
                {bank_payment_method.filter((value : any) => value.category.some((item : any) => item.name === "Manual Bank Transfer")).map((itm:any, index:any) => (
                  <Box 
                    className="box-list-item" 
                    onClick={() => {
                      setSelectMethod({...itm, payment_method: "Manual Bank Transfer", method : "Bank Transfer"})
                      setOpen(false)
                      setErrorMethod(false)
                    }}
                    key={index}
                  >
                    <Box className='left'>
                      <img src={itm.imageUrl} alt="logo-bca" />
                      <h3>Transfer Bank {itm.name_code}</h3>
                    </Box>
                    <Box className='right'>
                      <img src={arrowright} alt="logo-arrow" />
                    </Box>
                  </Box>
                ))}
              </Box>
          </Box> }
          </Box> }
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

const Loading = () => {
  return (
    <Box className="loading">
      <Box className='container-payment-method'>
        <Box className="box-list">
          <Skeleton variant="text" width={200} height={50} />
          {Array.from(new Array(2)).map((_, index) => (
          <Box className="box-list-item" key={index}>
            <Box className='left'>
              <Box><Skeleton variant="circular" width={35} height={35} /></Box>
              <Box ml={1}><Skeleton variant="text" width={185} height={35} /></Box>
            </Box>
            <Box className='right'>
              <Box><Skeleton variant="text" width={30} height={35} /></Box>
            </Box>
          </Box>
          ))}
        </Box> 
        <Box className="box-list">
          <Skeleton variant="text" width={178} height={50} />
          {Array.from(new Array(2)).map((_, index) => (
          <Box className="box-list-item" key={index}>
            <Box className='left'>
              <Box><Skeleton variant="circular" width={35} height={35} /></Box>
              <Box ml={1}><Skeleton variant="text" width={178} height={35} /></Box>
            </Box>
            <Box className='right'>
              <Box><Skeleton variant="text" width={30} height={35} /></Box>
            </Box>
          </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default DialogPaymentMethod;