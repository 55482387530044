import { Box } from '@mui/material'
import { Skeleton, Stack } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {createNewTransaction } from './redux/invoicesReducers';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { useEffect, useState } from 'react';
import copy from '../../../../assets/img/icon/copy.png'
import transfer from '../../../../assets/img/icon/transfer.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import DialogProof from './components/DialogProof';
import { clearMessageInvoice } from './redux/invoicesSlice';


const PaymentInvoice = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location : any = useLocation()

    const params = location.state === undefined ? null : location.state.params

    const { loading_create_transaction, data_transaction, proof_invoice, loading_proof_invoice } = useSelector((state : RootState) => state.invoice)

    const [openProof, setOpenProof] = useState(false);
    const [fileUrl, setFileUrl] = useState({
        name : '',
        url : '',
        type : 'upload'
    });

    const [copyTrue, setCopyTrue] = useState(false);
    const [type, setType] = useState("");

    const handleCopy = (value:any, name:string) => {
        return (
          <CopyToClipboard 
            text={`${value}`}
            onCopy={() => {
                setCopyTrue(true)
                setType(name)
                setTimeout(() => {
                  setCopyTrue(false)
                  setType("")
                }, 2000);
            }}
          >
            <Box className={copyTrue && name === type ? "copy copied" : "copy"}>
              <h5>{copyTrue && name === type ? 'Copied' : 'Copy'}</h5>
              <img alt='icon' src={copy}/>
            </Box>
          </CopyToClipboard>
        )
    }

    const isEmpty = (obj : any) => {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return true;
      }
      return false;
    }

    useEffect(() => {
      if(params !== null) {
        if(!isEmpty(data_transaction)) {
          dispatch(createNewTransaction(params))
        }
      } else {
        history.push('/dashboard/finance/invoice')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
      if(proof_invoice) {
        setOpenProof(false)
        setTimeout(() => {
          history.push('/dashboard/finance/invoice')
          dispatch(clearMessageInvoice())
        }, 1500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proof_invoice]);

    return (
        <Stack m={2} >
            <LoaderBackdrop initialState={loading_proof_invoice} />
            { loading_create_transaction ? 
            <LoadingPayment />
            :
            <Box className="payment-page">
              <h3>Finish your Invoice Payment!</h3>
              <p>Please make sure transfer to correct account number below.</p>

              { isEmpty(data_transaction) && (
              <Box className="box-detail-pay">
                <Box className="list-name">
                  <Box>
                    { data_transaction.method === "VA" ?
                    <h6>Virtual Account</h6> :
                    <h6>Manual Bank Transfer</h6> }
                  </Box>
                  <Box>
                    <img alt='icon' src={transfer}/>
                  </Box>
                </Box>
                <Box className="list-info">
                  <Box>
                    <p>Transaction ID</p>
                    <h6>{data_transaction.idTransaction}</h6>
                  </Box>
                  <Box/>
                </Box>
                
                <Box className="list-info">
                  <Box>
                    <p>Bank Name</p>
                    <h6>{data_transaction.banks.name} {data_transaction.method === "VA" ? "VA" : null }</h6>
                  </Box>
                  <Box/>
                </Box> 
                { data_transaction.method === "VA" ? null :
                <Box className="list-info">
                  <Box>
                    <p>Account Name</p>
                    <h6>{data_transaction.banks.accountName}</h6>
                  </Box>
                  <Box/>
                </Box> }
                <Box className="list-info">
                  <Box>
                    <p>{ data_transaction.method === "VA" ? "Virtual" : null } Account Number</p>
                    <h6>{data_transaction.banks.accountNumber}</h6>
                  </Box>
                  {handleCopy(data_transaction.banks.accountNumber, 'account_number')}
                </Box>
                <Box className="list-info">
                  <Box>
                    <p>Total Amount</p>
                    <h6>Rp. {data_transaction.amount.toLocaleString()}</h6>
                  </Box> 
                  {handleCopy(data_transaction.amount, 'amount')}
                </Box>
              </Box> )}

              <Box className="bottom-content">
                { data_transaction.method === "VA" ? null :
                <>
                <p>After you transfer, please upload your payment proof below.</p>
                <Box className="btn-upload">
                  <button onClick={() => setOpenProof(true)}>
                    Upload Payment Proof
                  </button>
                </Box>
                </> }
                <Box className="view-btn" onClick={() => history.push('/dashboard/finance/invoice')}>
                  <p>Back to Invoice</p>
                </Box>
              </Box>
            </Box> }

            <DialogProof open={openProof} setOpen={setOpenProof} 
            idTransaction={data_transaction && data_transaction.idTransaction} setFileUrl={setFileUrl} fileUrl={fileUrl}
            />
            <SnackBarAlert message="Thankyou for your payment! Upload payment proof is success!" initialState={proof_invoice} />
        </Stack>
    )
}


const LoadingPayment = () => {
    return (
      <Box className="payment-page">
      <h3>Creating your transaction ..</h3>
      <p>Please wait a second.</p>
      <Box className="box-detail-pay">
        <Box className="list-name">
          <Box>
            <Skeleton variant='text' height={45} width={180} />
          </Box>
          <Box>
            <Skeleton variant='rectangular' height={30} width={80} />
          </Box>
        </Box>
        <Box className="list-info">
          <Box mt={1}>
            <Skeleton variant='rectangular' height={15} width={120} />
            <Box my={1} />
            <Skeleton variant='rectangular' height={25} width={170} />
          </Box>
          <Box/>
        </Box>
        <Box className="list-info">
          <Box mt={1}>
            <Skeleton variant='rectangular' height={15} width={120} />
            <Box my={1} />
            <Skeleton variant='rectangular' height={25} width={150} />
          </Box>
          <Box/>
        </Box>
        <Box className="list-info">
          <Box mt={1}>
            <Skeleton variant='rectangular' height={15} width={120} />
            <Box my={1} />
            <Skeleton variant='rectangular' height={25} width={200} />
          </Box>
          <Box/>
        </Box>
        <Box className="list-info">
          <Box mt={1}>
            <Skeleton variant='rectangular' height={15} width={120} />
            <Box my={1} />
            <Skeleton variant='rectangular' height={25} width={150} />
          </Box>
          <Box mt={1}>
            <Skeleton variant='rectangular' height={25} width={80} />
          </Box>
        </Box>
        <Box className="list-info">
          <Box mt={1}>
            <Skeleton variant='rectangular' height={15} width={120} />
            <Box my={1} />
            <Skeleton variant='rectangular' height={25} width={150} />
          </Box>
          <Box mt={1}>
            <Skeleton variant='rectangular' height={25} width={80} />
          </Box>
        </Box>
      </Box>
    </Box>
    )
}

export default PaymentInvoice;
