import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, Stack } from '@mui/material';


const HoverInfoProduct = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#43ce81',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));
 
const HoverInfoPurchase : React.FC<any> = ({ item }) => {
  return (
    <div key={item._id}>
      <HoverInfoProduct
        title={
          <React.Fragment>
            <Stack flexDirection="row" justifyContent="space-between" className='hover-info'>
              <h6>{item.department}</h6>
              <h6>Rp.{item.grand_total.toLocaleString()}</h6>
            </Stack>
          </React.Fragment>
        }
      >
        <Box className="item">
            <h5>{item.code || item.name}</h5>
        </Box>
      </HoverInfoProduct>
    </div>
  );
}


export default HoverInfoPurchase