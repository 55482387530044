import { Box } from '@mui/material'
import { Button, Stack } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useHistory } from 'react-router-dom';
import HeadlineTitle from '../../../components/HeadlineTitle';
import TemplateCard from './components/TemplateCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useEffect, useState } from 'react';
import { clearTemplatesLocalData, getTemplateServerData, getTemplateServerDataLoadMore } from './redux/templateReducers';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { addLengthInfiniteTemplate, clearMessageTemplate } from './redux/templateSlice';
import SnackBarAlert from '../../../components/SnackBarAlert';
import PurchaseSearch from '../purchase_request/components/PurchaseSearch';
import PurchaseLoading from '../purchase_request/components/PurchaseLoading';
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from 'sweetalert';
import LoaderProgress from '../../../components/LoaderProgress';
import DataNotFound from '../../../components/DataNotFound';
import { checkValidation } from '../../../utilities/userFeaturesValidation';
import ButtonCreate from '../../../components/ButtonCreate';

const PageTemplate = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { 
        templates, total_templates, loading_templates,
        remove_template, loading_remove_template, 
        loading_edit, edit,loaded_template, total_infinite
    } = useSelector((state : RootState) => state.templates)

    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [valueSearch, setValueSearch] = useState("");
    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [activeStatus, setActiveStatus] = useState("All");

    const onClickCreateNew = async () => {
        dispatch(clearTemplatesLocalData())
        localStorage.removeItem('edited')
        history.push({
            pathname: "/dashboard/templates",
            search: `?page=create`, 
        })
    }

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
         
        const newdata : any = await dispatch(getTemplateServerDataLoadMore(params))
        if(newdata.type === "template-load-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                dispatch(addLengthInfiniteTemplate(newdata.payload.data.length))
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    
    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivTemplate").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if(edit) {
            history.push({
                pathname: "/dashboard/templates",
                search: `?page=create`, 
            })
        }
        // eslint-disable-next-line
    }, [edit]);

    useEffect(() => {
        const params = {
            limit,
            skip: 0,
            search : valueSearch,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
        dispatch(getTemplateServerData(params))
        setFetch(true)
        setHasMore(true)
        setSkip(0)
        // eslint-disable-next-line
    }, [valueSearch, rangeTopic, rangeFrom, rangeTo ]);


    useEffect(() => {
        if(remove_template) {
            const params = {
                limit,
                skip:0,
                search : valueSearch,
                'rangeTopic' : rangeTopic,
                'rangeFrom' : rangeFrom,
                'rangeTo' : rangeTo,
            }
            dispatch(getTemplateServerData(params))
            setSkip(0)
            setTimeout(() => {
                dispatch(clearMessageTemplate())            
            }, 2000);
        }
        // eslint-disable-next-line
    }, [ remove_template]);


    
    useEffect(() => {
        setDataInfinite(templates)
        if(templates.length >= total_templates) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
        // eslint-disable-next-line
    }, [templates, total_templates]);

    useEffect(() => {
        if(loaded_template) {
            setDataInfinite(templates)
        }
        // eslint-disable-next-line
    }, [templates, loaded_template]);

    useEffect(() => {
        if(fetch && loaded_template) {
            if(total_infinite >= total_templates) {
                setHasMore(false)
            }
        }
    }, [fetch, loaded_template, total_infinite, total_templates]);

    useEffect(() => {
        if(loaded_template && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total_templates) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, loaded_template, fetch, total_infinite]);

    return (
        <Stack m={2} >
            <LoaderBackdrop 
                loading={loading_remove_template || loading_edit} 
            />
            <BreadCrumbs 
                isPage={false}
                current="Template Page"
            />
            <Stack direction="row" justifyContent="space-between" py={1} >
                <Box>
                    <HeadlineTitle 
                        title='Template'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
                <Box sx={{display: 'flex'}}>
                    <Box>
                        { checkValidation('CRETEM101020') &&
                        <ButtonCreate 
                            name="Create New Template"
                            onClick={() => onClickCreateNew()}
                        />}
                    </Box>
                    <Box mr={2} />
                </Box> 
            </Stack>

            <PurchaseSearch 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                template={true}
                setSkip={setSkip}
                setRangeTopic={setRangeTopic}
                setRangeFrom={setRangeFrom}
                setRangeTo={setRangeTo}
            />
            
            { loading_templates ?
            <Stack mt={1}>
                {[1,2,3].map(idx => (
                    <PurchaseLoading key={idx} />
                ))}
            </Stack>
            :
            <div id="scrollableDivTemplate"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDivTemplate"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box> 
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((item:any, idx:number) => (
                           <TemplateCard item={item} key={idx} />
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> 
                :
                <Box mt={2} mx={0.2}>
                    <DataNotFound 
                        message="Template is not found."
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> } 
            <SnackBarAlert message={`Template successfully deleted!`} initialState={remove_template} />
        </Stack>
    )
}

export default PageTemplate;
