import { Grid, Stack, Box, Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ImageSlider from './component/ImageSlider'
import { getCalculateItem, getDetailProduct } from './redux/productReducers'
import verified from '../../../assets/img/icon/verified.png'
import store from '../../../assets/img/icon/store.png'
import ReactHtmlParser from 'react-html-parser';
import { getVendorDetail } from '../masters/mastersReducers'
import DialogPurchase from './component/DialogPurchase'
import { getPurchaseOpenStatus } from '../purchase_request/redux/purchasesReducers'
import { getTemplateOpen } from '../template/redux/templateReducers'
import EmptyData from '../../../components/EmptyData'
import ProductCardLoading from './component/ProductCardLoading'
import ProductCard from './component/ProductCard'
import ChatButton from '../../../components/ChatButton'
import swal from 'sweetalert'
import PreferredButton from '../../../components/PreferredButton'
import { getPreferredAll } from '../vendor_lists/redux/vendorListsReducer'
import { resetSnackBarPreferred } from '../vendor_lists/redux/vendorListsSlice'
import { checkValidation } from '../../../utilities/userFeaturesValidation'
import moment from 'moment'

function ProductDetail() {
    const history = useHistory()
    const location : any = useLocation()
    const dispatch = useDispatch()

    const { 
        detail, loading_detail,
    } = useSelector((state: RootState) => state.products)
    const { create } = useSelector((state : RootState) => state.preferred)
    const { 
        vendor_detail, loading_vendor_detail, 
    } = useSelector((state: RootState) => state.masters)

    const [selectedArea, setSelectedArea] = useState("");
    const [activeTab, setActiveTab] = useState("product");

    const [detailPurchase, setDetailPurchase] = useState({
        open: false, 
        type : "", 
        data : {}
    });

    const addToPurchaseItem = async (ele:any,name:string) => {
        let product = {
            item : {
                _id : ele._id,
                name : ele.name,
                sku : ele.sku,
                slug : ele.slug,
                images : ele.images,
                warehouse : {
                    _id : ele.warehouses.warehouse._id,
                    name : ele.warehouses.warehouse.name,
                    imageUrl : ele.warehouses.warehouse.imageUrl,
                    city : ele.warehouses.warehouse.coverages[0],
                },
                retail_price :  ele.price,
                stock :  ele.stock,
                minimum_order_quantity :  ele.minimum_order_quantity,
                quantity : ele.minimum_order_quantity,
                payment_term : ele.payment_term,
                vendor : ele.company,
                packages: null,
                order: null,
                discount : ele.discount.label === "SPECIAL" ? parseInt(ele.discount.value) : ele.discount.value,
                discount_type : ele.discount.label,
                discount_price : 0,
                tax : ele.tax,
                down_payment : ele.down_payment,
                total_tax : 0,
                total : 0,
                statuses : [],
                unit : ele.unit ? ele.unit.label : "N/A",
                category : ele.category ? ele.category : "N/A",
            },
            company : "null"
        }
        dispatch(getCalculateItem(product))
        setDetailPurchase({...detail, open:true, type : name})
    }


    useEffect(() => {
        if(isNotEmpty(detail)) {
            dispatch(getVendorDetail(detail.company._id))
        }
        // eslint-disable-next-line
    }, [detail]);

    useEffect(() => {
        if(location.state !== undefined) {
            let params = {
                slug : location.state.slug,
                coverage_area : location.state.area,
            }
            dispatch(getDetailProduct(params))
        }
        // eslint-disable-next-line
    }, [location]);


    // check empty 0bject
    const isNotEmpty = (obj: any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    const onClickProductVendor = () => {
        history.push({
            pathname: "/dashboard/products",
            search: `?page=vendor`, 
            state: { 
                id : detail.company._id,
                name : detail.company.name,
            }
        })
    }

    useEffect(() => {
        let area = localStorage.getItem('area') || ""
        if(area === "") {
            swal('Error', 'Please select area first', 'error')
        } else {
            setSelectedArea(JSON.parse(area).value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getPreferredAll())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(create) {
            dispatch(getPreferredAll())
            setTimeout(() => {
                dispatch(resetSnackBarPreferred())
            }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);

    return (
        <Stack sx={{m:2}}>
            <BreadCrumbs 
                isPage={true}
                link="/dashboard/products"
                page='Browse Product'
                current="Product Detail"
            />
            
            { loading_detail ? <LoadingDetail /> :
            <Stack className="container-detail-product">
                { isNotEmpty(detail) ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5} lg={5} xl={4}>
                        <Box className='image-card-box'>
                            <Box className="zoom-effect-container">
                                <ImageSlider 
                                    images={detail.images || []}
                                    slug={detail.slug}
                                    href={false}
                                    lightbox={true}
                                    thumbnail={true}
                                />
                            </Box> 
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7} lg={7} xl={8}>
                        <Box className='right-detail'>
                            <Box className='verified'>
                                <img alt='verified' src={verified}/>
                                <h6>Verified Seller</h6>
                            </Box>
                            <Box className='title-name'>
                                <h1>{detail.name.toLowerCase()}</h1>
                            </Box>
                            <Box className='title-info'>
                                <p>In Stock {detail.stock || 0} left</p>
                                <div className='line' />
                                <p>Minimal Order {detail.minimum_order_quantity} {detail.unit ? detail.unit.label : "N/A"}</p>
                            </Box>
                            <Box className='extra-information'>
                                <Box className='info'>
                                    <h3>Brand</h3>
                                    <h4>{detail.brand}</h4>
                                </Box>
                                <Box className='info'>
                                    <h3>Weight</h3>
                                    <h4>{detail.weight} kg</h4>
                                </Box>
                                <Box className='info'>
                                    <h3>Unit</h3>
                                    <h4>{(detail.unit && detail.unit.name ) || "N/A"}</h4>
                                </Box>
                                <Box className='info'>
                                    <h3>Dimensions</h3>
                                    <h4>{detail.dimension.H} cm x {detail.dimension.L} cm x {detail.dimension.W} cm</h4>
                                </Box>
                                <Box className='info'>
                                    <h3>Payment Term</h3>
                                    <h4>{detail.payment_term} Days</h4>
                                </Box>
                                <Box className='info'>
                                    <h3>Category</h3>
                                    {detail.categories && detail.categories.tree.length ?
                                    <h4>
                                        { detail.categories && detail.categories.tree.map((item: any, index: number) => (
                                            <span key={index}>
                                                {item.name}
                                                { index !== detail.categories.tree.length - 1 && <span> &gt; </span>}
                                            </span>
                                        )) }
                                        {/* Pantry &gt; Sugar */}
                                    </h4>
                                    :
                                    <h4>{(detail.categories && detail.category) || ""}</h4>  }
                                </Box>
                            </Box>
                            { detail.discount.label === "PERCENTAGE" && detail.discount.value !== 0  ?  
                            <Box className='price-container'>
                                <Box className="title-discount">
                                    <Box><h2>Rp. {detail.price.toLocaleString()}</h2></Box>
                                </Box>
                                <Box className="title-price">
                                    <h1>Rp. {(detail.price - ((detail.discount.value / 100) * detail.price)).toLocaleString()}</h1>    
                                </Box>
                                <Box className="discount"><h3>{detail.discount.value}% OFF</h3></Box>   
                            </Box>
                            :  detail.discount.label === "AMOUNT" && detail.discount.value !== 0 ? 
                            <Box className='price-container'>
                                <Box className="title-discount">
                                    <Box><h2>Rp. {detail.price.toLocaleString()}</h2></Box>
                                </Box>
                                <Box className="title-price">
                                    <h1>Rp. {(detail.price - detail.discount.value).toLocaleString()}</h1>    
                                </Box>
                                <Box className="discount"><h3>Rp. {detail.discount.value.toLocaleString()}</h3></Box>   
                            </Box>
                            :  detail.discount.label === "SPECIAL" ? 
                            <Box className='price-container'>
                                <Box className="title-discount">
                                    <Box><h2>Rp. {detail.price.toLocaleString()}</h2></Box>
                                </Box>
                                <Box className="title-price">
                                    <h1>Rp. {detail.discount.value.toLocaleString()}</h1>    
                                </Box>
                                <Box className="discount"><h3>SPECIAL</h3></Box>   
                            </Box> :
                            <Box className='price-container'>
                                <Box className="title-price">
                                    <h1>Rp. {detail.price.toLocaleString()}</h1>    
                                </Box>
                            </Box> }
                            <Box className="button-cards">
                                { checkValidation('CREPURREQ486033') &&
                                <Box 
                                    className="pr" 
                                    onClick={() => {
                                        addToPurchaseItem(detail,'Purchase Request')
                                        dispatch(getPurchaseOpenStatus())
                                    }}
                                >
                                    Add to Purchase Request 
                                </Box> 
                                }
                                { checkValidation('CRETEM101020') &&
                                <Box 
                                    className="template" 
                                    onClick={() => {
                                        addToPurchaseItem(detail,'Template')
                                        dispatch(getTemplateOpen())
                                    }}
                                >
                                    Add to Template
                                </Box> }
                            </Box>
                        </Box>
                        { loading_vendor_detail ? 
                        <Box className='seller'>
                            <Box className='store'>
                                <Skeleton variant="circular" width={50} height={50} />
                                <Box className='name'>
                                    <Skeleton variant="text" width={150} height={30} />
                                    <Skeleton variant="text" width={100} height={25} />
                                </Box>
                            </Box>
                            <Box className='chat'>
                                <Box className="btn-chat">
                                    <Skeleton variant="text" width={100} height={10} />
                                </Box>
                                <Skeleton variant="text" width={150} height={30} />
                            </Box>
                        </Box> :
                        <Box className='seller'>
                            { isNotEmpty(vendor_detail) && 
                            <>
                            <Box className='store'>
                                <img src={vendor_detail.logo || store} alt="store" />
                                <Box className='name' onClick={() => onClickProductVendor()}>
                                    <h2>{vendor_detail.legalName}</h2>
                                    <p>{vendor_detail.category.name}</p>
                                </Box> 
                            </Box>
                            <Box className='chat'>
                                <ChatButton vendor_detail={vendor_detail} />
                                <PreferredButton vendor_detail={vendor_detail} />
                                {/* <p>Open at 08.00 - 17.00 WIB</p> */}
                            </Box>
                            </> }
                        </Box> }
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className='descriptions'>
                            <Box className='menu-list'>
                                { arrayTabs.map((item, index) => (
                                    <Box 
                                        key={index} className={`${activeTab === item.value ? 'title-desc-active' : 'title-desc'}`}
                                        onClick={() => setActiveTab(item.value)}
                                    >
                                        <h2>{item.name}</h2>
                                    </Box>
                                ))}
                            </Box>
                            { activeTab === "product" ?
                            <Box p={1} mt={2}>
                                { ReactHtmlParser(detail.description && detail.description) }
                            </Box> 
                            : 
                            <Box className='product-detail'>
                                <Box className='extra-information'>
                                    <Box className='info'>
                                        <h3>Country of Origin</h3>
                                        <h4>{detail.country_of_origin || "N/A"}</h4>
                                    </Box>
                                    <Box className='info'>
                                        <h3>MFG Date</h3>
                                        <h4>{detail.made_in ? moment(detail.made_in).format('DD-MM-YYYY') : "N/A"}</h4>
                                    </Box>
                                    <Box className='info'>
                                        <h3>Expired Date</h3>
                                        <h4>{detail.made_in ? moment(detail.expired_in).format('DD-MM-YYYY') : "N/A"}</h4>
                                    </Box>
                                    <Box className='info'>
                                        <h3>Size</h3>
                                        <h4>{detail.size || "N/A"}</h4>
                                    </Box>
                                    <Box className='info'>
                                        <h3>Volume </h3>
                                        <h4>{detail.volume  || "N/A"}</h4>
                                    </Box>
                                    <Box className='info'>
                                        <h3>Special Handling info</h3>
                                        <h4>{detail.special_handling || "N/A"}</h4>
                                    </Box>
                                   
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Grid>

                </Grid> ) :
                (
                    <EmptyData 
                        message='Product not found'
                        caption="There is something wrong, please try again later"
                    />
                )}
            </Stack>  }

            <Stack id='rekomendasi'>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        
                        <Box mb={4} >
                            <Box>
                                {loading_detail ? 
                                <Skeleton variant="text" width={300} height={50} /> :
                                <h5>Product recomendation</h5> }
                            </Box>
                            <Box >
                                { loading_detail ? 
                                <ProductCardLoading loader={4} />
                                :
                                <Box>
                                { isNotEmpty(detail) &&
                                <Box>
                                    { detail.recommend.length > 0 ?
                                    <Grid container spacing={2} >
                                        { detail.recommend.map((ele:any, i : any) => (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                                                <ProductCard item={ele}  selectedArea={selectedArea}/>
                                            </Grid>
                                        ))}
                                    </Grid> :
                                    <Box mt={2}>
                                        <EmptyData 
                                            message="Sorry, we couldn't find any Products for you" 
                                        />
                                    </Box> }
                                </Box> }
                                </Box> }
                            </Box> 
                        </Box> 
                    </Grid>
                </Grid>
            </Stack>
            <DialogPurchase detail={detailPurchase} setDetail={setDetailPurchase} />
        </Stack>
    )
}


const LoadingDetail = () => {
    return (
        <Stack className="container-detail-product">
            <Grid container spacing={2}>
                <Grid item xs={12} md={5} lg={5} xl={4}>
                    <Box>
                        <Skeleton variant="rectangular" height={500} sx={{borderRadius:5}} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={7} xl={8}>
                    <Box className='right-detail'>
                        <Box className='verified'>
                            <Skeleton variant="rectangular" width={100} height={25} />
                        </Box>
                        <Box className='title-name'>
                            <Skeleton variant="text" width={300} height={45} />
                        </Box>
                        <Box className='title-info'>
                            <Skeleton variant="text" width={100} height={20} />
                            {/* <div className='line' /> */}
                            <Skeleton variant="text" width={100} height={20} />
                        </Box>
                        {/* <Box className='title-brand'>
                            <h4>{brand}</h4>
                        </Box> */}
                        <Box className='extra-information'>
                            <Box className='info'>
                                <h3><Skeleton variant="text" width={100} height={20} /></h3>
                                <h4><Skeleton variant="text" width={100} height={20} /></h4>
                            </Box>
                            <Box className='info'>
                                <h3><Skeleton variant="text" width={100} height={20} /></h3>
                                <h4><Skeleton variant="text" width={100} height={20} /></h4>
                            </Box>
                            <Box className='info'>
                                <h3><Skeleton variant="text" width={100} height={20} /></h3>
                                <h4><Skeleton variant="text" width={100} height={20} /></h4>
                            </Box>
                            <Box className='info'>
                                <h3><Skeleton variant="text" width={100} height={20} /></h3>
                                <h4><Skeleton variant="text" width={100} height={20} /></h4>
                            </Box>
                            <Box className='info'>
                                <h3><Skeleton variant="text" width={100} height={20} /></h3>
                                <h4><Skeleton variant="text" width={100} height={20} /></h4>
                            </Box>
                        </Box>
                        <Box className='price-container'>
                            <Box className="title-discount">
                                <Box><Skeleton variant="text" width={100} height={40} /></Box>
                            </Box>
                            <Box className="title-price">
                                <h1><Skeleton variant="text" width={100} height={40} /></h1>    
                            </Box>
                            <Box className="discount"><h3><Skeleton variant="text" width={40} height={20} /></h3></Box>   
                        </Box>
                        <Box className="button-cards">
                            <Box className="pr">
                                <Skeleton variant="text" width={100} height={20} />
                            </Box>
                            <Box className="template">
                                <Skeleton variant="text" width={100} height={20} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='seller'>
                        <Box className='store'>
                            <Skeleton variant="circular" width={50} height={50} />
                            <Box className='name'>
                                <Skeleton variant="text" width={150} height={30} />
                                <Skeleton variant="text" width={100} height={25} />
                            </Box>
                        </Box>
                        <Box className='chat'>
                            <Box className="btn-chat">
                                <Skeleton variant="text" width={100} height={10} />
                            </Box>
                            <Skeleton variant="text" width={150} height={30} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box className='descriptions'>
                        <Box className='menu-list'>
                            <Skeleton variant="text" width={120} height={50} />
                            <Box mx={1}/>
                            <Skeleton variant="text" width={120} height={50} />
                        </Box>
                        <Box p={1} mt={2}>
                            { [1,2,3,4,5,6,7,8,9,10].map((item) => (
                                <Skeleton key={item} variant="text" height={20} />
                            )) }
                        </Box>
                    </Box>
                </Grid>

            </Grid> 
        </Stack> 
    )
}

const arrayTabs = [
    {
        name: "Product Description",
        value: "product",
    },
    {
        name: "Product Detail",
        value: "detail",
    },
]

export default ProductDetail
