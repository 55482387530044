import { createSlice } from '@reduxjs/toolkit';
import { 
  addeNoteToVendorItem,
  addItemToPurchase,
  addPurchaseItem, approvePurchaseRequest, clearPurchasesLocalData, getOnePurchaseRequest, getPurchaseLocalData, 
  getPurchaseOpenStatus, 
  getPurchaseServerData, getUpdatePurchaseRequest, printPurchaseRequest, rejectPurchaseRequest, removePurchaseRequest, 
  removePurchasesItem, removePurchasesVendor, savePurchaseRequest, 
  submitPurchaseRequest, 
  updateCodePurchaseRequest, 
  updatePurchaseRequest
} from './purchasesReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  purchases : [], 
  loaded_purchases : false,
  total_purchases : 0,
  total_infinite : 0,
  loaded : false,
  auto_saved_value : 0,
  loading_purchases : false,
  create_purchase : false,
  loading_create_purchase : false,
  update_purchase : false,
  loading_update_purchase : false,
  remove_purchase : false,
  loading_remove_purchase : false,
  submit_purchase : false,
  loading_submit_purchase : false,
  approve_purchase : false,
  loading_approve_purchase : false,
  reject_purchase : false,
  loading_reject_purchase : false,
  detail : {},
  loading_detail : false,
  edit : false, 
  loading_edit : false,
  data: [], 
  loading: true,
  add_item : false,
  type : "",
  loading_add_item : false,
  remove_item : false,
  loading_remove_item : false,
  remove_vendor : false,
  loading_remove_vendor : false,
  saved : false,
  clear : false, 
  loading_clear : false,
  added_note : false,
  loading_added_note : false,
  type_error : "",
  value_error : 0,
  product_id : "",
  purchase_open : [],
  loading_purchase_open : false,
  purchase_item : false,
  loading_purchase_item : false,
  print : "",
  loading_print : false,
  edit_number : false,
  loading_edit_number : false,
};

export const purchasesSlices = createSlice({
  name: 'purchases',
  initialState,
  reducers: { 
    clearSnackBarValue (state) {
      state.create_purchase = false
      state.update_purchase = false
      state.remove_purchase = false
      state.submit_purchase = false
      state.approve_purchase = false
      state.reject_purchase = false
      state.add_item = false
      state.remove_item = false
      state.remove_vendor = false
      state.print = ""
      state.purchase_item = false
      state.added_note = false
      state.edit_number = false
    },
    clearValueErrorQty (state) {
      state.value_error = 0;
      state.type_error = ""
      state.product_id = ""
    },
    addLengthInfinite (state,action) {
      let existing = state.total_infinite + action.payload
      state.total_infinite = existing
    }
  },
  extraReducers: (builder) => {
    builder
      // get all purchase request server
      .addCase(getPurchaseServerData.pending, (state) => {
        state.loading_purchases = true;
        state.purchases = [];
        state.total_purchases = 0;
        state.create_purchase = false
        state.update_purchase = false
        state.remove_purchase = false
        state.submit_purchase = false
        state.approve_purchase = false
        state.added_note = false
        state.reject_purchase = false
        state.add_item = false
        state.remove_item = false
        state.remove_vendor = false
        state.print = ""
        state.loaded_purchases = false;

      })
      .addCase(getPurchaseServerData.fulfilled, (state, action:any) => {
        state.loading_purchases = false;
        state.purchases = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_purchases = action.payload.data.length > 0 ? action.payload.total.row : 0;
        state.total_infinite = action.payload.data.length > 0 ? action.payload.data.length : 0;
        state.loaded_purchases = true;
        // increment every hit to server
        
      })
      .addCase(getPurchaseServerData.rejected, (state, action : any) => {
        state.loading_purchases = false;
        errorSweetAlert(action.payload)
      })

      // get detail purchase request server
      .addCase(getOnePurchaseRequest.pending, (state) => {
        state.loading_detail = true;
        state.detail = {};
      })
      .addCase(getOnePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_detail = false;
        state.detail = action.payload
      })
      .addCase(getOnePurchaseRequest.rejected, (state, action : any) => {
        state.loading_detail = false;
        errorSweetAlert(action.payload)
      })

       // get purchase request open
       .addCase(getPurchaseOpenStatus.pending, (state) => {
        state.loading_purchase_open = true;
      })
      .addCase(getPurchaseOpenStatus.fulfilled, (state, action:any) => {
        state.loading_purchase_open = false;
        state.purchase_open = action.payload
      })
      .addCase(getPurchaseOpenStatus.rejected, (state, action : any) => {
        state.loading_purchase_open = false;
        errorSweetAlert(action.payload)
      })

      // get detail purchase request server
      .addCase(getUpdatePurchaseRequest.pending, (state) => {
        state.loading_edit = true;
        state.edit = false;
      })
      .addCase(getUpdatePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_edit = false;
        state.edit = action.payload;
        state.auto_saved_value = -1;
      })
      .addCase(getUpdatePurchaseRequest.rejected, (state, action : any) => {
        state.loading_edit = false;
        errorSweetAlert(action.payload)
      })

       // add item to purchase
       .addCase(addItemToPurchase.pending, (state) => {
        state.loading_purchase_item = true;
        state.purchase_item = false;
      })
      .addCase(addItemToPurchase.fulfilled, (state, action:any) => {
        state.loading_purchase_item = false;
        state.purchase_item = action.payload
      })
      .addCase(addItemToPurchase.rejected, (state, action : any) => {
        state.loading_purchase_item = false;
        errorSweetAlert(action.payload)
      })

      // create new purchase
      .addCase(savePurchaseRequest.pending, (state) => {
        state.loading_create_purchase = true;
      })
      .addCase(savePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_create_purchase = false;
        state.create_purchase = action.payload;
      })
      .addCase(savePurchaseRequest.rejected, (state, action : any) => {
        state.loading_create_purchase = false;
       errorSweetAlert(action.payload)
      })

      // update purchase
      .addCase(updatePurchaseRequest.pending, (state) => {
        state.loading_update_purchase = true;
      })
      .addCase(updatePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_update_purchase = false;
        state.update_purchase = action.payload;
      })
      .addCase(updatePurchaseRequest.rejected, (state, action : any) => {
        state.loading_update_purchase = false;
       errorSweetAlert(action.payload)
      })

      // submit purchase
      .addCase(submitPurchaseRequest.pending, (state) => {
        state.loading_submit_purchase = true;
      })
      .addCase(submitPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_submit_purchase = false;
        state.submit_purchase = action.payload;
      })
      .addCase(submitPurchaseRequest.rejected, (state, action : any) => {
        state.loading_submit_purchase = false;
        errorSweetAlert(action.payload)
      })

      // approved purchase
      .addCase(approvePurchaseRequest.pending, (state) => {
        state.loading_approve_purchase = true;
      })
      .addCase(approvePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_approve_purchase = false;
        state.approve_purchase = action.payload;
      })
      .addCase(approvePurchaseRequest.rejected, (state, action : any) => {
        state.loading_approve_purchase = false;
        errorSweetAlert(action.payload)
      })

      // reject purchase
      .addCase(rejectPurchaseRequest.pending, (state) => {
        state.loading_reject_purchase = true;
      })
      .addCase(rejectPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_reject_purchase = false;
        state.reject_purchase = action.payload;
      })
      .addCase(rejectPurchaseRequest.rejected, (state, action : any) => {
        state.loading_reject_purchase = false;
        errorSweetAlert(action.payload)
      })

      // remove purchase
      .addCase(removePurchaseRequest.pending, (state) => {
        state.loading_remove_purchase = true;
      })
      .addCase(removePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_remove_purchase = false;
        state.remove_purchase = action.payload;
      })
      .addCase(removePurchaseRequest.rejected, (state, action : any) => {
        state.loading_remove_purchase = false;
        errorSweetAlert(action.payload)
      })

      // print
      .addCase(printPurchaseRequest.pending, (state) => {
        state.loading_print = true;
        state.print = "";
      })
      .addCase(printPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_print = false;
        state.print = action.payload;
      })
      .addCase(printPurchaseRequest.rejected, (state, action : any) => {
        state.loading_print = false;
        errorSweetAlert(action.payload)
      })


      // get purchase request local data
      .addCase(getPurchaseLocalData.pending, (state) => {
        state.loading = true;
        state.create_purchase = false
        state.update_purchase = false
        state.remove_purchase = false
        state.submit_purchase = false
        state.approve_purchase = false
        state.reject_purchase = false
        state.add_item = false
        state.remove_item = false
        state.remove_vendor = false
        state.clear = false
        state.edit = false
        state.type_error = "";
        state.value_error = 0
        state.product_id = ""
        state.loaded = false;
      })
      .addCase(getPurchaseLocalData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload;
        state.loaded = true;
        state.auto_saved_value = state.auto_saved_value + 1
      })
      .addCase(getPurchaseLocalData.rejected, (state, action : any) => {
        state.loading = false;
        state.loaded = false;
       errorSweetAlert(action.payload)
      })

      // add item
      .addCase(addPurchaseItem.pending, (state) => {
        state.loading_add_item = true;
        state.value_error = 0;
        state.type_error = ""
        state.product_id = ""
      })
      .addCase(addPurchaseItem.fulfilled, (state, action:any) => {
        state.loading_add_item = false;
        state.add_item = action.payload.data;
        state.type = action.payload.type;
        state.type_error = action.payload.type_error;
        state.value_error = action.payload.value_error;
        state.product_id = action.payload.product_id;
      })
      .addCase(addPurchaseItem.rejected, (state, action : any) => {
        state.loading_add_item = false;
       errorSweetAlert(action.payload)
      })

      // remove item
      .addCase(removePurchasesItem.pending, (state) => {
        state.loading_remove_item = true;
      })
      .addCase(removePurchasesItem.fulfilled, (state, action:any) => {
        state.loading_remove_item = false;
        state.remove_item = action.payload;
      })
      .addCase(removePurchasesItem.rejected, (state, action : any) => {
        state.loading_remove_item = false;
       errorSweetAlert(action.payload)
      })

      // remove vendor
      .addCase(removePurchasesVendor.pending, (state) => {
        state.loading_remove_vendor = true;
      })
      .addCase(removePurchasesVendor.fulfilled, (state, action:any) => {
        state.loading_remove_vendor = false;
        state.remove_vendor = action.payload;
      })
      .addCase(removePurchasesVendor.rejected, (state, action : any) => {
        state.loading_remove_vendor = false;
        errorSweetAlert(action.payload)
      })

      // remove all purchase data local
      .addCase(clearPurchasesLocalData.pending, (state) => {
        state.loading_clear = true;
      })
      .addCase(clearPurchasesLocalData.fulfilled, (state, action:any) => {
        state.loading_clear = false;
        state.clear = action.payload;
      })
      .addCase(clearPurchasesLocalData.rejected, (state, action : any) => {
        state.loading_clear = false;
       errorSweetAlert(action.payload)
      })

      // remove item
      .addCase(addeNoteToVendorItem.pending, (state) => {
        state.loading_added_note = true;
      })
      .addCase(addeNoteToVendorItem.fulfilled, (state, action:any) => {
        state.loading_added_note = false;
        state.added_note = action.payload;
      })
      .addCase(addeNoteToVendorItem.rejected, (state, action : any) => {
        state.loading_added_note = false;
       errorSweetAlert(action.payload)
      })

      // update code pr
      .addCase(updateCodePurchaseRequest.pending, (state) => {
        state.loading_edit_number = true;
      })
      .addCase(updateCodePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_edit_number = false;
        state.edit_number = action.payload;
      })
      .addCase(updateCodePurchaseRequest.rejected, (state, action : any) => {
        state.loading_edit_number = false;
       errorSweetAlert(action.payload)
      })
  },
});

export const { clearSnackBarValue, clearValueErrorQty, addLengthInfinite} = purchasesSlices.actions;
export default purchasesSlices.reducer;
