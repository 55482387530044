import { Box } from '@mui/material'
import { Button, Grid, Skeleton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import receivable from '../../../../assets/img/dashboard/receiver.png'
import unpaid from '../../../../assets/img/icon/unpaid.png'
import grn from '../../../../assets/img/icon/grn.png'
import invoice from '../../../../assets/img/icon/invoice.png'
import { useState, useEffect } from 'react';
import StatisticInvoice from './components/StatisticInvoice';
import SearchInvoice from './components/SearchInvoice';
import CardInvoice from './components/CardInvoice';
import { RootState } from '../../../../app/store';
import { getAccountPayable, getInvoicesData, getInvoicesDataLoadMore, getStatistivInvoice } from './redux/invoicesReducers';
import { addLengthInvInfinite, createOpenInvoice } from './redux/invoicesSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from 'sweetalert';
import LoaderProgress from '../../../../components/LoaderProgress';
import DataNotFound from '../../../../components/DataNotFound';
import { getPreferredAll } from '../../vendor_lists/redux/vendorListsReducer';
import { resetSnackBarPreferred } from '../../vendor_lists/redux/vendorListsSlice';
import { getVendorFulfillment } from '../../chat/redux/chatReducers';

const PageInvoices = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { 
        invoices, loading_invoices, total_invoices, open_invoice,
        loading_statistic_inv, statistic_inv, payable, loading_payable,
        loaded_invoices, total_infinite, proof_invoice
    } = useSelector((state : RootState) => state.invoice)
    const { create } = useSelector((state : RootState) => state.preferred)

    
    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [filterType, setFilterType] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [valueSearch, setValueSearch] = useState("");
    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);
    const [hasMore,setHasMore] = useState(true);

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
            'type[]' : filterType,
            'lastStatus[]' : filterStatus,
        }
         
        const newdata : any = await dispatch(getInvoicesDataLoadMore(params))
        if(newdata.type === "invoices-vendor-load-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                dispatch(addLengthInvInfinite(newdata.payload.data.length))
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    
    const isNotEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key)) 
                return true;
        }
        return false;
    }

    const onClickPayInvoice = (data:any) => {
        dispatch(createOpenInvoice(data))
    }

    function getFetchData() {
        const params = {
            limit,
            skip,
            search : valueSearch,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
            'type[]' : filterType,
            'lastStatus[]' : filterStatus,
        }
        dispatch(getInvoicesData(params))
        setFetch(true)
        setHasMore(true)
        setSkip(0)
        dispatch(getAccountPayable())
        dispatch(getStatistivInvoice())
    }
    
    useEffect(() => {
        getFetchData()
        // eslint-disable-next-line
    }, [valueSearch, rangeTopic, rangeFrom, rangeTo, filterType, filterStatus]);

    useEffect(() => {
        if(proof_invoice) {
            setTimeout(() => {
                getFetchData()
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proof_invoice]);

    useEffect(() => {
        if(open_invoice) {
            history.push({
                pathname: "/dashboard/finance/invoice",
                search: `?page=detail`, 
            })
        }
        // eslint-disable-next-line
    }, [open_invoice]);

    useEffect(() => {
        dispatch(getPreferredAll())
        dispatch(getVendorFulfillment())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(create) {
            dispatch(getPreferredAll())
            setTimeout(() => {
                dispatch(resetSnackBarPreferred())
            }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);

    useEffect(() => {
        if(loaded_invoices) {
            setDataInfinite(invoices)
        }
        // eslint-disable-next-line
    }, [invoices, loaded_invoices]);


    useEffect(() => {
        if(fetch && loaded_invoices) {
            if(total_infinite >= total_invoices) {
                setHasMore(false)
            }
        }
    }, [fetch, loaded_invoices, total_infinite, total_invoices]);

    useEffect(() => {
        if(loaded_invoices && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total_invoices) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, loaded_invoices, fetch, total_infinite]);


    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivInv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    

    return (
        <Stack m={2} >
            <BreadCrumbs 
                isPage={false}
                current="Invoices"
            />

            <Box my={2}>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Account Payable"
                            value={payable.length && payable[0].totalAmount}
                            loading={loading_payable}
                            currency={true}
                            background="#fff"
                            src={receivable}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Total Invoice"
                            value={total_invoices}
                            loading={loading_invoices}
                            currency={false}
                            background="#fff"
                            src={invoice}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Unpaid Invoice"
                            value={isNotEmpty(statistic_inv) && statistic_inv.Unpaid.count}
                            currency={false}
                            loading={loading_statistic_inv}
                            background="#fff"
                            src={unpaid}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Paid Invoice"
                            value={isNotEmpty(statistic_inv) && statistic_inv.Paid.count}
                            currency={false}
                            loading={loading_statistic_inv}
                            background="#fff"
                            src={grn}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Stack direction="row" justifyContent="space-between" alignItems="center" >
                <Box>
                    <HeadlineTitle 
                        title='Invoices'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
            </Stack>
            <SearchInvoice
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                setRangeTopic={setRangeTopic}
                setRangeFrom={setRangeFrom}
                setRangeTo={setRangeTo}
                filterType={filterType}
                setFilterType={setFilterType}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
            />
            
            { loading_invoices ?
            <Stack mt={1}>
                {[1,2,3,4].map(idx => (
                    <Box key={idx} my={1}>
                        <Skeleton sx={{borderRadius: 2}} height={175} variant='rectangular' />
                    </Box>
                ))}
            </Stack>
            :
            <div id="scrollableDivInv"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDivInv"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                { dataInfinite.length > 5 && 
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box>  }
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((itm:any, idx:number) => (
                            <Box key={idx}>
                                <CardInvoice item={itm} onClickPayInvoice={onClickPayInvoice} />
                            </Box>
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> 
                :
                <Box mt={2} mx={0.2}>
                    <DataNotFound 
                        message="Invoices is not found."
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> } 


        </Stack>
    )
}


export default PageInvoices;
