const waiting_verified : any = [
    {
      child: [],
      features: [],
      navigation : {
        flag: "BUYER",
        has_child: false,
        iconImage: "https://mpindo.sgp1.cdn.digitaloceanspaces.com/admin/81263af3-9bf6-4917-9f01-a582b0b7060ahome(3).svg",
        is_parent: true,
        name: "Dashboard",
        path: "/dashboard",
        sort_number: "1"
      }
    }
]

export const all_route = [
  "/dashboard",
  "/dashboard/profile",
  "/dashboard/purchase-requests",
  "/dashboard/department",
  "/dashboard/templates",
  "/dashboard/purchase-orders",
  "/dashboard/finance/invoice",
  "/dashboard/message",
  "/dashboard/teams",
  "/dashboard/custom-role",
  "/dashboard/products",
  "/dashboard/invitation",
  "/dashboard/vendor-list",
  "/dashboard/token",
  "/dashboard/report-charts",
  "/dashboard/report-download"
]

const step_register : any = [
    {
      child: [],
      features: [],
      navigation : {
        flag: "BUYER",
        iconImage: "https://mpindo.sgp1.cdn.digitaloceanspaces.com/admin/81263af3-9bf6-4917-9f01-a582b0b7060ahome(3).svg",
        parent: true,
        name: "Registration",
        path: "/company-registration",
        sort_number: "1" 
      }
    }
]

const admin_menu : any = [
  {
    child: [],
    features: [],
    navigation : {
      flag: "BUYER",
      iconImage: "https://mpindo.sgp1.cdn.digitaloceanspaces.com/admin/97375360-5098-45ef-a572-a85faf9d54bdhome(2).svg",
      parent: true,
      name: "Select Company",
      path: "/login/select-company",
      sort_number: "1"
    }
  }
]

// get path navigation for unregistered user
export const getNavigationPathNotRegistered = () => {
  let path = []
  for(let nav of step_register) {
    if(nav.child.length !== 0) {
      for(let child of nav.child) {
        path.push(child.navigation.path)
      }
    }
    path.push(nav.navigation.path)
  }
  return path
}

// get menu navigation for unregistered user
export const getNavigationMenuNotRegistered = () => {
  return step_register
}

// get path navigation for registered user
export const getNavigationPathRegistered = (navs : any) => {
  let path = []
  for(let nav of navs.navigations) {
    if(nav.child.length !== 0) {
      for(let child of nav.child) {
        path.push(child.path)
      }
    }
    path.push(nav.navigation.path)
  }
  return path
}

// get menu navigation for registered user
export const getNavigationMenuRegistered = (navs : any) => {
  const data = navs.navigations.sort(function (a:any, b:any) {
    return a.navigation.sort_number - b.navigation.sort_number;
  });
  return data
}

// get path for super admin
export const getNavigationPathSuperAdmin = () => {
  let path = []
  for(let nav of admin_menu) {
    if(nav.child.length !== 0) {
      for(let child of nav.child) {
        path.push(child.path)
      }
    }
    path.push(nav.navigation.path)
  }
  return path
}

// get menu for super admin
export const getNavigationMenuSuperAdmin = () => {
  return admin_menu
}

// get path for unverified
export const getNavigationPathUnverified = (navs:any) => {
  const menu =  [...waiting_verified, ...navs]
  let path = []
  for(let nav of menu) {
    if(nav.child.length !== 0) {
      for(let child of nav.child) {
        path.push(child.path)
      }
    }
    path.push(nav.navigation.path)
  }
  return path
}

// get menu for unverified
export const getNavigationMenuUnverified = (navs:any) => {
  const menu =  [...waiting_verified, ...navs]
  const data = menu.sort(function (a:any, b:any) {
    return a.navigation.sort_number - b.navigation.sort_number;
  });
  return data

}