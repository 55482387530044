import React, { useState, useEffect } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import category from '../../../../assets/img/icon/app.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MenuCategory : React.FC<any> = ({ data, loading, selectedCategory, setSelectedCategory }) => {
  const [checked, setChecked] = useState(false);
  const [arrayCategory, setArrayCategory] = useState<any>([]);
  
  useEffect(() => {
    if(data.length > 0){
      let obj = {
        _idx : 0,
        name : data[0].name, 
        category : data
      }
      setArrayCategory([obj])
    }
  }, [data]);

  const onMouseChange = (ele:any, idx :number) => {
    let index = idx + 1
    if(ele.child){
      if(arrayCategory.find((item:any) => item._idx === index)){
        const newState = arrayCategory.map((obj:any) => {
          if(obj._idx === index) {
            return  {
                _idx : index,
                name : ele.name,
                category: ele.child
              }
          }
          return obj;
        })
        let filter = newState.filter((item:any) => item._idx <= index)
        setArrayCategory(filter)
      } else {
        setArrayCategory([...arrayCategory, {
          _idx : idx + 1,
          name : ele.name,
          category: ele.child
        }])
      }
    } else {
      let remove = arrayCategory.filter((item:any) => item._idx < index)
      setArrayCategory(remove)
    }
  }

  function onCloseHover () {
    setChecked(false);
    // let obj = {
    //   _idx : 0,
    //   name : arrayCategory[0].name,
    //   category : arrayCategory[0].category
    // }
    // setArrayCategory([obj])
  }


  const Container = (
    <Stack>
      { loading ?
      <Stack className='container-menu'>
        <Stack flexDirection="column" >
        { Array.from(new Array(8)).map((_, index) => (
              <Box key={index}>
                  <Skeleton variant="text" height={40} width={190} /> 
              </Box>
          ))}
        </Stack>
      </Stack> :
      <Stack className='container-menu'>
        { arrayCategory.map((ele:any, idx:any) => (
          <Box className='box-menu' key={idx}>
            <Box className='list-menu'>
              { ele.category.map((ele:any, i:any) => (
                <Box 
                  key={i} 
                  className={arrayCategory[idx+1] && arrayCategory[idx+1].name === ele.name ? 'title-active' : 'title'} 
                  onMouseEnter={event => onMouseChange(ele, idx)}
                  onClick={() => {
                    setSelectedCategory([ele.name])
                    onCloseHover()
                    // if(!ele.child){
                    // }
                  }}
                >
                  <p>{ele.name}</p>
                  { !ele.child ? null : <ChevronRightIcon className='icon-right'/> }
                </Box>
              ))}
            </Box>
            <Box>
              { idx !== arrayCategory.length - 1 ? <Box className='divider-middle'/> : null }
            </Box>
          </Box>
        ))}
      </Stack> }
    </Stack>
  );
 
  return (
    <Box 
      onMouseLeave={event => {
        onCloseHover()
      }}
    >
        <Box 
          className='category' 
          onMouseEnter={event => {
            setChecked(true);
          }}
        >
            <img alt='category' src={category} />
            <p>{ selectedCategory.length ? selectedCategory[0] : "Category" }</p>
        </Box>
        <Box sx={{ display: !checked ? 'none' : 'flex', position: 'absolute', zIndex: 2000 }}>
            <Fade in={checked}>{Container}</Fade>
        </Box>
    </Box>
  );
}

export default MenuCategory