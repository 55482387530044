import { Grid, Box, Skeleton } from "@mui/material"

export default function LoadingContent  ()  {
    return (
        <>
        <Grid item xs={12} sm={12} md={8}>
            <Box className="product-cart">
                <Box>
                    <Box p={1} ml={1}>
                        <Skeleton width={240} height={50} />
                    </Box>
                    <Box className="line-divider-bottom" />
                    <Box px={2}>
                        <Skeleton height={40} />
                        <Skeleton width={290} height={40} />
                        <Skeleton width={290} height={40} />
                    </Box>
                    <Box className="line-divider-bottom" />
                    <Box px={2}>
                        <Skeleton height={40} />
                        <Skeleton width={290} height={40} />
                        <Skeleton width={290} height={40} />
                    </Box>
                    <Box className="line-divider-bottom" />
                    <Box px={2}>
                        <Skeleton height={40} />
                        <Skeleton width={290} height={40} />
                        <Skeleton width={290} height={40} />
                    </Box>
                </Box>
            </Box>
        </Grid> 
        <Grid item xs={12} sm={12} md={4}>
            <Box className="product-summary">
                <Box px={2} pt={1} pb={0.5}>
                   <Skeleton height={50}/>
                </Box>
                <Box px={2} className="line-divider-bottom" />
                <Box className="summary" px={2}>
                    <Skeleton height={40} />
                    <Skeleton height={40}/>
                    <Skeleton height={40}/>
                </Box>
                <Box className="line-divider-bottom" />
                <Box className="summary" px={2}>
                    <Skeleton height={47}/>
                    <Skeleton height={47}/>
                </Box>
            </Box>
        </Grid> 
        </>
    )
}
