import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'
import {userCredentials} from '../../../../utilities/config'

export const postEmailBlast = createAsyncThunk(
    'invitation/post', 
    async (value : any, { rejectWithValue }) => {
        try {
            const body = {
                "emails": value.target,
                "platform": value.channel
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_SERVER}/sender`, body, {
                headers: {
                    Authorization : `Bearer ${userCredentials.id_token}`
                }
            },)
            if(response) { 
                return {data : true, message : "Success send email invitation"}
            } else {
                return rejectWithValue(response)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})
