import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userCredentials } from '../../../../utilities/config';
import {
    Sidebar,
    Search,
    ConversationList,
    Conversation,
    Avatar,
 } from '@chatscope/chat-ui-kit-react';
import profile from '../../../../assets/img/icon/profile.png'
import { ChatMessage, listChat } from '../interface';
import { Box } from '@mui/material';
import { RootState } from '../../../../app/store';
import { getConversationRoom } from '../redux/chatReducers';
import { initConversationActive } from '../redux/chatSlices';
import { socket } from '../../../../utilities/socket';


const ChatList = () => {
    const dispatch = useDispatch()
    const { conversation_lists, new_message, conversation_active } = useSelector((state:RootState) => state.chats);

    const [search,setSearch] = useState('');

    function getUnread(co_id: string) {
        return new_message.filter((c: any) =>
            (c.from_company_id === co_id || c.to_company_id === co_id)
            && c.from_company_id !== userCredentials.company_id
            && !c.read_by
        );
    }

    const chatUnRead = (row:any) => {
        return row.filter((data:any) => data.from_company_id !== userCredentials.company_id && ( !data.read_by || !data.read_by[userCredentials.company_id] ))
    }

    function getUnreadCount(company_id: string) {
        let unread = [...new_message]
        return unread.filter((item: any) => item.from_company_id === company_id).length
    }

    function chat_prop(element: any) {
        const unread = getUnread(element.company_id);
        return {
            onClick() {
                dispatch(initConversationActive(element))
                let payload = { company_id : element.company_id, new_message }
                dispatch(getConversationRoom(payload))
                if(unread?.length) {
                    socket.emit('read_msg', chatUnRead(new_message).map((c: ChatMessage) => c._id))
                }
            },
            name : element.company_name,
            info: element.last_message.attachment ? "[image]" : element.last_message.text,
            // lastActivityTime: handleTime(element.last_message?.createdAt),
            unreadCnt: getUnreadCount(element.company_id)
        }
    }

    const handleSearch = (l:listChat) =>
        search.length === 0
        || (l.company_name && l.company_name?.toLowerCase().indexOf(search.toLowerCase()) > -1)
    

    return(
        <Sidebar position="left" scrollable={false}>
            {conversation_lists.length > 0
                ? <Search placeholder="Search..." onChange={setSearch}/>
                : <Box sx={{textAlign:'center', pt:3}}>No Conversation yet</Box>
            }
            <ConversationList>
                    {conversation_lists.filter(handleSearch).map((item: any, idx: number) =>
                        <Conversation 
                            key={idx} {...chat_prop(item)} 
                            active={conversation_active?.company_id === item.company_id}
                        >
                            <Avatar 
                                src={item.company_logo || profile} 
                                name={item.company_name} size="md"
                            />             
                        </Conversation>
                    )}
            </ConversationList>
        </Sidebar>
    )
}

export default ChatList