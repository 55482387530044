// @ts-ignore
import { MainContainer } from '@chatscope/chat-ui-kit-react'
import { Box, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle'
import ChatAction from './components/ChatAction'
import ChatList from './components/ChatList'

function ChatPage() {

    const { conversation_lists} = useSelector((state:RootState) => state.chats);

    return(
    <Box m={2}>
        <BreadCrumbs 
            isPage={false}
            current="Message"
        />
        <Stack direction="row" justifyContent="space-between" py={1} >
            <Box>
                <HeadlineTitle 
                    title='Message'
                    variant="h6"
                    bold="bold"
                />
            </Box>
        </Stack>
        <Box className='chat-container'>
            <MainContainer responsive style={{borderRadius: 10}}>
            {conversation_lists.length > 0 ? 
            <>
                <ChatList/>
                <ChatAction width={45}/>
            </> 
            : 
            <>
                <ChatAction width={50}/>
            </> }
            </MainContainer>
        </Box>
    </Box>
  )
}

export default ChatPage
