import { Grid, Stack, Box, Chip, Skeleton, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import MenuCategory from '../../products/component/Category'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { getAddressCoverage, getFilterData, getProducts, getProductsLoadMore } from '../../products/redux/productReducers'
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'
import Fade from '@mui/material/Fade';
import hideicon from '../../../../assets/img/icon/hide.png'
import CardItemPR from './CardItemPR'
// import LoaderBackdrop from '../../../../components/LoaderBackdrop'
import HeadlineTitle from '../../../../components/HeadlineTitle'
import CardItemTemplate from '../../template/components/CardItemTemplate'
import swal from 'sweetalert'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoaderProgress from '../../../../components/LoaderProgress'
import DataNotFound from '../../../../components/DataNotFound'
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterMobile from '../../products/component/FilterMobile'

const SearchingProduct : React.FC<any> = ({
    selectedArea, setSelectedArea, template
}) => {

    const dispatch = useDispatch()
    const { 
        data, total, loading, loaded, 
        coverage, filter_brand,
        loading_filter, loading_coverage,
        filter_category, total_infinite, filter_vendor 
    } = useSelector((state: RootState) => state.products) 
    const { 
         add_item
    } = useSelector((state: RootState) => state.purchases)
    const { 
        add_item : template_add_item
    } = useSelector((state: RootState) => state.templates)

    const [show, setShow] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>([]);
    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [limit] = useState(24);
    const [skip, setSkip] = useState(0);
    const [hasMore,setHasMore] = useState(true);
    const [sortTopic, setSortTopic] = useState("createdAt");
    const [sortValue, setSortValue] = useState("DESC");
    const [sortBy, setSortBy] = useState("relevant");
    const [selectedVendor, setSelectedVendor] = useState<any>([]);
    const [selectedBrand, setSelectedBrand] = useState<any>([]);
    const [inputSearch, setInputSearch] = useState("");
    const [valueSearch, setValueSearch] = useState("");

    const [openFilter, setOpenFilter] = useState(false);


    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    }

    function onSortBy(e:any) {
        setSortBy(e.target.value)
        if(e.target.value === "relevant") {
            setSortTopic("createdAt")
            setSortValue("DESC")
        } else if(e.target.value === "price-asc") {
            setSortTopic("price")
            setSortValue("ASC")
        } else if(e.target.value === "price-desc") {
            setSortTopic("price")
            setSortValue("DESC")
        }
    }

    function onCloseHover () {
        setShow(false);
    }

    const fetchMoreData = async () => {
        const params = {
            skip : skip + 1,
            limit,
            sortTopic : sortTopic,
            sortValue : sortValue,
            'search' : valueSearch,
            'coverages[]' : selectedArea,
            'category[]' : selectedCategory
        }
         
        const newdata : any = await dispatch(getProductsLoadMore(params))
        if(newdata.type === "productss-load-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };


    useEffect(() => {
        dispatch(getAddressCoverage())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const params : any = {
            skip:0, 
            limit,
            sortTopic : sortTopic,
            sortValue : sortValue,
            'search' : valueSearch,
            'coverages[]' : selectedArea,
            'company[]' : selectedVendor,
            'brand[]' : selectedBrand,
            'category[]' : selectedCategory
        } 
        const param = {
            'coverages[]' : selectedArea,
        }
        if(selectedArea !== "") {
            dispatch(getProducts(params))
            dispatch(getFilterData(param))
            setFetch(true)
            setHasMore(true)
            setSkip(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueSearch, selectedArea, selectedBrand, selectedVendor, selectedCategory, sortTopic, sortValue]);

    useEffect(() => {
       if(add_item || template_add_item) {
            onCloseHover()
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [add_item, template_add_item]);

    useEffect(() => {
        if(loaded) {
            setDataInfinite(data)
        }
        // eslint-disable-next-line
    }, [data, loaded]);

    useEffect(() => {
        if(fetch && loaded) {
            if(total_infinite >= total) {
                setHasMore(false)
            }
        }
    }, [fetch, loaded, total_infinite, total]);

    useEffect(() => {
        if(loaded && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, loaded, fetch, total_infinite]);

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivProductPR").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
          setValueSearch(inputSearch);
        }, 500); // set a delay of 500 milliseconds
    
        return () => {
          clearTimeout(debounceSearch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inputSearch]);

    const Container = (
        <Box className='container-hover-pr'>
            <Box className='content-hover-pr'>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Box className='filter'>
                        { selectedCategory.length !== 0 ? 
                            <Box>
                                { selectedCategory.length ? 
                                <Chip 
                                    sx={{m:0.5}}
                                    label={selectedCategory[0]}
                                    color="primary"
                                    variant="outlined"
                                    onDelete={() => setSelectedCategory([])} 
                                /> : null }
                            </Box>
                            :
                            <Box>
                                <HeadlineTitle 
                                    title="Select category for spesific searching."
                                    bold="400"
                                    variant="body1"
                                />
                            </Box>
                        }
                        <Box className='sort'>
                            <Box onClick={() => setOpenFilter(true)} className='filter-product' >
                                <FilterListIcon sx={{ color: 'ActiveBorder' }}/>
                                <p>Filter</p> 
                            </Box>
                            <FilterMobile 
                                open={openFilter} 
                                setOpen={setOpenFilter} 
                                coverage={coverage}
                                loading_coverage={loading_coverage}
                                selectedArea={selectedArea}
                                setSelectedArea={setSelectedArea}
                                filter_vendor={filter_vendor}
                                loading_filter={loading_filter}
                                setSelectedVendor={setSelectedVendor}
                                selectedVendor={selectedVendor}
                                filter_brand={filter_brand} 
                                selectedBrand={selectedBrand}
                                setSelectedBrand={setSelectedBrand}
                                vendor={true}
                                hidearea={true}
                            />
                                <h6>Sort By</h6>
                                <Box>
                                    <select name="sortby" id="sortby" onChange={onSortBy} value={sortBy}>
                                        <option value="relevant">Relevant</option>
                                        <option value="price-desc">High Price</option>
                                        <option value="price-asc">Low Price</option>
                                    </select>
                                </Box>
                                <Box 
                                    component="img"
                                    src={hideicon}
                                    sx={{
                                        width: 36,
                                        height: 36,
                                        cursor: 'pointer',
                                        marginLeft: 2
                                    }}
                                    onClick={onCloseHover}
                                />
                        </Box>
                    </Box>
                </Stack>

                <Box  className="container-content">
                    { loading ? 
                    <LoaderCard />
                    : 
                    <Box id="scrollableDivProductPR"  className='overhiddenflowProductPR'>
                    { loaded  && 
                        <div >
                            { dataInfinite.length > 0 ? 
                            <Box mx={0.5}>
                                <InfiniteScroll
                                    dataLength={dataInfinite.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<LoaderProgress/>}
                                    scrollableTarget="scrollableDivProduct"
                                    endMessage={
                                        <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                            <Box>
                                                <HeadlineTitle title="No more products." variant="body2" bold='400' />
                                            </Box>
                                            { data.length > 13 && 
                                            <Box>
                                                <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                                    <p>Back to Top</p>
                                                </Button>
                                            </Box>  }
                                        </Stack>
                                    }
                                >
                                    <Grid container spacing={2} >
                                        { dataInfinite.map((ele:any, i : any) => (
                                            <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={i}>
                                                { template ? 
                                                <CardItemTemplate ele={ele} selectedArea={selectedArea}  /> :
                                                <CardItemPR ele={ele} selectedArea={selectedArea}  /> }
                                            </Grid>
                                        ))}
                                    </Grid>
                                </InfiniteScroll>
                            </Box> :
                            <Box mt={2}>
                                <DataNotFound 
                                    message="Sorry, we couldn't find any Products for you" 
                                    caption="Try to search another keyword or filter."
                                />
                            </Box> }
                        </div> }
                    </Box> }
                </Box>
            </Box>
        </Box>
      );

    return ( 
    <Stack>
        {/* <LoaderBackdrop loading={template_loading_add} /> */}
        <Box className='header-search' my={1}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={1.9} sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                    <MenuCategory 
                        data={filter_category}
                        loading={loading_filter}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={10.1}>
                    <form onSubmit={onSearch}>
                        <Box className='search-box' >
                            <input 
                                placeholder='Search Product'
                                onChange={(e) => setInputSearch(e.target.value)}
                                value={inputSearch}
                                onFocus={() => setShow(true)}
                            />
                            <Box>
                                { valueSearch === "" ? 
                                <Box className='notyping'>
                                    <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                                </Box>
                                :
                                <Box className='typing'>
                                    <img 
                                        alt='removeimg' className='removeimg' src={removeimg} 
                                        onClick={() => {
                                            setInputSearch("")
                                            setValueSearch("")
                                        }}
                                    />
                                    <Box className='divider-line'/>
                                    <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                                </Box> }
                            </Box>
                        </Box>
                    </form>
                   
                </Grid>
                {/* <Grid item xs={12} md={12} lg={2.1}  sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                    <Box className='recently-view'>
                        <img alt='category' src={hstory} />
                        <p>Recently Added</p>
                    </Box>
                </Grid> */}
            </Grid>    
        </Box>
        <Box className='hover-container'>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Box sx={{ display: !show ? 'none' : 'block' }}>
                        <Fade in={show}>{Container}</Fade>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Stack>
  )
}


const LoaderCard = () => {
    return (
    <Stack mt={2} >
        <Grid container spacing={2} sx={{ width: '100%' }}>
            { Array.from(new Array(6)).map((_, idx) => (
                <Grid item xs={12} md={6} sm={4} lg={2} xl={2} key={idx}>
                    <Stack>   
                        <div>
                            <Skeleton variant="rectangular" height={150} />
                        </div>
                        <Stack p={2} >
                            { Array.from(new Array(3)).map((_, index) => (
                                <div className='title-address' key={index}>
                                    <Skeleton variant="text" height={25} /> 
                                </div>
                            ))}
                            <div className='title-address'>
                                <Skeleton variant="text" height={47} />
                            </div>
                            <div className='title-address'>
                                <Skeleton variant="text" height={47} />
                            </div>
                        </Stack>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    </Stack>
    )
}

export default SearchingProduct