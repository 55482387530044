import { Stack, Box } from '@mui/material'
import empty from '../assets/img/icon/notfound.png'

function EmptyData(prop:any) {
    const { message } = prop

    return (
        <Stack mx={0.5} pb={4} id="product-notfound">
            <Box >
                <img alt="empty" src={empty} />
            </Box>
            <Box pl={2}>
                <h6>{message}</h6>
                <p>Please try to change filter or search.</p>
            </Box>
        </Stack>
    )
}

export default EmptyData