import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false

const checkIsSavedPurchases = async () => {
    const saved : any = await window.localStorage.getItem('saved') || ""
    if(saved !== "") {
        let parse = JSON.parse(saved)
        window.localStorage.setItem('saved', JSON.stringify({ 
            saved : false, 
            _id : parse._id, 
            code: parse.code,
            department: parse.department,
            updatedAt : parse.updatedAt,
        }))
    }
    return saved
}

export const getPurchaseServerData = createAsyncThunk(
    'purchase-request/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseServerLoadMore = createAsyncThunk(
    'purchase-request-loadmore/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseOpenStatus = createAsyncThunk(
    'purchase-open/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/request/open`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getOnePurchaseRequest = createAsyncThunk(
    'purchase-slug/get',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/${id}/details`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getUpdatePurchaseRequest = createAsyncThunk(
    'purchase-slug/update',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/${id}/details`, {
                headers
            })
            if(response.data) { 
                let items = response.data.items.map((element:any) => ({...element, vendor_name : element.vendor.name}) )
                const overwrite = await db.collection('purchase_request').set(items)
                window.localStorage.setItem('saved', JSON.stringify({ 
                    saved : true, 
                    _id : response.data._id, 
                    code: response.data.code,
                    department: response.data.department === "N/A" ? "" : response.data.department,
                    updatedAt : response.data.updatedAt,
                }))
                let area = {
                    value : response.data.address.city,
                    label : response.data.address.city,
                    address : response.data.address,
                }
                localStorage.setItem('area', JSON.stringify(area))
                if(overwrite) {
                    return true
                }
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateCodePurchaseRequest = createAsyncThunk(
    'purchase-code/update',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/purchase/request/${body.id}/number`, body.data, {
                headers
            })
            if(response.data) { 
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const savePurchaseRequest = createAsyncThunk(
    'purchase-request/create',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/purchase/request`, body, {
                headers
            })
            if(response) { 
                localStorage.setItem('saved', JSON.stringify({
                    saved : true, 
                    _id : response.data.id.id, 
                    code : response.data.id.code,
                    department : body.department,
                    updatedAt : body.updatedAt,
                }))
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const addItemToPurchase = createAsyncThunk(
    'purchase-add/update',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/addItem`, body, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updatePurchaseRequest = createAsyncThunk(
    'purchase-request/update',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/purchase/request/${body.id}`, body.data, {
                headers
            })
            if(response) { 
                localStorage.setItem('saved', JSON.stringify({
                    saved : true, 
                    _id : body.id, 
                    code : body.code,
                    department : body.data.department,
                    updatedAt : body.data.updatedAt,
                }))
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const submitPurchaseRequest = createAsyncThunk(
    'purchase-request/submit',
    async (id:string, { rejectWithValue }) => {
        try {
            const body = {
                user : userCredentials.fullname,
                note : "Submit Purchase Request",
                lastStatus : "Open"
            }
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/request/submit/${id}`, body, {
                headers
            })
            if(response) { 
                await db.collection('purchase_request').delete()
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const approvePurchaseRequest = createAsyncThunk(
    'purchase-request/approve',
    async (id:string, { rejectWithValue }) => {
        try {
            const body = {
                user : userCredentials.fullname,
                note : "Approve Purchase Request",
                lastStatus : "Submit"
            }
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/request/approved/${id}`, body, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const rejectPurchaseRequest = createAsyncThunk(
    'purchase-request/reject',
    async (data:any, { rejectWithValue }) => {
        try {
            const body = {
                user : userCredentials.fullname,
                note : data.note,
                lastStatus : "Submit"
            }
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/request/reject/${data.id}`, body, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const removePurchaseRequest = createAsyncThunk(
    'purchase-request/remove',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/purchase/request/${id}`, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const printPurchaseRequest = createAsyncThunk(
    'purchase-request/print',
    async (id:string, { rejectWithValue }) => {
        try {
            const body = {
                "data": {
                    "id": id,
                    "company": "null"
                }   
            }
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/pdf/purchase-request`, body, {
                headers
            })
            if(response.data) { 
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseLocalData = createAsyncThunk(
    'purchase-local/get',
    async (_, { rejectWithValue }) => {
        try {
            const purchases = await db.collection('purchase_request').get()
            if(purchases) {
                return purchases
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const addPurchaseItem = createAsyncThunk(
    'purchase-add/post',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/calculate-item`, body, {headers})
            if(response.data.result) { 
                const result = {
                    ...response.data.result, 
                    ...response.data.setting, 
                    note : body.item.note ? body.item.note : "",
                    category : body.item.category || "N/A",
                    vendor_name : response.data.result.vendor.name,
                } 
                const purchases = await db.collection('purchase_request').get()
                if(purchases.find((element : any) => element._id === result._id)) {
                    try {
                        const udpdate_purchases = await db.collection('purchase_request')
                        .doc({ _id: result._id })
                        .update(result)
                        if(udpdate_purchases) {
                            checkIsSavedPurchases()
                            return {
                                data: true,  
                                type : "Updated",
                                type_error : "", 
                                value_error : 0,
                                product_id : "" 
                            }
                        }
                    } catch (error) {
                        return rejectWithValue(error)
                    }
                } else {
                    try {
                        const add_purchases = await db.collection('purchase_request').add(result)
                        if(add_purchases) {
                            checkIsSavedPurchases()
                            return {
                                data: true, 
                                type : "Added",
                                type_error : "", 
                                value_error : 0,
                                product_id : "" 
                            }
                        }
                    } catch (error) {
                        return rejectWithValue(error)
                    }
                }
            } else {
                return { 
                    data: false, 
                    type : "", 
                    type_error : response.data.type, 
                    value_error : response.data.data,
                    product_id :  body.item._id
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const removePurchasesItem = createAsyncThunk(
    'purchase/remove-item', 
    async (id : string, { rejectWithValue }) => {
        const purchases = await db.collection('purchase_request').get()
        if(purchases.find((element : any) => element._id === id)) {
            try {
                const result = await db.collection('purchase_request')
                    .doc({ _id: id })
                    .delete()
                if(result) {
                    checkIsSavedPurchases()
                    return true
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const removePurchasesVendor = createAsyncThunk(
    'purchase/remove-vendor', 
    async (value : string, { rejectWithValue }) => {
        const collection = await db.collection('purchase_request').get();
        if(collection.find((element : any) => element.vendor.name === value)) {
            try {
                const result = await db.collection('purchase_request')
                    .doc({ vendor_name : value })
                    .delete()
                if(result) {
                    checkIsSavedPurchases()
                    return true
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        } else {
            return rejectWithValue('Vendor not found')
        }
    }
)

export const addeNoteToVendorItem = createAsyncThunk(
    'purchase/note-vendor', 
    async (value : any, { rejectWithValue }) => {
        const collection = await db.collection('purchase_request').get();
        if(collection.find((element : any) => element.vendor.name === value.name)) {
            try {
                const result = await db.collection('purchase_request')
                    .doc({ vendor_name : value.name })
                    .update({
                        note : value.note
                    })
                    
                if(result) {
                    checkIsSavedPurchases()
                    return true
                } else {
                    return rejectWithValue("Something is error")
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        } else {
            return rejectWithValue('Vendor not found')
        }
    }
)

export const clearPurchasesLocalData = createAsyncThunk(
    'purchase/clear', 
    async (_, { rejectWithValue }) => {
        try {
            await db.collection('purchase_request').delete()
            return true
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
