import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false


export const getTemplateServerData = createAsyncThunk(
    'template/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/template`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getTemplateServerDataLoadMore = createAsyncThunk(
    'template-load-more/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/template`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getTemplateOpen = createAsyncThunk(
    'template-open/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/template/fetch`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

 
export const getOneTemplate = createAsyncThunk(
    'template-slug/get',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/template/${id}/details`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const additemToTempalte = createAsyncThunk(
    'template-add/update',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/template/additem`, body, {
                headers
            })
            if(response.data) { 
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getUpdateTemplate = createAsyncThunk(
    'template-slug/update',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/template/${id}/details`, {
                headers
            })
            if(response.data) { 
                let items = response.data.items.map((element:any) => ({...element, vendor_name : element.vendor.name}) )
                const overwrite = await db.collection('template').set(items)
                if(overwrite) {
                    window.localStorage.setItem('edited', JSON.stringify({ 
                        _id : response.data._id, 
                        name: response.data.name, 
                        department : response.data.department === "N/A" ? "" : response.data.department,
                    }))
                    let area = {
                        value : response.data.address.city,
                        label : response.data.address.city,
                        address : response.data.address,
                    }
                    localStorage.setItem('area', JSON.stringify(area))
                    return true
                }
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getUseTemplate = createAsyncThunk(
    'template-use/get',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/template/${id}/use`, {
                headers
            })
            if(response.data) { 
                let items = response.data.items.map((element:any) => ({...element, vendor_name : element.vendor.name}) )
                const overwrite = await db.collection('purchase_request').set(items)
                if(overwrite) {
                    window.localStorage.setItem('saved', JSON.stringify({ 
                        saved : false, 
                        _id : "", 
                        code: "",
                        department: response.data.department === "N/A" ? "" : response.data.department,
                    }))
                    let area = {
                        value : response.data.address.city,
                        label : response.data.address.city,
                        address : response.data.address,
                    }
                    localStorage.setItem('area', JSON.stringify(area))
                    return true
                }
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const createNewTemplate = createAsyncThunk(
    'template/create',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/template`, body, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateTemplate = createAsyncThunk(
    'template/update',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/template/${body.id}`, body.data, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removeTemplate = createAsyncThunk(
    'Template/remove',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/template/${id}`, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getTemplateLocalData = createAsyncThunk(
    'Template-local/get',
    async (_, { rejectWithValue }) => {
        try {
            const Templates = await db.collection('template').get()
            if(Templates) {
                return Templates
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const addTemplateItem = createAsyncThunk(
    'Template-add/post',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/calculate-item`, body, {headers})
            if(response.data.result) { 
                const result = {
                    ...response.data.result, 
                    ...response.data.setting, 
                    vendor_name : response.data.result.vendor.name,
                } 
                const templates = await db.collection('template').get()
                if(templates.find((element : any) => element._id === result._id)) {
                    try {
                        const udpdate_templates = await db.collection('template')
                        .doc({ _id: result._id })
                        .update(result)
                        if(udpdate_templates) {
                            return {
                                data: true, 
                                type : "Updated",
                                type_error : "", 
                                value_error : 0,
                                product_id : "" 
                            }
                        }
                    } catch (error) {
                        return rejectWithValue(error)
                    }
                } else {
                    try {
                        const add_templates = await db.collection('template').add(result)
                        if(add_templates) {
                            return {
                                data: true, 
                                type : "Added",
                                type_error : "", 
                                value_error : 0,
                                product_id : "" 
                            }
                        }
                    } catch (error) {
                        return rejectWithValue(error)
                    }
                }
            } else {
                return { 
                    data: false, 
                    type : "", 
                    type_error : response.data.type, 
                    value_error : response.data.data,
                    product_id :  body.item._id
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const removeTemplatesItem = createAsyncThunk(
    'Template/remove-item', 
    async (id : string, { rejectWithValue }) => {
        const Templates = await db.collection('template').get()
        if(Templates.find((element : any) => element._id === id)) {
            try {
                const result = await db.collection('template')
                    .doc({ _id: id })
                    .delete()
                if(result) {
                    return true
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const removeTemplatesVendor = createAsyncThunk(
    'template/remove-vendor', 
    async (name : string, { rejectWithValue }) => {
        const templates = await db.collection('template').get()
        if(templates.find((element : any) => element.vendor.name === name)) {
            try {
                const result = await db.collection('template')
                    .doc({ vendor_name : name })
                    .delete()
                if(result) {
                    return true
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        } else {
            return rejectWithValue('Vendor not found')
        }
    }
)

export const clearTemplatesLocalData = createAsyncThunk(
    'template/clear', 
    async (_, { rejectWithValue }) => {
        try {
            await db.collection('template').delete()
            return true
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)