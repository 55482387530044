import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import imagebtn from '../../../../../assets/img/icon/image.png'
import ViewImage from '../../../../../components/ViewImage';
import { submitPaymentProofInvoice } from '../redux/invoicesReducers';
const Input = styled('input')({
  display: 'none',
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogProof : React.FC<any> = ({ open, setOpen, idTransaction, fileUrl, setFileUrl  }) => {

    const dispatch = useDispatch()
    const [openView, setOpenView] = React.useState(false);
    const [loading, setLoading] = React.useState(false);    
    
    function handleClose () {
        setOpen(false);
        setFileUrl({name : '', url: '', type: 'upload'})
    };
    
    const onChangeValueFile = (e : any) => {
      if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
      setLoading(true)
      const random = uuid();
      setTimeout(() => {
          var S3 = require("aws-sdk/clients/s3");
          const s3bucket = new S3({
              endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
              accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
              secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
          });
      
          if (e.target.files && e.target.files[0]) {
          const blob = e.target.files[0]
          const file_name = blob.name.replace(/\s/g, "")
          const params = { Body: blob, 
                          Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                          Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                          };
          s3bucket.putObject(params)
          .on('build', (request : any) => {
              request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
              request.httpRequest.headers['Content-Length'] = blob.size;
              request.httpRequest.headers['Content-Type'] = blob.type;
              request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
              request.httpRequest.headers['x-amz-acl'] = 'public-read';
          })
          .send((err : any, data : any) => {
              if (err){  
                  swal(`${err}`, `${err.stack}`, 'error')
              } 
              else {      
                  const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                  setFileUrl({...fileUrl, name : file_name, url : imageUrl, type : 'upload'})
                  setLoading(false)
              }}
          )} 
      }, 1000);
      } else {
          setLoading(false)
          swal("Error", "File type must be JPG, JPEG or PNG", "error")
      }
    }

    function onClickSubmit (id:string) {
      if(fileUrl.url !== '') {
        let payload = {
          id : id,
          body : {
            image: fileUrl.url,
          }
        }
        dispatch(submitPaymentProofInvoice(payload))
      } else {
        swal("Error", "Please upload image!", "error")
      }
    }

    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{fileUrl.type === "upload" ? "Upload" : "View"} Proof Payment</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          { loading ? 
          <Box className="proofpayment">
            <Skeleton height={200} width={200} />
          </Box>
          :
          <Box className="proofpayment">
            { fileUrl.url === "" ? 
            <Box className='box-images-btn'>
              <label htmlFor={`icon-button-files`} >
                <Input accept="image/*" id={`icon-button-files`} type="file"  onChange={(e) => onChangeValueFile(e)} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <img src={imagebtn} alt="icon"/>
                </IconButton>
                <p>Click here to Browse file</p>
              </label>
            </Box>
            :
            <Stack flexDirection="column" className='image-preview' >
              <img alt="file" src={fileUrl.url} onClick={()=> setOpenView(true)} />
              <Box className='btn-remove-img'
                  onClick={() => setFileUrl({...fileUrl, name : '', url : ''})}
              >
              </Box>
            </Stack> }
          </Box> }
        </DialogContent>
        <DialogActions>
          { fileUrl.type === "upload" ? 
          <Button 
            color="primary"
            size="small" autoFocus variant='contained' 
            disabled={fileUrl.url === '' ? true : false}
            onClick={() => onClickSubmit(idTransaction)}
          >
            Submit
          </Button> : null }
        </DialogActions>
      </BootstrapDialog>
      <ViewImage open={openView} setOpen={setOpenView} url={fileUrl.url} />
    </div>
  );
}

export default DialogProof;