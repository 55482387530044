import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import remove from '../../../../assets/img/icon/remove.png'
import StoreIcon from '@mui/icons-material/Store';
import plus from '../../../../assets/img/icon/plus.png'
import minus from '../../../../assets/img/icon/minus.png'
import { useDispatch, useSelector } from 'react-redux';
import { addTemplateItem, removeTemplatesItem, removeTemplatesVendor } from '../redux/templateReducers';
import { RootState } from '../../../../app/store';
import { clearValueErrorQty } from '../redux/templateSlice';

const ItemList : React.FC<any> = ({ item, index }) => {
    const dispatch = useDispatch()

    function sumTotal (data : any, name : string) {
        const price = data.reduce((total : any, itm : any) => {
          return total + itm[name]
        }, 0)
        return parseInt(price);
    } 

    function sumTotalDownPayment (data : any) {
        const price = data.reduce((total : any, itm : any) => {
          return total + (itm.down_payment / 100 * itm.total)
        }, 0)
        return parseInt(price);
    } 

    const removeVendorItems = (params:any) => {
        dispatch(removeTemplatesVendor(params[1][0].vendor.name))
    }

    return (
    <div key={index}>
        <Box className="cart-list">
            <Box className='container-company'>
                <Box className="item-company">
                    <StoreIcon className='icon-store' />
                    <Box>
                        <h2>{item[1][0].vendor.name}</h2>
                        <p>{item[1][0].warehouse.city.toLowerCase()}</p>
                    </Box>
                </Box>
                <Box>
                    <Box className='remove' onClick={() => removeVendorItems(item)}>
                        <h6>Remove</h6>
                    </Box>
                </Box>
            </Box>
            { item[1].map((ele : any, idx : number) => (
                <ProductItem ele={ele} idx={idx} key={idx} />
            ))}
            <Box className='bottom-content-vendor'>
                <Box className='total-dp'>
                    <h2>Total Down Payment Rp.{sumTotalDownPayment(item[1]).toLocaleString()}</h2>
                </Box>
                <Box className='line-divide' />
                <Box className='total-tax'>
                    <h2>PPN Rp.{sumTotal(item[1], 'total_tax').toLocaleString()}</h2>
                </Box>
                <Box className='line-divide' />
                <Box className='total-prices'>
                    <h2>Total : Rp.{(sumTotal(item[1], 'total') - sumTotal(item[1], 'total_tax')).toLocaleString()}</h2>
                </Box>
            </Box>
        </Box>
        <Box className="line-divider-bottom" />
    </div>
  )
}

const ProductItem : React.FC<any> = ({ele, idx}) => {
    const dispatch = useDispatch()

    const { type_error, value_error, product_id } = useSelector((state: RootState) => state.purchases)

    const [focused, setFocused] = React.useState(false)

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const [quantity, setQuantity] = useState(0);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [interacted, setInteracted] = useState<boolean>(false);

    function handleIncrease() {
        setQuantity(quantity + 1); 
        if (!interacted) {
            setInteracted(true);
          }
        resetTimer();
    }

    function handleDecrease() {
        if (quantity > 0) {
            setQuantity(quantity - 1);
            if (!interacted) {
              setInteracted(true);
            }
            resetTimer();
          }
    }

    function handleInactive(count:number) {
        let product = {
            item : {
                ...ele,
                quantity : count,
            },
            company : "null"
        }
        dispatch(addTemplateItem(product))
    }

    function resetTimer(): void {
        if (timer) {
          clearTimeout(timer);
        }
        if (interacted) {
          setTimer(setTimeout(() => handleInactive(quantity), 1000));
        }
      }

    useEffect(() => {
        resetTimer();
        return (): void => {
          if (timer) {
            clearTimeout(timer);
          }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);

    const onChange = (e : any) => {
        let value = parseFloat(e.target.value);
        setQuantity(value)
    }

    useEffect(() => {
        setQuantity(ele.quantity)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ele.quantity]);

    useEffect(() => {
        if(type_error !== "") {
            setTimeout(() => {
                setQuantity(ele.quantity)
                dispatch(clearValueErrorQty())
            }, 1500);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type_error]);

    useEffect(() => {
        if(!focused) {
            if(quantity === 0 || type_error !== "") {
                setQuantity(ele.quantity)
            } else if (quantity !== ele.quantity) {
                let product = {
                    item : {
                        ...ele,
                        quantity : quantity,
                    },
                    company : "null"
                }
                dispatch(addTemplateItem(product))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused]);

    return (
        <Box className='item-product' key={idx}>
            <Box 
                component="img"
                src={ele.images[0]}
            />
            <Box className='right-product'>
                <Box className='title-product'>
                    <h2>{ele.name}</h2>
                </Box>
                { ele.discount_type === "PERCENTAGE" && (ele.discount !== 0 || ele.extraDiscount !== 0)  ?  
                <Box className='price'>
                    { ele.extraDiscountType === "AMOUNT" ? 
                    <Box sx={{display:'flex'}}>
                        <Box className='discount'>
                            <h3>{ele.discount}%</h3> 
                        </Box> 
                        <Box mx={0.5}>&</Box>
                        <Box className='discount-amount'>
                            <h3>- Rp.{ele.extraDiscount.toLocaleString()}</h3>
                        </Box> 
                    </Box> :
                    <Box className='discount'>
                        <h3>{ele.discount + ele.extraDiscount}%</h3> 
                    </Box> }
                    <Box className='title-discount'>
                        <h2>Rp. {ele.retail_price.toLocaleString()}</h2>
                    </Box>
                    { ele.extraDiscountType === "AMOUNT" ? 
                    <Box className='title-price'>
                        <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount) / 100)) - ele.extraDiscount).toLocaleString()}</h1> 
                    </Box> :
                    <Box className='title-price'>
                        <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount + ele.extraDiscount) / 100))).toLocaleString()}</h1> 
                    </Box> }
                </Box> 
                :  ele.discount_type === "AMOUNT" && (ele.discount !== 0 || ele.extraDiscount !== 0) ? 
                <Box className='price'>
                    <Box className='discount-amount'>
                    <h3>- Rp.{(ele.discount + (ele.extraDiscount / ele.quantity)).toLocaleString()}</h3>
                    </Box> 
                    <Box className='title-discount'>
                        <h2>Rp.{ele.retail_price.toLocaleString()}</h2>
                    </Box>
                    <Box className='title-price'>
                    <h1>Rp.{(ele.retail_price - (ele.discount + (ele.extraDiscount / ele.quantity))).toLocaleString()}</h1>
                    </Box> 
                </Box> 
                :  ele.discount_type === "SPECIAL" ? 
                <Box className='price'>
                    <Box className='discount'>
                        <h3>SPECIAL</h3>
                    </Box> 
                    {/* <Box className='title-discount'>
                        <h2>Rp.{ele.retail_price.toLocaleString()}</h2>
                    </Box> */}
                    <Box pl={1} className='title-price'>
                        <h1>Rp.{ele.discount.toLocaleString()}</h1> 
                    </Box>
                </Box> :
                <Box className='price'>
                    <Box className='title-price'>
                        <h1>Rp.{ele.retail_price.toLocaleString()}</h1> 
                    </Box>
                </Box> }
                <Box className='tax'>
                    <p>+ PPN {ele.tax.label}</p>
                </Box>
                <Box className='bottom-content'>
                    <Box className='total-tax'>
                        <h2>DP {ele.down_payment}%</h2>
                    </Box>
                    <Box className='line-divide' />
                    <Box className='total-tax'>
                        <h2>PayTerm {ele.payment_term} days</h2>
                    </Box>
                    <Box className='line-divide' />
                    { ele.total_tax === 0 ? null : 
                    <>
                    <Box className='total-tax'>
                        <h2>PPN Rp.{ele.total_tax.toLocaleString()}</h2>
                    </Box>
                    <Box className='line-divide' />
                    </>
                    }
                    <Box className='total-prices'>
                        <h2>Rp.{(ele.total - ele.total_tax).toLocaleString()}</h2>
                    </Box>
                    <Box className='line-divide' />
                    {/* <HoverInfoProduct stock={ele.stock} min_order={ele.minimum_order_quantity}/>
                    <Box className='line-divide' /> */}
                    <Box className={type_error !== "" && product_id === ele._id ? "quantity-btn-error" : "quantity-btn"}>
                        <Box 
                            onClick={() => {
                                if(type_error === "" && product_id !== ele._id ) {
                                    handleDecrease()
                                }
                            }}
                        >
                            <Box
                                component="img"
                                src={minus} 
                                alt="minus"
                            />
                        </Box>
                        <Box className="input-qty">
                            <input type="number" onFocus={onFocus} onBlur={onBlur} value={quantity} onChange={onChange} />
                        </Box>
                        <Box onClick={() => {
                            if((type_error === "" || type_error === "minimum")) {
                                handleIncrease()
                            }
                        }}>
                            <Box
                                component="img"
                                src={plus} 
                                alt="plus"
                            />
                        </Box>
                        <Box mx={1}>
                            <h4>{ele.unit || "N/A"}</h4>
                        </Box>
                    </Box> 
                    <Box className='line-divide' />
                    <Box className='remove' onClick={() => dispatch(removeTemplatesItem(ele._id))}>
                        <img src={remove} alt="plus" />
                    </Box>
                </Box>
                <Box className='bottom-content'>
                    { type_error === "minimum" && product_id === ele._id ? 
                    <Box className='error-qty'>
                        <p>Min. order is <strong>{value_error}</strong>!</p>
                    </Box> : null }
                    { type_error === "stock" && product_id === ele._id ? 
                    <Box className='error-qty'>
                        <p>Stock left is <strong>{value_error}</strong>!</p>
                    </Box> : null }
                </Box>
            </Box>
        </Box>
    )
}

export default ItemList