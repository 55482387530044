import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/material';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getOneTemplate, getUpdateTemplate } from '../redux/templateReducers';
import { groupBy } from 'lodash'
import ItemCard from './ItemCard';
import moment from 'moment';
import calender from '../../../../assets/img/icon/calendar.png'
import DialogConfirmation from './DialogConfirmation';
import HistoryStatus from './HistoryStatus';
import LoadingContent from '../../purchase_request/components/LoadingItemList';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8, 
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const TemplateDetail : React.FC<any> = ({ open, setOpen, idTemplate, refetch }) => {

    const dispatch = useDispatch()
    const { detail, loading_detail } = useSelector((state : RootState) => state.templates)

    const [items, setItems] = React.useState([]);
    const [openConfirmation, setOpenConfirmation] = React.useState({
        open: false,
        type: '',
        id : ""
    });
    const [openStatus, setOpenStatus] = React.useState({
        open: false,
        data : []
    });

    React.useEffect(() => {
        if(idTemplate !== "") {
            dispatch(getOneTemplate(idTemplate))
        }
        // eslint-disable-next-line
    }, [idTemplate, refetch]);

    function handleClose () {
        setOpen(false);
    };

    // function check empty object
    function isEmpty (obj: any) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return true;
        }
        return false;
    }

    function sumTotal (data : any, name : string) {
        const total = data.reduce((total : any, itm : any) => {
          return total + itm[name]
        }, 0)
        return parseInt(total);
    }

    function sumTotalDownPayment (data : any) {
        const price = data.reduce((total : any, itm : any) => {
          return total + (itm.down_payment / 100 * itm.total)
        }, 0)
        return parseInt(price);
    } 

    React.useEffect(() => {
        if(isEmpty(detail)) {
            const convert_groupBy : any = Object.entries(groupBy(detail.items, (item:any) => {
                return JSON.stringify(item.vendor.name)
            }));
            setItems(convert_groupBy)
        }
        // eslint-disable-next-line
    }, [detail]);

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Detail Template</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
        <Grid container spacing={1}>
            {  loading_detail ? 
            <LoadingContent />
            :
            <>
            { items.length !== 0 ? 
            <>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
                <Box className="detail-purchase">
                    <Box className="top-div">
                        <Box className='left'>
                            <h4>{detail.name}</h4>
                            <Box className='line-right' />
                            <h5>{detail.department}</h5>
                        </Box>
                        <Box className='right'>
                            <img src={calender} alt='calender' />
                            <p>{moment(detail.date).format('lll')}</p>
                        </Box>
                    </Box>
                </Box>
                <Box className="product-cart">
                    <Box>
                        <Box px={2}>
                            <HeadlineTitle title="Purchase Items" variant="body2" bold="500" />
                        </Box>
                        <Box className="line-divider-bottom" />
                        <Box sx={{ maxHeight: 450, overflowX: 'auto' }}>
                            { items.map((item:any, index:any) => (
                                <ItemCard item={item} index={index} key={index}/>
                            ))}
                        </Box>
                    </Box> 
                </Box>
            </Grid> 
            <Grid item xs={12} md={4} sm={12} lg={4} xl={4}>
                <Box className="product-summary">
                    <Box px={1}>
                        <HeadlineTitle title={`Purchase Summary (${items.length} Vendor)`} variant="body2" bold="500" />
                    </Box>
                    <Box className="line-divider-bottom" />
                    <Box className="summary">
                        <Box className="summary-item">
                            <p>Sub Total</p>
                            <span>Rp.{(sumTotal(detail.items, 'total') + sumTotal(detail.items, 'discount_price') - sumTotal(detail.items, 'total_tax')).toLocaleString()}</span>
                        </Box>
                        <Box className="summary-item">
                            <p>Total PPN</p>
                            <span>+ Rp.{sumTotal(detail.items, 'total_tax').toLocaleString()}</span>
                        </Box>
                        <Box className="summary-item">
                            <p>Total Discount</p>
                            <span>- Rp.{sumTotal(detail.items, 'discount_price').toLocaleString()}</span>
                        </Box>
                    </Box>
                    <Box className="line-divider-bottom" />
                    <Box className="summary">
                        <Box className="summary-item">
                            <p>Total Price</p>
                            <span>Rp.{sumTotal(detail.items, 'total').toLocaleString()}</span>
                        </Box>
                        <Box className="summary-item">
                            <p>Total Down Payment</p>
                            <span>Rp.{sumTotalDownPayment(detail.items).toLocaleString()}</span>
                        </Box>
                    </Box>
                </Box> 
                <Box className='box-detail-address'>
                    <h5>Delivery Address </h5>
                    <p>{detail.address.label}</p>
                    <p>{detail.address.street}, {detail.address.city.toLowerCase()}, {detail.address.province}, {detail.address.country}, {detail.address.postcode}</p>
                </Box>

                {/* { detail.statuses.slice(-1)[0].name === 'Open' || detail.statuses.slice(-1)[0].name === 'Reject' ? */}
                <Box className='box-detail-btn'>
                    { checkValidation('USTEMP012344') &&
                    <Box className="btn-pr-submit" >
                        <button onClick={() => setOpenConfirmation({open:true, type: 'Use', id: detail._id})}>
                            <p>Use</p>
                        </button>
                    </Box> 
                    }
                    { checkValidation('UPDTEM580982') &&
                    <Box className="btn-pr-submit">
                        <button onClick={() => dispatch(getUpdateTemplate(detail._id))}>
                            <p>Edit</p>
                        </button>
                    </Box>
                    }
                    { checkValidation('DELTEM409264') &&
                    <Box className="btn-pr-reject">
                        <button onClick={() => setOpenConfirmation({open:true, type: 'Delete', id: detail._id})}>
                            <p>Delete</p>
                        </button>
                    </Box>
                    }
                </Box> 
            </Grid>
            </> : null }
            </> }
          </Grid> 
        </DialogContent>
      </BootstrapDialog>
      <DialogConfirmation  open={openConfirmation} setOpen={setOpenConfirmation} />
      <HistoryStatus open={openStatus} setOpen={setOpenStatus} />
    </div>
  );
}

export default TemplateDetail;