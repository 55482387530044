import { Box, Grid, Skeleton } from '@mui/material'
import React from 'react'

const PurchaseLoading : React.FC<any> = () =>  {
    return (
    <div className='container-item-purchase' > 
        <Box className="top-div">
            <Box className='left'>
                <Box pr={1}>
                    <Skeleton width={150} height={25} />
                </Box>
                <Box pr={1}>
                    <Skeleton width={150} height={25} />
                </Box>
                <Box>
                    <Skeleton width={100} height={25} />
                </Box>
            </Box>
            <Box className='right'>
                <Box className='status'>
                    <Skeleton width={100} height={25} />
                </Box>
                <Box >
                    <Skeleton width={10} height={25}/>
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={10} xl={10}>
                    <Box className='left-box'>
                        <h2><Skeleton width={180} height={40} /></h2>
                        <Box className='vendor-list'>
                            {[1,2,3,4,5,6,7].map(ele => (
                                <Box mx={0.5} key={ele}>
                                    <Skeleton width={150} height={40} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
                    <Box className='total-prices'>
                        <p><Skeleton width={80} height={20}/></p>
                        <h3><Skeleton width={150} height={30}/></h3>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='view-btn'>
                <p><Skeleton width={150} height={30} /></p>
            </Box>
            
            <Box className="btn-pr-reject">
                <Skeleton width={80} height={60}/>
            </Box>
            <Box className="btn-pr-submit">
                <Skeleton width={80} height={60}/>
            </Box>
        </Box>
    </div>
  )
}

export default PurchaseLoading