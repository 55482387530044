import { Box } from '@mui/material'
import { Grid, Stack } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useEffect, useState } from 'react';
import { getAddressCoverage } from '../products/redux/productReducers';
import emptycart from '../../../assets/img/icon/cart.png'
import departmentimg from '../../../assets/img/icon/department.png'
import delivery from '../../../assets/img/icon/delivery.png'
import ItemList from './components/ItemList';
import { groupBy } from 'lodash'
import { 
    getTemplateLocalData, 
    createNewTemplate,
    updateTemplate,
    clearTemplatesLocalData, 
} from './redux/templateReducers';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { userCredentials } from '../../../utilities/config';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { clearMessageTemplate } from './redux/templateSlice';
import SelectAddress from '../purchase_request/components/SelectAddress';
import SelectDepartment from '../purchase_request/components/SelectDepartment';
import SearchingProduct from '../purchase_request/components/SearchingProduct';
import LoadingContent from '../purchase_request/components/LoadingItemList';
import { getDepartmentByUser } from '../masters/mastersReducers';
import { useHistory } from 'react-router-dom';

function FormTemplate() {
    const history = useHistory()
    // const timeoutRef : any = useRef(null);

    const dispatch = useDispatch()
    const { 
        loading, data, remove_item, add_item, remove_vendor,
        loading_create_template, create_template, clear, 
        update_template, loading_update_template, 
        edit,
    } = useSelector((state: RootState) => state.templates)
    const { departments, loading_departments } = useSelector((state: RootState) => state.masters)

 
    const { coverage, loading_coverage,} = useSelector((state: RootState) => state.products)
    const [itemList, setItemList] = useState<any>([]);
    
    const [selectDepartment, setSelectDepartment] = useState("");
    const [selectedAddress, setSelectedAddress] = useState({});
    const [selectedArea, setSelectedArea] = useState("");

    const [edited, setEdited] = useState<any>({});
    const [templateName, setTemplateName] = useState("");

    const [saved, setSaved] = useState(false);

    
    function sumTotal (data : any, name : string) {
        const total = data.reduce((total : any, itm : any) => {
          return total + itm[name]
        }, 0)
        return parseInt(total);
    }

    function sumTotalDownPayment (data : any) {
        const price = data.reduce((total : any, itm : any) => {
          return total + (itm.down_payment / 100 * itm.total)
        }, 0)
        return parseInt(price);
    } 

    function onCreateNewTemplate(params:any) {
        const body = {
            name : templateName,
            buyer : "null",
            address : selectedAddress,
            fee : 0,
            date : new Date (),
            department : selectDepartment === "" ? "N/A" : selectDepartment,
            items : params,
            grand_total : sumTotal(params, 'total'),
            type : "INTERNAL",
            createdBy : userCredentials.fullname
        }
        if(edited._id === "") {
            dispatch(createNewTemplate(body)) 
        } else {
            let body_update = {
                id : edited._id,
                data : body
            }
            dispatch(updateTemplate(body_update))
        }
    }

    function updateLocalStorage () {
        let data = {
            _id : "",
            name : "",
            department : "",
        }
        setEdited(data)
        window.localStorage.setItem('edited', JSON.stringify(data))
    }

    useEffect(() => {
        if(data) {
            const convert_groupBy : any = Object.entries(groupBy(data, (item:any) => {
                return JSON.stringify(item.vendor.name)
            }));
            setItemList(convert_groupBy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);


    useEffect(() => {
        let area = localStorage.getItem('area') || ""
        if(coverage.length) {
            if(area === "") {
                localStorage.setItem('area', JSON.stringify(coverage[0]))
                setSelectedArea(coverage[0].value)
                setSelectedAddress(coverage[0].address)
            } else {
                setSelectedArea(JSON.parse(area).value)
                setSelectedAddress(JSON.parse(area).address)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverage]);

    useEffect(() => {
        dispatch(getAddressCoverage())
        dispatch(getTemplateLocalData())
        dispatch(getDepartmentByUser())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(add_item || remove_item || remove_vendor || clear || edit) {
            dispatch(getTemplateLocalData())
            // setInteracted(true)
            setSaved(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [add_item, remove_item, remove_vendor, clear, edit]);

    useEffect(() => {
        if(create_template || add_item || remove_item || remove_vendor ) {
            let saved = localStorage.getItem('edited') || ""
            if(saved === "") {
                updateLocalStorage()
            } else {
                setTemplateName(JSON.parse(saved).name)
                setEdited(JSON.parse(saved))
                setSelectDepartment(JSON.parse(saved).department)
            }
            setTimeout(() => {
                dispatch(clearMessageTemplate())            
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create_template, add_item, remove_item, remove_vendor]);

    useEffect(() => {
        if(create_template || update_template) {
            dispatch(clearTemplatesLocalData())
            updateLocalStorage() 
            setTimeout(() => {
                    history.push('/dashboard/templates')
            }, 1500);
            setSaved(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create_template, update_template]);

    useEffect(() => {
        let saved = localStorage.getItem('edited') || ""
        if(saved === "") {
            updateLocalStorage()
        } else {
            setTemplateName(JSON.parse(saved).name)
            setSelectDepartment(JSON.parse(saved).department)
            setEdited(JSON.parse(saved))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box m={2}>
            <BreadCrumbs 
                current={edited.name === "" ? "Create Template" : "Edit Template" }
                isPage={true}
                page="Template"
                link="/dashboard/templates"
            />
            <Stack direction="row" alignItems="center" pt={2} >
                { edited.name === "" ?
                <HeadlineTitle 
                    title="Create New Template"
                    variant="h6"
                    bold="bold"
                />
                :
                <HeadlineTitle 
                    title={`Edit Template  |   ${edited.name}`}
                    variant="h6"
                    bold="500"
                /> }
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <SearchingProduct 
                        selectedArea={selectedArea}
                        setSelectedArea={setSelectedArea}
                        template={true}
                    />
                    <Box className='selection-box'>
                        <SelectDepartment 
                            selectedData={selectDepartment}
                            setSelectedData={setSelectDepartment}
                            data={departments}
                            loading={loading_departments}
                            name="Select Department"
                            src={departmentimg}
                            isSaved={edited}
                            setIsSaved={setEdited}
                            template={true}
                        />
                        <SelectAddress 
                            selectedData={selectedArea}
                            setSelectedData={setSelectedArea}
                            setSelectedAddress={setSelectedAddress}
                            data={coverage}
                            loading={loading_coverage}
                            name="Select Address"
                            src={delivery}
                            items={data}
                            template={true}
                        />
                    </Box>
                </Grid>
                <>
                { loading ?
                <LoadingContent />
                :
                <>
                { itemList.length === 0 ? 
                    <Grid item xs={12} sm={12}>
                        <Box className="product-cart">
                            <Box className='empty-list'>
                                <Box 
                                    component="img"
                                    src={emptycart} 
                                    alt="empty-cart"
                                    sx={{width: 150, height: 150}}
                                />
                                <Box sx={{textAlign: 'center'}}>
                                    <HeadlineTitle
                                        title="Item List is Empty"
                                        variant="h6"
                                        bold="500"
                                    />
                                    <HeadlineTitle
                                        title="Please add product to Item List to create a Template!"
                                        variant="body2"
                                        bold="500"
                                    />
                                </Box>
                            </Box> 
                        </Box>
                    </Grid> :
                    <>
                    <Grid item xs={12} sm={12} md={8}>
                        <Box className="product-cart">
                            <Box>
                                <Box p={1} ml={1}>
                                    <HeadlineTitle title="Template Items" variant="h6" bold="500" />
                                </Box>
                                <Box className="line-divider-bottom" />
                                { itemList.map((item:any, index:any) => (
                                    <ItemList item={item} index={index} key={index}/>
                                ))}
                            </Box>
                        </Box>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={4}>
                        <Box className="product-summary">
                            <Box px={1} pt={1} pb={0.5}>
                                <HeadlineTitle title={`Template Summary (${itemList.length} Vendor)`} variant="h6" bold="500" />
                            </Box>
                            <Box className="line-divider-bottom" />
                            <Box className="summary">
                                <Box className="summary-item">
                                    <h6>Sub Total</h6>
                                    <h5>Rp.{(sumTotal(data, 'total') + sumTotal(data, 'discount_price') - sumTotal(data, 'total_tax')).toLocaleString()}</h5>
                                </Box>
                                <Box className="summary-item">
                                    <h6>Total PPN</h6>
                                    <h5>+ Rp.{sumTotal(data, 'total_tax').toLocaleString()}</h5>
                                </Box>
                                <Box className="summary-item">
                                    <h6>Total Discount</h6>
                                    <h5>- Rp.{sumTotal(data, 'discount_price').toLocaleString()}</h5>
                                </Box>
                            </Box>
                            <Box className="line-divider-bottom" />
                            <Box className="summary">
                                <Box className="summary-item">
                                    <h4>Total Price</h4>
                                    <h5>Rp.{sumTotal(data, 'total').toLocaleString()}</h5>
                                </Box>
                                <Box className="summary-item">
                                    <h4>Total Down Payment</h4>
                                    <h5>Rp.{sumTotalDownPayment(data).toLocaleString()}</h5>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={templateName === "" ? "input-name-template-error" : "input-name-template"}>
                            <input 
                                placeholder='Please input template name'
                                value={templateName}
                                onChange={(e) => {
                                    setTemplateName(e.target.value)
                                    // setInteracted(true)
                                    setSaved(false)
                                }}
                            />
                        </Box>
                        <Box className="btn-customs-save">
                            <button disabled={templateName === "" || saved ? true : false} onClick={() => onCreateNewTemplate(data)}>
                                <p>{saved ? "Template Saved" : "Save Template"}</p>
                            </button>
                        </Box>
                        <Box mt={1} className="btn-customs-submit">
                            <button onClick={() => window.location.href = "/dashboard/templates"}>
                                <p>Back to Template</p>
                            </button>
                        </Box>
                    </Grid> 
                    </> }
                </>
                }
                </>
            </Grid> 
            <LoaderBackdrop loading={loading_create_template  || loading_update_template} />
            {/* <SnackBarAlert message={`Item successfully ${type}!`} initialState={add_item} />
            <SnackBarAlert message="Item successfully Removed!" initialState={remove_item} />
            <SnackBarAlert message="Items successfully Removed!" initialState={remove_vendor} /> */}
            <SnackBarAlert message="Template successfully Created!" initialState={create_template}  />
            <SnackBarAlert message="Template successfully Updated!" initialState={update_template}  />
        </Box>
    )
}


export default FormTemplate;
