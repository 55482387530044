import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../app/store';
import { postEmailBlast } from './reducers/invitationReducers';
import Select from 'react-select'
// @ts-ignore
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import emailRegex from "./regexEmail.js";
import swal from 'sweetalert';
import HeadlineTitle from '../../../components/HeadlineTitle';
import ButtonCreate from '../../../components/ButtonCreate';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import StarRequired from '../../../components/StarRequired';
import { checkValidation } from '../../../utilities/userFeaturesValidation';

    
function InvitationPage() {
    const dispatch = useDispatch()

    const { loading_create, create } = useSelector((state : RootState) => state.invitation)

    const [emails, setEmails] = useState<string[] | []>([]);
    const [invalidEmails, setInvalidEmails] = useState<string[] | []>([]); 

    const [optionsChannel] = useState<any[]>([
        {
            value : "BUYER",
            label : "Buyer",
        },
        {
            value : "VENDOR",
            label : "Vendor",
        }
    ]);

    const [selectedChannel, setSelectedChannel] = useState<any>([]);
    const [errorSelectChannel, setErrorSelectChannel] = useState<boolean>(false);

     /* istanbul ignore next */
    const handleChangeChannel = (value: any) : void => {
        setSelectedChannel(value)
        setErrorSelectChannel(false)
    }
    
    const onSubmitSend = (e: any): void => {
        e.preventDefault()
        if(emails.length === 0) {
            swal('Error!', 'Email is required!', "error")
        } else if(selectedChannel.length === 0) {
            setErrorSelectChannel(true)
        } else {
            let data_send = {
                target : emails,
                channel : selectedChannel.value
            }
            dispatch(postEmailBlast(data_send))
        }
    }

    useEffect(() => {
        if(create) {
            setEmails([])
            setSelectedChannel([])
        }
    }, [create]);
    
    
    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_create}/>
            <Box pb={2}>
                <BreadCrumbs
                    current="Invitation"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between">
                <HeadlineTitle title='Invitation Email' variant="h6" bold="bold"/>
            </Stack>
            <Box>
                <p>You can send invitation email to other company to join with Pasar Pemesanan.</p>
            </Box>
            <Box id="invite-input" className="invitation-container">
                <Box pb={1}><h4>Target Emails <StarRequired/></h4></Box>
                <ReactMultiEmail
                    placeholder="You can input multiple email adresses in here"
                    emails={emails}
                    validateEmail={email => {
                        const isValid = emailRegex.test(email);

                        if (!isValid) {
                            setInvalidEmails([...invalidEmails, email]);
                        }
                        return isValid;
                    }}
                    onChange={(_emails: string[]) => {
                        setEmails(_emails);
                    }}
                    getLabel={(
                        email: string,
                        index: number,
                        removeEmail: (index: number) => void
                    ) => {
                    return (
                        <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                        </span>
                        </div>
                        );
                    }}
                    style={{
                        height: "100px",
                        cursor: "text",
                        fontSize: "14px",
                        width : "100% !impotant",
                    }}
                    className="react-multi-email-input"
                />
                
                <Box pt={2}>
                    <Box><h4>Platform <StarRequired/></h4></Box>
                    <Select
                        placeholder="Select Platform"
                        value={selectedChannel}
                        isSearchable={false}
                        options={optionsChannel}
                        onChange={handleChangeChannel}
                    />
                    { errorSelectChannel ? <div className="error-p"><p>Channel is required</p></div> : null }
                </Box>

                <Box className='action'>
                {checkValidation('CREINV079155') && 
                    <ButtonCreate 
                        name="Send Invitation"
                        onClick={onSubmitSend}
                    />
                }
                </Box>
            </Box>
        </Box>
    )   
}

export default InvitationPage
