import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Stack,Box} from '@mui/material';
import LoadingFilter from './LoadingFilter';

const  FilterVendor : React.FC<any> = ({ data, loading, setSelectedVendor, selectedVendor }) => {
    const [show, setShow] = useState(true);
    const [showMore, setShowMore] = useState(5);

    const [vendor, setVendor] = useState([]);
    const [searchText, setSearchText] = React.useState<any>("");
    
    const handleChange = (e:any) => {
        const results = data.filter((item:any) => {
            if (e.target.value === "") return data
            return item.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setSearchText(e.target.value)
        setVendor(results)
    }

    useEffect(() => {
        if(data) {
            setVendor(data);
        }
    }, [data]);

    function handleCheckBox(item:any) {
        if (!selectedVendor.includes(item)) {
            setSelectedVendor([...selectedVendor, item])
        } else {
            let filter = selectedVendor.filter((i:any) => i !== item)
            setSelectedVendor(filter)
        }
    }

    function checkSelected(item:any) {
        if (selectedVendor.includes(item)) {
            return true
        } else {
            return false
        }
    }

  return (
    <Stack>
        { loading ? <LoadingFilter/> : 
        <Box className='vendor'>
            <Box 
                className='title' 
                onClick={() => {
                    setShow(!show)
                    setShowMore(8)
                }}
            >
                <p>Vendor</p>
                <Box>
                    { show ? <ExpandLessIcon className='icon-expand'/> : <ExpandMoreIcon className='icon-expand'/> }
                </Box>
            </Box>
            { show ? 
            <Box className='checkbox-vendor'>
                <div className='input-brand'>
                    <input 
                        placeholder='Search for vendor'
                        type="text"
                        value={searchText}
                        onChange={handleChange}
                    />
                </div>
                { vendor.slice(0,showMore).map((item:any, idx:any) => (
                    <div key={idx}>
                        <input 
                            type='checkbox' 
                            id={item} 
                            name={item}
                            onChange={() => handleCheckBox(item)} 
                            checked={checkSelected(item)}
                        /> 
                        <label htmlFor={item}>{item.toLowerCase()}</label>
                    </div>
                )) }

                { vendor.length > showMore && showMore < vendor.length  ? 
                <div onClick={() => setShowMore(showMore + 10)}>
                    <h6>See More</h6>
                </div> : null }
            </Box> : null }
            <Box sx={{ borderBottom: 'solid 1px #ddd', my:1 }} />
        </Box> }
    </Stack>
  );
}

export default FilterVendor