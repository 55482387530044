import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Stack, Typography, Button } from '@mui/material';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import { clearPurchasesLocalData } from '../redux/purchasesReducers';
import { clearTemplatesLocalData } from '../../template/redux/templateReducers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const SelectAddress : React.FC<any> = ({ 
  data, loading, selectedData, setSelectedData, 
  name, src, setSelectedAddress, items, template 
}) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>({});

  function onCloseHover () {
    setShow(false);
  }

  function handleClose () {
      setOpen(false);
  };

  function handleSubmit () {
    dispatch(clearPurchasesLocalData())
    dispatch(clearTemplatesLocalData())
    localStorage.setItem('area', JSON.stringify(value))
    setSelectedData(value.label)
    setSelectedAddress(value.address)
    setShow(false)
    setOpen(false);
};

  const Container = (
    <Stack>
      { loading ?
      <Stack className='container-hover'>
        <Stack flexDirection="column" >
        { Array.from(new Array(8)).map((_, index) => (
              <Box key={index}>
                  <Skeleton variant="text" height={40} width={190} /> 
              </Box>
          ))}
        </Stack>
      </Stack> :
      <Stack className='container-hover'>
        { data.map((item:any, idx:any) => (
          <Box  
            key={idx}
            className={item.label === selectedData ? 'list-menu-active' : 'list-menu'} 
            onClick={() => {
              if(items.length) {
                setValue(item)
                setOpen(true)
              } else {
                setSelectedData(item.label)
                localStorage.setItem('area', JSON.stringify(item))
                setSelectedAddress(item.address)
                setShow(false)
              }
            }}
          >
            <p>{item.label}</p>
          </Box>
        ))}
      </Stack> } 
    </Stack>
  );

  return (
    <div>
    <Box onMouseLeave={onCloseHover} >
        <Box 
          className='selection-box-title'
          onMouseEnter={() => setShow(true)}
        >
            <img src={src} alt='icon' />
            <p>{selectedData === "" ? name : (selectedData && selectedData.toLowerCase()) || ""}</p>
            <KeyboardArrowDownIcon />
        </Box>
        <Box sx={{ display: !show ? 'none' : 'flex', position: 'absolute'}}>
            <Fade in={show}>{Container}</Fade>
        </Box>
    </Box>
    <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Confirmation!</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <Typography gutterBottom>
                Are you sure want to change delivery area from <strong>{selectedData}</strong> to <strong>{value.label}</strong> ?
            </Typography>
            <Typography gutterBottom>
                All existing {template ? "Template" : "Purchase"} Items from current Area will be removed.
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button size="small" autoFocus color="error" variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" autoFocus variant='contained' onClick={handleSubmit}>
            Change
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default SelectAddress