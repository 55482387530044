import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'; 
import Box from '@mui/material/Box';
import logo from '../assets/img/logo.png'
import logop from '../assets/img/logo-p.png'
import Feedback from './Feedback';
import { Avatar, Fade } from '@mui/material';
import {  Stack } from '@mui/material';
import { checkLoginSessionTime, userCredentials } from '../utilities/config';
import profile from '../assets/img/dashboard/profile.png'
import { useIdleTimer } from 'react-idle-timer'
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import MuiDrawer from '@mui/material/Drawer';
import Notifications from './Notifications';
import ChatNotif from '../features_app/dashboard/chat/components/ChatNotif';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';


const drawerWidth = 205;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerMini = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );


interface Props {
    window?: () => Window;
}

// const onClickRefresh = () => {
//     window.location.reload()
// }

const onClickSignOut = () => {
    localStorage.clear()
    window.location.href = "/"
}


const NavbarHeader = (props: Props) => {
    const location = useLocation()

    const { signin } = useSelector((state : RootState) => state.auth )


    const [dropDownOpen, setDropdownOpen] = React.useState({
        name : '',
        open : false
    });
    const [stayActive, setStayActive] = React.useState(false);
    const [profileShow, setProfileShow] = React.useState(false);
    const [openDraw, setOpenDraw] = React.useState(false);

    const handleDropDownMenu = (name : string) => {
        if(dropDownOpen.name === name) {
            setDropdownOpen({...dropDownOpen, name : name, open : !dropDownOpen.open });
        } else {
            setDropdownOpen({...dropDownOpen, name : name, open : true });
        }
    };
  
    const handleDrawerOpen = () => {
      setOpenDraw(true);
    };
  

    useEffect(() => {
        if(checkLoginSessionTime()) {
            onClickSignOut()
        }
        // eslint-disable-next-line
    }, []);


    const handleOnIdle = (event : any) => {
        onClickSignOut()
        getRemainingTime()
    }
    

    const { getRemainingTime } = useIdleTimer({
        timeout: 3600000,
        onIdle: handleOnIdle,
        debounce: 500
    })

    const checkPathNavigation = (path : string) => {
        let arrayPath = userCredentials.navigation_path 
        let check = arrayPath.includes(path)
        if(check) {
            return true
        } else {  
            return false 
        }
    }

    const onClickHide = () => {
        if(!stayActive) {
            setOpenDraw(false)
        }
    }

    const drawerComponent = (
        <div>   
            <Box className="sidebar-navbar" > 
                { userCredentials.navigation_menu.map((nav:any, index:any) => (
                    <div key={index}>
                    { nav.child.length === 0 ?
                    <NavLink exact to={nav.navigation.path} activeClassName="active-link" onClick={onClickHide}>
                        <ul>
                            <li className="navbar-list"> 
                                <div className="icon"> 
                                    <Box
                                        component="img"
                                        src={nav.navigation.iconImage}
                                        alt={nav.navigation.name}
                                        sx={{ width: '22px', height: '22px' }}
                                    />
                                </div>
                                { openDraw ? <span>{nav.navigation.name}</span> : null }
                            </li>
                        </ul>
                    </NavLink>
                    : 
                    <div className="sidebar-navbar-dropdown">
                        <div className="menu-dropdown" onClick={() => handleDropDownMenu(nav.navigation.name)}>
                            <Stack flexDirection="row">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.navigation.iconImage}
                                        alt={nav.navigation.name}
                                    />
                                </div>
                                <Box ml={1} mr={1}> { openDraw ? <span>{nav.navigation.name}</span> : null }</Box>
                                <Box>
                                    { dropDownOpen.name === nav.navigation.name && dropDownOpen.open ? 
                                    <ArrowDropUpIcon/> : <ArrowDropDownIcon/>  }
                                </Box>
                            </Stack>
                        </div>
                        <div className={dropDownOpen.name === nav.navigation.name && dropDownOpen.open ? 'menu-list-dropdown-active' : 'menu-list-dropdown'}>
                            { nav.child.map((child:any, indexchild:any) => (
                            <div key={indexchild}>
                            <NavLink exact to={child.path} activeClassName="active-link-child" onClick={onClickHide}>
                                <ul>
                                    <li className="navbar-list-child">
                                        <div className="icon">
                                            <Box
                                                component="img"
                                                src={child.iconImage}
                                                sx={{ width: '18px', height: '18px' }}
                                                alt={child.name}
                                            />
                                        </div>
                                        { openDraw ? <span>{child.name}</span> : null }
                                    </li>
                                </ul>
                            </NavLink>
                            </div>
                            ))}
                        </div>
                    </div>
                    }
                    </div>
                ))}
                <Feedback disabled={userCredentials.first_time ? true : false} openDraw={openDraw} />
            </Box>
        </div>
    );

    
    const ProfileComponent = (
        <Box className='container-profile'>
            <Box className='header-profile'>
                <Avatar alt="Remy Sharp" src={profile} /> 
                <Box className='header-profile-name'>
                    <h5>{userCredentials.fullname || "N/A"}</h5>
                    <p>{userCredentials.email || "N/A"}</p>
                </Box>
            </Box>
            {  location.pathname === '/company-registration' || location.pathname === "/login/select-company" ? null : 
            <>
            <Divider />
            { checkPathNavigation("/dashboard/profile")  && 
            <Link to="/dashboard/profile">
                <Box mt={1} className='menu-list'>
                    <BusinessIcon className='icon-menus'/>
                    <h4>Profile Company</h4>
                </Box>
            </Link> }
            { checkPathNavigation("/dashboard/teams") &&
            <Link to="/dashboard/teams">
                <Box className='menu-list'>
                    <GroupIcon className='icon-menus'/>
                    <h4>Team Members</h4>
                </Box>
            </Link> }
            </> }
            <Box 
                className='menu-list'
                onClick={onClickSignOut}
            >
                <ExitToAppIcon className='icon-menus'/>
                <h4>Sign Out</h4>
            </Box>
        </Box>
    )

    return (
        <div>
            <AppBar
                position="fixed"
                open={openDraw}
            >
                <Toolbar>
                    <Box 
                        className="logo-app-v1" 
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <a href="/dashboard">
                            <img 
                                alt="logo mpi dashboard" 
                                src={logop}
                            />
                        </a>
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                ...(openDraw && { display: 'none' }),
                            }}
                        >
                            <DoubleArrowIcon sx={{color:'#fff'}} />
                        </IconButton>
                    </Box>
                    <div style={{flexGrow: 1}} />
                    {  location.pathname === '/company-registration' || location.pathname === "/login/select-company" ? null : 
                    <>
                    <Box pr={2} pl={1}>
                        { !signin ? null :
                        <ChatNotif /> }
                    </Box>

                    <Box pr={2} pl={1}>
                        { !signin ? null :
                        <Notifications/>  }
                    </Box>
                    </> }
                    <Box 
                        className="right-navbar"  
                        onMouseLeave={() => {
                            setProfileShow(false);
                        }}
                    >
                        <Avatar 
                            sx={{ width: 35, height: 35, cursor: 'pointer' }}
                            src={profile}
                            onMouseEnter={() => {
                                setProfileShow(true);
                            }} 
                        />
                        <Box sx={{ display: !profileShow ? 'none' : 'flex', position: 'absolute', zIndex: 2000, right:3 }}>
                            <Fade in={profileShow}>{ProfileComponent}</Fade>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            
            <Stack 
                sx={{ display: {xs: !openDraw ? 'none' : 'block', sm: 'block'}}}
                onMouseEnter={() => {
                    if(!stayActive) {
                        setOpenDraw(true);
                    }
                }} 
                onMouseLeave={() => {
                    if(!stayActive) {
                        setOpenDraw(false);
                    }
                }}
            >
                <DrawerMini
                    variant="permanent"
                    open={openDraw}
                >
                    <DrawerHeader className='header-background' >
                        <div className="logo-app">
                            <a href="/dashboard">
                                <img 
                                    alt="logo" 
                                    src={logo}
                                />
                            </a>
                        </div>
                        <div className='hide-icon'>
                            { !stayActive ? 
                            <LastPageIcon 
                                className='icon-icon-hide'
                                onClick={() => {
                                    setStayActive(true)
                                    setOpenDraw(true)
                                }}
                            /> :
                            <FirstPageIcon 
                                className='icon-icon-hide'
                                onClick={() => {
                                    setStayActive(false)
                                    setOpenDraw(false)
                                }}
                            /> }
                        </div>
                    </DrawerHeader>
                    <Divider />
                    {drawerComponent}
                </DrawerMini>
            </Stack>
        </div>
    )
}

export default NavbarHeader;
 