import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ItemCard from './ItemCard'
import { createGoodReceiptNote, printDownloadPDF } from '../redux/ordersReducers'
import DialogSendEmail from './DialogSendEmail'
import DownloadPdf from '../../../../components/DownloadPdf'
import more from '../../../../assets/img/icon/more.png'
import HistoryStatus from '../../purchase_request/components/HistoryStatus'
import { checkValidation } from '../../../../utilities/userFeaturesValidation'


const PackageCard : React.FC<any> = ({ item, order, vendor }) =>  {
    const dispatch = useDispatch()

    const [itemsReceived, setItemsReceived] = useState<any>([]);
    const [defaulItems, setDefaulItems] = useState();
    const [update, setUpdate] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [inboundChange, setInboundChange] = useState(false);

    const [openStatus, setOpenStatus] = useState({
        open: false,
        data : []
    });
    // sum total price percentage change inbound
    function totalPercentageInbound(params:any) { 
        if(params.extraDiscountType === "AMOUNT") {
            return ((params.inbound.retail_price - ((params.discount / 100) * params.inbound.retail_price + params.extraDiscount)) * params.inbound.quantity) || 0
        } else {
            return ((params.inbound.retail_price - (((params.discount + params.extraDiscount) / 100) * params.inbound.retail_price)) * params.inbound.quantity ) || 0 
        }
    }

    // sum total price amount change inbound
    function totalAmountInbound(params:any) {
        return ((params.inbound.retail_price - (params.discount + params.extraDiscount )) * params.inbound.quantity) || 0
    }

    // sum total price special change inbound
    function totalSpecialInbound(params:any) {
        return (params.inbound.discount * params.inbound.quantity) || 0
    } 

    const handlerSumTotal = (params:any) => {
        setInboundChange(true)
        switch (params.discount_type) {
            case "PERCENTAGE":
                return totalPercentageInbound(params)
            case "AMOUNT":
                return totalAmountInbound(params)
            case "SPECIAL":
                return totalSpecialInbound(params)
            default:
                break;
        }
    }

    const handlerSumTax = (params:any) => {
        switch (params.discount_type) {
            case "PERCENTAGE":
                return totalPercentageInbound(params) * params.tax.value
            case "AMOUNT":
                return totalAmountInbound(params) * params.tax.value
            case "SPECIAL":
                return totalSpecialInbound(params) * params.tax.value
            default:
                break;
        }
    }

    function sumTotalInbound (data : any) {
        const total = data.reduce((total : any, itm : any) => {
          return total + itm.total_inbound
        }, 0)
        return parseInt(total);
    }

    function sumTaxInbound (data : any) {
        const total = data.reduce((total : any, itm : any) => {
          return total + itm.tax_inbound
        }, 0)
        return parseInt(total);
    }
    
    useEffect(() => {
        if(item.lastStatus === "Shipped") { 
            const ItemShipped = item.items.map((item : any) => {
                return {
                    ...item,
                    note : "",
                    received : item.quantity
                }
            })
            setItemsReceived(ItemShipped)
            setDefaulItems(ItemShipped) 
        } else if (item.lastStatus === "Delivered") { 
            const ItemShipped = item.items.map((item : any) => {
                return {
                    ...item,
                    note : "",
                    received : item.received
                }
            })
            setItemsReceived(ItemShipped)
            setDefaulItems(ItemShipped) 
        }  else if(item.lastStatus === "Pick") { 
            const ItemPick = item.items.map((item : any) => {
                return {
                    ...item,
                    note : "",
                    total_inbound : (item.inbound && handlerSumTotal(item)) || (item.total - item.total_tax),
                    tax_inbound : (item.inbound && handlerSumTax(item)) || item.total_tax,
                }
            })
            setItemsReceived(ItemPick)
            setDefaulItems(ItemPick) 
        } else {
            setItemsReceived(item.items)
            setDefaulItems(item.items)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.lastStatus]);


    const submitInboundReceipt = () => {
        const params = {
            package : item._id,
            delivery : item.delivery_note && item.delivery_note._id,
            date : new Date(),
            buyer : order.buyer,
            vendor : order.vendor,
            items : itemsReceived
        }
        dispatch(createGoodReceiptNote(params))
    }

    const onClickPrintPDF = (item:any) => {
        const params = {
            type : item.lastStatus === "Delivered" ? "goods-receipt" : item.lastStatus === "Shipped" ? "delivery-note" : "package-order",
            body : {
                "data": {
                    "id": item.lastStatus === "Delivered" ? item.receive_note && item.receive_note._id  : item.lastStatus === "Shipped" ? item.delivery_note && item.delivery_note._id  : item._id ,
                    "company": "null"
                }   
            }
        }
        dispatch(printDownloadPDF(params))
    }

    return (
    <div className='container-item-orders' > 
        <Box className="top-div">
            <Box className='left'>
                <h6>{item.code}</h6>
                <div className='package-detail'>
                    <p>Payment Term <strong>{item.payment_term}</strong> Days</p>
                    <Box className='line-rightt'/>
                    <p>{item.department}</p>
                    <Box className='history' onClick={() => setOpenStatus({open:true, data: item.statuses})}>
                        <img src={more} alt='more' />
                    </Box>
                </div>
            </Box>
            <Box className='right'>
                <Box className='container-btn-item'>
                    { item.lastStatus === "Shipped" ?
                    <>
                    { update ? 
                    <Box className="btn-pr-reject">
                        <button onClick={()=> {
                            setItemsReceived(defaulItems)
                            setUpdate(false)
                        }}>
                            <p>Cancel</p>
                        </button>
                    </Box> : 
                    <Box className="btn-pr-submit">
                        { checkValidation('UPINBITM1239743') &&
                        <button onClick={()=> {
                            setUpdate(true)
                        }}>
                            <p>Update Inbound</p>
                        </button> }
                    </Box> }
                    </> : null }

                    <Box ml={1} className="btn-pr-submit">
                        <button 
                            onClick={()=> {
                                setOpenEmail(true)
                            }}
                        >
                            <p>Send Email</p> 
                        </button>
                    </Box>
                    <DownloadPdf 
                        name="Download PDF"
                        onClick={() => onClickPrintPDF(item)}
                    />
                </Box>
            </Box> 
        </Box>

        <Box className='height-items'>
            {itemsReceived && itemsReceived.map((itm : any, index : number) => (
               <Box key={index}>
                 <ItemCard 
                    ele={itm} 
                    idx={index} 
                    update={update} 
                    setItemsReceived={setItemsReceived} 
                    itemsReceived={itemsReceived}
                    lastStatus={item.lastStatus}
                 />
                </Box>
            ))}
        </Box>

        <Box className={`bttm-content ${ inboundChange && itemsReceived && 'linethrought-bttm' } `}>
            <Box className='content-shipping'>
                <p>Shipping Cost</p>
                <h3>Rp.{(Math.floor(item.shipping_cost)).toLocaleString()}</h3>
            </Box>
            <Box className='content'>
                <p>Tax Price</p>
                { item.lastStatus === "Delivered" || item.lastStatus === "Completed" ? 
                <h3>Rp.{(Math.floor(item.sub_tax_receive)).toLocaleString()}</h3> :
                <h3>Rp.{(Math.floor(item.sub_tax)).toLocaleString()}</h3> }
            </Box>
            <Box className='content'>
                <p>Total Order + Tax</p>
                { item.lastStatus === "Delivered" || item.lastStatus === "Completed" ? 
                <h3>Rp.{(Math.floor(item.sub_total_receive)).toLocaleString()}</h3> :
                <h3>Rp.{(Math.floor(item.sub_total)).toLocaleString()}</h3> }
            </Box>
        </Box>

        { inboundChange && itemsReceived &&
        <Box className='bttm-content'>
            <Box className='content'>
                
            </Box>
            <Box className='content'>
                <p>Tax Price</p>
                <h3>Rp.{(Math.floor(sumTaxInbound(itemsReceived))).toLocaleString()}</h3>
            </Box>
            <Box className='content'>
                <p>Total Order + Tax</p>
                <h3>Rp.{(Math.floor(sumTotalInbound(itemsReceived) + sumTaxInbound(itemsReceived))).toLocaleString()}</h3>
            </Box>
        </Box> }

        { update ?
        <Box className='right-submit-btn'>
            <Box className="btn-pr-submit">
                <button onClick={()=> submitInboundReceipt()}>
                    <p>Submit Inbound</p>
                </button>
            </Box> 
        </Box> : null }
        <DialogSendEmail open={openEmail} setOpen={setOpenEmail} id={item._id} vendor={vendor} />
        <HistoryStatus open={openStatus} setOpen={setOpenStatus} />

    </div>
  )
}

export default PackageCard