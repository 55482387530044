import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/material';
import FilterArea from './FilterArea';
import FilterVendor from './FilterVendor';
import FilterBrand from './FilterBrand';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilterMobile :React.FC<any> = ({
    open, setOpen, coverage, loading_coverage,
    selectedArea, setSelectedArea, filter_vendor,
    loading_filter, setSelectedVendor, selectedVendor,
    filter_brand, selectedBrand, setSelectedBrand,
    vendor, hidearea
}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" />
            <Button autoFocus variant='outlined' color="inherit" onClick={handleClose}>
                Apply
            </Button>
          </Toolbar>
        </AppBar>
        <Box className='container-product'>
            <Box className='menu-left'>
              { hidearea ? null : 
                <FilterArea 
                    data={coverage} 
                    loading={loading_coverage} 
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                />  }
                {vendor &&
                <FilterVendor 
                    data={filter_vendor} 
                    loading={loading_filter}
                    setSelectedVendor={setSelectedVendor}
                    selectedVendor={selectedVendor}
                /> }
                <FilterBrand 
                    data={filter_brand} 
                    loading={loading_filter}
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                />
            </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default FilterMobile