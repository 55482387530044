import React, { useState } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SelectDepartment : React.FC<any> = ({ 
  data, loading, selectedData, setSelectedData, 
  name, src, isSaved, setIsSaved, template
}) => {
  const [show, setShow] = useState(false);
   
  function onCloseHover () {
    setShow(false);
  }

  function onClickItem (name:string) {
    setSelectedData(name)
    onCloseHover()
    if(template) {
      localStorage.setItem('edited', JSON.stringify({ 
          _id : isSaved._id, 
          name: isSaved.name,
          department: name,
      }))
      setIsSaved({
        ...isSaved,
        department : name, 
      })
    } else {
      localStorage.setItem('saved', JSON.stringify({ 
          saved : false, 
          _id : isSaved._id, 
          code: isSaved.code,
          department: name,
          updatedAt : null
      }))
      setIsSaved({
        ...isSaved,
        saved : false, 
      })
    }
  }

  const Container = (
    <Stack>
      { loading ?
      <Stack className='container-hover'>
        <Stack flexDirection="column" >
        { Array.from(new Array(8)).map((_, index) => (
              <Box key={index}>
                  <Skeleton variant="text" height={40} width={190} /> 
              </Box>
          ))}
        </Stack>
      </Stack> :
      <Stack className='container-hover'>
        { data.map((item:any, idx:any) => (
          <Box  
            key={idx}
            className={item.label === selectedData ? 'list-menu-active' : 'list-menu'} 
            onClick={() => { 
              onClickItem(item.label)
            }}
          >
            <p>{item.label}</p>
          </Box>
        ))}
        { data.length === 0 && 
        <Box className='list-menu'><p>Not found. Please create one!</p></Box> }
      </Stack> }
    </Stack>
  );

  return (
    <Box onMouseLeave={onCloseHover} >
        <Box 
          className='selection-box-title'
          onMouseEnter={() => setShow(true)}
        >
            <img src={src} alt='icon' />
            <p>{selectedData === "" ? name : selectedData.toLowerCase()}</p>
            <KeyboardArrowDownIcon />
        </Box>
        <Box sx={{ display: !show ? 'none' : 'flex', position: 'absolute'}}>
            <Fade in={show}>{Container}</Fade>
        </Box>
    </Box>
  );
}

export default SelectDepartment