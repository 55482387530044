import { createSlice } from '@reduxjs/toolkit';
import { createNewDepartment, getAllDepartment, updateDepartment } from './departmentReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  data: [], 
  loading : false,
  update : false, 
  loading_update : false,
  create : false,
  loading_create: false,
};

export const stepRegisterVendor = createSlice({
  name: 'departments',
  initialState, 
  reducers: {
    clearMessageDepartment : (state) => {
      state.create = false;
      state.update = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // get all department
      .addCase(getAllDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDepartment.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getAllDepartment.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

      // update department
      .addCase(updateDepartment.pending, (state) => {
        state.loading_update = true;
        state.update = false;
      })
      .addCase(updateDepartment.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.update = action.payload;
      })
      .addCase(updateDepartment.rejected, (state, action : any) => {
        state.loading_update = false;
        errorSweetAlert(action.payload)
      })

      // createa department
      .addCase(createNewDepartment.pending, (state) => {
        state.loading_create = true;
        state.create = false;
      })
      .addCase(createNewDepartment.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload;
      })
      .addCase(createNewDepartment.rejected, (state, action : any) => {
        state.loading_create = false;
        errorSweetAlert(action.payload)
      })

  },
});

export const { clearMessageDepartment } = stepRegisterVendor.actions;

export default stepRegisterVendor.reducer;
