import { createSlice } from '@reduxjs/toolkit';
import { 
  getOrdersData, getOrderDetail, getStatusCount, submitPickFulfillment, getStatusPackageCount, 
  getDetailStatusPackageCount, submitProofPayment, printDownloadPDF, approvalItemChange, createGoodReceiptNote, sendPurchaseToEmail 
} from './ordersReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  orders : [], 
  total_orders : 0,
  total_infinite : 0,
  orders_loaded : false,
  loading_orders : false,
  status : [],
  loading_status : false,
  packages : [],
  loading_package : false,
  status_package : [],
  loading_status_package : false,
  items : [],
  default_items : [],
  loading_items : false,
  pick : false, 
  loading_pick : false,
  detail : false,
  loading_detail : false,
  proof_payment : false,
  loading_proof_payment : false,
  download_pdf : "",
  loading_download_pdf : false,
  approval_item : false, 
  type_approval : "",
  loading_approval_item : false,
  create_grn : false, 
  loading_create_grn : false,
  send_email : false, 
  loading_send_email : false,
}; 

function calculateSingleDiscount(params:any) {
  switch (params.discount_type) {
    case "PERCENTAGE":
      return (params.discount / 100) * params.retail_price;
    case "AMOUNT":
      return params.discount;
    case "SPECIAL":
      return params.discount;
    default:
      return 0
  }
}

export const ordersSlices = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateValueAlert: (state) => {
      state.pick = false;
      state.proof_payment = false;
      state.download_pdf = "";
      state.type_approval = ""
      state.approval_item = false
      state.create_grn = false
      state.send_email = false
    },
    changeToDefaultItems: (state, action) => {
      state.items = action.payload
    },
    changeQuantityItem : (state, action:any) => {
      const { id, quantity, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          const total_tax = ((obj.retail_price - calculateSingleDiscount(obj)) * quantity) * obj.tax.value
          const total = (obj.retail_price - calculateSingleDiscount(obj)) * quantity
          return {
           ...obj,
           discount_price : calculateSingleDiscount(obj) * quantity,
           quantity: quantity,
           total : total + total_tax,
           total_tax : total_tax,
         }}
        return obj
      });
      state.items = newData
    },
    addLengthInfiniteOrder (state,action) {
      let existing = state.total_infinite + action.payload
      state.total_infinite = existing
    }
  },
  extraReducers: (builder) => {
    builder
      // get orders data
      .addCase(getOrdersData.pending, (state) => {
        state.loading_orders = true;
        state.detail = {};
        state.orders_loaded = false;
      })
      .addCase(getOrdersData.fulfilled, (state, action:any) => {
        state.loading_orders = false;
        state.orders = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_infinite = action.payload.data.length > 0 ? action.payload.data.length : 0;
        state.total_orders = action.payload.data.length > 0 ? action.payload.total.total : 0;
        state.orders_loaded = true;
      })
      .addCase(getOrdersData.rejected, (state, action : any) => {
        state.loading_orders = false;
        state.orders_loaded = false;
        errorSweetAlert(action.payload)
      })

      // get status order
      .addCase(getStatusCount.pending, (state) => {
        state.loading_status = true;
      })
      .addCase(getStatusCount.fulfilled, (state, action:any) => {
        state.loading_status = false;
        state.status = action.payload
      })
      .addCase(getStatusCount.rejected, (state, action : any) => {
        state.loading_status = false;
        errorSweetAlert(action.payload)
      })

      // get status packages
      .addCase(getStatusPackageCount.pending, (state) => {
        state.loading_package = true;
      })
      .addCase(getStatusPackageCount.fulfilled, (state, action:any) => {
        state.loading_package = false;
        state.packages = action.payload
      })
      .addCase(getStatusPackageCount.rejected, (state, action : any) => {
        state.loading_package = false;
        errorSweetAlert(action.payload)
      })

      // get status packages detail
      .addCase(getDetailStatusPackageCount.pending, (state) => {
        state.loading_status_package = true;
      })
      .addCase(getDetailStatusPackageCount.fulfilled, (state, action:any) => {
        state.loading_status_package = false;
        state.status_package = action.payload
      })
      .addCase(getDetailStatusPackageCount.rejected, (state, action : any) => {
        state.loading_status_package = false;
        errorSweetAlert(action.payload)
      })

       // get detail order
      .addCase(getOrderDetail.pending, (state) => {
        state.loading_detail = true;
        state.detail = false
      })
      .addCase(getOrderDetail.fulfilled, (state, action:any) => {
        state.loading_detail = false;
        state.detail = action.payload
      })
      .addCase(getOrderDetail.rejected, (state, action : any) => {
        state.loading_detail = false;
        errorSweetAlert(action.payload)
      })

       // submit pick
      .addCase(submitPickFulfillment.pending, (state) => {
        state.loading_pick = true;
        state.pick = false;
      })
      .addCase(submitPickFulfillment.fulfilled, (state, action:any) => {
        state.loading_pick = false;
        state.pick = action.payload
      })
      .addCase(submitPickFulfillment.rejected, (state, action : any) => {
        state.loading_pick = false;
        errorSweetAlert(action.payload)
      })

       // submit proof payment
      .addCase(submitProofPayment.pending, (state) => {
        state.loading_proof_payment = true;
        state.proof_payment = false;
      })
      .addCase(submitProofPayment.fulfilled, (state, action:any) => {
        state.loading_proof_payment = false;
        state.proof_payment = action.payload
      })
      .addCase(submitProofPayment.rejected, (state, action : any) => {
        state.loading_proof_payment = false;
        errorSweetAlert(action.payload)
      })

      // download pdf
      .addCase(printDownloadPDF.pending, (state) => {
        state.loading_download_pdf = true;
        state.download_pdf = "";
      })
      .addCase(printDownloadPDF.fulfilled, (state, action:any) => {
        state.loading_download_pdf = false;
        state.download_pdf = action.payload
      })
      .addCase(printDownloadPDF.rejected, (state, action : any) => {
        state.loading_download_pdf = false;
        errorSweetAlert(action.payload)
      })

      // approval item change
      .addCase(approvalItemChange.pending, (state) => {
        state.loading_approval_item = true;
        state.type_approval = ""
      })
      .addCase(approvalItemChange.fulfilled, (state, action:any) => {
        state.loading_approval_item = false;
        state.approval_item = action.payload.data;
        state.type_approval = action.payload.type;
      })
      .addCase(approvalItemChange.rejected, (state, action : any) => {
        state.loading_approval_item = false;
        errorSweetAlert(action.payload)
      })

       // create good receipt note
      .addCase(createGoodReceiptNote.pending, (state) => {
        state.loading_create_grn = true;
        state.create_grn = false;
      })
      .addCase(createGoodReceiptNote.fulfilled, (state, action:any) => {
        state.loading_create_grn = false;
        state.create_grn = action.payload;
      })
      .addCase(createGoodReceiptNote.rejected, (state, action : any) => {
        state.loading_create_grn = false;
        errorSweetAlert(action.payload)
      })

      // create send po
      .addCase(sendPurchaseToEmail.pending, (state) => {
        state.loading_send_email = true;
        state.send_email = false;
      })
      .addCase(sendPurchaseToEmail.fulfilled, (state, action:any) => {
        state.loading_send_email = false;
        state.send_email = action.payload;
      })
      .addCase(sendPurchaseToEmail.rejected, (state, action : any) => {
        state.loading_send_email = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { changeQuantityItem, changeToDefaultItems, updateValueAlert, addLengthInfiniteOrder } = ordersSlices.actions;

export default ordersSlices.reducer;
 