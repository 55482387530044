import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Box, Skeleton, Stack, TextField } from '@mui/material';
import { useDispatch, } from 'react-redux';
import ButtonCreate from '../../../../components/ButtonCreate';
import Select from 'react-select'
import { userCredentials } from '../../../../utilities/config';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getAllUsersTeams } from '../../teams/redux/teamsReducers';
import { createNewDepartment, updateDepartment } from '../redux/departmentReducers';
import StarRequired from '../../../../components/StarRequired';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const DialogDepartment : React.FC<any> = ({ 
  open, setOpen, 
  type, name, setName,
  setSelectedUsers, selectedUsers, 
  idDepartment
 }) => {

    const dispatch = useDispatch()
    const { data, loading } = useSelector((state: RootState) => state.users_team)

    const [optionsUsers, setOptionsUsers] = useState<any[]>([]);
    const [errorSelectUsers, setErrorSelectUsers] = useState<boolean>(false);

    function renderUsers(params:any) {
      let users : any = []
      params.map((item:any) => {
        users.push({
          userId : item.value,
          label : item.label
        })
        return users
      })
      return users
    }

    const onClickSubmit = (e: any): void => {
      e.preventDefault()
      if (selectedUsers.length === 0) {
        setErrorSelectUsers(true)
      } else {
        let body = {
          company : userCredentials.company_id,
          name : name,
          users : renderUsers(selectedUsers)
        }
        if(type === "Edit") {
          let params = {
            id : idDepartment,
            body : body
          }
          dispatch(updateDepartment(params))
        } else {
          dispatch(createNewDepartment(body))
        }
      }
    }
  

    const handleChangeUsers = (value: any) : void => {
        setSelectedUsers(value)
        setErrorSelectUsers(false)
    }

    function handleClose () {
      setOpen(false);
    };

    useEffect(() => {
      const params = {
        limit : 100,
        skip : 0,
        sortTopic : "createdAt",
        sortValue : "ASC"
      }
      dispatch(getAllUsersTeams(params))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if(data.length > 0) {
        let users : any = []
        data.map((item:any) => {
          users.push({
            value : item.profile._id,
            label : item.profile.name
          })
          return users
        })
        setOptionsUsers(users)
      }
    }, [data]);
  
    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{type === "Add" ? "Add New" : "Edit"} Department</Box>
           </Stack>
        </BootstrapDialogTitle>
        <form onSubmit={onClickSubmit}>
        <DialogContent dividers >
          <Box className="create-department">
              <Box pt={1}><h2>Department Name <StarRequired/></h2></Box>
              <TextField
                margin="dense"
                fullWidth
                id="fullname"
                placeholder="Kitchen"
                name="fullname"
                autoComplete="fullname"
                size='small'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Box pt={2} pb={1}><h2>Assign User <StarRequired/></h2></Box>
              { loading ?
              <Skeleton variant="rectangular" width="100%" height={60} />
              :
              <Select
                  placeholder="Select User"
                  value={selectedUsers}
                  isSearchable={false}
                  options={optionsUsers && optionsUsers}
                  onChange={handleChangeUsers}
                  isMulti={true}
                  // isDisabled={roleName === "Owner" ? true : false}
                  id="select-roles"
              /> }
              {errorSelectUsers && <Box color="red" fontSize={14}>Please assign to user!</Box>}
          </Box> 
        </DialogContent>
        <DialogActions>
          <ButtonCreate 
            name={type === "Add" ? "Submit" : "Save Department"}
            type="submit"
          />
        </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default DialogDepartment;