import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import ProductDetail from './ProductDetail';
import ProductHome from './ProductHome';
import ProductVendor from './ProductVendor';
import { RootState } from '../../../app/store';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert'; 
import { clearMessageTemplate } from '../template/redux/templateSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackBarValue } from '../purchase_request/redux/purchasesSlice';

function MainProductPage() {
    const dispatch = useDispatch()

    const { loading_purchase_item, purchase_item } = useSelector((state: RootState) => state.purchases)
    const { loading_template_item, template_item,  } = useSelector((state: RootState) => state.templates)

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'detail') {
            return <ProductDetail/>
        } if (active_page === 'vendor') {
            return <ProductVendor/>
        } else {
            return <ProductHome/>
        }
    }

    useEffect(() => {
        if(purchase_item || template_item) {
          setTimeout(() => {
            dispatch(clearMessageTemplate())
            dispatch(clearSnackBarValue())
          }, 1000);
        }
        // eslint-disable-next-line
    }, [purchase_item, template_item]);
 
    return (
        <Stack>
            <LoaderBackdrop loading={loading_purchase_item || loading_template_item} />
            {switchPage()}
            <SnackBarAlert message={`Success Added item to Purchase Request.`} initialState={purchase_item} />
            <SnackBarAlert message={`Success Added item to Template.`} initialState={template_item} />
        </Stack>
    )
}

export default MainProductPage
