import { createSlice } from '@reduxjs/toolkit';
import { 
  addNewAddressCompany,
  getCompanyDetail, getSettingCustomNumber, getSettingVisibility, setDefaultAddressCompany, updateAddressCompany, 
  updateContactCompany, 
  updateImageCompany, updateLegalDocumentCompany, updateProfileCompany, updateSettingCustomNumber, updateSettingPlatform 
} from './reducers/profilesReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  profile : {},
  loading_profile : false,
  update_profile : false,
  loading_update_profile : false,
  update_contact : false,
  loading_update_contact : false,
  update_legal : false,
  loading_update_legal : false,
  create_address : false,
  loading_create_address : false,
  update_address : false,
  loading_update_address : false,
  default_address : false,
  loading_default_address : false,
  visibility_product : {},
  loading_visibility_product : false,
  setting_platform : false,
  loading_setting_platform : false,
  setting_custom_number : {},
  loading_setting_custom_number : false,
  update_setting_custom_number : false,
  loading_update_setting_custom_number : false, 
};

export const getCompanyProfile = createSlice({
  name: 'profile/get',
  initialState, 
  reducers: {
    updateImageState (state) {
      state.update_image = false
    },
    updateProfileSnackBar (state) {
      state.update_profile = false
    },
    updateContactSnackBar (state) {
      state.update_contact = false
    },
    updateAddressSnackBar (state) {
      state.update_address = false
      state.create_address = false
      state.default_address = false
      state.update_setting_custom_number = false
    },
    updateLegalSnackBar (state) {
      state.update_legal = false
    },
    updatePlatformSnackBar (state) {
      state.setting_platform = false
    }
  },
  extraReducers: (builder) => {
    builder
      // get profile company
      .addCase(getCompanyDetail.pending, (state) => {
        state.loading_profile = true;
        state.update_image = false;
      })
      .addCase(getCompanyDetail.fulfilled, (state, action:any) => {
        state.loading_profile = false;
        state.profile = action.payload.data;
      })
      .addCase(getCompanyDetail.rejected, (state, action : any) => {
        state.loading_profile = false;
        errorSweetAlert(action.payload)
      })

      // update image company
      .addCase(updateImageCompany.pending, (state) => {
        state.loading_update_image = true;
        state.update_image = false;
      })
      .addCase(updateImageCompany.fulfilled, (state, action:any) => {
        state.loading_update_image = false;
        state.update_image = action.payload.data;
      })
      .addCase(updateImageCompany.rejected, (state, action : any) => {
        state.loading_update_image = false;
        errorSweetAlert(action.payload)
      })

      // update profile company
      .addCase(updateProfileCompany.pending, (state) => {
        state.loading_update_profile = true;
      })
      .addCase(updateProfileCompany.fulfilled, (state, action:any) => {
        state.loading_update_profile = false;
        state.update_profile = action.payload.data;
      })
      .addCase(updateProfileCompany.rejected, (state, action : any) => {
        state.loading_update_profile = false;
        errorSweetAlert(action.payload)
      })

      // update contact company
      .addCase(updateContactCompany.pending, (state) => {
        state.loading_update_contact = true;
      })
      .addCase(updateContactCompany.fulfilled, (state, action:any) => {
        state.loading_update_contact = false;
        state.update_contact = action.payload.data;
      })
      .addCase(updateContactCompany.rejected, (state, action : any) => {
        state.loading_update_contact = false;
        errorSweetAlert(action.payload)
      })

      // create address company
      .addCase(addNewAddressCompany.pending, (state) => {
        state.loading_create_address = true;
        state.create_address = false;
      })
      .addCase(addNewAddressCompany.fulfilled, (state, action:any) => {
        state.loading_create_address = false;
        state.create_address = action.payload.data;
      })
      .addCase(addNewAddressCompany.rejected, (state, action : any) => {
        state.loading_create_address = false;
        errorSweetAlert(action.payload)
      })

      // update address company
      .addCase(updateAddressCompany.pending, (state) => {
        state.loading_update_address = true;
        state.update_address = false;
      })
      .addCase(updateAddressCompany.fulfilled, (state, action:any) => {
        state.loading_update_address = false;
        state.update_address = action.payload.data;
      })
      .addCase(updateAddressCompany.rejected, (state, action : any) => {
        state.loading_update_address = false;
        errorSweetAlert(action.payload)
      })

      // default address company
      .addCase(setDefaultAddressCompany.pending, (state) => {
        state.loading_default_address = true;
        state.default_address = false;
      })
      .addCase(setDefaultAddressCompany.fulfilled, (state, action:any) => {
        state.loading_default_address = false;
        state.default_address = action.payload.data;
      })
      .addCase(setDefaultAddressCompany.rejected, (state, action : any) => {
        state.loading_default_address = false;
        errorSweetAlert(action.payload)
      })

      // update legal company
      .addCase(updateLegalDocumentCompany.pending, (state) => {
        state.loading_update_legal = true;
      })
      .addCase(updateLegalDocumentCompany.fulfilled, (state, action:any) => {
        state.loading_update_legal = false;
        state.update_legal = action.payload.data;
      })
      .addCase(updateLegalDocumentCompany.rejected, (state, action : any) => {
        state.loading_update_legal = false;
        errorSweetAlert(action.payload)
      })

      // get settting visibility product
      .addCase(getSettingVisibility.pending, (state) => {
        state.loading_visibility_product = true;
      })
      .addCase(getSettingVisibility.fulfilled, (state, action:any) => {
        if(action.payload.length === 0) {
          let data = {
            value : "OPEN",
            label : "OPEN"
          }
          state.visibility_product = data;
        } else {
          let data = {
            value : action.payload.isVisibility,
            label : action.payload.isVisibility
          }
          state.visibility_product = data
        }
        state.loading_visibility_product = false;
      })
      .addCase(getSettingVisibility.rejected, (state, action : any) => {
        state.loading_visibility_product = false;
        errorSweetAlert(action.payload)
      })

      // update setting platform
      .addCase(updateSettingPlatform.pending, (state) => {
        state.loading_setting_platform = true;
      })
      .addCase(updateSettingPlatform.fulfilled, (state, action:any) => {
        state.loading_setting_platform = false;
        state.setting_platform = action.payload;
      })
      .addCase(updateSettingPlatform.rejected, (state, action : any) => {
        state.loading_setting_platform = false;
        errorSweetAlert(action.payload)
      })

      // get setting custom number
      .addCase(getSettingCustomNumber.pending, (state) => {
        state.loading_setting_custom_number = true;
      })
      .addCase(getSettingCustomNumber.fulfilled, (state, action:any) => {
        state.loading_setting_custom_number = false;
        state.setting_custom_number = action.payload;
      })
      .addCase(getSettingCustomNumber.rejected, (state, action : any) => {
        state.loading_setting_custom_number = false;
        errorSweetAlert(action.payload)
      })

      // update setting custom number
      .addCase(updateSettingCustomNumber.pending, (state) => {
        state.loading_update_setting_custom_number = true;
      })
      .addCase(updateSettingCustomNumber.fulfilled, (state, action:any) => {
        state.loading_update_setting_custom_number = false;
        state.update_setting_custom_number = action.payload;
      })
      .addCase(updateSettingCustomNumber.rejected, (state, action : any) => {
        state.loading_update_setting_custom_number = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { 
  updateImageState, updateProfileSnackBar, 
  updateContactSnackBar, updateAddressSnackBar, 
  updateLegalSnackBar, updatePlatformSnackBar
} = getCompanyProfile.actions;
export default getCompanyProfile.reducer;
