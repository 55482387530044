import React, { useState, useEffect } from 'react';
import {Stack,Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingFilter from './LoadingFilter';

const  FilterArea : React.FC<any> = ({ data, loading, selectedArea, setSelectedArea }) => {
    const [show, setShow] = useState(true);
    const [showMore, setShowMore] = useState(10);

    const [area, setArea] = useState([]);
    const [searchText, setSearchText] = React.useState<any>("");

    const handleChange = (e:any) => {
        const results = data.filter((item:any) => {
            if (e.target.value === "") return data
            return item.city.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setSearchText(e.target.value)
        setArea(results)
    }

    useEffect(() => {
        if(data) {
            setArea(data);
        }
    }, [data]);

  return (
    <Stack>
        { loading ? <LoadingFilter/> : 
        <Box className='area'>
            <Box className='title' onClick={() => setShow(!show)}>
                <p>Coverage Area</p>
                <Box>
                    { show ? <ExpandLessIcon className='icon-expand'/> : <ExpandMoreIcon className='icon-expand'/> }
                </Box>
            </Box>
            { show ? 
            <Box className='checkbox-area'>
                <div className='input-brand'>
                    <input 
                        placeholder='Search for area'
                        type="text"
                        value={searchText}
                        onChange={handleChange}
                    />
                </div>
                { area.slice(0,showMore).map((item:any, idx:any) => (
                    <div key={idx}>
                        <input 
                            type='checkbox' 
                            id={item.value} 
                            name={item.value}
                            checked={selectedArea === item.value ? true : false}
                            onChange={() => {
                                setSelectedArea(item.value)
                                localStorage.setItem('area', JSON.stringify(item))
                            }} 
                        /> 
                        <label htmlFor={item.value}>
                            { item.value.length <= 20 ? 
                            <span>{item.value.toLowerCase()}</span> :
                            <span>{item.value.substring(0,20).toLowerCase()} .. </span> }
                        </label>
                    </div>
                )) }

                { area.length > showMore && showMore < area.length  ? 
                <div onClick={() => setShowMore(showMore + 10)}>
                    <h6>See More</h6>
                </div> : null }
            </Box> : null }
            <Box sx={{ borderBottom: 'solid 1px #ddd' }} />
        </Box> }
    </Stack>
  );
}

export default FilterArea