import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { Box,  } from '@mui/material';
import HoverInfoProduct from './HoverInfoProduct';
import ImageSlider from './ImageSlider';
import DialogPurchase from './DialogPurchase';
import { useDispatch } from 'react-redux';
import { getCalculateItem } from '../redux/productReducers';
import { getPurchaseOpenStatus } from '../../purchase_request/redux/purchasesReducers';
import { getTemplateOpen } from '../../template/redux/templateReducers';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const ProductCard : React.FC<any> = ({
    item,
    selectedArea,
}) => {

    const dispatch = useDispatch()
    const formatCash = (n:number) => {
        if (n < 1e3) return n;
        if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
    };

    const accumulateDiscount = (price : number, discount : number) => {
        return price - (price * discount / 100)
    }

    const [detail, setDetail] = useState({ 
        open: false, 
        type : "", 
        data : {}
    });

    const addToPurchaseItem = async (ele:any,name:string) => {
        let product = {
            item : {
                _id : ele._id,
                name : ele.name,
                sku : ele.sku,
                slug : ele.slug,
                images : ele.images,
                warehouse : ele.warehouses,
                retail_price :  ele.price,
                stock :  ele.stock,
                minimum_order_quantity :  ele.minimum_order_quantity,
                quantity : ele.minimum_order_quantity,
                payment_term : ele.payment_term,
                vendor : ele.company,
                packages: null,
                order: null,
                discount : ele.discount.label === "SPECIAL" ? parseInt(ele.discount.value) : ele.discount.value,
                discount_type : ele.discount.label,
                discount_price : 0,
                tax : ele.tax,
                down_payment : ele.down_payment,
                total_tax : 0,
                total : 0,
                statuses : [],
                unit : ele.unit ? ele.unit.label : "N/A",
                category : ele.category ? ele.category : "N/A",
            },
            company : "null",
            type : 'first'
        }
        dispatch(getCalculateItem(product))
        setDetail({...detail, open:true, type : name})
    }
    
    return (
        <Box>
            <Stack>   
                <Box className='image-card-box'>
                    <Box className="zoom-effect-container">
                        <ImageSlider 
                            images={item.images}
                            slug={item.slug}
                            href={true}
                            lightbox={false}
                            selectedArea={selectedArea}
                        />
                    </Box> 
                    <Box className='title-vendor'>
                        { item.company.name.length > 30 ? 
                        <h4>{item.company.name.toLowerCase().substring(0, 30)} ..</h4> :
                        <h4>{item.company.name}</h4> }
                    </Box>
                    <Box className='title-name'>
                        { item.name.length > 44 ? 
                        <h1>{item.name.toLowerCase().substring(0, 44)} ..</h1>
                        :
                        <h1>{item.name.toLowerCase()}</h1>
                        }
                    </Box>
                    <Box className='title-brand'>
                        <h4>{item.brand}</h4>
                    </Box>
                    { item.discount.label === "PERCENTAGE" && item.discount.value !== 0 ? 
                    <>
                        <Box className="title-price">
                            <h1>Rp. {accumulateDiscount(item.price, item.discount.value).toLocaleString()}</h1>    
                        </Box>
                        <Box className="title-discount">
                            <Box><h2>Rp. {item.price.toLocaleString()}</h2></Box>
                            <Box className="discount"><h3>{item.discount.value}%</h3></Box>   
                            <Box className="info-box">
                                <HoverInfoProduct stock={item.stock} min_order={item.minimum_order_quantity}/>
                            </Box>
                        </Box>
                    </> : item.discount.label === "AMOUNT" && item.discount.value !== 0 ? 
                    <>
                        <Box className="title-price">
                            <h1>Rp. {(item.price - item.discount.value).toLocaleString()}</h1>    
                        </Box>
                        <Box className="title-discount-amount">
                            <Box><h2>Rp. {item.price.toLocaleString()}</h2></Box>
                            <Box className="discount"><h3>Rp.{formatCash(item.discount.value)}</h3></Box>   
                            <Box className="info-box">
                                <HoverInfoProduct stock={item.stock} min_order={item.minimum_order_quantity}/>
                            </Box>
                        </Box>
                    </> : item.discount.label === "SPECIAL" ? 
                    <>
                        <Box className="title-price">
                            <h1>Rp. {item.discount.value.toLocaleString()}</h1>    
                        </Box>
                        <Box className="title-discount-special">
                            <Box><h2>Rp. {item.price.toLocaleString()}</h2></Box>
                            <Box className="discount"><h3>SPECIAL</h3></Box>   
                            <Box className="info-box">
                                <HoverInfoProduct stock={item.stock} min_order={item.minimum_order_quantity}/>
                            </Box>
                        </Box>
                    </> :
                    <>
                        <Box className="title-price">
                            <h1>Rp. {item.price.toLocaleString()}</h1>    
                        </Box>
                        <Box className="title-discount">
                            <Box className="info-box">
                                <HoverInfoProduct stock={item.stock} min_order={item.minimum_order_quantity}/>
                            </Box>
                        </Box> 
                    </> }
                    <Box className="button-cards">
                        { checkValidation('CREPURREQ486033') &&
                        <Box 
                            className="pr" 
                            onClick={() => {
                                addToPurchaseItem(item,'Purchase Request')
                                dispatch(getPurchaseOpenStatus())
                            }}
                        >
                            Add to Purchase Request 
                        </Box> }
                        { checkValidation('CRETEM101020') &&
                        <Box 
                            className="template" 
                            onClick={() => {
                                addToPurchaseItem(item,'Template')
                                dispatch(getTemplateOpen())
                            }}
                        >
                            Add to Template
                        </Box>
                        }
                    </Box>
                </Box>
            </Stack>
            <DialogPurchase detail={detail} setDetail={setDetail} />
        </Box>
        
    )
}

export default ProductCard
 