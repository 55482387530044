import { Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import calender from '../../../../assets/img/icon/calendar.png'
import { checkValidation } from '../../../../utilities/userFeaturesValidation'
import { getUpdateTemplate } from '../redux/templateReducers'
import DialogConfirmation from './DialogConfirmation'
import HistoryStatus from './HistoryStatus'
import TemplateDetail from './TemplateDetail'

const TemplateCard : React.FC<any> = ({ item }) =>  {
    const dispatch = useDispatch()

    const [openDetail, setOpenDetail] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState({
        open: false,
        type: '',
        id : ""
    });
    const [openStatus, setOpenStatus] = useState({
        open: false,
        data : []
    });
    const [idTemplate, setIdTemplate] = useState("");
    const [refetch, setRefetch] = useState(false);

    return (
    <div className='container-item-purchase'>
        <Box className="top-div">
            <Box className='left'>
                <h6>Name : {item.name}</h6>
                <Box className='line-right' />
                <h5>{item.department}</h5>
            </Box>
            <Box className='right'>
                <img src={calender} alt='calender' />
                <p>{moment(item.date).format('lll')}</p>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={10} xl={10}>
                    <Box className='left-box'>
                        <h2>Vendor List :</h2>
                        <Box className='vendor-list'>
                            { item.vendors.map((ele:any, i:any) => (
                                <Box className='box-vendor' key={i}>
                                    <h3>{ele.toLowerCase()}</h3>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
                    <Box className='total-prices'>
                        <p>Total</p>
                        <h3>Rp.{(Math.floor(item.grand_total)).toLocaleString()}</h3>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box 
                className='view-btn' 
                onClick={() => {
                    setIdTemplate(item._id)
                    setRefetch(!refetch)
                    setOpenDetail(true)
                }}
            >
                <p>View Template</p>
            </Box> 
            { item.lastStatus === 'Open' || item.lastStatus === 'Reject' ?
            <>
            { checkValidation('USTEMP012344') &&
            <Box className="btn-pr-submit" >
                <button onClick={() => setOpenConfirmation({open:true, type: 'Use', id: item._id})}>
                    <p>Use Template</p>
                </button>
            </Box> 
            }
            { checkValidation('UPDTEM580982') &&
            <Box className="btn-pr-submit">
                <button onClick={() => dispatch(getUpdateTemplate(item._id))}>
                    <p>Edit</p>
                </button>
            </Box>
            }
            { checkValidation('DELTEM409264') &&
            <Box className="btn-pr-reject">
                <button onClick={() => setOpenConfirmation({open:true, type: 'Delete', id: item._id})}>
                    <p>Delete</p>
                </button>
            </Box>
            }
            </>
            : null }
        </Box>

        <TemplateDetail open={openDetail} setOpen={setOpenDetail} idTemplate={idTemplate} refetch={refetch} />
        <DialogConfirmation open={openConfirmation} setOpen={setOpenConfirmation} />
        <HistoryStatus open={openStatus} setOpen={setOpenStatus} />
    </div>
  )
}

export default TemplateCard