import React, { useState, useEffect } from 'react'
import {
    Button,
    Grid,
    Box,
    TextField,
    Backdrop,
    CircularProgress,
    Skeleton
} from '@mui/material';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { changeStep, getActionCategoryCompany } from './stepFormSlice'
import { RootState } from '../../../../app/store';
import { userCredentials } from '../../../../utilities/config';
import PhoneInput from 'react-phone-number-input'
import StoreBanner from '../../../../components/StoreBanner';
import AddressFormList from '../../../../components/AddressFormList';
import required from '../../../../assets/img/icon/required.png'

const FormCompanyDetail : React.FC<any> = () => {
    const dispatch = useDispatch()
    const state_stepregister = useSelector((state : RootState) => state.step_register)
    const { category, loading_category } = useSelector((state : RootState) => state.step_state)

    const [localData, setLocalData] = useState<any>({});

    const [valueAddress, setValueAddress] = useState({
        label : "",
        country : "Indonesia",
        error_country : false,
        province : "",
        error_province : false,
        city : "",
        error_city : false,
        subdistrict : "",
        error_subdistrict : false,
        village : "",
        error_village : false,
        postcode : "",
        street : ""
    });

    const [companyForm, setCompanyForm] = useState<any>({
        name : "",
        legalName : "",
        logo : "",
        banner : "",
        name_hotline : "",
        phone_hotline : "",
        name_mobile : "",
        phone_mobile : ""
    });

    const onChangeFormValue = (e : any) => {
        const { name, value } = e.target 
        setCompanyForm({...companyForm, [name] : value })
    }

    const onChangeUrlValue = (url : string, name:string) => {
        setCompanyForm({...companyForm, [name] : url })
    }

    const [selectedCategoryCompany, setSelectedCategoryCompany] = useState<any>([]);
    const [errorCategoryCompany, setErrorCategoryCompany] = useState<boolean>(false);

    const optionsIsPKP  = [
        { value : false, label : "Perusahaan Non PKP"},
        { value : true, label : "Perusahaan PKP"}
    ]
    const [selectedIsPKP, setSelectedIsPKP] = useState<any>([]);
    const [errorIsPKP, setErrorIsPKP] = useState<boolean>(false);

    const [erroTechnicalNumber, setErroTechnicalNumber] = useState<boolean>(false);
    const [errorHotlineNumber, setErrorHotlineNumber] = useState<boolean>(false);

    const handleChangeCategoryCompany = (value: any) : void => {
        setErrorCategoryCompany(false)
        setSelectedCategoryCompany(value)
    }

    const handleChangeIsPKP = (value: any) : void => {
        setErrorIsPKP(false)
        setSelectedIsPKP(value)
    }

    function checkValidation() {
        let validate = true
        if (companyForm.phone_hotline === "") {
            setErrorHotlineNumber(true)
            validate = false
        } else if (companyForm.phone_mobile === "") {
            setErroTechnicalNumber(true)
            validate = false
        }  else if(selectedCategoryCompany.length === 0) {
            setErrorCategoryCompany(true)
            validate = false
        } else if (selectedIsPKP.length === 0) {
            setErrorIsPKP(true)
            validate = false
        } else if (valueAddress.province === "") {
            setValueAddress({...valueAddress, error_province : true})
            validate = false
        } else if (valueAddress.city === "") {
            setValueAddress({...valueAddress, error_city : true})
            validate = false
        } else if (valueAddress.subdistrict === "") {
            setValueAddress({...valueAddress, error_subdistrict : true})
            validate = false
        } else if (valueAddress.village === "") {
            setValueAddress({...valueAddress, error_village : true})
            validate = false 
        } else {
            validate = true
        }
        return validate
        // else if (valueAddress.postcode === "") {
        //     setValueAddress({...valueAddress, error_postcode : true})
        //     validate = false
        // } 
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if(checkValidation()) {
            let data_form = {
                "company": {
                    "name": companyForm.name,
                    "legalName": companyForm.legalName,
                    "isPKP": selectedIsPKP,
                    "category": selectedCategoryCompany,
                    "type": "",
                    "owner": "null",
                    "platform": "VENDOR",
                    "logo": companyForm.logo || "https://mpindo.sgp1.digitaloceanspaces.com/vendor/47adfa24-cc5d-4966-a075-f95108191c50no-photo.png",
                    "banner": companyForm.banner
                },
                "address": {
                    "company": "null",
                    "label": valueAddress.label,
                    "country": valueAddress.country,
                    "province": valueAddress.province,
                    "city": valueAddress.city,
                    "subdistrict": valueAddress.subdistrict,
                    "village": valueAddress.village,
                    "postcode": valueAddress.postcode,
                    "street": valueAddress.street,
                    "phone": companyForm.phone_mobile,
                    "details": "N/A"
                },
                "contactUs": {
                    "company": "null",
                    "email": userCredentials.email,
                    "phone": [
                        {
                            "label": companyForm.name_hotline,
                            "number": companyForm.phone_hotline,
                            "iconUrl": "-",
                            "onWhatsApp": false
                        },
                        {
                            "label": companyForm.name_mobile,
                            "number": companyForm.phone_mobile,
                            "iconUrl": "-",
                            "onWhatsApp": false
                        }
                    ]
                },
                bank : localData.bank ? localData.bank : {},
                legal_docs: localData.legal_docs ? localData.legal_docs : [],
                type_company: localData.type_company ? localData.type_company : {},
            }
            localStorage.setItem('detail_company', JSON.stringify(data_form))
            dispatch(changeStep(1))
        }
    }


    useEffect(() => {
        dispatch(getActionCategoryCompany())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(state_stepregister.company_detail) {
            dispatch(changeStep(1))
        }
        // eslint-disable-next-line
    }, [state_stepregister.company_detail])

    useEffect(() => {
        const local_data = localStorage.getItem('detail_company')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setSelectedCategoryCompany(data.company.category)
                setSelectedIsPKP(data.company.isPKP)
                setValueAddress({...valueAddress, 
                    country : data.address.country,
                    province : data.address.province,
                    city : data.address.city,
                    subdistrict : data.address.subdistrict,
                    village : data.address.village,
                    postcode : data.address.postcode,
                    street : data.address.street,
                    label : data.address.label,
                })
                setCompanyForm({...companyForm, 
                    name : data.company.name,
                    logo : data.company.logo,
                    banner : data.company.banner,
                    legalName : data.company.legalName,
                    name_hotline : data.contactUs.phone[0].label,
                    phone_hotline : data.contactUs.phone[0].number,
                    name_mobile : data.contactUs.phone[1].label,
                    phone_mobile : data.contactUs.phone[1].number,
                })
                setLocalData(data)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);



    return (
        <div>
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state_stepregister.loading_comp_detail}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="section-form-company-detail">
                <Box>
                    <form onSubmit={handleSubmit}>    
                        <Box mt={2} pb={1} pl={2}>
                            <h3>Basic Information (Required)</h3>
                        </Box>
                        <Box >
                            <StoreBanner 
                                logoUrl={companyForm.logo}
                                bannerUrl={companyForm.banner}
                                onChangeUrlValue={onChangeUrlValue}
                            />
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Legal Name <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            id="legalName"
                                            name="legalName"
                                            size="small"
                                            placeholder='PT Pasar Pemesanan Indonesia'
                                            value={companyForm.legalName}
                                            onChange={onChangeFormValue}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Business Name <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            id="name"
                                            placeholder='Pasar Pemesanan'
                                            value={companyForm.name}
                                            name="name"
                                            size="small"
                                            onChange={onChangeFormValue}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pt={1} pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Category Company <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <Box pt={1}>
                                            { loading_category ? 
                                            <Box mt={0} pt={0}><Skeleton height={60} /></Box>
                                            :
                                            <Select
                                                placeholder="Select Category Company"
                                                value={selectedCategoryCompany}
                                                isSearchable={true}
                                                options={category && category}
                                                onChange={handleChangeCategoryCompany}
                                                id="select-style-cat"
                                            /> }
                                        </Box>
                                        { errorCategoryCompany ? <div className="error-p"><p>Category is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pt={1} pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Taxable Type <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <Box pt={1}>
                                            <Select
                                                placeholder="Select Taxable "
                                                value={selectedIsPKP}
                                                isSearchable={true}
                                                options={optionsIsPKP && optionsIsPKP}
                                                onChange={handleChangeIsPKP}
                                                id="select-style-cat"
                                            />
                                        </Box>
                                        { errorIsPKP ? <div className="error-p"><p>Taxable Type is required</p></div> : null }
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{pt:2}}>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Contact Name Hotline <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            type="text"
                                            placeholder='John Doe'
                                            value={companyForm.name_hotline}
                                            onChange={onChangeFormValue}
                                            name="name_hotline"
                                            size="small"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Hotline Number <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <Box>
                                            <PhoneInput
                                                placeholder="Enter hotline number"
                                                value={companyForm.phone_hotline}
                                                onChange={(e) => {
                                                    setErrorHotlineNumber(false)
                                                    setCompanyForm({...companyForm, phone_hotline : e})
                                                }}
                                                defaultCountry="ID"
                                            />
                                        </Box>
                                        { errorHotlineNumber ? <div className="error-p"><p>Hotline Number is required</p></div> : null }
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} >
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Contact Name Mobile Number <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            type="text"
                                            value={companyForm.name_mobile}
                                            onChange={onChangeFormValue}
                                            name="name_mobile"
                                            placeholder='Mikel Doe'
                                            size="small"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                            Mobile Number <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <Box >
                                            <PhoneInput
                                                placeholder="Enter mobile number"
                                                value={companyForm.phone_mobile}
                                                onChange={(e) => { 
                                                    setErroTechnicalNumber(false)
                                                    setCompanyForm({...companyForm, phone_mobile : e})
                                                }}
                                                defaultCountry="ID"
                                            />
                                        </Box>
                                        {erroTechnicalNumber ? <div className="error-p"><p>Mobile Number is required</p></div> : null }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box mt={4} pb={1} pl={2}>
                            <h3>Address (Required)</h3>
                        </Box>
                        <Box p={2}>
                            <AddressFormList 
                                setValueAddress={setValueAddress}
                                valueAddres={valueAddress}
                            />
                        </Box>
                        
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, pb: 2 }}>
                            <Button
                                variant="contained"
                                color="inherit"
                                sx={{ mr: 1 }}
                                disabled
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button 
                                variant="contained"
                                type="submit"
                            >
                                Next
                            </Button>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    )
}

export default FormCompanyDetail
