import { Box, Skeleton } from '@mui/material';
import { Line } from 'react-chartjs-2';

const TotalOrders : React.FC<any> = ({ charts_orders, loading }) => {

    const data = {
        labels: charts_orders.labels,
        datasets: [
            {
                label : "",
                data: charts_orders.data,
                backgroundColor: 'rgb(67,160,71)',
                borderColor: 'rgb(67,160,71)',
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
        }, 
        scales: {
          yAxis: {
            min: 0,
          },
        },
      };

    return (
        <Box sx={{p:2}}>
            { loading ?
                <Box>
                    <Skeleton height={70} variant="rectangular" />
                </Box>
                :
            <div id="chart">
                <Line options={options} data={data} height={350}  />
            </div>
          }
        </Box>
    )
}

export default TotalOrders
