import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllBuyerCompany = createAsyncThunk(
    'buyer-list/get',
    async (params :any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/company/paginate`, {params,headers})
            if(response.data) {
                return response.data
            } else { 
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const getRoleBuyerCompany = createAsyncThunk(
    'role-company/get',
    async (id :string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/company/roles/${id}/BUYER`, {headers})
            if(response.data) {
                return response.data
            } else { 
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);