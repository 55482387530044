import { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button, 
    CircularProgress,
    Alert,
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { signInAction } from '../redux/authReducers';
import { userCredentials } from '../../../utilities/config';
import { IAuthState } from '../types';

const validationSchema = yup
  .object({
    password: yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
  })
  .required();


const ReSignInPage = () => {
  const dispatch = useDispatch()
  const { signin, isRegistered, loading_signin, error_signin } = useSelector((state : RootState) => state.auth )
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IAuthState>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (values: IAuthState): void => {
    let data = {
      email : userCredentials.email,
      password : values.password,
      shadow : false,
    }
    dispatch(signInAction(data))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  useEffect(() => {
    if (signin && isRegistered) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      },200);
    }
    // eslint-disable-next-line
  }, [isRegistered]);

  
  return (
    <>
      <Box m={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4rem 2rem',
              height: 'calc(100vh - 400px)',
            }}
          >
            <Box className='name-login' color="primary" sx={{ textAlign: 'center', paddingBottom: 3 }}>
              <h1>Relogin</h1> 
              <p>Please input your password to continue.</p>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1, maxWidth : 350 }}>
                  <TextField
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      {...register('password', { required: true })}
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      id="password"
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                      endAdornment : (
                          <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                          >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          </InputAdornment>
                      )
                      }}
                  />

                  { error_signin === null ? null :
                  <Box pt={1} sx={{ minWidth: 350 }}>
                      <Alert severity="error">{error_signin}</Alert>
                  </Box> }

                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }} 
                      size="medium"
                      color="primary"
                      disabled={loading_signin}
                  >
                      Submit 
                      { loading_signin && <CircularProgress  size={30} color="inherit" style={{ marginLeft: 10}} /> }
                  </Button>
              </Box>
            </form>
          </Box>
      </Box>
    </>
    )
}

export default ReSignInPage;
