import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getChartsReports = createAsyncThunk(
    'get/charts-reports',
    async (range : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/charts/buyer`, {
                params : {
                    "from" : `${range.from}`,
                    'to' : `${range.to}`
                },
                headers
            })
            if(response.data) {
                return response.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);  

export const getSumChartsReports = createAsyncThunk(
    'get/charts-sum-reports',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/report/buyer/sum/order`, {
                params : {
                    "id": userCredentials.buyer_id,
                },
                headers
            })
            if(response.data) {
                return {data : response.data, message : ""}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);  


export const downloadReports = createAsyncThunk(
    'post/download-reports',
    async (range : any, { rejectWithValue }) => { 
        try {
            let body = {
                "category": `${range.category}`,
                "from" : `${range.from}`,
                'to' : `${range.to}`,
            }
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/download/buyer`, body, {
                headers
            })
            if(response.data) {
                return response.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
); 
