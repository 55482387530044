import { Box } from '@mui/material'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../components/BreadCrumbs';
import HeadlineTitle from '../../../components/HeadlineTitle';
import { useState, useEffect } from 'react';
import OrderSearch from './components/OrderSearch';
import OrderCard from './components/OrderCard';
import { RootState } from '../../../app/store';
import { getDetailStatusPackageCount, getOrderDetail, getOrdersData, getOrdersDataLoadMore } from './redux/ordersReducers';
import OrderLoading from './components/OrderLoading';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { addLengthInfiniteOrder, updateValueAlert } from './redux/ordersSlice';
import SnackBarAlert from '../../../components/SnackBarAlert';
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from 'sweetalert';
import LoaderProgress from '../../../components/LoaderProgress';
import DataNotFound from '../../../components/DataNotFound';
import { useHistory, useLocation } from 'react-router-dom';
import OrderDetail from './components/OrderDetail';
import { getPreferredAll } from '../vendor_lists/redux/vendorListsReducer';
import { resetSnackBarPreferred } from '../vendor_lists/redux/vendorListsSlice';
import { getVendorFulfillment } from '../chat/redux/chatReducers';

const PageOrders = () => {
    const dispatch = useDispatch()
    const location : any = useLocation()
    const history = useHistory()
 
    const { 
        orders, total_orders, loading_orders, loading_pick, pick,
        proof_payment, loading_proof_payment,
        loading_download_pdf, download_pdf,
        loading_approval_item, approval_item, type_approval,
        loading_create_grn, loading_send_email, orders_loaded, total_infinite
    } = useSelector((state : RootState) => state.orders)
    const { download_inv, loading_download_inv } = useSelector((state : RootState) => state.invoice)
    const { create } = useSelector((state : RootState) => state.preferred)

    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [valueSearch, setValueSearch] = useState("");
    const [filterTopic, setFilterTopic] = useState(["lastStatus"]);
    const [filterValue, setFilterValue] = useState(["Active"]);
    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [activeStatus, setActiveStatus] = useState("Active");
    const [statusPackage, setStatusPackage] = useState("All");
    const [refetch, setRefetch] = useState(false);

    const [openDetail, setOpenDetail] = useState(false);
    const [statusPackageDetail, setStatusPackageDetail] = useState(statusPackage);

    function onClickDetailPackage (item:any, status:string,) {
        setStatusPackageDetail(status)
        dispatch(getOrderDetail({ id : item._id, status : status }))
        dispatch(getDetailStatusPackageCount(item._id))
        setOpenDetail(true)
    }

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch,
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
         
        const newdata : any = await dispatch(getOrdersDataLoadMore(params))
        if(newdata.type === "orders-data-load-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                dispatch(addLengthInfiniteOrder(newdata.payload.data.length))
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    
    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivOrder").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if(orders_loaded) {
            setDataInfinite(orders)
        }
        // eslint-disable-next-line
    }, [orders, orders_loaded]);

    useEffect(() => {
        if(fetch && orders_loaded) {
            if(total_infinite >= total_orders) {
                setHasMore(false)
            }
        }
    }, [fetch, orders_loaded, total_infinite, total_orders]);

    useEffect(() => {
        if(orders_loaded && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total_orders) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, orders_loaded, fetch, total_infinite]);

    useEffect(() => {
        const params = {
            limit,
            skip : 0,
            search : valueSearch,
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
        setFetch(true)
        setHasMore(true)
        setSkip(0)
        dispatch(getOrdersData(params))
        // eslint-disable-next-line
    }, [valueSearch, filterTopic, filterValue, rangeTo, rangeFrom, rangeTopic, refetch]);

    useEffect(() => {
        const params = {
            limit,
            skip : 0,
            search : valueSearch,
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
        if(proof_payment || pick) {
            setTimeout(() => {
                setSkip(0)
                dispatch(getOrdersData(params))
            }, 500);
        }
        // eslint-disable-next-line
    }, [proof_payment, pick]);

    useEffect(() => {
        if(pick || download_pdf || approval_item) {
            setTimeout(() => {
                dispatch(updateValueAlert())
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pick, download_pdf, approval_item]);

    useEffect(() => {
        if(download_pdf !== "" ) {
            window.location.assign(download_pdf)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [download_pdf]);

    useEffect(() => {
        if(download_inv !== "" ) {
            window.location.assign(download_inv)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [download_inv]);

    useEffect(() => {
        if(orders_loaded && location.state !== null) {
            setStatusPackageDetail(location.state.status)
            dispatch(getOrderDetail({ id : location.state.payload.idPo, status : location.state.status }))
            dispatch(getDetailStatusPackageCount(location.state.payload.idPo))
            setOpenDetail(true)
            setTimeout(() => {
                history.replace({ state: null })
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders_loaded]);

    
    useEffect(() => {
        if(location.state !== null) {
            if(location.state.status === "Waiting Down Payment") {
                setFilterTopic(['lastStatus', 'code'])
                setFilterValue([
                    location.state.status === "Waiting Down Payment" ? "Waiting Down Payment" : "Active",
                    location.state.payload.codePo
                ])
                setActiveStatus(location.state.status === "Waiting Down Payment" ? "Waiting Down Payment" : "Active")
                setStatusPackage(location.state.status)
            } else {
                setFilterTopic(['lastStatus','packages._id', 'code'])
                setFilterValue([
                    location.state.status === "Waiting Down Payment" ? "Waiting Down Payment" : "Active",
                    location.state.status,
                    location.state.payload.codePo
                ])
                setActiveStatus(location.state.status === "Waiting Down Payment" ? "Waiting Down Payment" : "Active")
                setStatusPackage(location.state.status)
            }
        }
    }, [location]);

    useEffect(() => {
        dispatch(getPreferredAll())
        dispatch(getVendorFulfillment())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    useEffect(() => {
        if(create) {
            dispatch(getPreferredAll())
            setTimeout(() => {
                dispatch(resetSnackBarPreferred())
            }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);


    return (
        <Stack m={2} className="order-container">
            <LoaderBackdrop 
                loading={loading_pick || loading_proof_payment || loading_download_pdf || loading_approval_item || loading_create_grn || loading_send_email || loading_download_inv} 
            />
            <BreadCrumbs 
                isPage={false}
                current="Purchase Orders"
            />
            <Stack direction="row" justifyContent="space-between" pt={1} >
                <Box>
                    <HeadlineTitle 
                        title='Purchase Orders'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
            </Stack>

            <OrderSearch 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                setFilterTopic={setFilterTopic}
                setFilterValue={setFilterValue}
                statusPackage={statusPackage}
                setStatusPackage={setStatusPackage}
                setSkip={setSkip}
                setRangeTopic={setRangeTopic}
                setRangeFrom={setRangeFrom}
                setRangeTo={setRangeTo}
                rangeFrom={rangeFrom}
                rangeTo={rangeTo}
            />

            { loading_orders ?
            <Stack mt={1}>
                {[1,2,3].map(idx => (
                    <OrderLoading key={idx} />
                ))}
            </Stack>
            :
            <div id="scrollableDivOrder" >
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDivOrder"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                { dataInfinite.length > 5 && 
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box>  }
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((item:any, idx:number) => (
                            <OrderCard 
                                item={item} 
                                key={idx} 
                                statusPackage={statusPackage}
                                onClickDetailPackage={onClickDetailPackage}
                            />
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> 
                :
                <Box mt={2} mx={0.2}>
                    <DataNotFound 
                        message="Order is not found."
                        caption="Try to change your search or filter."
                    />
                </Box>  }
            </div> } 

            <OrderDetail 
                open={openDetail} 
                setOpen={setOpenDetail} 
                statusPackageDetail={statusPackageDetail} 
                setStatusPackageDetail={setStatusPackageDetail} 
                setRefetch={setRefetch}
                refetch={refetch}
            />
            <SnackBarAlert message="Submit Pick List is success!" initialState={pick} />
            <SnackBarAlert message="Submit Proof Down Payment is success!" initialState={proof_payment} />
            <SnackBarAlert message={`${type_approval === "accept-item" ? "Approve" : "Reject"} item change is success!`} initialState={approval_item} />
            <SnackBarAlert message={`Become Preferred Buyer is successfuly requested`} initialState={create} />
        </Stack>
    )
}

export default PageOrders;
