import { Box } from '@mui/material'
import { Button, Stack } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useHistory } from 'react-router-dom';
import HeadlineTitle from '../../../components/HeadlineTitle';
import PurchaseCard from './components/PurchaseCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useEffect, useState } from 'react';
import { clearPurchasesLocalData, getPurchaseServerData, getPurchaseServerLoadMore } from './redux/purchasesReducers';
import PurchaseSearch from './components/PurchaseSearch';
import PurchaseLoading from './components/PurchaseLoading';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { addLengthInfinite, clearSnackBarValue } from './redux/purchasesSlice';
import SnackBarAlert from '../../../components/SnackBarAlert';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../components/LoaderProgress';
import swal from 'sweetalert';
import DataNotFound from '../../../components/DataNotFound';
import ButtonCreate from '../../../components/ButtonCreate';
import { checkValidation } from '../../../utilities/userFeaturesValidation';

const PagePurchase = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { 
        purchases, loading_purchases, total_purchases,
        remove_purchase, loading_remove_purchase, 
        submit_purchase, loading_submit_purchase,
        approve_purchase, loading_approve_purchase, 
        reject_purchase, loading_reject_purchase,
        loading_edit, edit, loading_print, print,
        loaded_purchases, total_infinite,
        edit_number, loading_edit_number
    } = useSelector((state : RootState) => state.purchases)

    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [valueSearch, setValueSearch] = useState("");
    const [filterTopic, setFilterTopic] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [activeStatus, setActiveStatus] = useState("All");

    const onClickCreateNewPR = async () => {
        dispatch(clearPurchasesLocalData())
        localStorage.removeItem('saved')
        history.push({
            pathname: "/dashboard/purchase-requests",
            search: `?page=create`, 
        })
    }

    useEffect(() => {
        if(edit) {
            history.push({
                pathname: "/dashboard/purchase-requests",
                search: `?page=create`, 
            })
        }
        // eslint-disable-next-line
    }, [edit]);

    useEffect(() => {
        if(print !== "") {
            window.location.assign(print)
        }
    }, [print]);

    useEffect(() => {
        const params = {
            limit,
            skip : 0 ,
            search : valueSearch, 
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
        dispatch(getPurchaseServerData(params))
        setFetch(true)
        setHasMore(true)
        setSkip(0)
        // eslint-disable-next-line
    }, [valueSearch, filterTopic, filterValue, rangeTo, rangeFrom, rangeTopic]);


    useEffect(() => {
        if(remove_purchase || submit_purchase || approve_purchase || reject_purchase || edit_number) {
            const params = {
                limit,
                skip : 0,
                search : valueSearch,
                'filterTopic[]' : filterTopic,
                'filterValue[]' : filterValue,
                'rangeTopic' : rangeTopic,
                'rangeFrom' : rangeFrom,
                'rangeTo' : rangeTo,
            }
            dispatch(getPurchaseServerData(params))
            setSkip(0)
            setTimeout(() => {
                dispatch(clearSnackBarValue())            
            }, 2000);
        }
        // eslint-disable-next-line
    }, [ remove_purchase, submit_purchase, approve_purchase, reject_purchase, edit_number]);

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch,
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
         
        const newdata : any = await dispatch(getPurchaseServerLoadMore(params))
        if(newdata.type === "purchase-request-loadmore/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                dispatch(addLengthInfinite(newdata.payload.data.length))
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    useEffect(() => {
        if(loaded_purchases) {
            setDataInfinite(purchases)
        }
        // eslint-disable-next-line
    }, [purchases, loaded_purchases]);

    useEffect(() => {
        if(fetch && loaded_purchases) {
            if(total_infinite >= total_purchases) {
                setHasMore(false)
            }
        }
    }, [fetch, loaded_purchases, total_infinite, total_purchases]);

    useEffect(() => {
        if(loaded_purchases && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total_purchases) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, loaded_purchases, fetch, total_infinite]);

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDiv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Stack m={2} className="order-container">
            <LoaderBackdrop 
                loading={loading_remove_purchase || loading_submit_purchase || loading_reject_purchase || loading_approve_purchase || loading_edit || loading_print || loading_edit_number} 
            />
            <BreadCrumbs 
                isPage={false}
                current="Purchase Requests Page"
            /> 
            <Stack direction="row" justifyContent="space-between" py={1} flexWrap="wrap">
                <Box>
                    <HeadlineTitle 
                        title='Purchase Requests'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
                { checkValidation('CREPURREQ486033') &&
                <Box sx={{display: 'flex'}}>
                    <Box>
                        <ButtonCreate 
                            name="Create New PR"
                            onClick={() => onClickCreateNewPR()}
                        />
                    </Box>
                    <Box mr={2} />
                </Box> }
            </Stack>

            <PurchaseSearch 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                setFilterTopic={setFilterTopic}
                setFilterValue={setFilterValue}
                setSkip={setSkip}
                setRangeTopic={setRangeTopic}
                setRangeFrom={setRangeFrom}
                setRangeTo={setRangeTo}
            />
            
            { loading_purchases ?
            <Stack mt={1}>
                {[1,2,3].map(idx => (
                    <PurchaseLoading key={idx} />
                ))}
            </Stack>
            :
            <div id="scrollableDiv"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDiv"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                { dataInfinite.length > 5 && 
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box>  }
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((item:any, idx:number) => (
                            <PurchaseCard item={item} key={idx} />
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> :
                <Box mt={2} mx={0.2}>
                    <DataNotFound 
                        message="Purchase Request is not found."
                        caption="Try to search another keyword or filter"
                    />
                </Box> }
            </div> }
            <SnackBarAlert message={`Purchase Request successfully submited!`} initialState={submit_purchase} />
            <SnackBarAlert message={`Purchase Request successfully deleted!`} initialState={remove_purchase} />
            <SnackBarAlert message={`Purchase Request successfully approved!`} initialState={approve_purchase} />
            <SnackBarAlert message={`Purchase Request successfully rejected!`} initialState={reject_purchase} />
            <SnackBarAlert message={`Code Purchase Request successfully updated!`} initialState={edit_number} />
        </Stack>
    )
}

export default PagePurchase;
