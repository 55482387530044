import { Box } from '@mui/material'
import { Skeleton, Stack } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import ButtonCreate from '../../../../components/ButtonCreate';
import { RootState } from '../../../../app/store';
import moment from 'moment';
import { getInvoiceDetail, printDownloadInvPDF } from './redux/invoicesReducers';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import arrowright from '../../../../assets/img/icon/next.png'; 
import { useEffect, useState } from 'react';
import DialogPaymentMethod from './components/DialogPaymentMethod';
import DialogPay from './components/DialogPay';
import { closeDownloadInv } from './redux/invoicesSlice';
import DownloadPdf from '../../../../components/DownloadPdf';

const InvoiceDetail = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location : any = useLocation()

    const { 
      open_invoice_data, loading_create_invoice, create_invoice,
      loading_invoice_detail, download_inv, loading_download_inv
    } = useSelector((state : RootState) => state.invoice)

    const { id, type } = location.state === undefined ? null : location.state    
    const [dialogMethod, setDialogMethod] = useState(false);

    const [selectMethod, setSelectMethod] = useState<any>({});
    const [errorMethod, setErrorMethod] = useState(false);

    const [openDetail, setOpenDetail] = useState(false);
    const [dataTransaction, setDataTransaction] = useState<any>({});
    
    const isEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    const handlePayInvoice = (value:any) => {
      if(value.directPayment) {
        history.push({
          pathname: "/dashboard/finance/invoice",
          search: `?page=payment`, 
          state: { 
            params : {
              method: "Bank Transfer",
              paymentGateway: "Direct Payment",
              accountNumber: value.bank.account_number,
              bank: value.bank.bank._id,
              amount: value.grandTotal,
              invoices: [
                  value._id
              ],
              banks : {
                name: value.bank.bank.label,
                accountNumber: value.bank.account_number,
                accountName: value.bank.account_name
              },
              company : value.bank.company
            }
          }
        })
      } else {
        if(isEmpty(selectMethod)) {
          history.push({
            pathname: "/dashboard/finance/invoice",
            search: `?page=payment`, 
            state: { 
              params : {
                method: selectMethod.method,
                paymentGateway: "Xendit",
                accountNumber: "null",
                bank: selectMethod._id,
                amount: value.grandTotal,
                invoices: [
                    value._id
                ],
                banks : {
                  name: value.bank.bank.label,
                  accountNumber: value.bank.account_number,
                  accountName: value.bank.account_name
                },
                bankName : selectMethod.name
              }
            }
          })
        } else  {
          setErrorMethod(true)
        }
      }      
    }

    useEffect(() => {
      if(id !== null) {
        localStorage.removeItem('transaction')
        let params = {
          id : id,
          type : type
        }
        dispatch(getInvoiceDetail(params))
      }   
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const finalPrice = (item : any) => {
      switch (item.discount_type) {
        case "PERCENTAGE":
          return item.retail_price - ((item.discount / 100) * item.retail_price);
        case "AMOUNT":
          return item.retail_price - item.discount;
        case "SPECIAL":
          return item.discount;
        default:
          return 0
      }
    }

    useEffect(() => {
      if(download_inv !== "" ) {
          window.location.assign(download_inv)
          dispatch(closeDownloadInv())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download_inv]);

    return (
        <Stack m={2} >
            <LoaderBackdrop 
                loading={loading_create_invoice || loading_download_inv} 
            />
            <BreadCrumbs 
                isPage={true}
                current="Detail Invoice"
                link='/dashboard/finance/invoice'
                page='Invoices'
            />

            <Stack sx={{my:1}} direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Box>
                    <HeadlineTitle 
                        title='Detail Invoice'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
            </Stack>

            { loading_invoice_detail ? 
            <LoadingInvoice />
            :
            <>
            {isEmpty(open_invoice_data) && (
            <Box className="invoice-container">
              <Box className="logo-company">
                <Box className="logo">
                 <img src={open_invoice_data.vendor.logo || ""} alt='logo company' />
                </Box>
                
                <Box className="company">
                  <h2>{open_invoice_data.vendor.legalName || "N/A" }</h2>
                  <p>{open_invoice_data.vendor.address.label || "N/A"} </p>
                  <p>{open_invoice_data.vendor.address.street || "N/A"}, {open_invoice_data.vendor.address.village.toLowerCase() || "N/A"}</p>
                  <p>
                    {open_invoice_data.vendor.address.subdistrict.toLowerCase() || "N/A"}, {open_invoice_data.vendor.address.city.toLowerCase() || "N/A"}, {open_invoice_data.vendor.address.province || "N/A"}, {open_invoice_data.vendor.address.postcode || "N/A"}
                  </p>
                </Box> 
              </Box>

              <Box className="invoice-info">
                <Box className="bill-to">
                  <h3>Bill To</h3>
                  <h4>{open_invoice_data.buyer.legalName || "N/A"}</h4>
                  <p>{open_invoice_data.buyer.address.label || "N/A"} </p>
                  <p>{open_invoice_data.buyer.address.street || "N/A"}, {open_invoice_data.buyer.address.village.toLowerCase() || "N/A"}</p>
                  <p>
                    {open_invoice_data.buyer.address.subdistrict.toLowerCase() || "N/A"}, {open_invoice_data.buyer.address.city.toLowerCase() || "N/A"}, {open_invoice_data.buyer.address.province || "N/A"}, {open_invoice_data.buyer.address.postcode || "N/A"}
                  </p>
                </Box>
                <Box className="inv-number">
                  <Box className='inv-status'>
                    <h6>Invoice {open_invoice_data.type}</h6>
                    { open_invoice_data.lastStatus === 'PAID' &&  
                    <Box className="status">
                      <h5>{open_invoice_data.lastStatus || "N/A"}</h5>
                    </Box> }
                  </Box>
                  <Box className="list">
                    <h5>Number :</h5>
                    <p>{open_invoice_data.invoiceNumber || "N/A"}</p>
                  </Box>
                  <Box className="list">
                    <h5>Due Date :</h5>
                    <p>{moment(open_invoice_data.dueDate).format('LL') || "N/A"}</p>
                  </Box>
                </Box>
              </Box>
              
              <Box >
                <table>
                  <thead>
                  <tr >
                    <th scope="col" >Product</th>
                    <th scope="col" >Qty</th>
                    <th scope="col" >Price</th>
                    <th scope="col" >Tax</th>
                    <th scope="col" >Tax Price</th>
                    <th scope="col" >Sub Total {open_invoice_data.type && open_invoice_data.type === "Down Payment" ? "DP" : ""} + Tax</th>
                  </tr>
                  </thead>
                  <tbody>
                  { open_invoice_data.items.map((itm:any, index:number) => (
                    <tr key={index} >
                      <td data-label="Product Name">{itm.name}</td>
                      <td data-label="Qty">{itm.received ? itm.received : itm.quantity} {itm.unit || "N/A"}</td>
                      <td data-label="Price">Rp. {Math.floor(finalPrice(itm)).toLocaleString()}</td>
                      <td data-label="Tax">{itm.tax.value * 100}%</td>
                      <td data-label="Tax Price">Rp. {Math.floor(itm.total_tax).toLocaleString()}</td>
                      <td data-label="Sub Total + Tax">Rp. {Math.floor(itm.total).toLocaleString()}</td>
                    </tr>
                  ))}
                  </tbody>
                </table> 
              </Box>
              <Box className="invoice-sum">
                <Box>
                  <Box className='item-sum'>
                    <h5>Total {open_invoice_data.type && open_invoice_data.type === "Down Payment" ? "DP" : ""} + Tax</h5>
                    <h2> Rp. {(open_invoice_data.subTotal ).toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum'>
                    <h5>Shipping Cost (+)</h5>
                    <h2>Rp. {open_invoice_data.shippingCosts.toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum'>
                    <h5>Total Tax</h5>
                    <h2>Rp. {open_invoice_data.taxTotal.toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum'>
                    <h5>Down Payment (-)</h5>
                    <h2> Rp. {open_invoice_data.downPayment.toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum-total'>
                    <h5>Grand Total </h5>
                    <h2>Rp. {open_invoice_data.grandTotal.toLocaleString()}</h2>
                  </Box>
                </Box>
              </Box>

              <Box className="document-referance">
                <h6>Document Reference</h6>
                <p><strong>Code Package :</strong> {open_invoice_data.reference.map((itm:any) => <span key={itm.codePackage}>{itm.codePackage}, </span> )}</p>
              </Box>

              <Box className="document-referance">
                <h6>Note</h6>
                <p>{open_invoice_data.note || "N/A"}</p>
              </Box>

              <Box className="line-bottom"/>
              <Box className="invoice-footer">
                <p>Invoice date {moment(open_invoice_data.createdAt).format('ll') || "N/A"}</p>
              </Box>
            </Box> )}
            </> }

            { loading_invoice_detail ? null :
            <>
            { open_invoice_data.lastStatus === 'PAID' || open_invoice_data.lastStatus === 'Waiting to Confirm' ?
            <Box className="payment-container">
              { open_invoice_data.infoPayment.length ?
              <Box className="method">
                <h6>Detail Payment</h6>
                <Box className="detail">
                  <p>Payment Method</p>
                  <h6>{open_invoice_data.infoPayment.slice(-1).pop().method || "N/A"}</h6>
                </Box>
                <Box className="detail">
                  <p>Payment Proof</p>
                  <a href={open_invoice_data.infoPayment.slice(-1).pop().fileUrl || "N/A"} target="_blank" rel="noreferrer" >Click View</a>
                </Box>
              </Box> : <Box/> }
              <DownloadPdf 
                name="Download PDF"
                onClick={() => {
                  let value = {
                    id: open_invoice_data._id,
                    type: "Final Payment",
                  }
                  dispatch(printDownloadInvPDF(value))
                }}
              />
            </Box>
            :
            <Box className="payment-container">
              { open_invoice_data.lastStatus === 'Ready for Payment' && open_invoice_data.infoPayment.length !== 0 ?
              <Box className="method">
                <h6>Waiting for Payment!</h6>
              </Box>
              :
              <Box className="method">
                <h6>Payment Method</h6>
                { open_invoice_data.directPayment ? 
                <Box className="name">
                  <h5>Manual Bank Transfer</h5>
                </Box>
                :
                <Box className={`select-method ${errorMethod && 'error'} `} onClick={() => setDialogMethod(true)}>
                  { isEmpty(selectMethod) ? 
                  <Box className="selected">
                    <img src={selectMethod.imageUrl} alt="icon" />
                    <h5>{selectMethod.name || "N/A"} {selectMethod.payment_method || "N/A"}</h5>
                  </Box> :
                  <h5>Select Payment Method</h5> }
                  <img src={arrowright} alt="icon"  />
                </Box> }
              </Box> }

              <Box className="btn-right">
                { open_invoice_data._id && 
                <DownloadPdf 
                  name="Download PDF"
                  onClick={() => {
                    let value = {
                      id: open_invoice_data._id,
                      type: "Final Payment",
                    }
                    dispatch(printDownloadInvPDF(value))
                  }}
                />}

                { open_invoice_data.lastStatus === 'Ready for Payment' && open_invoice_data.infoPayment.length !== 0 ?
                <ButtonCreate 
                    onClick={() => {
                      setDataTransaction(open_invoice_data.infoPayment)
                      setOpenDetail(true)
                  }}
                  name="Continue Pay Invoice"
                  disabled={open_invoice_data.type && open_invoice_data.type === "Draft" ? true : false}
                /> : 
                <ButtonCreate 
                  onClick={() => handlePayInvoice(open_invoice_data)}
                  disabled={open_invoice_data.type && open_invoice_data.type === "Draft" ? true : false}
                  name="Pay Invoice"
                /> }
              </Box>
            </Box> }
            </> }

            <DialogPaymentMethod 
              open={dialogMethod}  
              setOpen={setDialogMethod} 
              setSelectMethod={setSelectMethod} 
              selectMethod={selectMethod} 
              setErrorMethod={setErrorMethod}
            />
            <SnackBarAlert message="Create invoice is success!" initialState={create_invoice} />

            {isEmpty(open_invoice_data) && (
            <DialogPay 
              open={openDetail}  
              setOpen={setOpenDetail} 
              data_transaction={dataTransaction[0]} 
              invoice={open_invoice_data}
            /> )}
        </Stack>
    )
}


const LoadingInvoice = () => {
    return (
      <Box className="invoice-container">
        <Box className="logo-company">
          <Box className="logo">
            <Skeleton variant='circular'  height={75}  width={75} />
          </Box>
          <Box className="company">
            <Skeleton variant='text'  height={30}  width={150} />
            <Skeleton variant='text'  height={25}  width={200} />
            <Skeleton variant='text'  height={25}  width={220} />
            <Skeleton variant='text'  height={25}  width={200} />
          </Box>
        </Box>

        <Box className="invoice-info">
          <Box className="bill-to">
            <Skeleton variant='text'  height={30}  width={100} />
            <Skeleton variant='text'  height={25}  width={200} />
            <Skeleton variant='text'  height={25}  width={230} />
            <Skeleton variant='text'  height={25}  width={200} />
          </Box>
          <Box className="inv-number">
            <Skeleton variant='text'  height={35}  width={150} />
            <Box className="list">
              <Skeleton variant='text' width={175} />
            </Box>
            <Box className="list">
              <Skeleton variant='text' width={175} />
            </Box>
          </Box>
        </Box>
        <Box className="invoice-table">
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={30} />
        </Box>

        <Box className="document-referance">
          <Skeleton variant='text'  height={30}  width={150} />
          <Skeleton variant='text' height={30} width={250} />
        </Box>

        <Box className="invoice-footer">
          <p><Skeleton variant='text'  height={10} /></p>
        </Box>
      </Box>
    )
  }

export default InvoiceDetail;
