import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getSearchCompanyByCode = createAsyncThunk(
    'company-search/get',
    async (params : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/company/list`, {
                params,
                headers
            })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getOnePreferredProducts = createAsyncThunk(
    'preferred-products-lists/get',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/preferred-list/${id}/details`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPreferredListsData = createAsyncThunk(
    'preferred-lists/get',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/preferred-list/buyer`, {
                params,
                headers
            })
            if(response.data) {
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPreferredAll = createAsyncThunk(
    'preferred-all/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/preferred-list/list`, {
                headers
            })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPreferredListsDataLoadMore = createAsyncThunk(
    'preferred-lists-loadmore/get',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/preferred-list/buyer`, {
                params,
                headers
            })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postPreferredBuyerList = createAsyncThunk(
    'preferred-lists/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/preferred-list/request`, body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updatePreferredBuyerList = createAsyncThunk(
    'preferred-lists/update',
    async (params : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/preferred-list/${params.id}`, params.body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removePreferredBuyerLists = createAsyncThunk(
    'preferred-lists/remove',
    async (id : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/preferred-list/${id}`, {
                headers
            })
            if(response.data) {
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const downloadTemplatePreferred = createAsyncThunk(
    'download-preferred/template',
    async (_, { rejectWithValue }) => {
        try {
            let body = {
                "template": "PREFERRED",
                "company": "null",
                "warehouse": "null"
            }
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/excels`, body, {
                headers
            })
            if(response.data) { 
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const uploadBulkPreferedList= createAsyncThunk(
    'preferred-bulk/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
            bodyFormData.append('file', value.file[0]);
            bodyFormData.append('initial_discount', value.initial_discount);
            bodyFormData.append('company', 'null');
            bodyFormData.append('preferred', value.preferred);
            bodyFormData.append('payment_term', value.payment_term.value);
            bodyFormData.append('status', "ACTIVE");
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/preferred-list/bulk`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.id_token}`
                }
            }) 
            if(response.data) {
                return true
            }
            } catch (err : any) {
                return rejectWithValue(err)
        }
    }
);