import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import { Box } from '@mui/material';
import { useState } from 'react';
import copy from '../../../../../assets/img/icon/copy.png'
import transfer from '../../../../../assets/img/icon/transfer.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import DialogProof from './DialogProof';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import SnackBarAlert from '../../../../../components/SnackBarAlert';
import LoaderBackdrop from '../../../../../components/LoaderBackdrop';
import { clearMessageInvoice } from '../redux/invoicesSlice';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogPay : React.FC<any> = ({ open, setOpen, data_transaction, invoice, name }) => {

  const dispatch = useDispatch()
  const {  proof_invoice, loading_proof_invoice } = useSelector((state : RootState) => state.invoice)

    const [openProof, setOpenProof] = useState(false);
    const [fileUrl, setFileUrl] = useState({
        name : '',
        url : '',
        type : 'upload'
    });

    function handleClose () {
        setOpen(false);
    };
    
    const [copyTrue, setCopyTrue] = useState(false);
    const [type, setType] = useState("");

    const isEmpty = (obj : any) => {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return true;
      }
      return false;
    }

    const handleCopy = (value:any, name:string) => {
        return (
          <CopyToClipboard 
            text={`${value}`}
            onCopy={() => {
                setCopyTrue(true)
                setType(name)
                setTimeout(() => {
                  setCopyTrue(false)
                  setType("")
                }, 2000);
            }}
          >
            <Box className={copyTrue && name === type ? "copy copied" : "copy"}>
              <h5>{copyTrue && name === type ? 'Copied' : 'Copy'}</h5>
              <img alt='icon' src={copy}/>
            </Box>
          </CopyToClipboard>
        )
    }

    React.useEffect(() => {
      if(proof_invoice) {
        setOpenProof(false)
        setTimeout(() => {
          setOpen(false)
        }, 1000);
        setTimeout(() => {
          dispatch(clearMessageInvoice())
        }, 1400);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proof_invoice]);
    
    return (  
    <div>
      <LoaderBackdrop loading={loading_proof_invoice} />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{name || "Pay"}</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className='payment-page dialog-paymnt'>
          { isEmpty(data_transaction) && (
          <Box className="box-detail-pay box-dialog" >
            <Box className="list-name">
              <Box>
                { data_transaction.method === "VA" ?
                <h6>Virtual Account</h6> :
                <h6>Manual Bank Transfer</h6> }
              </Box>
              <Box>
                <img alt='icon' src={transfer}/>
              </Box>
            </Box>
            <Box className="list-info">
              <Box>
                <p>Transaction ID</p>
                <h6>{data_transaction._id}</h6>
              </Box>
              <Box/>
            </Box>
            <Box className="list-info">
              <Box>
                <p>Invoice Number</p>
                <h6>{invoice.invoiceNumber}</h6>
              </Box>
              {handleCopy(invoice.invoiceNumber, 'invoice_number')}
            </Box>
            <Box className="list-info">
              <Box>
                <p>Bank Name</p>
                <h6>{data_transaction?.bank?.name || "N/A"} {data_transaction.method === "VA" ? "VA" : null }</h6>
              </Box>
              <Box/>
            </Box> 
            {/* { data_transaction.method !== "VA" ? null :
            <Box className="list-info">
              <Box>
                <p>Account Name</p>
                <h6>{data_transaction.banks.accountName || ""}</h6>
              </Box>
              <Box/>
            </Box> } */}
            <Box className="list-info">
              <Box>
                <p>{ data_transaction.method === "VA" ? "Virtual" : null } Account Number</p>
                <h6>{data_transaction.accountNumber}</h6>
              </Box>
              {handleCopy(data_transaction.accountNumber, 'account_number')}
            </Box>
            <Box className="list-info">
              <Box>
                <p>Total Amount</p>
                <h6>Rp. {parseInt(data_transaction.amount).toLocaleString()}</h6>
              </Box> 
              {handleCopy(data_transaction.amount, 'amount')}
            </Box>
          </Box> )}
          </Box>
          
          { name !== "Detail Payment" ? 
          <Stack flexDirection="row" justifyContent="center" mt={2}>
            <Box className="btn-upload">
              <button onClick={() => setOpenProof(true)}>
                Upload Payment Proof
              </button>
            </Box>
          </Stack> : 
          <Stack className='box-detail'>
           { data_transaction && 
            <Box className="method">
              <Box className="detail">
                <p>Payment Method</p>
                <h6>{data_transaction.method}</h6>
              </Box>
              <Box className="detail">
                <p>Payment Proof</p>
                <a href={data_transaction.fileUrl} target="_blank" rel="noreferrer" >Click View</a>
              </Box>
            </Box> }
          </Stack> }
        </DialogContent>
      </BootstrapDialog>

      { isEmpty(data_transaction) && (
      <DialogProof 
        open={openProof} setOpen={setOpenProof} 
        fileUrl={fileUrl} setFileUrl={setFileUrl} idTransaction={data_transaction._id} 
      /> )}
      
      <SnackBarAlert message="Thankyou for your payment! Upload payment proof is success!" initialState={proof_invoice} />

    </div>
  );
}

export default DialogPay;