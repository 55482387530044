import React, { useState, useEffect } from 'react';
import {
  Paper,
  Step,
  Stepper,
  StepLabel,
  Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import FormCompanyDetail from './FormCompanyDetail';
import FormLegalDocument from './FormLegalDocument';

const steps = ['Company Detail', 'Legal Document'];

export default function StepperForm() {
  const initialStep = useSelector((state : RootState) => state.step_state)

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(initialStep.step_state)
  }, [initialStep])

  const borderStyle = () => {
    return {
      borderRadius:3
    }
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={2}>
          <Box p={3}>
          <Stepper activeStep={activeStep} sx={borderStyle}>
              {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                  optional?: React.ReactNode;
              } = {};
              return (
                  <Step key={index} {...stepProps}>
                  <StepLabel {...labelProps}>
                      <h3>{label}</h3>
                      </StepLabel>
                  </Step>
              );
              })}
          </Stepper>
          </Box>
      </Paper>


      <React.Fragment>
      { activeStep === 0 ? (
          <Paper elevation={2} sx={borderStyle}>
            <Box p={3} mt={2}>
                <Box>
                    <FormCompanyDetail
                        profile={false}
                    />
                </Box>
            </Box>
          </Paper>
      ) : (
          <Paper elevation={2} sx={borderStyle}>
            <Box p={3} mt={2}>
                <Box>
                  <FormLegalDocument/>
                </Box>
            </Box>
          </Paper>
      ) }
      </React.Fragment>
    </Box>
  );
}
