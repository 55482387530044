import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import FormPurchase from './FormPurchase';
import PagePurchase from './PagePurchase';

const MainPurchase = () => {
    
    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'create') {
            return <FormPurchase/>
        } else {
            return <PagePurchase/>
        }
    }


 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default MainPurchase
