import { createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";
import Axios from 'axios';
import { IAuthState } from '../types';
import { 
  getNavigationPathSuperAdmin, 
  getNavigationMenuSuperAdmin, getNavigationMenuNotRegistered, 
  getNavigationMenuRegistered, getNavigationPathNotRegistered, 
  getNavigationPathRegistered, 
  getNavigationPathUnverified,
  getNavigationMenuUnverified
} from '../../../utilities/getNavigation';
import moment from 'moment';

export const signInAction = createAsyncThunk(
    'auth/signin',
    async (value : IAuthState, { rejectWithValue }) => {
          const data = {
            'grant_type': 'password',
            'username': value.email,
            'password': value.password,
            'client_id': process.env.REACT_APP_CLIENT_AUTH,
            'connection': process.env.REACT_APP_CONNECTION_AUTH,
            'scope': 'openid',
            "app_metadata": {
              "platform": "buyer"
            }
          }
          const data_shadow = {
            'grant_type': 'password',
            'username': value.email,
            'password': value.password,
            'client_id': process.env.REACT_APP_CLIENT_AUTH,
            'connection': process.env.REACT_APP_CONNECTION_AUTH,
            'scope': 'openid',
            "app_metadata": {
              "platform": "admin",
              "companyId" : (value.shadow && value.company_id) || "",
              "role" :  (value.shadow && value.role_id) || ""
            }
          }
          try {
            const signin :any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/oauth/token`, value.shadow ? data_shadow : data)
            if(signin.data.id_token) {
              const decoded : any = jwt_decode(signin.data.id_token)   
              const decode_token = decoded['https://pasarpemesanan.com/credential']
              if(decode_token.isRegistered || decode_token.isShadow) { 
                const navigations :any = await Axios.get(`${process.env.REACT_APP_API_SERVER}/navigations`, {
                  headers: {
                    'Authorization' : `Bearer ${signin.data.id_token}`
                  }
                })
                if(navigations.data.navigations) {
                  if(decode_token.isVerify || decode_token.isShadow) {
                    let credential = {
                      access_token : signin.data.access_token, 
                      id_token : signin.data.id_token, 
                      expires_in : signin.data.expires_in,
                      email : decode_token.profile.email,
                      fullname : decode_token.profile.name, 
                      features : decode_token.hasRole.features, 
                      company : decode_token.company, 
                      company_id : decode_token.hasRole.company_id, 
                      auth_id : decode_token.authId,
                      createdAt : decode_token.profile.createdAt,
                      isVerify : decode_token.isVerify,
                      isRegistered : decode_token.isRegistered || decode_token.isShadow,
                      login: true,
                      role : decode_token.hasRole.role.name, 
                      login_start_date : moment().format(),
                      navigation_path : getNavigationPathRegistered(navigations.data),
                      navigation_menu : getNavigationMenuRegistered(navigations.data),
                    }
                    return credential
                  } else {
                    let credential = {
                      access_token : signin.data.access_token, 
                      id_token : signin.data.id_token, 
                      expires_in : signin.data.expires_in,
                      email : decode_token.profile.email,
                      fullname : decode_token.profile.name, 
                      createdAt : decode_token.profile.createdAt,
                      features : decode_token.hasRole.features, 
                      company : decode_token.company, 
                      company_id : decode_token.hasRole.company_id, 
                      auth_id : decode_token.authId,
                      isRegistered : decode_token.isRegistered || decode_token.isShadow,
                      isVerify : decode_token.isVerify,
                      login: true,
                      role : decode_token.hasRole.role.name, 
                      navigation_path : getNavigationPathUnverified(navigations.data.navigations),
                      navigation_menu : getNavigationMenuUnverified(navigations.data.navigations),
                      login_start_date : moment().format()
                    }
                    return credential
                  }
                } else {
                  return rejectWithValue("Something Error get Navigations")
                }
              } else {
                let credential = {
                  access_token : signin.data.access_token,
                  id_token : signin.data.id_token, 
                  expires_in : signin.data.expires_in,
                  email : decode_token.profile.email,
                  fullname : decode_token.profile.name, 
                  features : [], 
                  auth_id : decode_token.authId,
                  createdAt : decode_token.profile.createdAt,
                  isRegistered : decode_token.isRegistered,
                  isVerify : decode_token.isVerify,
                  login_start_date : moment().format(),
                  login: true,
                  role : "", 
                  navigation_path : getNavigationPathNotRegistered(),
                  navigation_menu : getNavigationMenuNotRegistered(),
                }
                return credential
              }
            } else {
                return rejectWithValue("Something Error")
            }
          } catch (error:any) {
            return rejectWithValue(error.response.data.error_description)
          }
    }
);



export const signInSuperAdminAction = createAsyncThunk(
  'auth/signin-superadmin',
  async (value : IAuthState, { rejectWithValue }) => {
      const data = {
          'grant_type': 'password',
          'username': value.email,
          'password': value.password,
          'client_id': process.env.REACT_APP_CLIENT_AUTH,
          'connection': process.env.REACT_APP_CONNECTION_AUTH,
          'scope': 'openid',
          "app_metadata": {
            "platform": "admin"
          }
        }
        try {
          const signin :any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/oauth/token`, data)
          if(signin.data.id_token) {
            const decoded : any = jwt_decode(signin.data.id_token)   
            const decode_token = decoded['https://pasarpemesanan.com/credential']
            if(decode_token.isAdmin && decode_token.isAdmin === true) { 
              let credential = {
                access_token : signin.data.access_token,
                id_token : signin.data.id_token, 
                expires_in : signin.data.expires_in,
                email : decode_token.profile.email,
                fullname : decode_token.profile.name, 
                features : [], 
                auth_id : decode_token.authId,
                createdAt : decode_token.profile.createdAt,
                isRegistered : decode_token.isRegistered,
                login_start_date : moment().format(),
                login: true,
                role : "", 
                navigation_path : getNavigationPathSuperAdmin(),
                navigation_menu : getNavigationMenuSuperAdmin(),
              } 
              return credential
            } else {
              return rejectWithValue("Dont ever try to hack our system! We will find you looser!")
            }
          } else {
              return rejectWithValue("Something Error")
          }
        } catch (error:any) {
          if(error.response.status === 500) {
            return rejectWithValue("You account is not superadmin! Dont ever try again!")
          } 
          return rejectWithValue(error.response.data.error_description)
        }
  }
);


export const signUpAction = createAsyncThunk(
  'auth/signup',
  async (value : IAuthState, { rejectWithValue }) => {
      const data = {
          'email': value.email,
          'password': value.password,
          'client_id': process.env.REACT_APP_CLIENT_AUTH,
          'connection': process.env.REACT_APP_CONNECTION_AUTH,
          "user_metadata": {
            "name": value.fullname,
            "username": value.email,
            "platform": "buyer"
          }
      }
      try {
        const signup = await Axios.post(`${process.env.REACT_APP_API_AUTH}/dbconnections/signup`, data)
        if(signup) {
          return { data: true }
        } 
      } catch (error:any) {
        return rejectWithValue(error.response.data.description)
      }
  }
);

  export const resetPasswordAction = createAsyncThunk(
    "forgot/password",
    async(email:string, { rejectWithValue } ) => {
        const data = {
            'email': email,
            'client_id': process.env.REACT_APP_CLIENT_AUTH,
            'connection': process.env.REACT_APP_CONNECTION_AUTH,
        }
        try {
            const forgot = await Axios.post(`${process.env.REACT_APP_API_AUTH}/dbconnections/change_password`, data)
            if(forgot) {
                return true
            }
        } catch (error:any) {
            if(error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue("Something Wrong");
            }
        }
    }
  )
