import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getProducts = createAsyncThunk(
    'productss/get',
    async (params : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/browse-purchase`, {
                params,
                headers
            })
            if(response.data) {
                let product = response.data.data
                return {data : product, total : product.length === 0 ? 0 : response.data.total.row }
            } else {
                return rejectWithValue(response.data.message || { message : "Something went wrong"})
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getProductsLoadMore = createAsyncThunk(
    'productss-load-more/get',
    async (params : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/browse-purchase`, {
                params,
                headers
            })
            if(response.data) {
                let product = response.data.data
                return {data : product, total : product.length === 0 ? 0 : response.data.total.row }
            } else {
                return rejectWithValue(response.data.message || { message : "Something went wrong"})
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAddressCoverage = createAsyncThunk(
    'coverage/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/address`, {
                params : {
                    skip : 0,
                    limit : 200
                }, headers
            })
            if(response.data) {
                let data = []
                for(let item of response.data.data) {
                    data.push({
                        value : item.city,
                        label : item.city,
                        address : item
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return {data : sortAccending}
            } else {
                return rejectWithValue(response.data.message || { message : "Something went wrong"})
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getFilterData = createAsyncThunk(
    'filter/get',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/filter`, {params, headers})
            if(response.data) {
                return {data : response.data}
            } else {
                return rejectWithValue(response.data.message || { message : "Something went wrong"})
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getDetailProduct = createAsyncThunk(
    'detail/get',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/${params.slug}/details`, { 
                params : {
                    coverage_area : params.coverage_area,
                },
                headers
            })
            if(response.data) {
                return {data : response.data}
            } else {
                return rejectWithValue(response.data.message || { message : "Something went wrong"})
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getCalculateItem = createAsyncThunk(
    'calculate-item/get',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/calculate-item`, body, {headers})
            if(response.data.result) {
                const result = {
                    ...response.data.result, 
                    ...response.data.setting, 
                    vendor_name : response.data.result.vendor.name,
                    unit : body.item.unit
                } 
                return {
                    data: result,  
                    type_error : "", 
                    value_error : 0,
                    type : body.type
                }
            } else {
                return { 
                    data: false, 
                    type_error : response.data.type, 
                    value_error : response.data.data,
                    type : body.type
                }
            }
          } catch (err : any) { 
            return rejectWithValue(err)
        }
    }
);


export const createNewPurchase = createAsyncThunk(
    'create-purchase/post',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/calculate-item`, body, {headers})
            if(response.data.result) {
                const result = {
                    ...response.data.result, 
                    ...response.data.setting, 
                    vendor_name : response.data.result.vendor.name,
                } 
                return {
                    data: result,  
                    type_error : "", 
                    value_error : 0,
                }
            } else {
                return { 
                    data: false, 
                    type_error : response.data.type, 
                    value_error : response.data.data,
                }
            }
          } catch (err : any) { 
            return rejectWithValue(err)
        }
    }
);