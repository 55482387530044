import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {  getUseTemplate, removeTemplate } from '../redux/templateReducers';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../app/store';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogConfirmation : React.FC<any> = ({ open, setOpen }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { use_template } = useSelector((state : RootState) => state.templates)


    function handleClose () {
        setOpen({...open, open: false});
    };

    const [value, setValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    function onClickContinue (type:string) {
        switch (type) {
            case 'Use':
                dispatch(getUseTemplate(open.id))
                handleClose()
                break;
            case 'Delete':
                dispatch(removeTemplate(open.id))
                handleClose()
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
      if(use_template) {
        history.push('/dashboard/purchase-requests?page=create')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [use_template]);
    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open.open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Confirmation!</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <Typography gutterBottom>
                Are you sure want to {open.type} this Template?
            </Typography>
            { open.type === 'Reject' && 
            <Box> 
                <TextField 
                    fullWidth
                    placeholder='Note'
                    margin='dense' 
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                />
            </Box> }
        </DialogContent>
        <DialogActions>
          <Button size="small" autoFocus color="error" variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button  size="small" autoFocus variant='contained' color={open.type === "Delete" ? "error" : "primary"} onClick={() => onClickContinue(open.type)}>
            {open.type}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default DialogConfirmation;