import { Stack, Box, Button, Grid, Skeleton } from '@mui/material'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Select from 'react-select' 
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updatePlatformSnackBar } from '../profileSlice';
import { getSettingVisibility, updateSettingPlatform } from '../reducers/profilesReducers';

const SettingPlatform : React.FC<any> = ({ profile }) => {

    const dispatch = useDispatch()
    const { visibility_product, loading_visibility_product, setting_platform, loading_setting_platform } = useSelector((state : RootState) => state.profile)

    const optionsVisibility  = [
        { value : "OPEN", label : "OPEN"},
        { value : "EXCLUSIVE", label : "EXCLUSIVE"}
    ]

    const [productVisibility, setProductVisibility] = useState<any>([]);

    const handleChangeVisibility = (value: any) : void => {
        setProductVisibility(value)
    }

    const onClickSave = () => {
        const body = {
            isVisibility: productVisibility.value,
            platform : "BUYER"
        }
        dispatch(updateSettingPlatform(body))
    }

    useEffect(() => {   
        dispatch(getSettingVisibility(profile._id))
        // eslint-disable-next-line
    }, [profile._id]);

    useEffect(() => {
        if(visibility_product) {
            setProductVisibility(visibility_product)
        }
    }, [visibility_product]);

    useEffect(() => {
        if(setting_platform) {
            setTimeout(() => {
                dispatch(updatePlatformSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [setting_platform]);

    return (
        <Stack minHeight={250} width="100%" >
            <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box ><h4>Visibility</h4></Box>
                    { loading_visibility_product ? 
                    <Box mt={0} pt={0}><Skeleton height={60} /></Box>
                    :
                    <Box pt={1}>
                        <Select
                            placeholder="Select Product Visibility"
                            value={productVisibility}
                            isSearchable={true} 
                            options={optionsVisibility}
                            onChange={handleChangeVisibility}
                            id="select-style-cat"
                        /> 
                    </Box> }
                </Grid>
            </Grid>

            {/* { checkValidation('UPSETPLCOM6424352') &&  */}
            <Stack 
                sx={{my:3}} 
                flexDirection="row" 
                justifyContent="flex-end"
            >
                <Button 
                    onClick={onClickSave} 
                    disabled={loading_setting_platform} 
                    variant='contained'
                >{loading_setting_platform ? "Loading" : "Save"}
                </Button>
            </Stack> 

            <SnackBarAlert message={`Success update Setting Platform Company.`} initialState={setting_platform} />

        </Stack>
    )
}

export default SettingPlatform
