import { Stack, Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import calender from '../../../../../assets/img/icon/calendar.png'
import store from '../../../../../assets/img/icon/store.png'
import ChatButton from '../../../../../components/ChatButton'
import PreferredButton from '../../../../../components/PreferredButton'
import DialogPay from './DialogPay'

const CardInvoice : React.FC<any> = ({ item }) =>  {
    const history = useHistory()

    const [openDetail, setOpenDetail] = useState(false);
    const [openDetailName, setOpenDetailName] = useState("");
    const [dataTransaction, setDataTransaction] = useState<any>({});

    function onClickDetail(id:string) {
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=detail`, 
            state : {
                id : id,
                type : 'details'
            }
        })
    }

    function onClickContinuePay(item:any) {
        setDataTransaction(item.infoPayment[0])
        setOpenDetail(true)
        setOpenDetailName('Finish your Payment')
    }

    function onClickCheckPayment(item:any) {
        setDataTransaction(item.infoPayment[0])
        setOpenDetail(true)
        setOpenDetailName('Detail Payment')
    }

    function onClickPayInvoice(id:string) {
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=detail`, 
            state : {
                id : id,
                type : 'details'
            }
        })
    }

    function getStyle (name:string) {
        switch (name) {
            case 'Ready for Payment':
                return {  backgroundColor : '#ff9f43' }
            case 'Waiting to Confirm':
                return {  backgroundColor : '#fb8500' }
            case 'Approved': 
                return {  backgroundColor : '#43a047' }
            case 'Reject':
                return {  backgroundColor : '#ee4054' }
            default:
                return {  backgroundColor : '#43a047' }
        }
    }

    return (
    <Stack 
        className='container-item-invoice' 
        sx={{  
            border: `1px solid ${item.type && item.type ===  "Down Payment" ? 'gray' : 'transparant'}`,
            backgroundColor: item.type && item.type ===  "Draft" ? '#f4f4f4 !important' : ''
        }}
    >
        <Box className="top-div" sx={{ backgroundColor: item.type && item.type ===  "Draft" ? '#f4f4f4 !important' : '' }}>
            <Box className='left'>
                <img src={calender} alt='calender' />
                <p>{moment(item.createdAt).format('LL')}</p>
                <Box className='line-right' />
                <h5>{item.invoiceNumber}</h5>
                <Box className='line-right' />
                <h5 className={item.type && item.type ===  "Down Payment" ? 'color-red' : 'color-black'}>Invoice { (item.type && item.type) || "Final Payment" }</h5>
            </Box>
            <Box className='right'>
                <Box className='status' sx={getStyle(item.lastStatus)}>
                    {item.type === "Draft" ? <h6>Draft</h6> : <h6>{item.lastStatus}</h6>}
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={8} xl={8}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.vendor.logo || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.vendor.legalName}</h2>
                                <Box className='chat' >
                                    <ChatButton vendor_detail={item.vendor} />
                                    <PreferredButton vendor_detail={item.vendor} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={12} lg={4} xl={4}>
                    <Box className='total-prices'>
                        <p>Total Amount</p>
                        <h3>Rp.{(Math.floor(item.grandTotal)).toLocaleString()}</h3>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='left-div'> 
                <p>Due Date : {moment(item.dueDate).format('LL')}</p>
            </Box>
            <Box className='right-div'>
                <Box className='view-btn' onClick={() => onClickDetail(item._id)}>
                    <p>View Detail</p>
                </Box>
                { item.lastStatus === 'Ready for Payment' && item.infoPayment.length !== 0 &&
                <Box className="btn-confirm" >
                    <button onClick={() => onClickContinuePay(item)}>
                        <p>Continue to Pay</p>
                    </button>
                </Box> }
                { item.lastStatus === 'Waiting to Confirm' && item.infoPayment.length !== 0 &&
                <Box className="btn-confirm-check" >
                    <button onClick={() => onClickCheckPayment(item)}>
                        <p>Check Payment</p>
                    </button>
                </Box> }
                { item.lastStatus === 'Ready for Payment' && item.infoPayment.length === 0 &&
                <Box className="btn-confirm" >
                    <button 
                        onClick={() => onClickPayInvoice(item._id)}
                        disabled={item.type && item.type === "Draft" ? true : false}
                    >
                        <p>Pay Invoice</p>
                    </button>
                </Box>  }
                
            </Box>
        </Box>
        
        <DialogPay open={openDetail}  setOpen={setOpenDetail} data_transaction={dataTransaction} invoice={item} name={openDetailName} />
    </Stack>
  )
}

export default CardInvoice