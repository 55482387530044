import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }


export const getInvoiceNumber = createAsyncThunk(
    'invoice-number/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/number`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoicesData = createAsyncThunk(
    'invoices-vendor/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/buyer`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoicesDataLoadMore = createAsyncThunk(
    'invoices-vendor-load-more/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/buyer`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoiceDetail = createAsyncThunk(
    'invoices-detail/get',
    async (value:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/${value.id}/${value.type}`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const createNewInvoice = createAsyncThunk(
    'invoice-create/post',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/invoice`, body, {
                headers
            })
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const createNewTransaction = createAsyncThunk(
    'transantion-new/post',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transactions`, body, {
                headers
            })
            if(response.data) {
                if(body.method !== "VA") {
                    return { 
                        data : true, 
                        value : {
                            ...body, 
                            idTransaction : response.data._id, 
                            invoiceNumber : response.data.invoices 
                        } 
                    } 
                } 
                return { 
                    data : true, 
                    value : { 
                        ...body, 
                        idTransaction : response.data.id, 
                        banks : {
                            name : body.bankName,
                            accountNumber : response.data.account_number,
                        },
                        invoiceNumber : response.data.invoices 
                    } 
                } 
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const submitPaymentProofInvoice = createAsyncThunk(
    'proof-invoice-payment/post',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transactions/proofOfPayment/${params.id}`, params.body, {
                headers
            })
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAllBankPaymentMethod = createAsyncThunk(
    'bank-payment-method/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank/fetch`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAccountPayable = createAsyncThunk(
    'invoices-Payable/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/account-payable/summary-without-range`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);

export const getTotalPurchases = createAsyncThunk(
    'total-purchases/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/total-orders/purchase/summary-without-date`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getStatistivInvoice = createAsyncThunk(
    'invoices-statistic/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/statisticBuyer`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const printDownloadInvPDF = createAsyncThunk(
    'download-inv-pdf/print',
    async (value:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/printPdf/${value.id}/${value.type}`, {
                headers
            })
            if(response.data) { 
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


