import { Box } from '@mui/material'
import { Grid, Stack } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useHistory, useLocation } from 'react-router-dom';
import SearchingProduct from './components/SearchingProduct';
import HeadlineTitle from '../../../components/HeadlineTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useEffect, useState } from 'react';
import { getAddressCoverage } from '../products/redux/productReducers';
import emptycart from '../../../assets/img/icon/cart.png'
import departmentimg from '../../../assets/img/icon/department.png'
import delivery from '../../../assets/img/icon/delivery.png'
import ItemList from './components/ItemList';
import { groupBy } from 'lodash'
import { 
    clearPurchasesLocalData, getPurchaseLocalData, 
    savePurchaseRequest, submitPurchaseRequest, updatePurchaseRequest 
} from './redux/purchasesReducers';
import { userCredentials } from '../../../utilities/config';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { clearSnackBarValue } from './redux/purchasesSlice';
import SelectAddress from './components/SelectAddress';
import SelectDepartment from './components/SelectDepartment';
import { clearUseTemplate } from '../template/redux/templateSlice';
import { getDepartmentByUser } from '../masters/mastersReducers';
import moment from 'moment';

function FormPurchase() {
    const history = useHistory()
    const location = useLocation()
    const state_location : any = location.state
    const name : any = state_location === undefined ? undefined : state_location.name

    const dispatch = useDispatch()
    const {  
        data, remove_item, add_item, remove_vendor,
        create_purchase, clear, submit_purchase,
        update_purchase, added_note,
        edit
    } = useSelector((state: RootState) => state.purchases)

    const { coverage, loading_coverage,} = useSelector((state: RootState) => state.products)
    const { departments, loading_departments } = useSelector((state: RootState) => state.masters)

    
    const [itemList, setItemList] = useState<any>([]);
    
    const [selectDepartment, setSelectDepartment] = useState("");
    const [selectedAddress, setSelectedAddress] = useState({}); 
    const [selectedArea, setSelectedArea] = useState("");

    const [isSaved, setIsSaved] = useState<any>({});

    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [interacted, setInteracted] = useState<boolean>(false);


    function sumTotal (data : any, name : string) {
        const total = data.reduce((total : any, itm : any) => {
          return total + itm[name]
        }, 0)
        return parseInt(total);
    }


    function sumTotalDownPayment (data : any) {
        const price = data.reduce((total : any, itm : any) => {
          return total + (itm.down_payment  / 100 * itm.total)
        }, 0)
        return parseInt(price);
    } 

    function dispatchSavePurchase (value:any) {
        const body = {
            code : userCredentials.company.code,
            buyer : "null",
            address : selectedAddress,
            fee : 0,
            date : new Date (),
            department : selectDepartment === "" ? "Not Defined" : selectDepartment,
            items : value,
            grand_total : sumTotal(value, 'total'),
            type : "INTERNAL",
            createdBy : userCredentials.fullname,
            updatedAt : new Date (),
        }
        if(isSaved._id === "") {
            dispatch(savePurchaseRequest(body))
        } else {
            let body_update = {
                id : isSaved._id,
                code : isSaved.code,
                data : body
            }
            dispatch(updatePurchaseRequest(body_update))
        }
    }

    function onSavePurchaseRequest(params:any) {
        setInteracted(false)
        dispatchSavePurchase(params)
        
    }

    function onCreatePurchaseRequest(params:any) {
        setInteracted(false)
        if (timer) {
            clearTimeout(timer);
        }
        dispatchSavePurchase(params)
    }

    function updateLocalStorage () {
        let data = {
            saved : false,
            _id : "",
            code : "",
            department : "",
            updatedAt : null
        }
        setIsSaved(data)
        window.localStorage.setItem('saved', JSON.stringify(data))
    }

    function handleInactive(data:any) {
        if(data.length > 0 && coverage.length > 0) {
            onSavePurchaseRequest(data)
        }
    }

    function resetTimer(): void {
        if (timer) {
            clearTimeout(timer);
        }
        if (interacted) {
            setTimer(setTimeout(() => handleInactive(data), 5000));
        }
    }

    useEffect(() => {
        if(data.length > 0) {
            resetTimer();
            return (): void => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if(data) {
            const convert_groupBy : any = Object.entries(groupBy(data, (item:any) => {
                return JSON.stringify(item.vendor.name)
            }));
            setItemList(convert_groupBy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        let area = localStorage.getItem('area') || ""
        if(coverage.length) {
            if(area === "") {
                localStorage.setItem('area', JSON.stringify(coverage[0]))
                setSelectedArea(coverage[0].value || "")
                setSelectedAddress(coverage[0].address || "")
            } else {
                setSelectedArea(JSON.parse(area).value || "")
                setSelectedAddress(JSON.parse(area).address || "")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverage]);



    useEffect(() => {
        dispatch(clearUseTemplate())
        dispatch(getAddressCoverage())
        dispatch(getPurchaseLocalData())
        dispatch(getDepartmentByUser())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if(add_item || remove_item || remove_vendor || edit || added_note) {
            dispatch(getPurchaseLocalData())
            setInteracted(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [add_item, remove_item, remove_vendor, edit, added_note, ]);

    useEffect(() => {
        if(clear) {
            dispatch(getPurchaseLocalData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear]);


    useEffect(() => {
        if(create_purchase || add_item || remove_item || remove_vendor || update_purchase || added_note) {
            let saved : any = localStorage.getItem('saved') || ""
            if(saved === "") {
                updateLocalStorage()
            } else {
                setIsSaved(JSON.parse(saved))
                setSelectDepartment(JSON.parse(saved).department)
            }
            setTimeout(() => {
                dispatch(clearSnackBarValue())            
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create_purchase, add_item, remove_item, remove_vendor, update_purchase, selectDepartment, added_note]);

    useEffect(() => {
        if(submit_purchase) {
            dispatch(clearPurchasesLocalData())
            updateLocalStorage()
            setTimeout(() => {
                    history.push('/dashboard/purchase-requests')
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit_purchase]);


    useEffect(() => {
        let saved = localStorage.getItem('saved') || ""
        if(saved === "") {
            updateLocalStorage()
        } else {
            setSelectDepartment(JSON.parse(saved).department)
            setIsSaved(JSON.parse(saved))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box m={2}>
            <BreadCrumbs 
                current={name === undefined ? "Create Purchase Request" : "Update Purchase Request" }
                isPage={true}
                page="Purchase Request"
                link="/dashboard/purchase-requests"
            />
            <Stack direction="row" alignItems="center" pt={2} >
                { isSaved.code === "" ?
                <HeadlineTitle 
                    title="Create New Purchase Request"
                    variant="h6"
                    bold="bold"
                />
                :
                <HeadlineTitle 
                    title={`Edit Purchase Request  |   ${isSaved.code}`}
                    variant="h6"
                    bold="500"
                /> }
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <SearchingProduct 
                        selectedArea={selectedArea}
                        setSelectedArea={setSelectedArea}
                    />
                    <Box className='selection-box'>
                        <SelectDepartment 
                            selectedData={selectDepartment}
                            setSelectedData={setSelectDepartment}
                            data={departments}
                            loading={loading_departments}
                            name="Select Department"
                            src={departmentimg}
                            isSaved={isSaved}
                            setIsSaved={setIsSaved}
                            template={false}
                        />
                        <SelectAddress 
                            selectedData={selectedArea}
                            setSelectedData={setSelectedArea}
                            setSelectedAddress={setSelectedAddress}
                            data={coverage}
                            loading={loading_coverage}
                            name="Select Address"
                            src={delivery}
                            items={data}
                        />
                    </Box>
                </Grid>
                <>
                { itemList.length === 0 ? 
                    <Grid item xs={12} sm={12}>
                        <Box className="product-cart">
                            <Box className='empty-list'>
                                <Box 
                                    component="img"
                                    src={emptycart} 
                                    alt="empty-cart"
                                    sx={{width: 150, height: 150}}
                                />
                                <Box sx={{textAlign: 'center'}}>
                                    <HeadlineTitle
                                        title="Item List is Empty"
                                        variant="h6"
                                        bold="500"
                                    />
                                    <HeadlineTitle
                                        title="Please add product to Item List to create Purchase Request!"
                                        variant="body2"
                                        bold="500"
                                    />
                                </Box>
                            </Box> 
                        </Box>
                    </Grid> :
                    <>
                    <Grid item xs={12} sm={12} md={8}>
                        <Box className="product-cart">
                            <Box>
                                <Box p={1} ml={1}>
                                    <HeadlineTitle title="Purchase Items" variant="h6" bold="500" />
                                </Box>
                                <Box className="line-divider-bottom" />
                                { itemList.map((item:any, index:any) => (
                                    <ItemList item={item} index={index} key={index}/>
                                ))}
                            </Box> 
                        </Box>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={4}>
                        <Box className="product-summary">
                            <Box px={1} pt={1} pb={0.5}>
                                <HeadlineTitle title={`Purchase Summary (${itemList.length} Vendor)`} variant="h6" bold="500" />
                            </Box>
                            <Box className="line-divider-bottom" />
                            <Box className="summary">
                                <Box className="summary-item">
                                    <h6>Sub Total</h6>
                                    <h5>Rp.{(sumTotal(data, 'total') + sumTotal(data, 'discount_price') - sumTotal(data, 'total_tax')).toLocaleString()}</h5>
                                </Box>
                                <Box className="summary-item">
                                    <h6>Total PPN</h6>
                                    <h5>+ Rp.{sumTotal(data, 'total_tax').toLocaleString()}</h5>
                                </Box>
                                <Box className="summary-item">
                                    <h6>Total Discount</h6>
                                    <h5>- Rp.{sumTotal(data, 'discount_price').toLocaleString()}</h5>
                                </Box>
                            </Box>
                            <Box className="line-divider-bottom" />
                            <Box className="summary">
                                <Box className="summary-item">
                                    <h4>Total Price</h4>
                                    <h5>Rp.{sumTotal(data, 'total').toLocaleString()}</h5>
                                </Box>
                                <Box className="summary-item">
                                    <h4>Total Down Payment</h4>
                                    <h5>Rp.{sumTotalDownPayment(data).toLocaleString()}</h5>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="btn-customs-submit">
                            <button disabled={isSaved.saved} onClick={() => { 
                                onCreatePurchaseRequest(data)
                            }}>
                                <p>Save Purchase Request</p>
                            </button>
                        </Box>
                        <Box className="btn-customs-save">
                            <button disabled={!isSaved.saved} onClick={() => dispatch(submitPurchaseRequest(isSaved._id))}>
                                <p>Submit Purchase Request</p>
                            </button>
                        </Box>
                        <Box py={2} pl={1}>
                            {isSaved.updatedAt === null ? null :
                                <p>Last saved was {moment(isSaved.updatedAt).fromNow() || null}</p>
                            }
                        </Box>
                    </Grid> 
                    </> }
                </>
            </Grid> 
            {/* <LoaderBackdrop loading={loading_create_purchase || loading_submit_purchase || loading_update_purchase} /> */}
            {/* <SnackBarAlert message={`Purchase Items successfully ${type}!`} initialState={add_item} />
            <SnackBarAlert message="Purchase Items successfully Removed!" initialState={remove_item} />
            <SnackBarAlert message="Succesfully added note to vendor level!" initialState={added_note} /> */}
            <SnackBarAlert message="Purchase Items successfully Removed!" initialState={remove_vendor} />
            <SnackBarAlert message="Purchase request successfully Saved!" initialState={create_purchase || update_purchase}  />
            <SnackBarAlert message="Purchase request successfully Submit!" initialState={submit_purchase}  />
        </Box>
    )
}


export default FormPurchase;
 