import { useState, useEffect } from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useDispatch } from 'react-redux';
import HeadlineTitle from '../../../components/HeadlineTitle';
import ButtonCreate from '../../../components/ButtonCreate';
import DataNotFound from '../../../components/DataNotFound';
import DialogDepartment from './components/DialogDepartment';
import ButtonAction from '../../../components/ButtonAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllDepartment } from './redux/departmentReducers';
import { clearMessageDepartment } from './redux/departmentSlice';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { checkValidation } from '../../../utilities/userFeaturesValidation';

const DepartmentPage = () => {

    const dispatch = useDispatch()
    const { 
        data, loading, loading_create, 
        loading_update, create, update
    } = useSelector((state : RootState) => state.department)

    const [idDepartment, setIdDepartment] = useState <any>(null);
    const [name, setName] = useState<string>('');
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [typeDialog, setTypeDialog] = useState("Add");
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const params = {
            limit : 10,
            skip : 0,
            sortTopic : "createdAt",
            sortValue : "ASC"
        }
        dispatch(getAllDepartment(params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(create || update) {
            setOpenDialog(false)
            setTypeDialog("")
            setSelectedUsers([])
            setName("")
            const params = {
                limit : 10,
                skip : 0,
                sortTopic : "createdAt",
                sortValue : "ASC"
            }
            dispatch(getAllDepartment(params))
            setTimeout(() => {
                dispatch(clearMessageDepartment())
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create, update]);


    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_create || loading_update } />
            <BreadCrumbs
                isPage={false}
                current="Department"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
                <Box>
                    <HeadlineTitle title='Department' variant="h6" bold="bold" />
                </Box>
                {checkValidation('CREDEP773490') && 
                <Box>
                    <ButtonCreate 
                        name="Add New Department"
                        onClick={() => {
                            setTypeDialog("Add")
                            setOpenDialog(true)
                            setSelectedUsers([])
                            setName("")
                        }}
                    />
                </Box> }
            </Stack>
            
            
            { loading ?
            <LoaderRoles />
            :
            <Box mt={2} className='department-container'>
                { data.length > 0 ? 
                <Grid container spacing={2}>
                    { data.map((item:any, index:number) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <Box className='dep-item' >
                            <Box className='dep-item__name'>
                                <h1>{item.name}</h1>
                            </Box>
                            <Box className='dep-item__user'>
                                <h6>User Assigned</h6>
                            </Box>
                            <Box className='dep-list__user'>
                                {item.users.map((itm:any, idx:number) => (
                                    <Box className='dep-list__user-item' key={idx}>
                                        <p>{itm.label}</p>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='dep-item__action'>
                                <Box className="items">
                                {checkValidation('UPDDEP265664') && 
                                    <ButtonAction 
                                        name="Edit"
                                        outlined={true}
                                        onClick={() => {
                                            setTypeDialog("Edit")
                                            setOpenDialog(true)
                                            setIdDepartment(item._id)
                                            setName(item.name)
                                            setSelectedUsers(() => {
                                                let arr : any = []
                                                item.users.map((itm:any) => {
                                                    arr.push({
                                                        value : itm.userId,
                                                        label : itm.label
                                                    })
                                                    return arr
                                                })
                                                return arr
                                            })
                                        }}
                                    />
                                }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                </Grid>
                :
                <Box mt={2}>
                    <DataNotFound 
                        message="You dont have any Department yet."
                        caption="Add Department by clicking add button."
                    />
                </Box> }
            </Box> }

            <DialogDepartment 
                open={openDialog} 
                setOpen={setOpenDialog} 
                type={typeDialog} 
                name={name}
                setName={setName}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                idDepartment={idDepartment}
            />

            <SnackBarAlert message={`New Department is successfuly created`} initialState={create} />
            <SnackBarAlert message={`Department is successfuly updated`} initialState={update} />
        </Box>
    )
}

const LoaderRoles = () => {
    return (
        <Box mt={2} className='roles-container'>
            <Grid container spacing={2}>
                { [0,1,2,4].map((_, index:number) => (
                <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                    <Box className='roles-card' >
                        <Box className='roles-item' >
                            <Box className='roles-item__image'>
                                <Skeleton variant="rectangular" width={100} height={50} />
                            </Box>
                            <Box className='roles-item__name'>
                                <Skeleton variant="text" width={150} height={30} />
                                <Skeleton variant="text" width={150} height={20}/>
                            </Box>
                        </Box>
                        <Box className='roles-item__action'>
                            <Box px={1}>
                            <Skeleton variant="text" width={100} height={40} />
                            </Box>
                            <Box px={1}>
                            <Skeleton variant="text" width={100} height={40} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default DepartmentPage
