import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Forgot from '../features_app/auth/forgot/Forgot';
import Login from '../features_app/auth/login/Login'
import Register from '../features_app/auth/register/Register';
import StepRegister from '../features_app/dashboard/step_register/StepRegister';
import Error404 from '../features_app/error404/Error404';
import DashboardLayout from '../utilities/DashboardLayout';
import MainHomePage from '../features_app/dashboard/home/MainIHomePage';
import ProfilePage from '../features_app/dashboard/profile/ProfilePage';
import SelectCompany from '../features_app/dashboard/select_company/SelectCompany';
import MainProductPage from '../features_app/dashboard/products/MainProductPage';
import MainTemplate from '../features_app/dashboard/template/MainTemplate';
import MainPurchase from '../features_app/dashboard/purchase_request/MainPurchase';
import PageOrders from '../features_app/dashboard/orders/PageOrders';
import MainPageInvoice from '../features_app/dashboard/finance/invoices/MainPageInvoice';
import ChatPage from '../features_app/dashboard/chat/ChatPage';
import ChartsDownload from '../features_app/dashboard/reports/download/ChartsDownload';
import VendorLists from '../features_app/dashboard/vendor_lists/VendorLists';
import InvitationPage from '../features_app/dashboard/invitation/InvitationPage';
import TeamsPage from '../features_app/dashboard/teams/TeamsPage';
import RolesPage from '../features_app/dashboard/roles/RolesPage';
import DepartmentPage from '../features_app/dashboard/department/DepartementPage';
import PageTokenCredentials from '../features_app/dashboard/token_credentials/PageTokenCredentials';
import RootAdmin from '../features_app/auth/root/RootAdmin';
import ChartsPage from '../features_app/dashboard/reports/charts/ChartsPage';
import ExtensionsPage from '../features_app/dashboard/extensions/ExtensionsPage';

function AppRoute() {  
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/root-access" component={RootAdmin} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={Forgot} />
          <DashboardLayout exact path="/login/select-company" component={SelectCompany} />
          <DashboardLayout exact path="/company-registration" component={StepRegister} />
          <DashboardLayout exact path="/dashboard" component={MainHomePage} />
          <DashboardLayout exact path="/dashboard/profile" component={ProfilePage} />
          <DashboardLayout exact path="/form/application" component={ProfilePage} />
          <DashboardLayout exact path="/dashboard/purchase-requests" component={MainPurchase} />
          <DashboardLayout exact path="/dashboard/department" component={DepartmentPage} />
          <DashboardLayout exact path="/dashboard/templates" component={MainTemplate} />
          <DashboardLayout exact path="/dashboard/purchase-orders" component={PageOrders} />
          <DashboardLayout exact path="/dashboard/finance/invoice" component={MainPageInvoice} />
          <DashboardLayout exact path="/dashboard/message" component={ChatPage} />
          <DashboardLayout exact path="/dashboard/extensions" component={ExtensionsPage} />
          <DashboardLayout exact path="/dashboard/teams" component={TeamsPage} />
          <DashboardLayout exact path="/dashboard/custom-role" component={RolesPage} />
          <DashboardLayout exact path="/dashboard/products" component={MainProductPage} />
          <DashboardLayout exact path="/dashboard/invitation" component={InvitationPage} />
          <DashboardLayout exact path="/dashboard/vendor-list" component={VendorLists} />
          <DashboardLayout exact path="/dashboard/token" component={PageTokenCredentials} />
          <DashboardLayout exact path="/dashboard/report-charts" component={ChartsPage} />
          <DashboardLayout exact path="/dashboard/report-download" component={ChartsDownload}/>
          <Route exact path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AppRoute;
