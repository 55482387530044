import { createSlice } from '@reduxjs/toolkit';
import { getAddressCoverage, getCalculateItem, getDetailProduct, getFilterData, getProducts } from './productReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  data : [],
  total_infinite : 0,
  total : 0,
  loading : true,
  loaded : false,
  coverage : [],
  loading_coverage : false,
  filter_brand : [],
  filter_vendor : [],
  filter_category : [],
  loading_filter : false,
  detail : {},
  loading_detail : true,
  item : false,
  loading_item : false,
  type_error : "",
  value_error : 0,
};

export const productSlice = createSlice({
  name: 'productss',
  initialState, 
  reducers: {
    clearErrorQty (state) {
      state.type_error = ""
    },
    clearMessageBar (state) {
      state.message_barcode = ""
    },
    clearStateCalculte (state) {
      state.item = false
      state.type_error = "";
      state.value_error = 0;
    },
    addLengthInfiniteProduct (state,action) {
      let existing = state.total_infinite + action.payload
      state.total_infinite = existing
    }
  },
  extraReducers: (builder) => {
    builder 

      // get products status
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getProducts.fulfilled, (state, action:any) => {
        state.data = action.payload.data;
        state.total = action.payload.total;
        state.total_infinite = action.payload.data.length > 0 ? action.payload.data.length : 0;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getProducts.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })


      // get coverage
      .addCase(getAddressCoverage.pending, (state) => {
        state.loading_coverage = true;
      })
      .addCase(getAddressCoverage.fulfilled, (state, action:any) => {
        state.loading_coverage = false;
        state.coverage = action.payload.data;
      })
      .addCase(getAddressCoverage.rejected, (state, action : any) => {
        state.loading_coverage = false;
        errorSweetAlert(action.payload)
      })

      // get filter
      .addCase(getFilterData.pending, (state) => {
        state.loading_filter = true;
      })
      .addCase(getFilterData.fulfilled, (state, action:any) => {
        state.loading_filter = false;
        state.filter_brand = action.payload.data.brand;
        state.filter_vendor = action.payload.data.company;
        state.filter_category = action.payload.data.category;
      })
      .addCase(getFilterData.rejected, (state, action : any) => {
        state.loading_filter = false;
        errorSweetAlert(action.payload)
      })

      // get slug
      .addCase(getDetailProduct.pending, (state) => {
        state.loading_detail = true;
      })
      .addCase(getDetailProduct.fulfilled, (state, action:any) => {
        state.loading_detail = false;
        state.detail = action.payload.data;
      })
      .addCase(getDetailProduct.rejected, (state, action : any) => {
        state.loading_detail = false;
        errorSweetAlert(action.payload)
      })

       // get calculate item
      .addCase(getCalculateItem.pending, (state) => {
        state.loading_item = true;
        // state.item = false;
      })
      .addCase(getCalculateItem.fulfilled, (state, action:any) => {
        state.item = action.payload.type !== undefined && action.payload.data !== false ? action.payload.data : action.payload.type === undefined && action.payload.data !== false ? action.payload.data : state.item ;
        state.type_error = action.payload.type_error;
        state.value_error = action.payload.value_error;
        state.loading_item = false;
      })
      .addCase(getCalculateItem.rejected, (state, action : any) => {
        state.loading_item = false;
        errorSweetAlert(action.payload)
      })
      
  },
});


export const { clearErrorQty, clearStateCalculte, addLengthInfiniteProduct } = productSlice.actions;

export default productSlice.reducer;
