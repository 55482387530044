import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const checkStatusCompany = createAsyncThunk(
    'company-status/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/buyer/detail?buyer_id=${userCredentials.buyer_id}`, {headers})
            if(response.data) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } 
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getStatistic = createAsyncThunk(
    'statistic/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/report/dashboard/buyer?buyer_id=${userCredentials.buyer_id}`, {headers})
            if(response.data) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } 
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);