import { Box } from '@mui/material'
import { useState, useEffect } from 'react';
import plus from '../../../../assets/img/icon/plus.png'
import minus from '../../../../assets/img/icon/minus.png'
import ImageSlider from '../../products/component/ImageSlider';
import HoverInfoProduct from '../../products/component/HoverInfoProduct';
import { useDispatch } from 'react-redux';
import { addTemplateItem } from '../redux/templateReducers';

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false


const CardItemTemplate : React.FC<any> = ({
    ele,
    selectedArea
}) => {

    const dispatch = useDispatch()

    const [quantity, setQuantity] = useState<number>(0);
    const [focused, setFocused] = useState(false)

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    useEffect(() => {
        setQuantity(ele.minimum_order_quantity);
    }, [ele.minimum_order_quantity]);

    const onChange = (e : any) => {
        let value = parseFloat(e.target.value);
        setQuantity(value)
        // if(value === isNaN || value === "") {
        //     setQuantity(0)
        // } else {
        //     setQuantity(parseFloat(value))
        // }
    }

    const onPlus = () => {
        if(quantity <= ele.stock) {
            setQuantity(quantity + 1)
        }
    }

    const onMinus = () => {
        if(quantity > 0) {
            setQuantity(quantity - 1)
        }
    }

    const checkQuantityItem = async () => {
        const purchases = await db.collection('template').get()
        if(purchases.find((element : any) => element._id === ele._id)) {
            let find = purchases.find((element : any) => element._id === ele._id)
            let sum = find.quantity + quantity
            return sum < ele.stock ? sum : ele.stock
        } 
        return quantity < ele.stock ? quantity : ele.stock
    }

    const addToPurchaseItem = async () => {
        let product = {
            item : {
                _id : ele._id,
                name : ele.name,
                sku : ele.sku,
                slug : ele.slug,
                images : ele.images,
                warehouse : ele.warehouses,
                retail_price :  ele.price,
                stock :  ele.stock,
                minimum_order_quantity :  ele.minimum_order_quantity,
                quantity : await checkQuantityItem(),
                payment_term : ele.payment_term,
                vendor : ele.company,
                packages: null,
                order: null,
                discount : ele.discount.label === "SPECIAL" ? parseInt(ele.discount.value) : ele.discount.value,
                discount_type : ele.discount.label,
                discount_price : 0,
                tax : ele.tax,
                down_payment : ele.down_payment,
                total_tax : 0,
                total : 0,
                statuses : [],
                unit : ele.unit ? ele.unit.label : "N/A",
                category : ele.category ? ele.category : "N/A",
            },
            company : "null"
        }
        dispatch(addTemplateItem(product))
        setQuantity(ele.minimum_order_quantity)
    }

    const formatCash = (n:number) => {
        if (n < 1e3) return n;
        if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
    };

    const accumulateDiscount = (price : number, discount : number) => {
        return price - (price * discount / 100)
    }

    useEffect(() => {
        if(!focused) {
            if(quantity === 0 || quantity > ele.stock) {
                setQuantity(ele.minimum_order_quantity)
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused]);
    
    return (
    <Box className='image-card-box-pr'>
        <Box className="zoom-effect-container">
            <ImageSlider 
                images={ele.images}
                slug={ele.slug}
                href={true}
                lightbox={false}
                selectedArea={selectedArea}
            />
        </Box> 
        <Box className='title-brand'>
            <h4>{ele.company.name}</h4>
        </Box>
        <Box className='title-name'>
        { ele.name.length > 44 ? 
            <h1>{ele.name.toLowerCase().substring(0, 44)} ..</h1>
            :
            <h1>{ele.name.toLowerCase()}</h1>
        }
        </Box>
        { ele.discount.label === "PERCENTAGE" && ele.discount.value !== 0 ? 
        <>
            <Box className="title-price">
                <h1>Rp. {accumulateDiscount(ele.price, ele.discount.value).toLocaleString()}</h1>    
            </Box>
            <Box className="title-discount">
                <Box><h2>Rp. {ele.price.toLocaleString()}</h2></Box>
                <Box className="discount"><h3>{ele.discount.value}%</h3></Box>   
                <Box className="info-box">
                    <HoverInfoProduct stock={ele.stock} min_order={ele.minimum_order_quantity}/>
                </Box>
            </Box>
        </> : ele.discount.label === "AMOUNT" && ele.discount.value !== 0 ? 
        <>
            <Box className="title-price">
                <h1>Rp. {(ele.price - ele.discount.value).toLocaleString()}</h1>    
            </Box>
            <Box className="title-discount-amount">
                <Box><h2>Rp. {ele.price.toLocaleString()}</h2></Box>
                <Box className="discount"><h3>Rp.{formatCash(ele.discount.value)}</h3></Box>   
                <Box className="info-box">
                    <HoverInfoProduct stock={ele.stock} min_order={ele.minimum_order_quantity}/>
                </Box>
            </Box>
        </> : ele.discount.label === "SPECIAL" ? 
        <>
            <Box className="title-price">
                <h1>Rp. {ele.discount.value.toLocaleString()}</h1>    
            </Box>
            <Box className="title-discount-special">
                <Box><h2>Rp. {ele.price.toLocaleString()}</h2></Box>
                <Box className="discount"><h3>SPECIAL</h3></Box>   
                <Box className="info-box">
                    <HoverInfoProduct stock={ele.stock} min_order={ele.minimum_order_quantity}/>
                </Box>
            </Box>
        </> :
        <>
            <Box className="title-price">
                <h1>Rp. {ele.price.toLocaleString()}</h1>    
            </Box>
            <Box className="title-discount">
                <Box className="info-box">
                    <HoverInfoProduct stock={ele.stock} min_order={ele.minimum_order_quantity}/>
                </Box>
            </Box> 
        </> }
        <Box className="box-qty-btn">
            <Box className={quantity < ele.minimum_order_quantity || quantity > ele.stock ? "add-qty add-qty-error" : "add-qty"}>
                <Box className="add-qty-box" onClick={onMinus}>
                    <img src={minus} alt="minus" />
                </Box>
                <Box className="input-qty">
                    <input type="number" onFocus={onFocus} onBlur={onBlur} value={quantity} onChange={onChange} />
                </Box>
                <Box className="add-qty-box" onClick={onPlus}>
                    <img src={plus} alt="plus" />
                </Box>
            </Box>
            <Box className='error-qty'>
                { quantity < ele.minimum_order_quantity ? 
                <Box className='error-qty'>
                    <p>Min. order is <strong>{ele.minimum_order_quantity}</strong>!</p>
                </Box> : null }
                { quantity > ele.stock ? 
                <Box className='error-qty'>
                    <p>Stock left is <strong>{ele.stock}</strong>!</p>
                </Box> : null }
            </Box>

            <Box className="btn-customs-add">
                <button 
                    disabled={quantity < ele.minimum_order_quantity || quantity > ele.stock ? true : false} 
                    onClick={addToPurchaseItem}
                >
                    <p>Add to Template</p>
                </button>
            </Box>
        </Box>
    </Box>
    )
}

export default CardItemTemplate
