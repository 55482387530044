import React, { useEffect, useState } from 'react'
import { Grid, TextField, Box, Skeleton } from '@mui/material'
import Select from 'react-select'
import axios from 'axios'
import swal from 'sweetalert';
import required from '../../src/assets/img/icon/required.png';

const AddressFormList : React.FC<any> = ({
    setValueAddress,
    valueAddres
}) => {

    // state for Country
    const optionsCountry : any = [{ value : "Indonesia", label: "Indonesia" }];
    const [selectedCountry, setSelectedCountry] = useState<any>([{ value : "Indonesia", label: "Indonesia" }]);

    // state for Province
    const [optionsProvince, setOptionsProvince] = useState<any>([]);
    const [loadingProvince, setLoadingProvince] = useState(false);

    // state for City
    const [optionsCity, setOptionsCity] = useState<any>([]);
    const [loadingCity, setLoadingCity] = useState(false);

    // state for Village
    const [optionsVillage, setOptionsVillage] = useState<any>([]);
    const [loadingVillage, setLoadingVillage] = useState(false);

    // state for SubDistrict
    const [optionsSubDistrict, setOptionsSubDistrict] = useState<any>([]);
    const [loadingSubDistrict, setLoadingSubDistrict] = useState(false);


    const clearAll = () => {
        setOptionsSubDistrict([])
        setOptionsVillage([])
    }

    const clearAll2 = () => {
        setOptionsVillage([])
    }

    const handleChangeCountry = (value: any) : void => {
        setSelectedCountry(value)
    }

    const handleChangeProvince = (value: any) : void => {
        getDataCity(value.label)
        setValueAddress({...valueAddres, 
            province : value.label,
            city : "",
            subdistrict : "",
            village : "",
            error_province : false,
            error_city : false,
            error_subdistrict : false,
            error_village : false,
        })
        clearAll()
    }

    const handleChangeCity = (value: any) : void => {
        getDataSubDistrict(value.label)
        setValueAddress({...valueAddres, 
            city : value.label,
            subdistrict : "",
            village : "",
            error_city : false,
            error_subdistrict : false,
            error_village : false,
        })
        clearAll2()
    }

    const handleChangeSubDistrict = (value: any) : void => {
        getDataVillage(value.label)
        setValueAddress({...valueAddres, 
            subdistrict : value.label,
            village : "",
            error_subdistrict : false,
            error_village : false,
        })
    }

    const handleChangeVillage = (value: any) : void => {
        setValueAddress({...valueAddres, 
            village : value.label,
            error_village : false,
        })
    }


    const proceedProvince = (params : any) => {
        let province = []
        for(let prov of params) {
            province.push({
                value : prov.name,
                label : prov.name,
            })
        }
        let sortAccending = province.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsProvince(sortAccending)
        setLoadingProvince(false)
    }

    const proceedCity = (params : any) => {
        let city = []
        for(let prov of params) {
            city.push({
                value : prov.name,
                label : prov.name,
            })
        }
        let sortAccending = city.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsCity(sortAccending)
        setLoadingCity(false)
    }

    const proceedSubDistrict = (params : any) => {
        let subdistrict = []
        for(let prov of params) {
            subdistrict.push({
                value : prov.name,
                label : prov.name,
            })
        }
        let sortAccending = subdistrict.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsSubDistrict(sortAccending)
        setLoadingSubDistrict(false)
    }

    const proceedVillage = (params : any) => {
        let village = []
        for(let prov of params) {
            village.push({
                value : prov.name,
                label : prov.name,
            })
        }
        let sortAccending = village.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsVillage(sortAccending)
        setLoadingVillage(false)
    }


    const getDataProvince = async () => {
        setLoadingProvince(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/province?country=Indonesia`)
            if(response.data) {
                proceedProvince(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingProvince(false)
            } 
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingProvince(false)
        }
    }

    const getDataCity = async (name : string) => {
        setLoadingCity(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/city?province=${name}`)
            if(response.data) {
                proceedCity(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingCity(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingCity(false)
        }
    }

    const getDataSubDistrict = async (name : string) => {
        setLoadingSubDistrict(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/district?city=${name}`)
            if(response.data) {
                proceedSubDistrict(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingSubDistrict(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingSubDistrict(false)
            
        }
    }

    const getDataVillage = async (name : string) => {
        setLoadingVillage(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/village?district=${name}`)
            if(response.data) {
                proceedVillage(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingVillage(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingVillage(false)
        }
    }

    
    useEffect(() => {
        getDataProvince()
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Label <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        name="label"
                        placeholder='Head Office'
                        onChange={(e) => setValueAddress({...valueAddres, label : e.target.value })}
                        value={valueAddres.label}
                        type="text"
                        id="label"
                        size="small"
                        required
                    />
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Country (Negara) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    <Box pt={1} pb={1}>
                        <Select
                            placeholder="Select Country"
                            value={selectedCountry}
                            isSearchable={true}
                            options={optionsCountry && optionsCountry}
                            onChange={handleChangeCountry}
                            id="select-style-countryy"
                        />
                    </Box>
                    { valueAddres.error_country ? <div className="error-p"><p>Country is required</p></div> : null }
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Province (Provinsi) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    { loadingProvince ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                        <Select
                            placeholder="Select Province"
                            defaultValue={{value : valueAddres.province, label: valueAddres.province || 'Select Province'}}
                            isSearchable={true}
                            options={optionsProvince && optionsProvince}
                            onChange={handleChangeProvince}
                            id="select-style-provincee"
                        /> 
                    </Box> }
                    { valueAddres.error_province ? <div className="error-p"><p>Province is required</p></div> : null }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        City (Kota) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    { loadingCity ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select City"
                        value={{value : valueAddres.city, label: valueAddres.city || 'Select City'}}
                        isSearchable={true}
                        options={optionsCity && optionsCity}
                        onChange={handleChangeCity}
                        id="select-style-cityy"
                    /> 
                    </Box> }
                    { valueAddres.error_city ? <div className="error-p"><p>City is required</p></div> : null }
                </Grid>

                <Grid item xl={6} lg={6} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Sub District (Kecamatan) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    { loadingSubDistrict ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Sub District"
                        value={{value : valueAddres.subdistrict, label: valueAddres.subdistrict || 'Select Sub District'}}
                        isSearchable={true}
                        options={optionsSubDistrict && optionsSubDistrict}
                        onChange={handleChangeSubDistrict}
                        id="select-style-subdistrict"
                    /> 
                    </Box> }
                    { valueAddres.error_subdistrict ? <div className="error-p"><p>Sub District is required</p></div> : null }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Village (Kelurahan) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    { loadingVillage ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Village"
                        value={{value : valueAddres.village, label: valueAddres.village || 'Select Village'}}
                        isSearchable={true}
                        options={optionsVillage && optionsVillage}
                        onChange={handleChangeVillage}
                        id="select-style-village"
                    /> 
                    </Box> }
                    { valueAddres.error_village ? <div className="error-p"><p>Village is required</p></div> : null }
                </Grid>

                <Grid item xl={6} lg={6} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Zip Code (Kode Pos) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        name="postcode"
                        placeholder="12345"
                        onChange={(e) => setValueAddress({...valueAddres, postcode : e.target.value })}
                        value={parseInt(valueAddres.postcode)}
                        type="number"
                        id="postcode"
                        size="small"
                        required
                        InputProps={{
                            inputProps: { 
                                min : 0
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Street (Jalan) <span><img src={required} width="10px" alt='icon'/></span>
                    </Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        name="street"
                        placeholder="Jalan Merdeka 1"
                        onChange={(e) => setValueAddress({...valueAddres, street : e.target.value })}
                        value={valueAddres.street}
                        type="text"
                        id="street"
                        size="small"
                        multiline
                        rows={3}
                        required
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default AddressFormList

