import { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    CircularProgress,
    Alert
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import logo from '../../../assets/img/logo-color.png'
import { signInSuperAdminAction } from '../redux/authReducers';
import { IAuthState } from '../types';

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
  })
  .required();


const RootAdmin = () => {
  const dispatch = useDispatch()
  const { signin, loading_signin, error_signin } = useSelector((state : RootState) => state.auth )

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IAuthState>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (data: IAuthState): void => {
    dispatch(signInSuperAdminAction(data))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  useEffect(() => {
    if(signin) {
      setTimeout(() => {
        window.location.href = '/login/select-company'
      },200);
    } 
    // eslint-disable-next-line
  }, [signin]);


  return ( 
    <div className="container-box-auth">
        <Box className='paper-box'>
          <Box>
              <a href='/'>
                  <img src={logo} alt="logo" />
              </a>
          </Box>

          <Box className='name-login' color="primary" >
            <h1>Superadmin</h1> 
            <p>Sign in with your super admin credential.</p>
          </Box>

          <Box className='form-auth' >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box><h2>Email Address</h2></Box>
              <Box>
                <TextField
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  {...register('email', { required: true })}
                  margin="dense"
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name="email"
                  autoComplete="email"
                  size='small'
                />
              </Box>
              <Box mt={1}><h2>Password</h2></Box>
              <Box>
                <TextField
                  error={!!errors.password}
                  helperText={errors.password && errors.password.message}
                  {...register('password', { required: true })}
                  margin="dense"
                  fullWidth
                  name="password"
                  placeholder="********" 
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment : (
                      <InputAdornment position="end"> 
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  size='small'
                />
              </Box>

              { error_signin === null ? null :
              <Box pt={1}>
                  <Alert severity="error">{error_signin}</Alert>
              </Box> 
              }
              <Box className='button-auth'>
                <button type='submit' disabled={loading_signin}>
                  <p> Sign In </p>
                  { loading_signin && <CircularProgress size={20} color="inherit" style={{ marginLeft: 10}} /> }
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </div>
    )
}

export default RootAdmin;
