import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Box, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCreate from '../../../components/ButtonCreate';
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../components/TableData';
import DialogResignin from './DialogResignin';
import { RootState } from '../../../app/store';
import { getRoleBuyerCompany } from './reducers/selectCompanyReducers';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ListUserRoles : React.FC<any> = ({ 
  open, setOpen, detail
 }) => {

    const dispatch = useDispatch()
    const { roles, loading_roles, } = useSelector((state : RootState) => state.all_buyer)

    const handleClose = () => {
      setOpen(false);
    };


    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState<any>({
      company_id : "",
      role_id : ""
    });

    // function object is not empty
    const isNotEmpty = (obj : any) => {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return true;
      }
      return false;
    }

    useEffect(() => {
      if(isNotEmpty(detail)){
        dispatch(getRoleBuyerCompany(detail._id))
      }
      // eslint-disable-next-line
    }, [detail]);

    const columns: TableColumn<any>[] = [
      {
          name: 'No',
          width: '70px',
          cell: (row, index) => (
              <p>
              { page > 1 ? 
                  <>
                  {index + 1 + (limit * (page-1))}
                  </>
                  :
                  <> 
                  {index + 1}
                  </>
              }
              </p>
          )
      },
      // {
      //     name: 'Name',
      //     selector: row => row.legalName,
      // },
      // {
      //     name: 'Email',
      //     selector: row => row.type && row.type.name || "-",
      // },
      {
          name: 'Role',
          selector: row => row.name,
      },
      {
          name: 'ACTION',
          cell: (row) => (
              <ButtonCreate 
                  name="Shadow this Role"
                  onClick={() => {
                    setOpenDialog(true)
                    setData({
                      company_id : detail._id,
                      role_id : row._id
                    })
                  }}
              />
          ),
      },
  ];

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Company's Role</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Stack pt={2}>
            <DataTableBase 
                columns={columns}
                data={roles}
                progressPending={loading_roles}
                pagination
                onChangePage={(value) => setPage(value)}
                onChangeRowsPerPage={(value) => setLimit(value)}
                paginationPerPage={limit}
                noDataComponent="Role is not found, please try again"
                persistTableHead
            />
          </Stack>
        </DialogContent>
      </BootstrapDialog>
      <DialogResignin open={openDialog} setOpen={setOpenDialog} data={data} />
    </div>
  );
}

export default ListUserRoles;