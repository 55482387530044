import { Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import calender from '../../../../assets/img/icon/calendar.png'
import more from '../../../../assets/img/icon/more.png'
import editing from '../../../../assets/img/icon/editing.png'
import cancel from '../../../../assets/img/icon/hide.png'
import submit from '../../../../assets/img/icon/verified.png'
import DownloadPdf from '../../../../components/DownloadPdf'
import { checkValidation } from '../../../../utilities/userFeaturesValidation'
import { getUpdatePurchaseRequest, printPurchaseRequest, updateCodePurchaseRequest } from '../redux/purchasesReducers'
import DialogConfirmation from './DialogConfirmation'
import HistoryStatus from './HistoryStatus'
import PurchaseDetail from './PurchaseDetail'

const PurchaseCard : React.FC<any> = ({ item }) =>  {
    const dispatch = useDispatch()

    const [openDetail, setOpenDetail] = useState(false);    
    const [openConfirmation, setOpenConfirmation] = useState({
        open: false,
        type: '',
        id : ""
    });
    const [openStatus, setOpenStatus] = useState({
        open: false,
        data : []
    });
    const [idPurchases, setIdPurchases] = useState("");
    const [edited, setEdited] = useState(false);
    const [numberPR, setNumberPR] = useState("");
    const [error, setError] = useState(false);

    function getStyle (name:string) {
        switch (name) {
            case 'Open':
                return {  backgroundColor : '#ff9f43' }
            case 'Submit':
                return {  backgroundColor : '#0091d6' }
            case 'Approved':
                return {  backgroundColor : '#43a047' }
            case 'Reject':
                return {  backgroundColor : '#ee4054' }
            default:
                return {  backgroundColor : '#43a047' }
        }
    }

    function onClickEdit() {
        setNumberPR("")
        setEdited(true)
    }

    function onClickCancel(code:string) {
        setNumberPR(code)
        setEdited(false)
    }

    function onClickSubmit() {
        if(numberPR === "") {
            setError(true)
        } else {
            let body = {
                data : {
                    code : numberPR.trim()
                },
                id : item._id
            }
            dispatch(updateCodePurchaseRequest(body))
        }
    }

    function handleChange(event:any) {
        const value = event.target.value;
        setNumberPR(value);
        setError(false)
    };

    return (
    <div className='container-item-purchase'>
        <Box className="top-div">
            <Box className='left'>
                <img src={calender} alt='calender' />
                <p>{moment(item.date).format('lll')}</p>
                <Box className='line-right' />
                <h5>{item.department}</h5>
                <Box className='line-right' />
                { !edited ? 
                <>
                    <h6>{item.code}</h6> 
                    { item.lastStatus === "Open" ? 
                    <div className='edited-icon'  onClick={onClickEdit}>
                        <img alt="edit" src={editing}/>
                    </div> : null }
                </> 
                :
                <>
                    <Box className='edited-pr'>
                        <input 
                            placeholder='Type your custom number ..' 
                            type="text" 
                            value={numberPR} 
                            onChange={handleChange} 
                            style={{borderColor: error ? 'red' : 'green'}}
                        />
                    </Box> 
                    <div className='edited-icon' onClick={() => onClickCancel(item.code)}>
                        <img alt="cancel" src={cancel} />
                    </div>
                    <div className='edited-icon' onClick={onClickSubmit}>
                        <img alt="submit" src={submit}/>
                    </div>
                </>
                }

            </Box>
            <Box className='right'>
                <Box className='status' sx={getStyle(item.lastStatus)}>
                    <h6>{item.lastStatus}</h6>
                </Box>
                <Box onClick={() => setOpenStatus({open:true, data: item.statuses})}>
                    <img src={more} alt='more' />
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={10} xl={10}>
                    <Box className='left-box'>
                        <h2>Vendor List :</h2>
                        <Box className='vendor-list'>
                            { item.vendors.map((ele:any, i:any) => (
                                <Box className='box-vendor' key={i}>
                                    <h3>{ele.toLowerCase()}</h3>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
                    <Box className='total-prices'>
                        <p>Total Purchase</p>
                        <h3>Rp.{(Math.floor(item.grand_total)).toLocaleString()}</h3>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box  
                className='view-btn' 
                onClick={() => {
                    setIdPurchases(item._id)
                    setOpenDetail(true)
                }}
            >
                <p>View Purchase Request</p>
            </Box>
            <Box className='line-divider' />
            { checkValidation('DONPDF0912312') &&
            <DownloadPdf 
                name='Download PDF'
                onClick={() => dispatch(printPurchaseRequest(item._id))}
            /> }
            { item.lastStatus === 'Open' || item.lastStatus === 'Reject' ?
            <>
            { checkValidation('SUBPURREQ404827') &&
            <Box className="btn-pr-submit" >
                <button onClick={() => setOpenConfirmation({open:true, type: 'Submit', id: item._id})}>
                    <p>Submit</p>
                </button>
            </Box> }
            { checkValidation('UPDPURREQ737071') &&
            <Box className="btn-pr-submit">
                <button onClick={() => dispatch(getUpdatePurchaseRequest(item._id))}>
                    <p>Edit</p>
                </button>
            </Box>
            }
            { checkValidation('DELPURREQ377495') &&
            <Box className="btn-pr-reject">
                <button onClick={() => setOpenConfirmation({open:true, type: 'Delete', id: item._id})}>
                    <p>Delete</p>
                </button>
            </Box>
            }
            </> : item.lastStatus === 'Submit' ?
            <>
            { checkValidation('APPPURREQ454744') && 
            <Box className="btn-pr-submit">
                <button onClick={() => setOpenConfirmation({open:true, type: 'Approve', id: item._id})}>
                    <p>Approve</p>
                </button>
            </Box>
            }
            { checkValidation('REJPURREQ323699') && 
            <Box className="btn-pr-reject">
                <button onClick={() => setOpenConfirmation({open:true, type: 'Reject', id: item._id})}>
                    <p>Reject</p>
                </button>
            </Box> 
            }
            </> 
            : null }
        </Box>

        <PurchaseDetail open={openDetail} setOpen={setOpenDetail} idPurchases={idPurchases} />
        <DialogConfirmation open={openConfirmation} setOpen={setOpenConfirmation} />
        <HistoryStatus open={openStatus} setOpen={setOpenStatus} />
    </div>
  )
}

export default PurchaseCard 