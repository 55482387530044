import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features_app/auth/redux/authSlice';
import stepFormReducer from '../features_app/dashboard/step_register/components/stepFormSlice';
import stepRegisterReducer from '../features_app/dashboard/step_register/stepRegisterSlice'
import teamsReducers from '../features_app/dashboard/teams/redux/teamsSlice'
import getProfileReducer from '../features_app/dashboard/profile/profileSlice'
import getVendorLists from '../features_app/dashboard/vendor_lists/redux/vendorListsSlice'
import homeDashboardReducer from '../features_app/dashboard/home/homeDashboardSlice'
import getAllBuyerReducers from '../features_app/dashboard/select_company/selectCompanySlice'
import departmentReducers from '../features_app/dashboard/department/redux/departmentSlice'
import chatReducers from '../features_app/dashboard/chat/chatSlice'
import notificationsReducers from '../features_app/dashboard/home/notification/notifiicationSlice'
import chatReportsReducers from '../features_app/dashboard/reports/charts/chartsSlice'
import invitationReducers from '../features_app/dashboard/invitation/invitationSlice'
import mastersReducers from '../features_app/dashboard/masters/mastersSlice'
import productsReducers from '../features_app/dashboard/products/redux/productSlice'
import purchasesReducers from '../features_app/dashboard/purchase_request/redux/purchasesSlice'
import templatesReducers from '../features_app/dashboard/template/redux/templateSlice'
import ordersReducers from '../features_app/dashboard/orders/redux/ordersSlice'
import getInvoicesReducer from '../features_app/dashboard/finance/invoices/redux/invoicesSlice'
import getRolessReducer from '../features_app/dashboard/roles/redux/rolesSlice'
import getChatsReducers from '../features_app/dashboard/chat/redux/chatSlices'
import getReducerTokens from '../features_app/dashboard/token_credentials/redux/tokenCredentialSlice'
import getReducerExtensions from '../features_app/dashboard/extensions/redux/extensionsSlice'

export const store = configureStore({
  reducer: {
    auth : authReducer,
    step_state : stepFormReducer,
    step_register : stepRegisterReducer,
    users_team : teamsReducers,
    profile : getProfileReducer,
    preferred : getVendorLists,
    dashboard : homeDashboardReducer,
    all_buyer : getAllBuyerReducers,
    department : departmentReducers,
    chat : chatReducers,
    notification : notificationsReducers,
    chart_report : chatReportsReducers,
    invitation : invitationReducers,
    masters : mastersReducers,
    products : productsReducers,
    purchases : purchasesReducers,
    templates : templatesReducers,
    orders : ordersReducers,
    invoice: getInvoicesReducer,
    roles: getRolessReducer,
    chats : getChatsReducers,
    tokens : getReducerTokens,
    extensions : getReducerExtensions,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
