import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import FormTemplate from './FormTemplate';
import PageTemplate from './PageTemplate';

const MainTemplate = () => {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'create') {
            return <FormTemplate/>
        } else {
            return <PageTemplate/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default MainTemplate
