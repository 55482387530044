import { Stack, Grid, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'
import SelectDate from '../../../../components/SelectDate'

const PurchaseSearch : React.FC<any> = ({
    setValueSearch, valueSearch,
    activeStatus, setActiveStatus,
    setFilterTopic, setFilterValue,
    template, setSkip, setRangeTopic,
    setRangeFrom, setRangeTo
}) => {

    const [inputSearch, setInputSearch] = useState("");

    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    } 

    function onChangeStatus (status:string) {
        setActiveStatus(status)
        setSkip(0)
        if(status === "All") {
            setFilterTopic([])
            setFilterValue([])
        } else {
            setFilterTopic(["lastStatus"])
            setFilterValue([status])
        }
    }

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
          setValueSearch(inputSearch);
        }, 500); // set a delay of 500 milliseconds
    
        return () => {
          clearTimeout(debounceSearch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inputSearch]);

    return (
    <Stack className="container-search-purchases">
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={9}>
                <form onSubmit={onSearch}>
                <Box className='search-box'>
                        <input 
                            placeholder={`Search vendor, ${template ? 'name' : 'name'} or department ...`}
                            onChange={(e) => setInputSearch(e.target.value)}
                            value={inputSearch}
                        />
                        <Box>
                            { valueSearch === "" ? 
                            <Box className='notyping'>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box>
                            :
                            <Box className='typing'>
                                <img 
                                    alt='removeimg' className='removeimg' src={removeimg} 
                                    onClick={() => {
                                        setInputSearch("")
                                        setValueSearch("")
                                    }}
                                />
                                <Box className='divider-line'/>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box> }
                    </Box>
                </Box>
                </form>
            </Grid>
            <Grid item xs={12} md={12} lg={3}  sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                <SelectDate 
                    setRangeTopic={setRangeTopic}
                    setRangeFrom={setRangeFrom}
                    setRangeTo={setRangeTo}
                    type='date'
                />
            </Grid>
        </Grid>
        { template ? null :
        <Stack className='status'>
            <Box><h5>Status</h5></Box>
            <Box className='list-status'>
                { status.map((item:any, index:number) => (
                    <Box 
                        key={index} 
                        className={activeStatus === item ? 'status-box active' : 'status-box'}
                        onClick={() => onChangeStatus(item)}
                    >
                        <h6>{item}</h6>
                    </Box>
                )) }
            </Box>
        </Stack> }
    </Stack>
  )
}

const status = ["All", "Open", "Submit", "Approved", "Reject"]

export default PurchaseSearch