import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import PackageCard from './PackageCard';
import { getDetailStatusPackageCount, getOrderDetail } from '../redux/ordersReducers';
import EmptyData from '../../../../components/EmptyData';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
 
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8, 
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const OrderDetail : React.FC<any> = ({ open, setOpen, statusPackageDetail, setStatusPackageDetail }) => {

    const dispatch = useDispatch()
    const { detail, loading_detail, status_package, loading_status_package, approval_item, create_grn } = useSelector((state : RootState) => state.orders)

    useEffect(() => {
      if(detail._id !== undefined) {
        dispatch(getOrderDetail({ id : detail._id, status : statusPackageDetail === "All" ? "New" : statusPackageDetail }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPackageDetail]); 
 
    useEffect(() => {
      if((create_grn || approval_item) && detail._id !== undefined) {
        setTimeout(() => {
          dispatch(getDetailStatusPackageCount(detail._id))
          dispatch(getOrderDetail({ id : detail._id, status : statusPackageDetail === "All" ? "New" : statusPackageDetail }))
        }, 1500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approval_item, create_grn ]);

    function handleClose () {
        setOpen(false);
    };

    const getDataCountPackage = (name:string) => {
      const data = status_package.filter((item:any) => item._id === name)
      return data.length ? <span className="notify-badge">{data.length ? data[0].count : 0}</span> : null
    }

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold" className='detail-order-title'>
                  <h3>Detail Orders</h3>
                  <h2>
                    {detail._id !== undefined && detail.code}
                  </h2>
                </Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className="detail-packages">
            <Stack className='status-package'>
              { loading_status_package ? 
              <Box className='list-status'>
                  { array_tabs.map((_, index:number) => (
                      <Box className='container-status' key={index}>
                          <Box key={index}  className='status-box'>
                              <Skeleton height={30} width={80} sx={{m:0}} />
                          </Box>
                      </Box>
                  )) }
              </Box>:
              <Box className='list-status'>
                  { array_tabs.map((item:any, index:number) => (
                      <Box className='container-status' key={index}>
                          {getDataCountPackage(item.value)}
                          <Box 
                              key={index} 
                              className={statusPackageDetail === item.value ? 'status-box active' : 'status-box'}
                              onClick={() => setStatusPackageDetail(item.value)}
                          >
                              <h6>{item.label}</h6>
                          </Box>
                      </Box>
                  ))}
              </Box> } 
            </Stack>
            <Stack className='card-packages'>
                { loading_detail ?
                <LoadingItems/> 
                : 
                <Box>
                    {detail._id !== undefined && detail.packages.map((itm:any, indx:number) => (
                      <Box key={indx}>
                        <PackageCard item={itm} idx={indx} order={detail} vendor={detail.vendor}/>
                      </Box>
                    ))}
                    <Box m={1}>
                      {detail._id !== undefined && detail.packages.length === 0 && <EmptyData message={`Package ${statusPackageDetail} is not found.`} />}
                    </Box>
                </Box>
                }
            </Stack>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

const LoadingItems = () => {
  return (
      <Box className="loading-container">
         <Box m={0} p={0}> <Skeleton height={60} /></Box>
         <Box m={0} p={0}> <Skeleton height={60} /></Box>
         <Box m={0} p={0}> <Skeleton height={60} /></Box>
      </Box>
  )
}

const array_tabs = [
  { label: "New", value: "New" },
  { label: "Pick & Pack", value: "Pick" },
  { label: "Ready to Ship", value: "Ready to Ship" },
  { label: "Shipped", value: "Shipped" },
  { label: "Delivered", value: "Delivered" },
  { label: "Completed", value: "Completed" },
  { label: "Canceled", value: "Cancel" },
]

export default OrderDetail;

