import React , {  useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Stack, Box, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOnePreferredProducts } from '../redux/vendorListsReducer';
import { RootState } from '../../../../app/store';
import TableProductList from './TableProductList';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogDetail : React.FC<any> = ({ 
  open, setOpen, name, detail
}) => {

    const dispatch = useDispatch()
    
    const { loading_preferred_products, preferred_products } = useSelector((state : RootState) => state.preferred)

    function handleClose () {
        setOpen(false);
    };

    function isNotEmpty (obj:any) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return true;
      }
      return false;
    }

    useEffect(() => {
      if(open && detail._id !== undefined) {
        dispatch(getOnePreferredProducts(detail._id))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail, open]);

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{name}</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className="container-content-preferred">
            <Box className="card-content">
                <Box>
                  <Box className='card-company'> 
                    <Box className='top-header'>
                      <h2>{detail && detail.company.legalName}</h2>
                    </Box>
                    <Box className='code'>
                      <p>{detail && detail.company.code}</p>
                    </Box>
                  </Box>
                  <Box className='form-preferred'>
                    { loading_preferred_products ? 
                    <Box className='form-product'>
                      <Skeleton variant="text" height={100} />
                      <Skeleton variant="text" height={50} />
                      <Skeleton variant="text" height={50} />
                      <Skeleton variant="text" height={50} />
                      <Skeleton variant="text" height={50} />
                      <Skeleton variant="text" height={50} />
                    </Box> :
                    <Box >
                      { isNotEmpty(preferred_products) &&  
                      <Box>
                        <TableProductList 
                          dataProducts={preferred_products.product}
                        />
                      </Box> }
                    </Box> }

                  </Box>
                </Box> 
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default DialogDetail;