import { Box } from '@mui/material'
import StepperForm from './components/StepperForm'
import {userCredentials} from './../../../utilities/config'
import { useEffect, useState } from 'react';
import ReSignInPage from '../../auth/resignin/resignin';

function StepRegister() {
    const [relogin, setRelogin] = useState(false);

    useEffect(() => {
        const local_data = localStorage.getItem('relogin')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setRelogin(data)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    return (
        <Box m={2}>
            { relogin ? 
             <ReSignInPage />
             :
            <div>
                <div className="section-top">
                        <h3>Welcome to Pasar Pemesanan, {userCredentials.fullname}</h3>
                        <p>Please fill these form to complete the company registration.</p>
                </div>
                <div className="section-form-stepper">
                    <Box p={3}>
                        <StepperForm/>
                    </Box>
                </div>
            </div> 
            }
        </Box>
    )
}

export default StepRegister
