import { Stack, Grid, Box,} from '@mui/material'
import React, { useState, } from 'react'
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'

const PreferredSearch : React.FC<any> = ({
    setValueSearch, valueSearch,
}) => {

    const [inputSearch, setInputSearch] = useState("");

    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    }
    return (
    <Stack className="container-search-preferred">
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
                <form onSubmit={onSearch}>
                <Box className='search-box'>
                        <input 
                            placeholder={`Search by name or company code..`}
                            onChange={(e) => setInputSearch(e.target.value)}
                            value={inputSearch}
                        />
                        <Box>
                            { valueSearch === "" ? 
                            <Box className='notyping'>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box>
                            :
                            <Box className='typing'>
                                <img 
                                    alt='removeimg' className='removeimg' src={removeimg} 
                                    onClick={() => {
                                        setInputSearch("")
                                        setValueSearch("")
                                    }}
                                />
                                <Box className='divider-line'/>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box> }
                    </Box>
                </Box>
                </form>
            </Grid>
        </Grid>
       
    </Stack>
  )
}


export default PreferredSearch