import { Stack, Box, Button, Paper, TextField, Grid, Skeleton } from '@mui/material'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getActionTypeCompany } from '../../step_register/components/stepFormSlice';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { v4 as uuid } from 'uuid';
import { styled } from '@mui/material/styles';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateLegalSnackBar } from '../profileSlice';
import { getCompanyDetail, updateLegalDocumentCompany } from '../reducers/profilesReducers';
import swal from 'sweetalert';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const Input = styled('input')({
    display: 'none',
  });

const Legal : React.FC<any> = ({ profile }) => {

    const dispatch = useDispatch()
    const { loading_update_legal, update_legal } = useSelector((state : RootState) => state.profile)

    // const [localData, setLocalData] = useState<any>({});
    const [legalDocState, setLegalDocState] = useState<any>([]);

    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState("");

    const onChangeValue = (index : any, value : any) => {
        let copyState = [...legalDocState]
        const newData = copyState.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        setLegalDocState(newData)
    }

    const onChangeValueFile = (index : any, e : any) => {
        setLoading(true)
        setIndex(index)
        const copyState = [...legalDocState]
        const random = uuid();
        setTimeout(() => {
            var S3 = require("aws-sdk/clients/s3");
            const s3bucket = new S3({
                endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
            });
        
            if (e.target.files && e.target.files[0]) {
            const blob = e.target.files[0]
            const file_name = blob.name.replace(/\s/g, "")
            const params = { Body: blob, 
                            Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                            Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                            };
            s3bucket.putObject(params)
            .on('build', (request : any) => {
                request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                request.httpRequest.headers['Content-Length'] = blob.size;
                request.httpRequest.headers['Content-Type'] = blob.type;
                request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                request.httpRequest.headers['x-amz-acl'] = 'public-read';
            })
            .send((err : any, data : any) => {
                if (err){  
                    swal("Error",  `${err.stack}`, "error")
                } 
                else {      
                    const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                    const newData = copyState.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            file : {
                                url : imageUrl,
                                fileName : file_name,
                            }
                        }
                        return obj
                    });
                    setLegalDocState(newData)
                    setLoading(false)
                }}
            )} 
        }, 1000);
    }

    const onClickSave = (e : any) => {
        e.preventDefault()
        const data = {
            id : profile._id,
            data : legalDocState
        }
        dispatch(updateLegalDocumentCompany(data))
    }

    const proceedState = (data:any) => {
        let state = []
        for(const element of data) {
            state.push({
                company : element.company,
                legal : element.legal,
                title: element.legal.name,
                file : element.file,
                number : element.number,
                error : false,
                _id : element._id
            })
        }
        setLegalDocState(state)
    }

    useEffect(() => { 
        if(profile) {
            proceedState(profile.legalDocuments)
        }
        // eslint-disable-next-line
    }, [profile]);

    useEffect(() => {
        if(update_legal) {
            dispatch(getCompanyDetail())
            setTimeout(() => {
                dispatch(updateLegalSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [update_legal]);



    useEffect(() => {
        dispatch(getActionTypeCompany())
        // eslint-disable-next-line
    }, []);
    

    return (
        <Stack mt={2}>
            <Box>
            <div className="section-form-company-detail">
               <form onSubmit={(e) => onClickSave(e)}>
                <Box > 
                { legalDocState.length === 0  ? null :
                    <Box>
                        <Box >
                            <Grid container spacing={3} >
                                {legalDocState?.map((val : any, i :any) => (
                                <Grid item xl={6} lg={6} xs={12} key={i}>
                                    <Paper sx={{p:2, borderRadius: 3, minHeight: 210}} elevation={2}>
                                        <Box pt={2}><h4>{val.title}</h4></Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            placeholder={`Number ${val.title}`}
                                            value={val.number}
                                            name="number"
                                            size="small"
                                            onChange={(e) => onChangeValue(i, e)}
                                            disabled={!checkValidation('UPLEGDOC0002')}
                                        />
                                        <Box>
                                            { loading && i === index ? 
                                            <Skeleton 
                                                variant="rectangular" 
                                                height={50} 
                                                sx={{ borderRadius: '4px', mt: 2 }}
                                            /> :
                                            <Box>
                                                { val.file.url === "https" ?
                                                <Grid my="1rem" flexDirection="row" justifyContent="center" >
                                                    <Box>
                                                    <label htmlFor={`icon-button-files-${i}`} >
                                                        <Input 
                                                            accept="application/pdf,image/*" 
                                                            id={`icon-button-files-${i}`} 
                                                            type="file"  
                                                            onChange={(e) => onChangeValueFile(i,e)} 
                                                        />
                                                        { checkValidation('UPLEGDOC0002') &&
                                                        <Box className='btn-icon-upload'>
                                                            <FileUploadIcon sx={{ color : "#0091d6" }}/> Upload Document
                                                        </Box>
                                                        }
                                                    </label>
                                                    </Box>
                                                </Grid>
                                                :
                                                <Stack flexDirection="row" alignItems="center" flexWrap="wrap">
                                                    <Box pt={2} pr={2}>
                                                        <a  className="link-file-name"
                                                            href={val.file.url} target="_blank" 
                                                            rel="noreferrer">{val.file.fileName}
                                                        </a>
                                                    </Box>
                                                    <Box>
                                                        <label htmlFor={`icon-button-files-${i}`} >
                                                            <Input 
                                                                accept="application/pdf,image/*" 
                                                                id={`icon-button-files-${i}`} 
                                                                type="file"  
                                                                onChange={(e) => onChangeValueFile(i,e)} 
                                                            />
                                                            { checkValidation('UPLEGDOC0002') &&
                                                            <Box 
                                                                sx={{
                                                                    color: 'red',
                                                                    cursor: 'pointer',
                                                                    fontSize: 12,
                                                                    pl: 1,
                                                                    pt : 2
                                                                }}
                                                            >
                                                                Change
                                                            </Box> }
                                                        </label>
                                                    </Box>
                                                </Stack> }
                                            </Box> }
                                        </Box>
                                    </Paper>
                                </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                    }
                </Box> 
                { checkValidation('UPLEGDOC0002') &&
                <Stack sx={{my:3}} flexDirection="row" justifyContent="flex-end">
                    <Button 
                        variant='contained' type='submit'
                        disabled={loading_update_legal}
                    >
                        {loading_update_legal ? "Loading.." : "Save"}
                    </Button>
                </Stack> 
                }
                </form>
            </div>
            </Box>

            <SnackBarAlert message={`Success update Legal Document Company.`} initialState={update_legal} />

        </Stack>
    )
}

export default Legal
