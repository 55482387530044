import { createSlice } from '@reduxjs/toolkit';
import { createNewInvoice, createNewTransaction, getAccountPayable, getAllBankPaymentMethod, getInvoiceDetail, getInvoiceNumber, getInvoicesData, getStatistivInvoice, getTotalPurchases, printDownloadInvPDF, submitPaymentProofInvoice } from './invoicesReducers';
import { errorSweetAlert } from '../../../../../utilities/errorhandling';

// funtion to check local storage data_invoice
const checkLocalStorageInv = () => {
  let data_invoice = {};
  if(localStorage.getItem('data_invoice') !== null) {
    data_invoice = JSON.parse(localStorage.getItem('data_invoice') || '{}');
  }
  return data_invoice;
}


const checkLocalStorageTransaction = () => {
  let transaction = {};
  if(localStorage.getItem('transaction') !== null) {
    transaction = JSON.parse(localStorage.getItem('transaction') || '{}');
  }
  return transaction;
}

const initialState: any = {
  invoices : [], 
  loaded_invoices : false,
  total_invoices : 0,
  total_infinite : 0,
  loading_invoices : false,
  invoice_detail : null,
  loading_invoice_detail : false,
  invoice_number : null,
  loading_invoice_number : false,
  create_invoice : false,
  loading_create_invoice : false,
  open_invoice_data : checkLocalStorageInv(),
  open_invoice : false,
  loading_create_transaction : false,
  create_transaction : false,
  data_transaction : checkLocalStorageTransaction(),
  proof_invoice : false, 
  loading_proof_invoice : false,
  bank_payment_method : [],
  loading_bank_payment_method : false,
  payable : [],
  loading_payable : false,
  purchases : [],
  loading_total_purchases : false,
  statistic_inv : {},
  loading_statistic_inv : false,
  download_inv : "",
  loading_download_inv : false,
};  

export const invoiceSlices = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    createOpenInvoice: (state, action) => {
      localStorage.setItem('data_invoice', JSON.stringify(action.payload))
      state.open_invoice_data = action.payload;
      state.open_invoice = true;
    },
    closeDownloadInv: (state) => {
      state.download_inv = "";
    },
    closeOpenInvoice: (state) => {
      state.open_invoice = false;
    },
    clearMessageInvoice: (state) => {
      state.proof_invoice = false;
      state.data_transaction = {}
      localStorage.removeItem('transaction')
    },
    addLengthInvInfinite (state,action) {
      let existing = state.total_infinite + action.payload
      state.total_infinite = existing
    }
  },
  extraReducers: (builder) => {
    builder
      // get invoices data
      .addCase(getInvoicesData.pending, (state) => {
        state.loading_invoices = true;
        state.detail = {};
        state.loaded_invoices = false;
        state.total_invoices = 0;
      })
      .addCase(getInvoicesData.fulfilled, (state, action:any) => {
        state.loading_invoices = false;
        state.invoices = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_invoices = action.payload.data.length > 0 ? action.payload.total.row : 0;
        state.total_infinite = action.payload.data.length > 0 ? action.payload.data.length : 0;
        state.loaded_invoices = true;
      })
      .addCase(getInvoicesData.rejected, (state, action : any) => {
        state.loading_invoices = false;
        errorSweetAlert(action.payload)
      })

      // get status
      .addCase(getInvoiceNumber.pending, (state) => {
        state.loading_invoice_number = true;
      })
      .addCase(getInvoiceNumber.fulfilled, (state, action:any) => {
        state.loading_invoice_number = false;
        state.invoice_number = action.payload
      })
      .addCase(getInvoiceNumber.rejected, (state, action : any) => {
        state.loading_invoice_number = false;
        errorSweetAlert(action.payload)
      })

       // get invoices detail
      .addCase(getInvoiceDetail.pending, (state) => {
        state.loading_invoice_detail = true;
        state.invoice_detail = {}
      })
      .addCase(getInvoiceDetail.fulfilled, (state, action:any) => {
        state.loading_invoice_detail = false;
        state.invoice_detail = action.payload
        state.open_invoice_data = action.payload
      })
      .addCase(getInvoiceDetail.rejected, (state, action : any) => {
        state.loading_invoice_detail = false;
        errorSweetAlert(action.payload)
      })

      //  create invoice
      .addCase(createNewInvoice.pending, (state) => {
        state.loading_create_invoice = true;
        state.create_invoice = false;
      })
      .addCase(createNewInvoice.fulfilled, (state, action:any) => {
        state.loading_create_invoice = false;
        state.create_invoice = action.payload
        localStorage.removeItem('data_invoice')
      })
      .addCase(createNewInvoice.rejected, (state, action : any) => {
        state.loading_create_invoice = false;
        errorSweetAlert(action.payload)
      })


      // create transaction
      .addCase(createNewTransaction.pending, (state) => {
        state.loading_create_transaction = true;
        state.create_transaction = false;
        state.data_transaction = {}
      })
      .addCase(createNewTransaction.fulfilled, (state, action:any) => {
        state.loading_create_transaction = false;
        state.create_transaction = action.payload.data
        state.data_transaction = action.payload.value
        localStorage.setItem('transaction', JSON.stringify(action.payload.value))
      })
      .addCase(createNewTransaction.rejected, (state, action : any) => {
        state.loading_create_transaction = false;
        errorSweetAlert(action.payload)
      })

      // payment proof invoice
      .addCase(submitPaymentProofInvoice.pending, (state) => {
        state.loading_proof_invoice = true;
        state.proof_invoice = false;
      })
      .addCase(submitPaymentProofInvoice.fulfilled, (state, action:any) => {
        state.loading_proof_invoice = false;
        state.proof_invoice = action.payload
      })
      .addCase(submitPaymentProofInvoice.rejected, (state, action : any) => {
        state.loading_proof_invoice = false;
        errorSweetAlert(action.payload)
      })


      // get bank payment method
      .addCase(getAllBankPaymentMethod.pending, (state) => {
        state.loading_bank_payment_method = true;
      })
      .addCase(getAllBankPaymentMethod.fulfilled, (state, action:any) => {
        state.loading_bank_payment_method = false;
        state.bank_payment_method = action.payload
      })
      .addCase(getAllBankPaymentMethod.rejected, (state, action : any) => {
        state.loading_bank_payment_method = false;
        errorSweetAlert(action.payload)
      })


       // get payable
      .addCase(getAccountPayable.pending, (state) => {
        state.loading_payable = true;
      })
      .addCase(getAccountPayable.fulfilled, (state, action:any) => {
        state.loading_payable = false;
        state.payable = action.payload
      })
      .addCase(getAccountPayable.rejected, (state, action : any) => {
        state.loading_payable = false;
        errorSweetAlert(action.payload)
      })

      // get total purchases
      .addCase(getTotalPurchases.pending, (state) => {
        state.loading_total_purchases = true;
      })
      .addCase(getTotalPurchases.fulfilled, (state, action:any) => {
        state.loading_total_purchases = false;
        state.purchases = action.payload
      })
      .addCase(getTotalPurchases.rejected, (state, action : any) => {
        state.loading_total_purchases = false;
        errorSweetAlert(action.payload)
      })

       // statuistik invoice
      .addCase(getStatistivInvoice.pending, (state) => {
        state.loading_statistic_inv = true;
      })
      .addCase(getStatistivInvoice.fulfilled, (state, action:any) => {
        state.loading_statistic_inv = false;
        state.statistic_inv = action.payload
      })
      .addCase(getStatistivInvoice.rejected, (state, action : any) => {
        state.loading_statistic_inv = false;
        errorSweetAlert(action.payload)
      })

      // download pdf
      .addCase(printDownloadInvPDF.pending, (state) => {
        state.loading_download_inv = true;
        state.download_inv = "";
      })
      .addCase(printDownloadInvPDF.fulfilled, (state, action:any) => {
        state.loading_download_inv = false;
        state.download_inv = action.payload
      })
      .addCase(printDownloadInvPDF.rejected, (state, action : any) => {
        state.loading_download_inv = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { 
  createOpenInvoice, closeDownloadInv, closeOpenInvoice, clearMessageInvoice,
  addLengthInvInfinite 
} = invoiceSlices.actions;

export default invoiceSlices.reducer;
