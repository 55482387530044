import chatIcon from '../../../assets/img/icon/chat.png'
import {  useEffect } from 'react'
import { Box } from '@mui/material'
import { MainContainer } from '@chatscope/chat-ui-kit-react'
import ChatList from './components/ChatList'
import ChatAction from './components/ChatAction'
import dropdown from '../../../assets/img/icon/down.png'
import { useDispatch, useSelector } from 'react-redux'
import Slide from '@mui/material/Slide';
import { useLocation } from 'react-router-dom'
import { onFloatingChat } from './redux/chatSlices'
import { checkValidation } from '../../../utilities/userFeaturesValidation'
import { userCredentials } from '../../../utilities/config'


const ChatFloating = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { conversation_lists, open_floating } = useSelector((state:any) => state.chats);

    const handleChange = () => {
        dispatch(onFloatingChat(!open_floating))
    };

    useEffect(() => {
        dispatch(onFloatingChat(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const Container = (
        <Box className='container-float-chat'>
            <Box px={2} pb={1} display='flex' justifyContent='space-between' >
                <h2>Chat</h2>
                <Box className='dropwdown' onClick={handleChange}>
                    <img src={dropdown} alt='down' />
                </Box>
            </Box>
            <Box className='chat-container-float'>
                <MainContainer responsive style={{borderRadius: 10}}>
                {conversation_lists.length > 0 ? 
                <>
                    <ChatList/>
                    <ChatAction width={40}/>
                </> 
                : 
                <>
                    <ChatAction width={50}/>
                </> }
                </MainContainer>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ display: checkValidation('REAMES692744') && userCredentials.company.isVerified ? 'block' : 'none'  }}>
            <Box 
                className="chat-floating-button"
                onClick={handleChange}
            >   { !open_floating && (
                <Box className='floating-card-chat'>
                    <Box component='img' src={chatIcon} alt='chat'/>
                    <h6>Chat</h6>
                </Box> 
                )}
            </Box>
            <Box >
                <Slide direction="up" in={open_floating} mountOnEnter unmountOnExit >
                    {Container}
                </Slide>
            </Box>
        </Box>
    )
}

export default ChatFloating