import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const LoadingFilter = () => {
  return (
    <Box className='load-skeleton'>
        <Box className='title' >
            <Skeleton animation="wave" width={110} height={30}/>
        </Box>
        <Box className='checkbox-load' mb={1}>
            { [1,2,3,4,5,6].map((item) => (
                <div key={item}>
                    <Skeleton animation="wave"  />
                </div>
            )) }
        </Box> 
    </Box>
  );
}

export default LoadingFilter