import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { approvePurchaseRequest, rejectPurchaseRequest, removePurchaseRequest, submitPurchaseRequest } from '../redux/purchasesReducers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogConfirmation : React.FC<any> = ({ open, setOpen }) => {

    const dispatch = useDispatch()

    function handleClose () {
        setOpen({...open, open: false});
    };

    const [value, setValue] = React.useState('');
    const [errorNote, setErrorNote] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorNote(false)
        setValue(event.target.value);
    };

    function onClickContinue (type:string) {
        switch (type) {
            case 'Submit':
                dispatch(submitPurchaseRequest(open.id))
                handleClose()
                break;
            case 'Delete':
                dispatch(removePurchaseRequest(open.id))
                handleClose()
                break;
            case 'Approve':
                dispatch(approvePurchaseRequest(open.id))
                handleClose()
                break;
            case 'Reject':
                if(value !== "") {
                    let data = {
                        id: open.id,
                        note: value
                    }
                    dispatch(rejectPurchaseRequest(data))
                    handleClose()
                } else {
                    setErrorNote(true)
                }
                break;
            default:
                break;
        }
    }
    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open.open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Confirmation!</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <Typography gutterBottom>
                Are you sure want to {open.type} this Purchase?
            </Typography>
            { open.type === 'Reject' && 
            <Box> 
                <TextField 
                    fullWidth
                    placeholder='Note'
                    margin='dense' 
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                />
                { errorNote && <Typography color="error">Note is required</Typography> }
            </Box> }
        </DialogContent>
        <DialogActions>
          <Button size="small" autoFocus color="error" variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            color={open.type === "Delete" ? "error" : "primary"} 
            size="small" autoFocus variant='contained' 
            onClick={() => onClickContinue(open.type)}
          >
            {open.type}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default DialogConfirmation;