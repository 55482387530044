import { createSlice } from '@reduxjs/toolkit';
import { getCompanyCategoryVendor, postActionCompanyDetail, postAddUserManagement, postCompanyDetail, postLegalDocument } from './reducers/stepRegisterReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';


const initialState: any = {
  create_company : false,
  loading_create_company : false,
  error_create : null,
  company_detail: false, 
  loading_comp_detail : false,
  error_comp_detail : null,
  legal_document: false, 
  loading_legal_document : false,
  error_legal_document : null,
  user: false, 
  loading_user : false,
  error_user : null,
  company_category : [],
  loaded_company_category : false,
  loading_company_category : false,
};

export const stepRegisterVendor = createSlice({
  name: 'step-register',
  initialState, 
  reducers: {
    defaulCompanyDetail: (state) => {
      state.company_detail = false; 
    },
  },
  extraReducers: (builder) => {
    builder

      // create company detail
      .addCase(postActionCompanyDetail.pending, (state) => {
        state.loading_create_company = true;
        state.create_company = false;
        state.error_create = null;
      })
      .addCase(postActionCompanyDetail.fulfilled, (state, action:any) => {
        state.loading_create_company = false;
        state.create_company = action.payload.data;
        errorSweetAlert(action.payload)

      })
      .addCase(postActionCompanyDetail.rejected, (state, action : any) => {
        state.loading_create_company = false;
        state.error_comp_detail = action.payload; 
        state.error_create = action.payload;
        errorSweetAlert(action.payload)

      })
      
      // get company category
      .addCase(getCompanyCategoryVendor.pending, (state) => {
        state.loading_company_category = true;
        state.loaded_company_category = false;
      })
      .addCase(getCompanyCategoryVendor.fulfilled, (state, action:any) => {
        state.loading_company_category = false;
        state.company_category = action.payload
        state.loaded_company_category = true;
      })
      .addCase(getCompanyCategoryVendor.rejected, (state, action : any) => {
        state.loading_company_category = false;
        errorSweetAlert(action.payload)
      })

      // put company detail
      .addCase(postCompanyDetail.pending, (state) => {
        state.loading_comp_detail = true;
        state.company_detail = false;
      })
      .addCase(postCompanyDetail.fulfilled, (state, action:any) => {
        state.loading_comp_detail = false;
        state.company_detail = action.payload.data;
        errorSweetAlert(action.payload)

      })
      .addCase(postCompanyDetail.rejected, (state, action : any) => {
        state.loading_comp_detail = false;
        state.error_comp_detail = action.payload; 
        errorSweetAlert(action.payload)
      })
      // put legal document
      .addCase(postLegalDocument.pending, (state) => {
        state.loading_legal_document = true;
      })
      .addCase(postLegalDocument.fulfilled, (state, action:any) => {
        state.loading_legal_document = false;
        state.legal_document = action.payload.data;
        errorSweetAlert(action.payload)

      })
      .addCase(postLegalDocument.rejected, (state, action : any) => {
        state.loading_legal_document = false;
        state.error_legal_document = action.payload; 
        errorSweetAlert(action.payload)
      })
      // put user management
      .addCase(postAddUserManagement.pending, (state) => {
        state.loading_user = true;
      })
      .addCase(postAddUserManagement.fulfilled, (state, action:any) => {
        state.loading_user = false;
        state.user = action.payload.data;
        errorSweetAlert(action.payload)

      })
      .addCase(postAddUserManagement.rejected, (state, action : any) => {
        state.loading_user = false;
        state.error_user = action.payload; 
        errorSweetAlert(action.payload)
      })
      
  },
});

export const { defaulCompanyDetail } = stepRegisterVendor.actions

export default stepRegisterVendor.reducer;
