import { Box } from '@mui/material'
import { Button, Stack } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import TokenCard from './components/TokenCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useEffect, useState } from 'react';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert';
import PurchaseLoading from '../purchase_request/components/PurchaseLoading';
import DataNotFound from '../../../components/DataNotFound';
import { getAllTokenCredentials } from './redux/tokenCredentialReducers';
import DialogAddToken from './components/DialogAddToken';
import { resetTokenCreation } from './redux/tokenCredentialSlice';

const PageTokenCredentials = () => {
    const dispatch = useDispatch()

    const { 
        tokens, loading_tokens,
        remove_token, loading_remove_token,
        create_token, loading_create_token,
    } = useSelector((state : RootState) => state.tokens)

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const params = {
            limit : 100,
            skip: 0,
        }
        dispatch(getAllTokenCredentials(params))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(create_token){
            setOpen(false)
            const params = {
                limit : 100,
                skip: 0,
            }
            dispatch(getAllTokenCredentials(params))
            setTimeout(() => {
                resetTokenCreation()
            }, 1500);
        }
        // eslint-disable-next-line
    }, [create_token]);

    return (
        <Stack m={2} >
            <LoaderBackdrop 
                loading={loading_create_token || loading_remove_token} 
            />
            <BreadCrumbs 
                isPage={false}
                current="Credential Token"
            />
            <Stack direction="row" justifyContent="space-between" py={1} >
                <Box>
                    <HeadlineTitle 
                        title='Credential Token'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
                <Box sx={{display: 'flex'}}>
                    <Box>
                        <Button  
                            variant="contained" 
                            color="primary"
                            size='small'
                            onClick={() => setOpen(true)}
                        >
                            Generate Token
                        </Button>
                    </Box>
                    <Box mr={2} />
                </Box> 
            </Stack>
            
            { loading_tokens ?
            <Stack mt={1}>
                {[1,2,3].map(idx => (
                    <PurchaseLoading key={idx} />
                ))}
            </Stack>
            :
            <div id="scrollableDivTemplate"  className="overhiddenflow">
                { tokens.length > 0 ? 
                <Box>
                    {tokens.map((item:any, idx:number) => (
                        <TokenCard item={item} key={idx} />
                    ))}
                </Box>
                :
                <Box mt={2} mx={0.2}>
                    <DataNotFound 
                        message="Token is not found."
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> } 
            <SnackBarAlert message={`Token Credential is successfully created!`} initialState={create_token} />
            <SnackBarAlert message={`Token Credential is successfully deleted!`} initialState={remove_token} />
            <DialogAddToken  open={open} setOpen={setOpen} />
        </Stack>
    )
}

export default PageTokenCredentials;
