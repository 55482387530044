import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Stack } from '@mui/material';
import info from '../../../../assets/img/icon/info.png';


const HoverInfoProduct = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const TooltipHTML : React.FC<any> = ({ stock, min_order }) => {
  return (
    <div>
      <HoverInfoProduct
        title={
          <React.Fragment>
            <Stack flexDirection="column" className='hover-info-product'>
              <h6>Stock left <strong>{stock}</strong></h6>
              <h6>Min. order <strong>{min_order}</strong></h6>
            </Stack>
          </React.Fragment>
        }
      >
        <img src={info} alt="plus" />
      </HoverInfoProduct>
    </div>
  );
}


export default TooltipHTML