import { Stack, Box, Button, Grid, Paper } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { addNewAddressCompany, getCompanyDetail, setDefaultAddressCompany, updateAddressCompany } from '../reducers/profilesReducers';
import AddressFormList from '../../../../components/AddressFormList';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateContactSnackBar } from '../profileSlice';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';
import ButtonCreate from '../../../../components/ButtonCreate';

const Address : React.FC<any> = ({ profile }) => {

    const { 
        update_address, create_address, 
        default_address, loading_update_address, 
        loading_create_address, loading_default_address 
    } = useSelector((state : RootState) => state.profile)

    const dispatch = useDispatch()

    const [valueAddress, setValueAddress] = useState({
        label : "",
        country : "Indonesia",
        error_country : false,
        province : "",
        error_province : false,
        city : "",
        error_city : false,
        subdistrict : "",
        error_subdistrict : false,
        village : "",
        error_village : false,
        postcode : "",
        error_postcode : false,
        street : "",
        isDefault : false,
    });

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });
    
    const [idAddress, setIdAddress] = useState("");

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
        setValueAddress({...valueAddress, 
            province : "",
            city : "",
            subdistrict : "",
            village : "",
            postcode : "",
            street : "",
            label : "",
            isDefault : false,
            country : "Indonesia",
            error_country : false,
        })
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })
    }
    
    const onClickSave = () => {
        const body = {
            id : idAddress,
            data : {
                "company": profile.addresses[0].company,
                "label": valueAddress.label,
                "country": valueAddress.country,
                "province": valueAddress.province,
                "city": valueAddress.city,
                "subdistrict": valueAddress.subdistrict,
                "village": valueAddress.village,
                "postcode": valueAddress.postcode,
                "street": valueAddress.street,
                "phone": "null",
                "details": "null",
                "isdefault": valueAddress.isDefault
            }
        }
        dispatch(updateAddressCompany(body))
    }

    const onClickSubmit = () => {
        const body = {
            "company": "null",
            "label": valueAddress.label,
            "country": valueAddress.country,
            "province": valueAddress.province,
            "city": valueAddress.city,
            "subdistrict": valueAddress.subdistrict,
            "village": valueAddress.village,
            "postcode": valueAddress.postcode,
            "street": valueAddress.street,
            "phone": "null",
            "details": "null",
            "isdefault": valueAddress.isDefault
        }
        dispatch(addNewAddressCompany(body))
    }

    useEffect(() => {
        if(update_address || create_address || default_address) {
            handleClose()
            dispatch(getCompanyDetail())
            setTimeout(() => {
                dispatch(updateContactSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [update_address, create_address, default_address]);

    return (
        <Stack mt={2} mb={5}>
            <Box>
                {checkValidation('CRECOMADD329470') &&
                <Stack sx={{mb:3}} flexDirection="row" justifyContent="flex-start">
                    {/* <Button variant='contained' size='small' onClick={() => handleOpen("Create New Address")}>Add New Address</Button> */}
                    <ButtonCreate 
                        name="Add New Address"
                        onClick={() => handleOpen("Create New Address")}
                    />
                </Stack>
                }
                { profile.addresses.length === 0 ? 
                <Box>
                    You dont have any addressed.
                </Box> :
                <Grid container spacing={1}>
                    { profile.addresses.map((itm:any, idx:any) => (
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={idx}>
                            <Paper 
                                sx={{ 
                                    p:2, borderRadius: 3, backgroundColor: '#fff',
                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                                    border: `1px solid ${!itm.isDefault ? '#fff' : '#43a047'}`,
                                    my:1
                                }} 
                                elevation={3}
                            >
                            <Box className='box-address'>
                                <Box>
                                    <Box className='address-label'>{itm.label}</Box>
                                    <Box className='address-name'>
                                        {itm.street}
                                    </Box>
                                    <Box className='address-name'> 
                                        {itm.village.toLowerCase()}, {itm.subdistrict.toLowerCase()}, {itm.city.toLowerCase()}, {itm.province}
                                    </Box>
                                    <Box className='address-name'>
                                        {itm.postcode}, {itm.country}
                                    </Box>
                                </Box>
                                {checkValidation('UPDCOMADD784210') &&
                                <Stack flexDirection="row" alignContent="center" flexWrap="wrap" rowGap={1}>
                                    <Box mr={1}>
                                        <Button 
                                            variant='contained' size='small' 
                                            color={!itm.isDefault ? "primary" : "success"}
                                            onClick={() => { 
                                                setIdAddress(itm._id)
                                                dispatch(setDefaultAddressCompany(itm._id))
                                            }}
                                            disabled={loading_default_address || itm.isDefault}
                                        >
                                            { loading_default_address && idAddress === itm._id ? "Loading" : itm.isDefault ? "Default" : "Set Default" }
                                        </Button>
                                    </Box>
                                    
                                    <Box>
                                        <Button 
                                            variant='outlined' size='small'
                                            onClick={() => {
                                                setIdAddress(itm._id)
                                                setValueAddress({...valueAddress, 
                                                    country : itm.country,
                                                    province : itm.province,
                                                    city : itm.city,
                                                    subdistrict : itm.subdistrict,
                                                    village : itm.village,
                                                    postcode : itm.postcode,
                                                    street : itm.street,
                                                    label : itm.label,
                                                    isDefault : itm.isDefault,
                                                })
                                                handleOpen("Edit Address")
                                            }}
                                        
                                        >Edit</Button>
                                    </Box>
                                </Stack>
                                }
                            </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid> }

                <Dialog open={open.open} maxWidth="md" fullWidth={true}  >
                    <DialogTitle>{open.name}</DialogTitle>
                    <DialogContent sx={{minHeight: 400}}>
                        <Stack>
                            <AddressFormList 
                                setValueAddress={setValueAddress}
                                valueAddres={valueAddress}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleClose} 
                            color="error" 
                            variant='outlined'
                        >Cancel</Button>
                        { open.name === "Edit Address" ? 
                        <Button 
                            onClick={onClickSave} 
                            variant="contained"
                            disabled={loading_update_address}
                        >
                            { loading_update_address ? "Loading" : "Save" }
                        </Button> :
                        <Button 
                            onClick={onClickSubmit} 
                            variant="contained"
                            disabled={loading_create_address}
                        >
                            { loading_create_address ? "Loading" : "Submit" }
                        </Button> }
                    </DialogActions>
                </Dialog>

                <SnackBarAlert message={`Success Create Address.`} initialState={create_address} />
                <SnackBarAlert message={`Success Update Address.`} initialState={update_address} />
                <SnackBarAlert message={`Success Update Default Address.`} initialState={default_address} />
            </Box>
        </Stack>
    )
}

export default Address
