import { Box, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import plus from '../../../../assets/img/icon/plus.png'
import minus from '../../../../assets/img/icon/minus.png'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getCalculateItem } from '../redux/productReducers';
import { clearErrorQty } from '../redux/productSlice';

const ItemPurchase : React.FC<any> = ({loading_item}) => {
    const dispatch = useDispatch()

    const { item, type_error, value_error } = useSelector((state: RootState) => state.products)

    const [focused, setFocused] = React.useState(false)
    
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const [quantity, setQuantity] = useState(0);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [interacted, setInteracted] = useState<boolean>(false); 

    function handleIncrease() {
        if(type_error === "" || type_error === "minimum") {
            setQuantity(quantity + 1); 
        }
        if (!interacted) {
            setInteracted(true);
          }
        resetTimer();
    }

    function handleDecrease() {
        if (quantity > 0) {
            if(type_error === "" || type_error === "stock") {
                setQuantity(quantity - 1);
            }
            if (!interacted) {
              setInteracted(true);
            }
            resetTimer();
          }
    }

    function handleInactive(count:number) {
        let product = {
            item : {
                ...item,
                quantity : count,
            },
            company : "null"
        }
        dispatch(getCalculateItem(product))
    }

    function resetTimer(): void {
        if (timer) {
          clearTimeout(timer);
        }
        if (interacted) {
          setTimer(setTimeout(() => handleInactive(quantity), 1000));
        }
      }

    useEffect(() => {
        resetTimer();
        return (): void => {
          if (timer) {
            clearTimeout(timer);
          }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);
 
    const onChange = (e : any) => {
        let value = parseFloat(e.target.value);
        setQuantity(value)
    }

    useEffect(() => {
        if(type_error !== "") {
            setTimeout(() => {
                setQuantity(parseFloat(value_error) || parseFloat(item.quantity))
                dispatch(clearErrorQty())
            }, 1000);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type_error]);

    useEffect(() => {
        if(!focused) {
            if(quantity === 0) {
                setQuantity(parseFloat(value_error) || parseFloat(item.quantity))
            } else if (quantity !== item.quantity) {
                let product = {
                    item : {
                        ...item,
                        quantity : quantity,
                    },
                    company : "null"
                }
                dispatch(getCalculateItem(product))
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused]);

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
            if(quantity === 0) {
                setQuantity(parseFloat(value_error) || parseFloat(item.quantity))
            } else if (quantity !== item.quantity) {
                let product = {
                    item : {
                        ...item,
                        quantity : quantity,
                    },
                    company : "null"
                }
                dispatch(getCalculateItem(product))
            } 
        }, 500); // set a delay of 500 milliseconds
    
        return () => {
          clearTimeout(debounceSearch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [quantity]);

    return (
    <Box>
        <Box className='item-product'>
            <Box 
                component="img"
                src={item.images[0]}
            />
            <Box className='right-product'>
                <Box className='title-product'> 
                    <h2>{item.name}</h2>
                </Box>
                { item.discount_type === "PERCENTAGE" && (item.discount !== 0 || item.extraDiscount !== 0) ?  
                <Box className='price'>
                    <Box className="discount-box">
                        <Box className='title-discount'>
                            <h2>Rp. {item.retail_price.toLocaleString()}</h2>
                        </Box>
                        { item.extraDiscountType === "AMOUNT" ? 
                        <Box sx={{display:'flex'}}>
                            <Box className='discount'>
                                <h3>{item.discount}%</h3> 
                            </Box> 
                            <Box mx={0.5}>&</Box>
                            <Box className='discount-amount'>
                                <h3>- Rp.{item.extraDiscount.toLocaleString()}</h3>
                            </Box> 
                        </Box> :
                        <Box className='discount'>
                            <h3>{item.discount + item.extraDiscount}%</h3> 
                        </Box> }
                    </Box>
                    { item.extraDiscountType === "AMOUNT" ? 
                    <Box className='title-price'>
                        <h1>Rp. {(item.retail_price - (item.retail_price * ((item.discount) / 100)) - item.extraDiscount).toLocaleString()}</h1> 
                    </Box> :
                    <Box className='title-price'>
                        <h1>Rp. {(item.retail_price - (item.retail_price * ((item.discount + item.extraDiscount) / 100))).toLocaleString()}</h1> 
                    </Box> }
                </Box> 
                :  item.discount_type === "AMOUNT" && (item.discount !== 0 || item.extraDiscount !== 0) ? 
                <Box className='price'>
                    <Box className="discount-box">
                        <Box className='title-discount'>
                            <h2>Rp.{item.retail_price.toLocaleString()}</h2>
                        </Box>
                        <Box className='discount-amount'>
                            <h3>- Rp.{(item.discount + (item.extraDiscount / item.quantity)).toLocaleString()}</h3>
                        </Box> 
                    </Box>
                    <Box className='title-price'>
                        <h1>Rp.{(item.retail_price - (item.discount + (item.extraDiscount / item.quantity))).toLocaleString()}</h1> 
                    </Box>
                </Box>  
                :  item.discount_type === "SPECIAL" ? 
                <Box className='price'>
                    <Box className='title-price'>
                        <h1>Rp.{item.discount.toLocaleString()}</h1> 
                    </Box>
                    <Box className="discount-box">
                        {/* <Box className='title-discount'>
                            <h2>Rp.{item.retail_price.toLocaleString()}</h2>
                        </Box> */}
                        <Box className='discount'>
                            <h3>SPECIAL</h3>
                        </Box> 
                    </Box>
                </Box> :
                <Box className='price'>
                    <Box className='title-price'>
                        <h1>Rp.{item.retail_price.toLocaleString()}</h1> 
                    </Box>
                </Box> }
                <Box className='tax'>
                    <p>+ PPN {item.tax.label}</p>
                </Box>
                { loading_item ? 
                <Box> 
                    <Skeleton height={40} width={180} />
                </Box>    
                :
                <Box className={type_error !== "" ? "quantity-btn-error" : "quantity-btn"}>
                    <Box onClick={() => handleDecrease()}>
                        <Box
                            component="img"
                            src={minus} 
                            alt="minus"
                        />
                    </Box>
                    <Box className="input-qty">
                        <input 
                            type="number" 
                            onFocus={onFocus} 
                            onBlur={onBlur} 
                            value={quantity} 
                            onChange={onChange} 
                        />
                    </Box>
                    <Box onClick={() => handleIncrease()}>
                        <Box
                            component="img"
                            src={plus} 
                            alt="plus"
                        />
                    </Box>
                    <Box ml={1} pb={0.5}>
                        <h4>{(item.unit && item.unit) || "Unit N/A"}</h4>
                    </Box>
                </Box> }
                <Box className='bottom-content'>
                    { type_error === "minimum"  ? 
                    <Box className='error-qty'>
                        <p>Min. order is <strong>{value_error}</strong>!</p>
                    </Box> : null }
                    { type_error === "stock"  ? 
                    <Box className='error-qty'>
                        <p>Stock left is <strong>{value_error}</strong>!</p>
                    </Box> : null }
                </Box>
                <Box className='bottom-content'>
                    <Box className="div-one">
                        <Box className='total-prices'>
                            <h2>Rp.{(item.total - item.total_tax).toLocaleString()}</h2>
                        </Box>
                        <Box className='line-divide' />
                        <Box className='total-tax'>
                            { item.total_tax === 0 ?
                            <h2>+ PPN Rp.0</h2> :
                            <h2>+ PPN Rp.{item.total_tax.toLocaleString()}</h2>
                        }
                        </Box>
                    </Box>
                    <Box className='div-second'>
                        <Box className='text-info'>
                            <h2>Down Payment {item.down_payment}%</h2>
                        </Box>
                        <Box className='text-info'> 
                            <h2>Payment Term {item.payment_term} days</h2>
                        </Box>
                    </Box>
                    
                </Box>
                
            </Box>
        </Box>
    </Box>
    )
}

export default ItemPurchase