import { useEffect, useState } from 'react';
import { Box, Stack, Grid, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import SalesValues from './components/SalesValue';
import TotalOrders from './components/TotalOrders';
import { getChartsReports } from './reducers/chartsReducers';
import moment from 'moment';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import CardTiles from './components/CardTiles';
import payableimg from '../../../../assets/img/dashboard/payable.png'
import CardPurchase from './components/CardPurchase';
import SelectDate from '../../../../components/SelectDate';



const ChartsPage = () => {

    const dispatch = useDispatch()

    const { data, loading, charts_purchase, charts_orders } = useSelector((state : RootState) => state.chart_report)

    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);

    useEffect(() => {
        let range = {
            from : moment(rangeFrom).format('YYYY-MM-DD'),
            to : moment(rangeTo).format('YYYY-MM-DD'),
            topic : rangeTopic
        }
        dispatch(getChartsReports(range))
        // eslint-disable-next-line
    }, [rangeTo, rangeFrom]);

    return (
        <Box m={2}>
            <BreadCrumbs
                isPage={false}
                current="Charts Report"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center">
                <Box>
                    <HeadlineTitle title='Charts Report' variant="h6" bold="bold" />
                </Box>
                <Box className='filter-date-paper'>
                    <SelectDate 
                        setRangeTopic={setRangeTopic}
                        setRangeFrom={setRangeFrom}
                        setRangeTo={setRangeTo}
                        name="Filter Date"
                        type='date'
                    />
                </Box>
            </Stack>

            <Grid pt={0.5} container spacing={2}>
                <Grid item xs={12} md={6} lg={3} sm={6}>
                    <Box className='card-three-tiles'>
                        <CardPurchase 
                            label="Purchase Today"
                            value={(data.totalSummaryOrderTodays && data.totalSummaryOrderTodays.total) || 0}
                            loading={loading}
                            currency={true}
                            background="#fff"
                            src={payableimg}
                        />
                        <CardPurchase 
                            label="Purchase This Month"
                            value={(data.totalSummaryOrderMonthly &&  data.totalSummaryOrderMonthly.total) || 0}
                            loading={loading}
                            currency={true}
                            background="#fff"
                            src={payableimg}
                        />
                        <CardPurchase 
                            label="Purchase This Year"
                            value={(data.totalSummaryOrderYearly &&  data.totalSummaryOrderYearly.total) || 0}
                            loading={loading}
                            currency={true}
                            background="#fff"
                            src={payableimg}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={9} sm={6}>
                    <Box className='card-six-tiles'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Total PO's"
                                value={(data.totalOrders && data.totalOrders.total) || 0}
                                loading={loading}
                                count={(data.totalOrders && data.totalOrders.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Pending Down Payment"
                                value={(data.downPayment && data.downPayment.totalAmount) || 0}
                                loading={loading}
                                count={(data.downPayment && data.downPayment.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Order Rejection"
                                value={(data.orderReject && data.orderReject.grandTotal) || 0}
                                loading={loading}
                                count={(data.orderReject && data.orderReject.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Items Bought"
                                value={(data.productSummary && data.productSummary.grandTotal) || 0}
                                loading={loading}
                                count={(data.productSummary && data.productSummary.qty) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Account Payable"
                                value={(data.accountPayable && data.accountPayable.totalAmount) || 0}
                                loading={loading}
                                count={(data.accountPayable && data.accountPayable.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Total Savings Orders"
                                value={(data.totalSavingOrder && data.totalSavingOrder.total) || 0}
                                loading={loading}
                                count={(data.totalSavingOrder && data.totalSavingOrder.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                    </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={4}>
                <Box className='card-lines-paper'>
                    <h2>Purchase Value</h2>
                    <SalesValues 
                        charts_purchase={charts_purchase}
                        loading={loading}
                    /> 
                </Box>
            </Box>
            <Box mt={4}>
                <Box className='card-lines-paper'>
                    <h2>Total Orders</h2>
                    <TotalOrders 
                        charts_orders={charts_orders}
                        loading={loading}
                    />
                </Box>
            </Box>


            <Grid pt={4} container spacing={2}>
                <Grid mb={2} item xs={12} md={12} lg={12} sm={12}>
                    <Box className='card-brand-sales'>
                        <h2>Top 10 Most Purchase Product</h2>
                        <Box className='table-card' mt={2}>
                            <TableContainer >
                                <Table className='table' aria-label="simple table">
                                    <TableHead sx={{backgroundColor: '#43a047'}}>
                                        <TableRow>
                                            <TableCell align="center" sx={{color:'#fff'}}>PRODUCT NAME</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>QUANTITY</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>TOTAL IDR</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    { loading ? 
                                    <LoadingTable />
                                    :
                                    <TableBody>
                                        { (data.productList && data.productList.length && data.productList.map((item:any, index:any) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{item.name || "N/A"}</TableCell>
                                                <TableCell align="center">{item.qty || "N/A"}</TableCell>
                                                <TableCell align="center">Rp. {item.price.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                        ))) || 
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>No Data</TableCell>
                                        </TableRow>
                                        }
                                    </TableBody> }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Box className='card-seller-sales'>
                        <h2>Seller by Sales Value</h2>
                        <Box className='table-card' mt={2}>
                            <TableContainer >
                                <Table className='table' aria-label="simple table">
                                    <TableHead sx={{backgroundColor: '#43a047'}}>
                                        <TableRow>
                                            <TableCell align="center" sx={{color:'#fff'}}>SELLER</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>TOTAL IDR</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    { loading ? 
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        { (data.totalCompanies && data.totalCompanies.length && data.totalCompanies.map((item:any, index:any) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{textTransform: 'capitalize'}}>{item.name || "N/A"}</TableCell>
                                                <TableCell align="center">Rp. {item.total.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                        ))) || 
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>No Data</TableCell>
                                        </TableRow>
                                        }
                                    </TableBody> }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Box className='card-brand-sales'>
                        <h2>Purchase By Category</h2>
                        <Box className='table-card' mt={2}>
                            <TableContainer >
                                <Table className='table' aria-label="simple table">
                                    <TableHead sx={{backgroundColor: '#43a047'}}>
                                        <TableRow>
                                            <TableCell align="center" sx={{color:'#fff'}}>CATEGORY</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>TOTAL IDR</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    { loading ? 
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                            <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        { (data.productCategoryList && data.productCategoryList.length && data.productCategoryList.map((item:any, index:any) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{textTransform: 'capitalize'}}>{item._id || "N/A"}</TableCell>
                                                <TableCell align="center">Rp. {item.grandTotal.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                        ))) || 
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>No Data</TableCell>
                                        </TableRow>
                                        }
                                    </TableBody> }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
               
            </Grid>

        </Box>
    )
}

const LoadingTable = () => {
    return (
        <TableBody>
            <TableRow>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
            </TableRow>
        </TableBody>
    )
}

export default ChartsPage
