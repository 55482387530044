import React from 'react'
import imagestore from '../../../../assets/img/placeholder.png'
import bannerstore from '../../../../assets/img/banner.jpg'
import { Box, Stack, Chip } from '@mui/material';

interface IBannerStore {
    profile: any;
}

const VendorBanner : React.FC<IBannerStore> = ({
    profile,
}) => {

    return (
    <div className='company-banner'>
        <div className='img-banner'>
            <img  
                src={profile.banner || bannerstore}
                alt="banner" 
            /> 
        </div>
        <Stack flexDirection="row" justifyContent="space-between">
            <Stack flexDirection="row" >
                <div className='img-store'>
                    <img 
                        alt="store"
                        src={profile.logo || imagestore }
                    />  
                </div>
                <Box className='side-name'>
                    <h2>{profile.legalName}</h2>
                    <h4>{profile.name}</h4>
                    <Stack direction="row" gap={1} sx={{pt:1}} justifyContent="flex-start" flexWrap="wrap">
                        <Chip label={`Category : ${profile.category.name}`} size='small'/>
                        <Chip label={`PKP : ${profile.isPKP ? "Yes" : 'No'}`} size='small'/>
                    </Stack>
                </Box> 
            </Stack>
        </Stack>
    </div>
  )
}

export default VendorBanner