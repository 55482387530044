import React, { useEffect, useState } from 'react'
import { Button, Stack, Grid, Box, TextField } from '@mui/material'
import Select from 'react-select' 
import HoverInfo from '../../../../components/HoverInfo'
import { useDispatch, useSelector } from 'react-redux'
import { getSettingCustomNumber, updateSettingCustomNumber } from '../reducers/profilesReducers'
import { RootState } from '../../../../app/store'
import LoaderBackdrop from '../../../../components/LoaderBackdrop'
import SnackBarAlert from '../../../../components/SnackBarAlert'
import { updateAddressSnackBar } from '../profileSlice'
import swal from 'sweetalert'


const CustomNumbering : React.FC<any> = ({profile}) => {

    const dispatch = useDispatch()
    const { 
        loading_setting_custom_number, setting_custom_number, 
        loading_update_setting_custom_number, update_setting_custom_number 
    } = useSelector((state : RootState) => state.profile)


    const [valueForm, setValueForm] = useState<any>({
        counterNumberOfDigits : "4",
        counterMaxValue : 9999,
        counterResetValue : "1",
        startingNumber : "0001",
        counterReset : "yearly",
        result : ""
    });

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);

    const validateInput = (value:any) => {
        const pattern = new RegExp(/^(?:[^{}]*{{(?:YYYY|YY|MX|WW|DD|MM|COUNTER)}})+[^{}]*$/);
        if (pattern.test(value)) {
            setError(false)
        } else {
            setError(true)
        }
    };

    const onHandlechange = (event:any) => {
        const { name, value } = event.target;
        setValueForm({
            ...valueForm,
            [name] : value
        })
    }

    const handleChange = (event:any) => {
        const value = event.target.value;
        setInputValue(value);
        validateInput(value);
    };


    const onClickSave = (e:any) => {
        e.preventDefault()
        let body = {
            "company": profile._id,
            "format": inputValue,
            "counterNumberOfDigits": parseInt(valueForm.counterNumberOfDigits),
            "counterResetValue": parseInt(valueForm.counterResetValue),
            "counterMaxValue": valueForm.counterMaxValue,
            "startingNumber": valueForm.startingNumber,
            "counterReset": valueForm.counterReset,
        }
        if(!error) {
            if(parseInt(valueForm.counterNumberOfDigits) !== valueForm.startingNumber.length) {
                swal('Error', 'Counter starting value must be the same with digits of counter number!', 'error')
                return
            } else if (parseInt(valueForm.counterNumberOfDigits) !== valueForm.counterMaxValue.toString().length) {
                swal('Error', 'Counter max value must be the same with digits of counter number!', 'error')
                return
            } else {
                dispatch(updateSettingCustomNumber(body))
            }
        }
    }

    function fetchData() {
        if(profile._id) {
            dispatch(getSettingCustomNumber(profile._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(update_setting_custom_number) {
            setTimeout(() => {
                fetchData()
                dispatch(updateAddressSnackBar())
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update_setting_custom_number]);


    useEffect(() => {
        if(!isEmpty(setting_custom_number)) {
            setValueForm({
                counterNumberOfDigits : setting_custom_number.counterNumberOfDigits,
                counterMaxValue : setting_custom_number.counterMaxValue,
                counterResetValue : setting_custom_number.counterResetValue,
                startingNumber : setting_custom_number.startingNumber,
                counterReset : setting_custom_number.counterReset,
                result : setting_custom_number.result
            })
            setInputValue(setting_custom_number.format)
        }
    }, [setting_custom_number]);

    // create a function check object is not empty
    const isEmpty = (obj:any) => {
        return Object.keys(obj).length === 0;
    }


    const format = [
        {
            label : "Yearly",
            value : "yearly"
        },
        {
            label : "Monthly",
            value : "monthly"
        },
        {
            label : "Weekly",
            value : "weekly"
        },
    ]

    let brace = "{{...}}"

    return (
    <Stack minHeight={250} width="100%" >
        <LoaderBackdrop loading={loading_update_setting_custom_number || loading_setting_custom_number} />
        <form onSubmit={onClickSave}>
        <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} xs={12}>
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                    <h4>Format</h4>
                    <HoverInfo 
                        component={
                            <Box>
                               For dynamic formatting one can add the code within double curly braces {brace} : <br/>
                                YY, YYYY: year in (2, 4 digits) <br/>
                                MM: month in 2 digits arabic (1,2,3,4, …) <br/>
                                MX: month in roman (VIII = 8, August) <br/>
                                WW: week number in 2 digits <br/>
                                DD: day of the month in 2 digits <br/>
                                COUNTER: current increment counter
                            </Box>
                        }
                    />
                </Stack>
                <Box pt={1}>
                    <TextField 
                        size='small'
                        placeholder='Ex: BOI-{{YYYY}}{{MM}}-{{COUNTER}}'
                        fullWidth
                        onChange={handleChange}
                        value={inputValue}
                        required
                    />
                    { error ? 
                    <Box pt={0.5} color="red" fontSize={13}>
                        <p>Format is wrong!</p>
                    </Box> : null }
                </Box> 
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
                <Box><h4>Counter number of digits</h4></Box>
                <Box pt={1.3}>
                    <TextField 
                        size='small'
                        placeholder='5'
                        fullWidth
                        type='number'
                        name="counterNumberOfDigits"
                        value={valueForm.counterNumberOfDigits}
                        onChange={onHandlechange}
                        required
                    />
                </Box> 
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                    <h4>Counter starting value</h4>
                    <HoverInfo 
                        component={
                            <Box>
                                This is the value at which the counter should first start. This is useful to match the current numbering value to allow for continuity.
                            </Box>
                        }
                    />
                </Stack>
                <Box pt={1}>
                    <TextField 
                        size='small'
                        placeholder='00001'
                        type='number'
                        fullWidth
                        name="startingNumber"
                        value={valueForm.startingNumber}
                        onChange={onHandlechange}
                        required
                    />
                </Box> 
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
                <Box><h4>Counter reset</h4></Box>
                <Box pt={1.3}>
                    <Select
                        placeholder="Select Reset"
                        value={{label: valueForm.counterReset, value: valueForm.counterReset}}
                        isSearchable={true} 
                        options={format}
                        onChange={(e) => setValueForm({...valueForm, counterReset: e?.value})}
                        id="select-style-cat"
                        required
                    /> 
                </Box> 
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
                <Box><h4>Counter reset value</h4></Box>
                <Box pt={1.3}>
                    <TextField 
                        size='small'
                        placeholder='1'
                        fullWidth
                        type='number'
                        name="counterResetValue"
                        value={valueForm.counterResetValue}
                        onChange={onHandlechange}
                        required
                    />
                </Box> 
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                    <h4>Counter max value</h4>
                    <HoverInfo 
                        component={
                            <Box>
                                The counter wil also reset when reaching this value.
                            </Box>
                        }
                    />
                </Stack>
                <Box pt={1}>
                    <TextField 
                        size='small'
                        placeholder='9999'
                        type='number'
                        fullWidth
                        name="counterMaxValue"
                        value={valueForm.counterMaxValue}
                        onChange={onHandlechange}
                        required
                    />
                </Box> 
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12}>
                <Box>
                    <h4>Output sample of the next PR/PO</h4>
                    <Box pt={1}>
                        <h2>{valueForm.result || "-"}</h2>
                    </Box>
                </Box>
            </Grid>
        </Grid>

        {/* { checkValidation('UPSETPLCOM6424352') &&  */}
        <Stack 
            sx={{my:3}} 
            flexDirection="row" 
            justifyContent="flex-end"
        >
            <Button 
                type='submit'
                disabled={loading_setting_custom_number} 
                variant='contained'
            >
                Save
            </Button>
        </Stack> 
        </form>
        <SnackBarAlert message={`Success saved custom numbering setting.`} initialState={update_setting_custom_number} />
    </Stack>
  )
}

export default CustomNumbering