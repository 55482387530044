import { Box } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { getConversationRoom } from '../features_app/dashboard/chat/redux/chatReducers';
import { addConvesationLists, clearConversationRoom, initConversationActive, onFloatingChat } from '../features_app/dashboard/chat/redux/chatSlices';


const ChatButton : React.FC<any> = ({ vendor_detail }) => {
    const dispatch = useDispatch()
    const { conversation_lists, new_message, list_fulfillment, loading_list_fulfillment } = useSelector((state:RootState) => state.chats);

    const onClickStartChat = (vendor:any) => {
        let data = {
            company_id: vendor._id,
            company_name: vendor.legalName,
            company_logo : vendor.logo,
            last_message : {
                text: ""
            }
        }
        let conversation_exist = [...conversation_lists]
        let exist = conversation_exist.find((item:any) => item.company_id === vendor._id)
        dispatch(onFloatingChat(true))
        dispatch(initConversationActive(data))
        if(!exist) {
            dispatch(clearConversationRoom())
            dispatch(addConvesationLists(data))
        } else {
            let payload = { company_id : vendor._id, new_message }
            dispatch(getConversationRoom(payload))

        } 
    }

    const checkFullfilmentExist = (company:any, lists:any) => {
        const { _id } = company
        let exist = lists.find((item:any) => item === _id)
        if(exist) {
            return true
        } else {
            return false
        }
    }

    return (
    <Box>
        { !loading_list_fulfillment && checkFullfilmentExist(vendor_detail, list_fulfillment) ?
        (
        <Box 
            className="btn-chat" 
            onClick={() => onClickStartChat(vendor_detail)}
        >
            <p>Chat Seller</p>
        </Box>
        ) : (
        <Box 
            className="btn-chat-disabled" 
        >
            <p>Chat Unavailable for this Vendor</p>
        </Box>)}
    </Box>
  )
}

export default ChatButton