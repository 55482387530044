import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }


export const getStatusCount = createAsyncThunk(
    'status-orders/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/order/count`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);

export const getStatusPackageCount = createAsyncThunk(
    'status-package/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/order/countStatusPackage`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getDetailStatusPackageCount = createAsyncThunk(
    'status-package-detail/get',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/order/${id}/countPackage`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getOrdersData = createAsyncThunk(
    'orders-data/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/orders`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getOrdersDataLoadMore = createAsyncThunk(
    'orders-data-load-more/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/orders`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getOrderDetail = createAsyncThunk(
    'detail-order/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase/order/${params.id}/${params.status}/details`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const submitPickFulfillment = createAsyncThunk(
    'pick-fulfillment/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/fulfillment/inbound/Pick/${params.id}`, params.body, {
                headers
            })
            if(response.data) { 
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const submitProofPayment = createAsyncThunk(
    'proof-payment/update',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/proofPayment/${params.id}`, params.body, {
                headers
            })
            if(response.data) { 
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const printDownloadPDF = createAsyncThunk(
    'download-pdf/print',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/pdf/${params.type}`, params.body, {
                headers
            })
            if(response.data) { 
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const approvalItemChange = createAsyncThunk(
    'accept-reject-item/update',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/order/${params.type}`, params.body, {
                headers
            })
            if(response.data) { 
                return {data:true, type : params.type}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);




export const createGoodReceiptNote = createAsyncThunk(
    'submit-grn/post',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/received`, body, {
                headers
            })
            if(response.data) { 
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);




export const sendPurchaseToEmail = createAsyncThunk(
    'send-po-email/post',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/pdf/send-email`, body, {
                headers
            })
            if(response.data) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);