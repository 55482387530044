import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Box, InputAdornment, Stack, TextField } from '@mui/material';
import ButtonCreate from '../../../../components/ButtonCreate';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getAllCustomRoles } from '../../roles/redux/rolesReducers';
import { userCredentials } from '../../../../utilities/config';
import { createNewTeamMember, updateTeamMember } from '../redux/teamsReducers';
import StarRequired from '../../../../components/StarRequired';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required") 
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password Must Contain 8 Characters and at least have one Special Case Character, One Lowercase and One Number"
      ),
    fullname: yup.string()
      .required("Full Name is required"),
  })
  .required();


const DialogAddTeam : React.FC<any> = ({ 
  open, setOpen, 
  type, detail
 }) => {

    const dispatch = useDispatch()
    const { data, loading } = useSelector((state : RootState) => state.roles)
    const { create } = useSelector((state: RootState) => state.users_team)

    const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors }
    } = useForm<any>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema)
    });
    
    const [userId, setUserId] = useState("");
    const [roleName, setRoleName] = useState("");
    const [optionsRoles,setOptionsRoles] = useState<any[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<any>([]);
    const [errorSelectRoles, setErrorSelectRoles] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    

    const onSubmit = (data: any): void => {
      if(selectedRoles.length === 0) {
        setErrorSelectRoles(true)
      } else {
        let body = {
          email: data.email,
          password: data.password,
          name: data.fullname,
          username: data.fullname,
          roleId: selectedRoles.value,
          companyId : userCredentials.company_id,
          platform : "BUYER"
        }
        if(type === "Edit"){
          let body_edit = {
            id : userId,
            body : {
              name: data.fullname,
              username: data.fullname,
              roleId: selectedRoles.value,
              companyId : userCredentials.company_id,
              platform : "BUYER"
            }
          }
          dispatch(updateTeamMember(body_edit))
        } else {
          dispatch(createNewTeamMember(body))
        }
      }
    } 
  
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
    };
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const handleChangeRoles = (value: any) : void => {
        setSelectedRoles(value)
        setErrorSelectRoles(false)
    }

    function handleClose () {
      setOpen(false);
      reset()
      setSelectedRoles([])
    };

    useEffect(() => {
      if(type === "Edit"){
        setValue("fullname", detail.profile.name);
        setValue("email", detail.profile.email);
        setValue("password", "Aris12345!!");
        setSelectedRoles({ value: detail.hasRole.role._id, label: detail.hasRole.role.name })
        setRoleName(detail.hasRole.role.name)
        setUserId(detail._id)
      } else {
        setValue("fullname", "");
        setValue("email", "");
        setValue("password", "");
        reset()
        setSelectedRoles([])
        setRoleName("")
        setUserId("")
      }
      // eslint-disable-next-line
    }, [type, open]);

    useEffect(() => {
      if(data.length){
        const options = data.filter(((itm:any) => itm.name !== "Owner")).map((item : any) => {
          return {
            value: item._id,
            label: item.name
          }
        })
        setOptionsRoles(options)
      }
      // eslint-disable-next-line
    }, [data]);
  
    useEffect(() => {
      if(create) {
          reset()
      }
      // eslint-disable-next-line
  }, [create]);

    useEffect(() => {
      dispatch(getAllCustomRoles())
      // eslint-disable-next-line
    }, []);
    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{type === "Add" ? "Add New" : "Edit"} Team Member</Box>
           </Stack>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent dividers >
          <Box className="create-team">
            <Box className='form-auth' >
                <Box pt={1}><h2>Full Name <StarRequired/></h2></Box>
                <TextField
                  error={!!errors.fullname}
                  helperText={errors.fullname && errors.fullname.message}
                  {...register('fullname', { required: true })}
                  margin="dense"
                  fullWidth
                  id="fullname"
                  placeholder="John Doe"
                  name="fullname"
                  size='small'
                  inputProps={{
                    autocomplete: 'name',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                { type === "Edit" ? null : 
                <>
                <Box pt={2}><h2>Email Address <StarRequired/></h2></Box>
                <TextField
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  {...register('email', { required: true})}
                  margin="dense"
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name="email"
                  size='small'
                  disabled={type === "Edit" ? true : false}
                  inputProps={{
                    autocomplete: 'mail',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                <Box pt={2}><h2>Password <StarRequired/></h2></Box>
                <TextField
                  error={!!errors.password}
                  helperText={errors.password && errors.password.message}
                  {...register('password', { required: true })}
                  margin="dense"
                  fullWidth
                  name="password"
                  placeholder="********"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  size='small'
                  disabled={type === "Edit" ? true : false}
                  inputProps={{
                    autocomplete: 'pass',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  InputProps={{
                    endAdornment : (
                      <InputAdornment position="end">
                        { type === "Edit" ? null : 
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton> }
                      </InputAdornment>
                    )
                  }}
                />
                </> }
                <Box pt={2} pb={1}><h2>Role <StarRequired/></h2></Box>
                { loading ?
                <Select
                    placeholder="Loading.."
                    id="select-roles"
                />
                :
                <Select
                    placeholder="Select Role"
                    value={selectedRoles}
                    isSearchable={false}
                    options={optionsRoles && optionsRoles}
                    onChange={handleChangeRoles}
                    isDisabled={roleName === "Owner" ? true : false}
                    id="select-roles"
                /> }
                { errorSelectRoles && <Box pt={1} color="red" fontSize={14}>Role is required</Box> }
            </Box> 
          </Box> 
        </DialogContent>
        <DialogActions>
          <ButtonCreate 
            name={type === "Add" ? "Create Team Member" : "Save Changes"}
            type="submit"
            />
        </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default DialogAddTeam;