import { Box, Stack,  } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import {  useState, useEffect } from 'react'
import { useDispatch, } from 'react-redux'
import HeadlineTitle from '../../../components/HeadlineTitle';
import PreferredSearch from './components/PreferredSearch';
import CardPreferred from './components/CardPreferred';
import ButtonCreate from '../../../components/ButtonCreate';
import DialogCreation from './components/DialogCreation';
import { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import { getPreferredListsData, getPreferredListsDataLoadMore, getSearchCompanyByCode } from './redux/vendorListsReducer';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from 'sweetalert';
import LoaderProgress from '../../../components/LoaderProgress';
import { Skeleton } from '@mui/lab';
import Button from '@mui/material/Button';
import DataNotFound from '../../../components/DataNotFound';
import { getVendorFulfillment } from '../chat/redux/chatReducers';
import { checkValidation } from '../../../utilities/userFeaturesValidation';


function VendorLists() {

    const { 
        data, total_data, loading, loading_search, search_buyer, 
        loading_create, loading_update,
        create, update, loading_bulk_upload, bulk_upload
    } = useSelector((state : RootState) => state.preferred)

    const dispatch = useDispatch()

    const [valueSearchCompany, setValueSearchCompany] = useState("");
    const [valueSearch, setValueSearch] = useState("");
    const [limit,setSkip ] = useState(10);
    const [skip, ] = useState(0);
    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [hasMore,setHasMore] = useState(true);

    const [dialogCreation, setDialogCreation] = useState(false);
    const [dialogName, setDialogName] = useState("");
    const [activeStatus, setActiveStatus] = useState("New");

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch
        }
         
        const newdata : any = await dispatch(getPreferredListsDataLoadMore(params))
        if(newdata.type === "'preferred-lists-loadmore/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    useEffect(() => {
        setDataInfinite(data)
        if(data.length >= total_data) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [data, total_data]);

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivPreferred").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const params = {
            limit,
            skip : 0,
            search : valueSearch
        }
        dispatch(getPreferredListsData(params))
        // eslint-disable-next-line
    }, [valueSearch]);
    
    useEffect(() => {
        const search = {
            limit : 10,
            skip : 0,
            'filterTopic[]' : 'platform',
            'filterValue[]' : 'VENDOR',
            'search' : valueSearchCompany
        }
        if(valueSearchCompany !== "" && valueSearchCompany !== undefined) {
            dispatch(getSearchCompanyByCode(search))
        }
        // eslint-disable-next-line
    }, [valueSearchCompany]);

    useEffect(() => {
        if(create || update || bulk_upload) {
            const params = {
                limit,
                skip : 0,
                search : valueSearch
            }
            dispatch(getPreferredListsData(params))
        }
        // eslint-disable-next-line
    }, [create, update, bulk_upload]);

    useEffect(() => {
        dispatch(getVendorFulfillment())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_create || loading_update || loading_bulk_upload } />
            <BreadCrumbs 
                isPage={false}
                current="Preferred Vendor List"
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={1} flexWrap="wrap">
                <Box>
                    <HeadlineTitle 
                        title='Preferred Vendor List'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
                {checkValidation('CREPREVENLIS480427') && 
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <ButtonCreate 
                        name="Request Add Vendor"
                        onClick={() => {
                            setDialogName("Request Add New Preferred Vendor")
                            setDialogCreation(true)
                        }}
                    />
                </Stack> }
            </Stack>

            <PreferredSearch 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
            />

            { loading ?
            <Stack mt={1} className="item-card-preferred">
                {[1,2,3].map(idx => (
                    <Box key={idx} my={0.5}>
                        <Skeleton variant="rectangular" width={"100%"} height={250} sx={{borderRadius:2}} />
                    </Box>
                ))}
            </Stack>
            :
            <div id="scrollableDivPreferred"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDivFulfillment"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box> 
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((item:any, idx:number) => (
                            <Box key={idx}>
                                <CardPreferred item={item} />
                            </Box>
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> :
                <Box mt={2} mx={0.2}>
                    <DataNotFound 
                        message="Preferred is not found." 
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> }
            
            <DialogCreation  
                open={dialogCreation} 
                setOpen={setDialogCreation} 
                name={dialogName} 
                valueSearch={valueSearchCompany}
                setValueSearch={setValueSearchCompany}
                search_buyer={search_buyer}
                loading_search={loading_search}
            />
        </Box>
    )
}

export default VendorLists
