import { Box } from "@mui/material"
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import DialogNote from "./DialogNote"
import { approvalItemChange } from "../redux/ordersReducers"
import { checkValidation } from "../../../../utilities/userFeaturesValidation"

const ItemCard : React.FC<any> = ({ele, idx, update, lastStatus, itemsReceived, setItemsReceived}) => {
    const dispatch = useDispatch()

    const [openNote, setOpenNote] = useState(false);
    const [note, setNote] = useState("");
    const [received, setReceived] = useState<any>(0);
    const [valueNote, setValueNote] = useState("");

    const [focused, setFocused] = React.useState(false)
    const [focusedNote, setFocusedNote] = React.useState(false)

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)
    const onFocusNote = () => setFocusedNote(true)
    const onBlurNote = () => setFocusedNote(false)

    const onChange = (e : any) => {
        let value = parseFloat(e || 0); 
        setReceived(value)
    } 

    useEffect(() => {
        if(lastStatus === "Delivered") {
            setReceived(ele.received)
        } else {
            setReceived(ele.quantity)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(update) {
            setValueNote("")
            setReceived(ele.quantity)
        }
        // eslint-disable-next-line
    }, [update]);
    
    useEffect(() => {
        if(received !== "0") {
            if(!focused) {
                const newData = itemsReceived.map((item : any, index : number) => {
                    if(item._id === ele._id) {
                        return {
                            ...item,
                            received : parseFloat(received)
                        }
                    } else {
                        return item
                    } 
                })
                setItemsReceived(newData)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, received]);

    useEffect(() => {
        if(!focusedNote) {
            const newData = itemsReceived.map((item : any, index : number) => {
                if(item._id === ele._id) {
                    return {
                        ...item,
                        note : valueNote
                    }
                } else {
                    return item
                }
            })
            setItemsReceived(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedNote, valueNote]);

    // sum total price percentage change inbound
    function totalPercentageInbound(params:any) {
        if(params.extraDiscountType === "AMOUNT") {
            return (params.inbound.retail_price - ((params.discount / 100) * params.inbound.retail_price + params.extraDiscount)) * params.inbound.quantity
        } else {
            return (params.inbound.retail_price - (((params.discount + params.extraDiscount) / 100) * params.inbound.retail_price)) * params.inbound.quantity
        }
    }

    // sum total price amount change inbound
    function totalAmountInbound(params:any) {
        return ((params.inbound.retail_price - (params.discount + params.extraDiscount )) * params.inbound.quantity) || 0
    }

    // sum total price special change inbound
    function totalSpecialInbound(params:any) {
        return (params.inbound.discount * params.inbound.quantity) || 0
    }


    function checkLastObject(arrayOfObjects:any) {
        const lastObjectIndex = arrayOfObjects.length - 1;
        const lastObject = arrayOfObjects[lastObjectIndex];
        return lastObject;
    }

    function checkStatusExists(status:any, arrayOfObjects:any) {
        if(arrayOfObjects.length && checkLastObject(arrayOfObjects).name === status) {
            return true
        }
        return false
    }


    return (
        <Box 
            className={`
                item-product 
                ${update && 'active-update'} 
                ${checkStatusExists("Inbound Item", ele.statuses) && 'approval'}
                ${lastStatus === "Cancel" && 'canceled'}
            `} 
            key={idx}
        >
            <Box 
                component="img"
                src={ele.images[0]}
            />
            <Box className='right-product'>
                <Box className='title-product'> 
                    <h2>{ele.name}</h2>
                </Box>
                <Box>
                    { ele.discount_type === "PERCENTAGE" && ele.discount !== 0  ?  
                    <Box className={`price ${ele.inbound && ele.inbound.retail_price !== ele.retail_price && 'inbound'}`}>
                        { ele.extraDiscountType === "AMOUNT" ? 
                        <Box sx={{display:'flex'}}>
                            <Box className='discount'>
                                <h3>{ele.discount}%</h3> 
                            </Box> 
                            <Box mx={0.5}>&</Box>
                            <Box className='discount-amount'>
                                <h3>- Rp.{ele.extraDiscount.toLocaleString()}</h3>
                            </Box> 
                        </Box> :
                        <Box className='discount'>
                            <h3>{ele.discount + ele.extraDiscount}%</h3> 
                        </Box> }
                        <Box className='title-discount'>
                            <h2>Rp. {ele.retail_price.toLocaleString() || "N/A"}</h2>
                        </Box> 
                        { ele.extraDiscountType === "AMOUNT" ? 
                        <Box className='title-price'>
                            <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount) / 100)) - ele.extraDiscount).toLocaleString()}</h1> 
                        </Box> :
                        <Box className='title-price'>
                            <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount + ele.extraDiscount) / 100))).toLocaleString()}</h1> 
                        </Box> }
                    </Box> 
                    :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                    <Box className={`price ${ele.inbound && ele.inbound.retail_price !== ele.retail_price && 'inbound'}`}>
                        <Box className='discount-amount'>
                            <h3>- Rp.{(ele.discount + ele.extraDiscount ).toLocaleString() || "N/A"}</h3>
                        </Box> 
                        <Box className='title-discount'>
                            <h2>Rp.{ele.retail_price.toLocaleString() || "N/A"}</h2> 
                        </Box> 
                        <Box className='title-price'>
                            <h1>Rp.{(ele.retail_price - (ele.discount + ele.extraDiscount )).toLocaleString() || "N/A"}</h1> 
                        </Box>
                    </Box> 
                    :  ele.discount_type === "SPECIAL" ? 
                    <Box className={`price ${ele.inbound && ele.inbound.discount !== ele.discount && 'inbound'}`}>
                        <Box className='discount'>
                            <h3>SPECIAL</h3>
                        </Box> 
                        <Box ml={1} className='title-price'>
                            <h1>Rp.{ele.discount.toLocaleString() || "N/A"}</h1> 
                        </Box>
                    </Box> :
                    <Box className={`price ${ele.inbound && ele.inbound.retail_price !== ele.retail_price && 'inbound'}`}>
                        <Box className='title-price'>
                            <h1>Rp.{ele.retail_price.toLocaleString() || "N/A"}</h1> 
                        </Box> 
                    </Box> }
                </Box>
                { ele.inbound && (ele.inbound.retail_price !== ele.retail_price || ele.inbound.discount !== ele.discount) &&
                <Box>
                    { ele.discount_type === "PERCENTAGE" && ele.discount !== 0  ?  
                    <Box className='price' pt={0.5}>
                        <Box className='discounts'>
                           
                        </Box>
                        <Box className='title-discount'>
                            <h2>Rp. {ele.inbound.retail_price.toLocaleString() || "N/A"}</h2> 
                        </Box> 
                        { ele.extraDiscountType === "AMOUNT" ? 
                            <Box className='title-price'>
                                <h1>Rp. {(ele.inbound.retail_price - ((ele.inbound.discount / 100) * ele.inbound.retail_price + ele.inbound.extraDiscount)).toLocaleString()}</h1> 
                            </Box> :
                            <Box className='title-price'>
                            <h1>Rp. {(ele.inbound.retail_price - (((ele.inbound.discount + ele.inbound.extraDiscount) / 100) * ele.inbound.retail_price)).toLocaleString()}</h1> 
                        </Box> }
                    </Box> 
                    :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                    <Box className='price' pt={0.5}>
                        <Box className='discounts'>
                        </Box> 
                        <Box pl={2} className='title-discount'>
                            <h2>Rp.{ele.inbound.retail_price.toLocaleString() || "N/A"}</h2>
                        </Box> 
                        <Box className='title-price'>
                            <h1>Rp.{(ele.inbound.retail_price - (ele.inbound.discount + ele.inbound.extraDiscount)).toLocaleString() || "N/A"}</h1> 
                        </Box>
                    </Box> 
                    :  ele.discount_type === "SPECIAL" ? 
                    <Box className='price' pt={0.5}>
                        <Box className='discounts'>
                        </Box> 
                        <Box className='title-discount'>
                        </Box> 
                        <Box pl={3} className='title-price'>
                            <h1>Rp.{ele.inbound.discount.toLocaleString() || "N/A"}</h1> 
                        </Box>
                    </Box> :
                    <Box className='price'>
                        <Box className='title-price'>
                            <h1>Rp.{ele.inbound.retail_price.toLocaleString() || "N/A"}</h1> 
                        </Box> 
                    </Box> }
                </Box> }

                <Box className={`bottom-content ${ele.inbound && (ele.inbound.quantity !== ele.quantity || ele.inbound.discount !== ele.discount || ele.inbound.retail_price !== ele.retail_price  )  && 'inbound'}`}>
                    <Box className="left-box">
                        { update  ?
                        <Box className="input-note">
                            <input 
                                type="text" placeholder="Note" 
                                value={valueNote} onChange={(e) => setValueNote(e.target.value)} 
                                onFocus={onFocusNote} onBlur={onBlurNote} 
                            />
                        </Box> : null }
                    </Box>
                    <Box className="right-box">
                        { update ? null :
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                        <Box className='total-tax'>
                            <h2>+PPN {ele.tax.label} = Rp.{ele.total_tax.toLocaleString() || "N/A"}</h2>
                        </Box>
                        <Box className='line-divide' />
                        </>
                        }
                        </> }

                        <Box className="total-prices">
                            <h2>Rp.{(ele.total - ele.total_tax).toLocaleString() || "N/A"}</h2>
                        </Box>

                        <Box className='line-divide' />
                        <Box className={`total-qty ${ele.received && ele.received !== ele.quantity && (lastStatus === "Delivered" || lastStatus === "Completed") && 'total-qty-linethrough'}`}>
                            <h2>{ele.quantity.toLocaleString() || "N/A"} {ele.unit || "N/A"}</h2>
                        </Box>
                        { update ?
                        <>
                        <Box className='line-divide' />
                        <Box className="quantity-btn">
                            <Box className="input-qty">
                                <p>Received</p>
                                <input 
                                    type="number" 
                                    onFocus={onFocus} 
                                    onBlur={onBlur} 
                                    value={parseFloat(received).toLocaleString() || "N/A"}
                                    onChange={(e) => onChange(e.target.value)} 
                                />
                            </Box>
                        </Box> 
                        </>
                        :
                        null
                        }
                    </Box>
                </Box>

                { checkStatusExists("Inbound Item", ele.statuses) ?
                <Box className='bottom-content'>
                    <Box/>
                    <Box className="right-box">
                        { ele.discount_type === "PERCENTAGE" ?
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                            <Box className='total-tax'>
                                <h2>+PPN {ele.tax.label} = Rp.{Math.floor(totalPercentageInbound(ele) * ele.tax.value).toLocaleString()}</h2>
                            </Box>
                            <Box className='line-divide' />
                        </>
                        }
                        <Box className='total-prices'>
                            <h2>Rp.{totalPercentageInbound(ele).toLocaleString()}</h2> 
                        </Box> 
                        </>
                        :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                            <Box className='total-tax'>
                                <h2>+PPN {ele.tax.label} = Rp.{Math.floor(totalAmountInbound(ele) * ele.tax.value).toLocaleString()}</h2>
                            </Box>
                            <Box className='line-divide' />
                        </>
                        }
                        <Box className='total-prices'>
                            <h2>Rp.{totalAmountInbound(ele).toLocaleString()}</h2> 
                        </Box>
                        </>
                        :
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                            <Box className='total-tax'>
                                <h2>+PPN {ele.tax.label} = Rp.{Math.floor(totalSpecialInbound(ele) * ele.tax.value).toLocaleString()}</h2>
                            </Box>
                            <Box className='line-divide' />
                        </>
                        }
                        <Box className='total-prices'>
                            <h2>Rp.{totalSpecialInbound(ele).toLocaleString()}</h2> 
                        </Box> 
                        </> }
                        
                        { ele.inbound.quantity === ele.quantity ? null : 
                        <>
                        <Box className='line-divide' />
                        <Box className='total-qty'>
                            <h2>{(ele.inbound.quantity.toLocaleString()) || "N/A"} {ele.unit || "N/A"}</h2>
                        </Box>
                        </> }
                        <Box className='line-divide' />
                        <Box className="print-btn-note" >
                            <button onClick={() => {
                                setNote(ele.inbound.note_inbound)
                                setOpenNote(true) 
                            }} >
                                <p>View Note</p>
                            </button>
                        </Box>
                        <Box className='line-divide' />
                        { checkValidation('REJITEPURORD445923') &&
                        <Box className="print-btn-reject" >
                            <button onClick={() => dispatch(approvalItemChange({type: 'reject-item', body : ele}))}>
                                <p>Reject</p>
                            </button>
                        </Box>
                        }
                        <Box className='line-divide' />
                        { checkValidation('APPITEPURORD220764') &&
                        <Box className="print-btn-approve" >
                            <button onClick={() => dispatch(approvalItemChange({type: 'accept-item', body : ele}))}>
                                <p>Approve</p>
                            </button>
                        </Box> 
                        }
                       
                    </Box>
                </Box> : null }

                { ele.received && (lastStatus === "Delivered" || lastStatus === "Completed") ?
                <Box className='bottom-content'>
                    <Box/>
                    <Box className="right-box">
                        <Box className="received-status" >
                            <p>Received </p>
                        </Box>
                        <Box className='line-divide' />
                        <Box className='total-qty-received'>
                            <h2>{(ele.received.toLocaleString()) || "N/A"} {ele.unit || "N/A"}</h2>
                        </Box>
                    </Box>
                </Box> : null } 
            </Box>
            <DialogNote open={openNote} setOpen={setOpenNote} note={note} name="Note Item" />
        </Box>
    )
}

export default ItemCard