import { Grid, Skeleton, Stack } from '@mui/material'
import React from 'react'

const ProductCardLoading : React.FC<any> = ({loader}) => {

    return (
        <Stack mt={2}>
            { }
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                { Array.from(new Array(loader)).map((_, i : any) => (
                    <Grid item xs={12} md={6} sm={6} lg={3} xl={3} key={i}>
                        <Stack
                            sx={{
                                borderRadius: 3,
                                backgroundColor: '#fff'
                            }}
                        >   
                            <div className='image-card'>
                                <Skeleton variant="rectangular"  height={250} />
                            </div>
                            <Stack p={2} >
                                { Array.from(new Array(3)).map((_, index) => (
                                    <div className='title-address' key={index}>
                                        <Skeleton variant="text" height={25} /> 
                                    </div>
                                ))}
                                <div className='title-address'>
                                    <Skeleton variant="text" height={47} />
                                </div>
                                <div className='title-address'>
                                    <Skeleton variant="text" height={47} />
                                </div>
                            </Stack>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default ProductCardLoading