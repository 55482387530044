import { createSlice } from '@reduxjs/toolkit';
import { 
  additemToTempalte,
  addTemplateItem, clearTemplatesLocalData, createNewTemplate, 
  getOneTemplate, getTemplateLocalData, getTemplateOpen, getTemplateServerData, 
  getUpdateTemplate, getUseTemplate, removeTemplate, removeTemplatesItem, 
  removeTemplatesVendor, updateTemplate 
} from './templateReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  templates : [], 
  loaded : false,
  loaded_template : false,
  total_templates : 0,
  total_infinite : 0,
  auto_saved_value : 0,
  loading_templates : false,
  create_template : false,
  loading_create_template : false,
  update_template : false,
  loading_update_template : false,
  remove_template : false,
  loading_remove_template : false,
  use_template : false,
  loading_use_template : false,
  detail : {},
  loading_detail : false,
  edit : false, 
  loading_edit : false,
  data: [], 
  loading: true,
  add_item : false,
  type : "", 
  loading_add_item : false,
  remove_item : false,
  loading_remove_item : false,
  remove_vendor : false,
  loading_remove_vendor : false,
  saved : false,
  clear : false, 
  loading_clear : false,
  type_error : "",
  value_error : 0,
  product_id : "",
  template_open : [],
  loading_template_open : false,
  template_item : false, 
  loading_template_item : false,
};

export const templatessSlices = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    clearMessageTemplate (state) {
      state.create_template = false
      state.update_template = false
      state.remove_template = false
      state.add_item = false
      state.remove_item = false
      state.remove_vendor = false
      state.template_item = false
    },
    clearValueErrorQty (state) {
      state.value_error = 0;
      state.type_error = ""
      state.product_id = ""
    },
    clearUseTemplate (state) {
      state.use_template = false
    },
    addLengthInfiniteTemplate (state,action) {
      let existing = state.total_infinite + action.payload
      state.total_infinite = existing
    }
  },
  extraReducers: (builder) => {
    builder
      // get all purchase request server
      .addCase(getTemplateServerData.pending, (state) => {
        state.loading_templates = true;
        state.templates = [];
        state.total_templates = 0;
        state.create_template = false
        state.update_template = false
        state.remove_template = false
        state.add_item = false
        state.remove_item = false
        state.remove_vendor = false
        state.loaded_template = false;

      })
      .addCase(getTemplateServerData.fulfilled, (state, action:any) => {
        state.loading_templates = false;
        state.templates = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_templates = action.payload.data.length > 0 ? action.payload.total.row : 0;
        state.total_infinite = action.payload.data.length > 0 ? action.payload.data.length : 0;
        state.loaded_template = true;

      })
      .addCase(getTemplateServerData.rejected, (state, action : any) => {
        state.loading_templates = false;
        errorSweetAlert(action.payload)
      })

      // get detail template server
      .addCase(getOneTemplate.pending, (state) => {
        state.loading_detail = true;
        state.detail = {};
      })
      .addCase(getOneTemplate.fulfilled, (state, action:any) => {
        state.loading_detail = false;
        state.detail = action.payload
      })
      .addCase(getOneTemplate.rejected, (state, action : any) => {
        state.loading_detail = false;
        errorSweetAlert(action.payload)
      })

      // get detail template open
      .addCase(getTemplateOpen.pending, (state) => {
        state.loading_template_open = true;
      })
      .addCase(getTemplateOpen.fulfilled, (state, action:any) => {
        state.loading_template_open = false;
        state.template_open = action.payload
      })
      .addCase(getTemplateOpen.rejected, (state, action : any) => {
        state.loading_template_open = false;
        errorSweetAlert(action.payload)
      })

        // add item template
        .addCase(additemToTempalte.pending, (state) => {
          state.loading_template_item = true;
        })
        .addCase(additemToTempalte.fulfilled, (state, action:any) => {
          state.loading_template_item = false;
          state.template_item = action.payload
        })
        .addCase(additemToTempalte.rejected, (state, action : any) => {
          state.loading_template_item = false;
          errorSweetAlert(action.payload)
        })

      // get detail template server
      .addCase(getUpdateTemplate.pending, (state) => {
        state.loading_edit = true;
        state.edit = false;
      })
      .addCase(getUpdateTemplate.fulfilled, (state, action:any) => {
        state.loading_edit = false;
        state.edit = action.payload
        state.auto_saved_value = -1;
      })
      .addCase(getUpdateTemplate.rejected, (state, action : any) => {
        state.loading_edit = false;
        errorSweetAlert(action.payload)
      })

      // use template
      .addCase(getUseTemplate.pending, (state) => {
        state.loading_use_template = true;
        state.use_template = false;
      })
      .addCase(getUseTemplate.fulfilled, (state, action:any) => {
        state.loading_use_template = false;
        state.use_template = action.payload
      })
      .addCase(getUseTemplate.rejected, (state, action : any) => {
        state.loading_use_template = false;
        errorSweetAlert(action.payload)
      })

      // create new purchase
      .addCase(createNewTemplate.pending, (state) => {
        state.loading_create_template = true;
      })
      .addCase(createNewTemplate.fulfilled, (state, action:any) => {
        state.loading_create_template = false;
        state.create_template = action.payload;
      })
      .addCase(createNewTemplate.rejected, (state, action : any) => {
        state.loading_create_template = false;
        errorSweetAlert(action.payload)
      })

      // update purchase
      .addCase(updateTemplate.pending, (state) => {
        state.loading_update_template = true;
      })
      .addCase(updateTemplate.fulfilled, (state, action:any) => {
        state.loading_update_template = false;
        state.update_template = action.payload;
      })
      .addCase(updateTemplate.rejected, (state, action : any) => {
        state.loading_update_template = false;
        errorSweetAlert(action.payload)
      })

      // remove purchase
      .addCase(removeTemplate.pending, (state) => {
        state.loading_remove_template = true;
      })
      .addCase(removeTemplate.fulfilled, (state, action:any) => {
        state.loading_remove_template = false;
        state.remove_template = action.payload;
      })
      .addCase(removeTemplate.rejected, (state, action : any) => {
        state.loading_remove_template = false;
        errorSweetAlert(action.payload)
      })


      // get purchase request local data
      .addCase(getTemplateLocalData.pending, (state) => {
        state.loading = true;
        state.create_template = false
        state.update_template = false
        state.remove_template = false
        state.add_item = false
        state.remove_item = false
        state.remove_vendor = false
        state.clear = false
        state.edit = false
        state.type_error = "";
        state.value_error = 0
        state.product_id = ""
        state.loaded = false;
      })
      .addCase(getTemplateLocalData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload;
        state.loaded = true;
        state.auto_saved_value = state.auto_saved_value + 1
      })
      .addCase(getTemplateLocalData.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

      // add item
      .addCase(addTemplateItem.pending, (state) => {
        state.loading_add_item = true;
        state.value_error = 0;
        state.type_error = ""
        state.product_id = ""
      })
      .addCase(addTemplateItem.fulfilled, (state, action:any) => {
        state.loading_add_item = false;
        state.add_item = action.payload.data;
        state.type = action.payload.type;
        state.type_error = action.payload.type_error;
        state.value_error = action.payload.value_error;
        state.product_id = action.payload.product_id;
      })
      .addCase(addTemplateItem.rejected, (state, action : any) => {
        state.loading_add_item = false;
        errorSweetAlert(action.payload)
      })

      // remove item
      .addCase(removeTemplatesItem.pending, (state) => {
        state.loading_remove_item = true;
      })
      .addCase(removeTemplatesItem.fulfilled, (state, action:any) => {
        state.loading_remove_item = false;
        state.remove_item = action.payload;
      })
      .addCase(removeTemplatesItem.rejected, (state, action : any) => {
        state.loading_remove_item = false;
        errorSweetAlert(action.payload)
      })

      // remove vendor
      .addCase(removeTemplatesVendor.pending, (state) => {
        state.loading_remove_vendor = true;
      })
      .addCase(removeTemplatesVendor.fulfilled, (state, action:any) => {
        state.loading_remove_vendor = false;
        state.remove_vendor = action.payload;
      })
      .addCase(removeTemplatesVendor.rejected, (state, action : any) => {
        state.loading_remove_vendor = false;
        errorSweetAlert(action.payload)
      })

      // remove all purchase data local
      .addCase(clearTemplatesLocalData.pending, (state) => {
        state.loading_clear = true;
      })
      .addCase(clearTemplatesLocalData.fulfilled, (state, action:any) => {
        state.loading_clear = false;
        state.clear = action.payload;
      })
      .addCase(clearTemplatesLocalData.rejected, (state, action : any) => {
        state.loading_clear = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { clearMessageTemplate, clearValueErrorQty, clearUseTemplate, addLengthInfiniteTemplate } = templatessSlices.actions;
export default templatessSlices.reducer;
