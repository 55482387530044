import { Box } from '@mui/material'
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../components/TableData';
import React from 'react'


const TableProductList : React.FC<any> = ({
    dataProducts
}) => {

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'Product',
            selector: row => row.name,
        },
        {
            name: 'Discount Type',
            cell: (row) => (
                <p>{row.discount.label}</p>
            ),
        },
        {
            name: 'Discount Value',
            cell: (row) => (
                <Box>
                    { row.discount.label === 'PERCENTAGE' ? 
                    <p>{row.discount.value}%</p> :
                    <p>Rp. {row.discount.value.toLocaleString()}</p> }
                </Box>
            ),
        },
        
    ];

    return (
        <Box sx={{pt:1}}>
            <DataTableBase 
                columns={columns}
                data={dataProducts}
                noDataComponent="There is no product discount list for this Buyer"
            />
        </Box>
    )
}

export default TableProductList
