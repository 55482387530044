import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Stack, Box, Skeleton } from '@mui/material';
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'
import ButtonCreate from '../../../../components/ButtonCreate';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchBuyer } from '../redux/vendorListsSlice';
import { postPreferredBuyerList } from '../redux/vendorListsReducer';
import { RootState } from '../../../../app/store';
import DataNotFound from '../../../../components/DataNotFound';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogCreation : React.FC<any> = ({ 
  open, setOpen, name, valueSearch,
  search_buyer, setValueSearch, loading_search,
  hideFound

}) => {

    const dispatch = useDispatch()
    
    const { 
      create, update, bulk_upload
    } = useSelector((state : RootState) => state.preferred)

    const [inputSearch, setInputSearch] = useState("");
    const [companySetting, setCompanySetting] = useState<any>({
      initial_discount : "",
      payment_term : "",
      product : [],
      status : "ACTIVE",
    });


    function handleClose () {
        setOpen(false);
        setValueSearch("")
        setInputSearch("")
        setCompanySetting({
          ...companySetting,
          initial_discount : "",
          payment_term : "",
          product : [],
          status : "ACTIVE",
        })
        dispatch(resetSearchBuyer())
    };


    function onSearch(e:any) {
      e.preventDefault()
      setValueSearch(inputSearch)
    }

    function onClickSubmit(e:any) {
        let body = {
          preferred : "null",
          company : search_buyer.length && search_buyer[0]._id,
          initial_discount : 0,
          payment_term : {
            value : 0,
            label : `0 Days`,
          },
          product : [],
          status : "REQUESTED",
          down_payment: 0,
          credit_limit: {
            isActive: false,
            amount: 0
          },
          minimum_total_order: {
            isActive: false,
            amount: 0
          },
        }
        dispatch(postPreferredBuyerList(body))
    }


    React.useEffect(() => {
      if(create || update || bulk_upload) {
          handleClose()
          dispatch(resetSearchBuyer())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create, update, bulk_upload]);

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{name}</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className="container-content-preferred">
            <Box className="container-search">
              <form onSubmit={onSearch}>
                <Box className='search-box'>
                  <input 
                      placeholder="Search company by code, business name, legal name ..."
                      onChange={(e) => setInputSearch(e.target.value)}
                      value={inputSearch}
                  />
                  <Box>
                    { valueSearch === "" ? 
                    <Box className='notyping'>
                        <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                    </Box>
                    :
                    <Box className='typing'>
                        <img 
                            alt='removeimg' className='removeimg' src={removeimg} 
                            onClick={() => {
                                setInputSearch("")
                                setValueSearch("")
                            }}
                        />
                        <Box className='divider-line'/>
                        <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                    </Box> }
                  </Box>
                </Box>
              </form>
            </Box> 

          
            { loading_search ? 
            <Box className="card-content">
              <Box p={0.5}>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={310} sx={{ borderRadius: 3 }}/>
              </Box>
            </Box>
            :
            <Box className="card-content">
              { search_buyer.length === 0 ? 
                <DataNotFound 
                  message="Company not found."
                  caption="Please search company by code, business name or legal name."
                />
                :
                <Box>
                  { hideFound ? null :
                  <Box mb={2}><p>Choose company you want to add become preferred buyer.</p></Box> }
                  {search_buyer.map((item:any, index:number) => (
                    <Box my={1} className='card-company' key={index}> 
                      <Stack flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                        <Box>
                          <Box className='top-header'>
                            <h2>{item.legalName}</h2>
                          </Box>
                          <Box pt={0.5} className='code'>
                            <p>Code : {item.code}</p>
                          </Box>
                        </Box>
                        <Box py={1}>
                            <ButtonCreate 
                              name="Request"
                              type="button"
                              onClick={onClickSubmit}
                            />
                        </Box>
                      </Stack>
                    </Box>
                  ))}
                </Box> }
            </Box> }
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default DialogCreation;