import { createSlice } from '@reduxjs/toolkit';
import { getAllBuyerCompany, getRoleBuyerCompany } from './reducers/selectCompanyReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  data : [],
  loading: false,
  roles : [],
  loading_roles : false,
  login_as_buyer : false,
  loading_as_buyer: false
};

export const getAllBuyerLists = createSlice({
  name: 'selec-company',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get buyer list
      .addCase(getAllBuyerCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBuyerCompany.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_data = action.payload.data.length > 0 ? action.payload.total.row : 0;

      })
      .addCase(getAllBuyerCompany.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })
      
      // get buyer list by search
      .addCase(getRoleBuyerCompany.pending, (state) => {
        state.loading_roles = true;
        state.roles = [];
      })
      .addCase(getRoleBuyerCompany.fulfilled, (state, action:any) => {
        state.roles = action.payload;
        state.loading_roles = false;

      })
      .addCase(getRoleBuyerCompany.rejected, (state, action : any) => {
        state.loading_roles = false;
        errorSweetAlert(action.payload)
      })
  },
});


export default getAllBuyerLists.reducer;
