import { Box } from '@mui/material'
import React from 'react'

const ButtonCreate :React.FC<any> = ({ name, onClick, disabled, outlined, color, type }) => {
  const styles = {
    background: `${color} !important`,
  }
  return (
    <Box sx={styles} className={outlined ? 'button-create-outlined' : 'button-create'}>
        <button onClick={onClick} disabled={disabled} type={type}>
            <p>{name}</p>
        </button>
    </Box>
  )
}

export default ButtonCreate