import { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    CircularProgress,
    Alert
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { signInAction } from '../redux/authReducers';
import logo from '../../../assets/img/logo.png'
import logocolor from '../../../assets/img/logos.png'
import { Link } from 'react-router-dom';
import { IAuthState } from '../types';


const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
  })
  .required();


const Login = () => {
  const dispatch = useDispatch()
  const { signin, isRegistered, loading_signin, error_signin } = useSelector((state : RootState) => state.auth )

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IAuthState>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (data: IAuthState): void => {
    let params = {
      email : data.email,
      password : data.password,
      shadow : false
    }
    dispatch(signInAction(params))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  useEffect(() => {
    if(signin && !isRegistered) {
      setTimeout(() => {
        window.location.href = '/company-registration'
      },200);
    } else if (signin && isRegistered) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      },200);
    }
    // eslint-disable-next-line
  }, [signin, isRegistered]);


  return ( 
    <div className='container-auth'>
      <div className='auth-box'>
        <div className='left-img'>
            <img alt="logo" src={logo} />
            <h1>Pasar Pemesanan</h1>
            <h2>Platform that provides services to <br/> ease the procurement process for the business.</h2>
        </div>
        <div className='right-content'>
            <div className='form-auth-container'>
                <img alt="logo" src={logocolor} />
                <h3>Hello Again!</h3>
                <h6>Please enter your credential to sign in to Buyer Platform.</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-group'>
                      <Box><h2>Email Address</h2></Box>
                      <Box>
                        <TextField
                          error={!!errors.email}
                          helperText={errors.email && errors.email.message}
                          {...register('email', { required: true })}  
                          margin="dense"
                          fullWidth
                          id="email"
                          placeholder="your@email.com"
                          name="email"
                          autoComplete="email"
                          size='small'
                        />
                      </Box>
                      <Box mt={1}><h2>Password</h2></Box>
                      <Box>
                        <TextField
                          error={!!errors.password}
                          helperText={errors.password && errors.password.message}
                          {...register('password', { required: true })}
                          margin="dense"
                          fullWidth
                          name="password"
                          placeholder="********" 
                          id="password"
                          autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment : (
                              <InputAdornment position="end"> 
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          size='small'
                        />
                      </Box>
                      <Box className='forgot-text'>
                          <Link to="/forgot-password"><p>Forgot Password?</p></Link>
                      </Box>

                      { error_signin === null ? null :
                      <Box pt={1}>
                          <Alert severity="error">{error_signin}</Alert>
                      </Box> 
                      }
                      <Box className='button-auth'>
                              <button type='submit' disabled={loading_signin}>
                              <p> Sign In </p>
                              { loading_signin && <CircularProgress size={20} color="inherit" style={{ marginLeft: 10}} /> }
                              </button>
                          </Box>
                      </div>
                  </form>
              </div>
              <div className='footer-auth'>
                  <p>Don't have an account?  <Link to="/register">Sign Up</Link></p>
              </div>
          </div>
      </div>
  </div>
    )
}

export default Login;
