import { Alert, AlertTitle, Grid, Stack } from '@mui/material'
import { Box } from '@mui/material'
import {userCredentials} from './../../../utilities/config'
import CardStatistic from './components/CardStatistic'
import { NavLink,  useHistory } from 'react-router-dom'
import { checkValidation } from '../../../utilities/userFeaturesValidation'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useEffect } from 'react'
import cargo from '../../../assets/img/dashboard/cargo.png'
import cart from '../../../assets/img/dashboard/cart.png'
import order from '../../../assets/img/dashboard/order.png'
import payableimg from '../../../assets/img/dashboard/payable.png'
import { getAccountPayable, getTotalPurchases } from '../finance/invoices/redux/invoicesReducers'
import { getStatusCount } from '../orders/redux/ordersReducers'
import { getPurchaseServerData } from '../purchase_request/redux/purchasesReducers'
import ButtonCreate from '../../../components/ButtonCreate'

function HomeDashboard() {
    const history = useHistory()

    const dispatch = useDispatch()
    const { payable, loading_payable, purchases, loading_total_purchases } = useSelector((state : RootState) => state.invoice)
    const { status, loading_status } = useSelector((state : RootState) => state.orders)
    const { loading_purchases, total_purchases } = useSelector((state : RootState) => state.purchases)

    const username = userCredentials === null ? "" : userCredentials.fullname
    const company = userCredentials === null ? "" : userCredentials.company.legalName

    useEffect(() => {
        if(userCredentials.company.isVerified) {
            let param = {
                from : '',
                to : ''
            }
            dispatch(getStatusCount(param))
            dispatch(getAccountPayable())
            const params = {
                limit : 1,
                skip : 0 ,
            }
            dispatch(getPurchaseServerData(params))
            dispatch(getTotalPurchases())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCredentials]);

    const getDataCount = (name:string) => {
        const data = status.filter((item:any) => item._id === name)
        return (data.length && data[0].count) || 0
    }
    
    return (
        <Box m={2}> 
            { !userCredentials.company.isVerified ? 
            <Box>
                 <Box>
                    <h3>Welcome back, {username}</h3>
                    <Box pt={1} fontWeight="400">{company}</Box>
                </Box>
                <Box pt={2}>
                    <Alert severity="success">
                    <AlertTitle>Information</AlertTitle>
                        We are still reviewing your company information for verification process. Some features may not be available until your account is verified. <br/> Please wait for our confirmation. Thank you for your patience. 
                    </Alert>
                </Box>
            </Box>
            :
            <Box >
                <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" rowGap={1}>
                    <Box>
                        <h3>Welcome back, {username}</h3>
                        <Box pt={1} fontWeight="400">{company}</Box>
                    </Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', columnGap: 1}}>
                        { checkValidation('CREPURREQ486033') &&
                        <ButtonCreate 
                            name="Create New PR"
                            onClick={() => {
                                history.push({
                                    pathname: "/dashboard/purchase-requests",
                                    search: `?page=create`, 
                                })
                            }}
                        /> }
                        { checkValidation('CRETEM101020') &&
                        <ButtonCreate 
                            name="Create New Template"
                            outlined={true}
                            onClick={() => {
                                history.push({
                                    pathname: "/dashboard/templates",
                                    search: `?page=create`, 
                                })
                            }}
                        />}
                    </Box> 
                </Stack>
                <Stack sx={{pt:3}} >
                    <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <NavLink to="/dashboard/finance/invoice">
                            <CardStatistic 
                                label="Account Payable"
                                value={payable.length && payable[0].totalAmount}
                                loading={loading_payable}
                                currency={true}
                                background="#fff"
                                src={payableimg}
                            />
                            </NavLink>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <NavLink to="/dashboard/finance/invoice">
                            <CardStatistic 
                                label="Total Purchases"
                                value={purchases.total}
                                loading={loading_total_purchases}
                                currency={true}
                                background="#fff"
                                src={order}
                            />
                            </NavLink>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <NavLink to="/dashboard/purchase-requests">
                            <CardStatistic 
                                label="Total PR"
                                value={total_purchases}
                                loading={loading_purchases}
                                currency={false}
                                background="#fff"
                                src={cart} 
                            />
                            </NavLink>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <NavLink to="/dashboard/purchase-orders">
                            <CardStatistic 
                                label="Active PO"
                                value={getDataCount('Active')}
                                loading={loading_status}
                                currency={false}
                                background="#fff"
                                src={cargo}
                            />
                            </NavLink>
                        </Grid>
                    </Grid>
                </Stack>
            </Box> }
        </Box>
    )
}

export default HomeDashboard
