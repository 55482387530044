import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addeNoteToVendorItem } from '../redux/purchasesReducers';
import { RootState } from '../../../../app/store';
import { clearSnackBarValue } from '../redux/purchasesSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const NoteDialog : React.FC<any> = ({ open, setOpen, vendor, note }) => {

    const dispatch = useDispatch()
    const {  added_note } = useSelector((state: RootState) => state.purchases)
    function handleClose () {
        setOpen(false);
    };

    const [value, setValue] = React.useState('');
    const [errorNote, setErrorNote] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorNote(false)
        setValue(event.target.value);
    };
    
    const onClickSave = () => {
      let params = {
        note: value,
        name : vendor
      }
      dispatch(addeNoteToVendorItem(params))
    }

    React.useEffect(() => {
      if(note !== "" || note !== null) {
        setValue(note)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [note]);

    React.useEffect(() => {
      if(added_note) {
        setOpen(false)
       setTimeout(() => {
        dispatch(clearSnackBarValue())
       }, 3000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [added_note]);


    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{value === "" || note === null ? "Add" : "Edit"} Note</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <Box> 
                <TextField 
                    fullWidth
                    placeholder='Note'
                    margin='dense' 
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                />
                { errorNote && <Typography color="error">Note is required</Typography> }
            </Box> 
        </DialogContent>
        <DialogActions>
          <Button size="small" color="error" variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            color="primary"
            size="small" 
            variant='contained' 
            onClick={() => onClickSave()}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default NoteDialog;