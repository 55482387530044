import { Stack, Box, Button, TextField, Grid } from '@mui/material'
import {useState, useEffect} from 'react'
import { userCredentials } from '../../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { updateContactCompany } from '../reducers/profilesReducers';
import PhoneInput from 'react-phone-number-input'
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateContactSnackBar } from '../profileSlice';
import StarRequired from '../../../../components/StarRequired';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const Contact : React.FC<any> = ({ profile }) => {

    const dispatch = useDispatch()
    const { update_contact, loading_update_contact } = useSelector((state : RootState) => state.profile)

    const [erroTechnicalNumber, setErroTechnicalNumber] = useState<boolean>(false);
    const [errorHotlineNumber, setErrorHotlineNumber] = useState<boolean>(false);

    const [companyForm, setCompanyForm] = useState<any>({
        name_hotline : "",
        phone_hotline : "",
        name_mobile : "",
        phone_mobile : ""
    });

    const onChangeFormValue = (e : any) => {
        const { name, value } = e.target 
        setCompanyForm({...companyForm, [name] : value })
    }

    useEffect(() => {
        if(profile) {
            setCompanyForm({...companyForm,
                name_hotline : profile.contactUs.length === 0 ? "" : profile.contactUs[0].phone[0].label,
                phone_hotline : profile.contactUs.length === 0 ? "" : profile.contactUs[0].phone[0].number,
                name_mobile : profile.contactUs.length === 0 ? "" : profile.contactUs[0].phone[1].label,
                phone_mobile : profile.contactUs.length === 0 ? "" : profile.contactUs[0].phone[1].number,
            })
        }
        // eslint-disable-next-line
    }, [profile]);


    const onClickSave = () => {
        const body = {
            id : profile.contactUs[0]._id,
            data : {
                "company": profile._id,
                "email": profile.contactUs.length === 0 ? userCredentials.email : profile.contactUs[0].email,
                "phone": [
                    {
                        "label": companyForm.name_hotline,
                        "number": companyForm.phone_hotline,
                        "iconUrl": "Http",
                        "onWhatsApp": false,
                    },
                    {
                        "label": companyForm.name_mobile,
                        "number": companyForm.phone_mobile,
                        "iconUrl": "Http",
                        "onWhatsApp": false,
                    }
                ]
            }
        }
        dispatch(updateContactCompany(body))
    }

    useEffect(() => {
        if(update_contact) {
            setTimeout(() => {
                dispatch(updateContactSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [update_contact]);


    return (
        <Stack mt={2}>
            <Grid container spacing={2} >
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box pt={1}><h4>Contact Name Hotline <StarRequired/></h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        type="text"
                        placeholder='John Doe'
                        value={companyForm.name_hotline}
                        onChange={onChangeFormValue}
                        name="name_hotline"
                        size="small"
                        required
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box pt={1}><h4>Hotline Number <StarRequired/></h4></Box>
                    <Box>
                        <PhoneInput
                            placeholder="Enter hotline number"
                            value={companyForm.phone_hotline}
                            onChange={(e) => {
                                setErrorHotlineNumber(false)
                                setCompanyForm({...companyForm, phone_hotline : e})
                            }}
                            defaultCountry="ID"
                        />
                    </Box>
                    { errorHotlineNumber ? <div className="error-p"><p>Hotline Number is required</p></div> : null }
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box pt={1}><h4>Contact Name Mobile Number <StarRequired/></h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        type="text"
                        value={companyForm.name_mobile}
                        onChange={onChangeFormValue}
                        name="name_mobile"
                        placeholder='Mikel Doe'
                        size="small"
                        required
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box pt={1}><h4>Mobile Number <StarRequired/></h4></Box>
                    <Box >
                        <PhoneInput
                            placeholder="Enter mobile number"
                            value={companyForm.phone_mobile}
                            onChange={(e) => { 
                                setErroTechnicalNumber(false)
                                setCompanyForm({...companyForm, phone_mobile : e})
                            }}
                            defaultCountry="ID"
                        />
                    </Box>
                    {erroTechnicalNumber ? <div className="error-p"><p>Mobile Number is required</p></div> : null }
                </Grid>
            </Grid>
            { checkValidation('UPCONCOM972341') &&
            <Stack sx={{my:3}} flexDirection="row" justifyContent="flex-end">
                <Button 
                    variant='contained'
                    onClick={onClickSave}
                    disabled={loading_update_contact}
                >{loading_update_contact ? "Loading.." : "Save"}</Button>
            </Stack>
            }
            <SnackBarAlert message={`Success update Contact Company.`} initialState={update_contact} />
        </Stack>
    )
}

export default Contact
